import React from "react"
import "./container-app.css"

export function ContainerApp() {
  return (
    <div>
      <a
        href="https://intelbras.com/pt-br/aplicativo-de-gerenciamento-dos-roteadores-da-linha-wi-force-app-meu-wi-fi-intelbras"
        target="_blank"
        rel="noreferrer"
      >
        <img
          alt="app_menu_wifi"
          src="/assets/img/app_meu_wifi.png"
          width={"5%"}
          className="app-mobile-img"
        />
      </a>
    </div>
  )
}
