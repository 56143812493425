import { backendStatus } from "@services/backend/backend"
import { addGateway, setGateways, updateGatewayInfo } from "./gateway-slice"
import { TYPE_GATEWAY } from "@features/devices/common/constants"

export const associateGateway = async (
  backend,
  user,
  toastContext,
  dispatch,
  gateway
) => {
  let result = await backend.create("device", {
    id: gateway.id,
    token: gateway.token,
    alias: gateway.name,
    description: gateway.description,
    assoc: user.activeGroup,
    type: TYPE_GATEWAY
  })
  if (result.status !== backendStatus.SUCCESS) {
    console.log(result.content)
    toastContext.addToast({
      title: result.content.body,
      status: "error",
    })
  } else {
    toastContext.addToast({
      title: "Operação efetuada com sucesso!",
      status: "success",
    })
    dispatch(addGateway(result.content))
  }
}

export const dissociateGateway = async (
  backend,
  user,
  toastContext,
  dispatch,
  gwId
) => {
  let result = await backend.delete(`device?type=gateway"`, {
    id: gwId,
    org: user.activeGroup,
    email: user.user.Email,
  })
  if (result.status !== backendStatus.SUCCESS) {
    toastContext.addToast({
      title: result.content.body,
      status: "error",
    })
  } else {
    toastContext.addToast({
      title: "Operação efetuada com sucesso!",
      status: "success",
    })
    // dispatch(removeGateway({ id: gwId }));
  }
}

export const retrieveGateways = async (
  backend,
  user,
  toastContext,
  dispatch
) => {
  let result = await backend.retrieveFresh(
    `devices?org=${user.activeGroup}&pagenumber=1&type=gateway`
  )
  if (result.status !== backendStatus.SUCCESS) {
    toastContext.addToast({
      title: result.content.body,
      status: "error",
    })
  } else {
    const data = result.content.data
    dispatch(setGateways(data.gateways))
  }
}

export const checkGatewayStatus = async (
  backend,
  user,
  toastContext,
  dispatch
) => {
  let result = await backend.retrieveFresh(
    `device/gateway/status/${user.activeGroup}`
  )
  if (result.status !== backendStatus.SUCCESS) {
    toastContext.addToast({
      title: result.content.body,
      status: "error",
    })
  } else {
    dispatch(setGateways(result.content))
  }
}

export const editGateway = async (backend, user, toastContext, gateway, dispatch) => {
  let result = await backend.update(`device`, {
    ...gateway,
    org: user.activeGroup,
  })
  if (result.status !== backendStatus.SUCCESS) {
    toastContext.addToast({
      title: result.content.body,
      status: "error",
    })
  } else {
    toastContext.addToast({
      title: "Operação efetuada com sucesso!",
      status: "success",
    })
    dispatch(updateGatewayInfo(result.content))
  }
}
