import { backendStatus } from "@services/backend/backend"
import { removeTab, setDevices } from "./device-slice"

export const fetchDevices = async (
    backend,
    user,
    toastContext,
    dispatch,
    pageNumber,
    type,
    status,
    pagesize
) => {
    let result = await backend.retrieveFresh(
        `devices?pagenumber=${pageNumber}&type=${type}&status=${status}&org=${user.activeGroup}&pagesize=${pagesize}`
    )
    if (result.status !== backendStatus.SUCCESS) {
        toastContext.addToast({
            title: "Problema ao retornar devices",
            status: "error",
        })
        return []
    }
    dispatch(setDevices(result.content.data))
    return {
        pageSize: result.content.page_size,
        totalItems: result.content.total_items,
        totalPages: result.content.total_pages,
        currentPage: result.content.current_page
    }
}

export const dissociate = async (
    backend,
    user,
    toastContext,
    dispatch,
    id
) => {
    let result = await backend.delete(`device?id=` + id)
    if (result.status !== backendStatus.SUCCESS) {
        toastContext.addToast({
            title: result.content.body,
            status: "error",
        })
    } else {
        toastContext.addToast({
            title: "Operação efetuada com sucesso!",
            status: "success",
        })
        dispatch(removeTab({ id: id }))
    }
}

export const associate = async (
    backend,
    type,
    toastContext,
    dispatch,
    id
) => {
    let result = await backend.delete(`device?type=` + type)
    if (result.status !== backendStatus.SUCCESS) {
        toastContext.addToast({
            title: result.content.body,
            status: "error",
        })
    } else {
        toastContext.addToast({
            title: "Operação efetuada com sucesso!",
            status: "success",
        })
    }
}


export const resourceDevice = async (backend, toastContext, resource, id) => {
    let result = await backend.create(`device/${resource}?id=` + id)
    if (result.status !== backendStatus.SUCCESS) {
        toastContext.addToast({
            title: result.content.body,
            status: "error",
        })
    } else {
        toastContext.addToast({
            title: "Operação efetuada com sucesso!",
            status: "success",
        })
    }
}


export const getDeviceById = async (backend, toastContext, id) => {
    let result = await backend.retrieveFresh("device?id=" + id)
    if (result.status !== backendStatus.SUCCESS) {
        toastContext.addToast({
            title: result.content.body,
            status: "error",
        })
        return {}
    }
    return result.content
}
