import { MdSettings } from "react-icons/md"
import { CardTool } from "../card-tool"
import { ToolLink } from "../tool-link"

export function CustomFirmwareOnu() {
  return (
    <ToolLink href={"http://customize-pon.apps.intelbras.com.br/"}>
      <CardTool title={"Customização de firmware de ONUs/ONTs"}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img
            style={{ width: "55%", height: "50%" }}
            src="/assets/img/devices/WiFiber 121 AC.png"
            alt="WiFiber Device"
          />
          <MdSettings
            size={70}
            color={"#00a335"}
            style={{ marginTop: "35%", marginLeft: "-14%" }}
          />
        </div>
      </CardTool>
    </ToolLink>
  )
}
