import {
    Button,
    Dropdown,
    DropdownMenu,
    Input,
    InputIconInside,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
} from "@conecte-se/ui-components-reactjs"
import { InputText } from "@features/devices/components/inputs"
import { OperationMode } from "@features/devices/components/operation-mode"
import { useContext, useEffect, useState } from "react"
import { MdArrowDropDown, MdArrowDropUp } from "react-icons/md"
import { BackendContext } from "@services/backend/backend"
import { ToastContext } from "src/context/ToastContext"
import { UserContext } from "src/context/UserContext"
import {
    requiredValue,
    validateIP,
    verificaGatewayId,
} from "src/common/validators"
import { updateOlt } from "../../../redux/olt/olt-actions"
import { getDeviceById } from "@features/devices/redux/deviceTab/device-actions"

const EditOlt = ({ id, close, allowMode = false }) => {
    const [olt, setOlt] = useState();
    const user = useContext(UserContext)
    const backend = useContext(BackendContext)
    const toastContext = useContext(ToastContext)
    const [errors, setErrors] = useState({})
    const [dropdownOpened, setDropdownOpened] = useState(false)
    const [divHeight, setDivHeight] = useState("auto")
    const [mode, setMode] = useState()
    const [step, setStep] = useState(1)
    const [pons, setPons] = useState([])
    const [configVlan, setConfigVlan] = useState(["ETH1", "ETH1", "ONU", "ETH1"])
    const [indexVlan, setIndexVlan] = useState("1000")
    const [uplinkAuto, setUplinkAuto] = useState(false)
    const [title, setTitle] = useState("Editar OLT")
    const [btnText, setBtnText] = useState("CONTINUAR")


    useEffect(() => {
        const fetchInfo = async () => {
            try {
                const deviceResult = await getDeviceById(backend, toastContext, id);
                setOlt(deviceResult);
                setMode(deviceResult.operation_mode)
            } catch (error) {
                console.error("Failed to fetch device info:", error);
            }
        };
        fetchInfo();
    }, []);
    function toggleDropdown() {
        setDropdownOpened(() => !dropdownOpened)
        if (!dropdownOpened) {
            setDivHeight(
                olt?.available_operation_modes
                    ? `${350 + 43 * olt?.available_operation_modes?.length}px`
                    : "auto"
            )
        } else {
            setDivHeight("auto")
        }
    }

    const clearInputField = (property) => {
        setOlt({ ...olt, [property]: "" })
    }

    const handleChange = (e) => {
        setOlt({
            ...olt,
            [e.target.name]: e.target.value,
        })
    }


    const handleError = (name, error) => {
        if (error) {
            setErrors({ ...errors, [name]: error })
        } else {
            const { [name]: removed, ...rest } = errors
            setErrors(rest)
        }
    }
    const handleEditOlt = () => {
        if (mode === olt?.operation_mode || step >= 2) {
            const configsOperationMode = {
                operation_mode: mode,
                pons: pons,
                config_vlan: configVlan,
                index_vlan: indexVlan,
                uplink_auto: uplinkAuto,
            }
            updateOlt(backend, toastContext, olt, configsOperationMode)
            close()
        } else {
            setStep((prevStep) => prevStep + 1)
            setBtnText("Confirmar")
        }
        handleTitle()
    }

    const handleBack = () => {
        if (step === 1) {
            close()
        } else {
            setStep((prevStep) => prevStep - 1)
            setBtnText("Continuar")
        }
        setTitle("Editar OLT")
    }

    function handleTitle() {
        let newTitle = "Editar OLT"

        if (mode === "MANUALLY") {
            newTitle = "Configurando para modo Manual"
        } else if (mode === "VLANPORPON") {
            newTitle = "Configurando para modo Vlan Por Pon"
        } else if (mode === "AUTOSERVICE") {
            newTitle = "Configurando para modo Autoserviço"
        }

        setTitle(newTitle)
    }
    function allowModeOperation(label) {
        const lastSoftware =
            olt?.model === "OLT_8820I" ? "2.106" : "V100R001B01D002P003SP2_intelbras"
        const versions =
            olt?.model === "OLT_8820I"
                ? [lastSoftware]
                : [
                    lastSoftware,
                    "V100R001B01D002P002SP4_intelbras-1",
                    "V100R001B01D002P003SP6_intelbras",
                ]
        if (
            (olt?.operation_mode === "AUTOSERVICE" && label === "VLANPORPON") ||
            (olt?.operation_mode === "VLANPORPON" && label === "AUTOSERVICE") ||
            (olt?.operation_mode === "MANUALLY" &&
                label === "VLANPORPON" &&
                !versions.some((version) => version === olt?.software_version)) ||
            (olt?.operation_mode === "VLANPORPON" &&
                label === "MANUALLY" &&
                !versions.some((version) => version === olt?.software_version))
        ) {
            return true
        }
        return false
    }
    function showLabelDropdown(label) {
        const lastSoftware =
            olt?.model === "OLT_8820I" ? "2.106" : "V100R001B01D002P003SP2_intelbras"
        const versions =
            olt?.model === "OLT_8820I"
                ? [lastSoftware]
                : [
                    lastSoftware,
                    "V100R001B01D002P002SP4_intelbras-1",
                    "V100R001B01D002P003SP6_intelbras",
                ]
        if (allowModeOperation(label)) {
            return (
                <li style={{ display: "inline-block" }}>
                    <span>{label}</span>
                    <div style={{ color: "red", fontSize: "0.8em" }}>
                        {!versions.some((version) => version === olt?.software_version)
                            ? `É necessário atualizar a OLT para usar esse modo de operação.`
                            : `Não é possivel trocar do modo ${olt?.operation_mode} para ${label}.`}
                    </div>
                </li>
            )
        }
        return <span>{label}</span>
    }
    function Step1() {
        return (
            <>
                <div style={{ display: "flex" }}>
                    <div style={{ width: "60%" }}>
                        <Label>ID do gateway</Label>
                        <InputText
                            disabled={true}
                            id="gw_id"
                            value={olt?.gw_id}
                            label="ID do gateway"
                            length={{ max: 12 }}
                            name="gw_id"
                            validate={{
                                action: (e) => verificaGatewayId(e),
                                handleError: handleError,
                            }}
                            onChange={handleChange}
                            clearField={{
                                action: clearInputField,
                                text: "Limpar",
                            }}
                        />
                    </div>
                    <div style={{ width: "40%", marginLeft: "10px" }}>
                        <Label>Nome</Label>
                        <InputText
                            id="alias"
                            value={olt?.alias}
                            label="Nome"
                            name="alias"
                            validate={{
                                action: (e) => requiredValue(e),
                                handleError: handleError,
                            }}
                            onChange={handleChange}
                            clearField={{
                                action: clearInputField,
                                text: "Limpar",
                            }}
                        />
                    </div>
                </div>
                <div style={{ flexDirection: "column" }}>
                    <div className="mb-3">
                        <Label>Endereço IP</Label>
                        <InputText
                            id="ip"
                            name="ip"
                            value={olt?.ip}
                            label="Endereço IP"
                            onChange={handleChange}
                            validate={{
                                action: (e) => validateIP(e),
                                handleError: handleError,
                            }}
                            clearField={{
                                action: clearInputField,
                                text: "Limpar",
                            }}
                        />
                    </div>
                    <div className="mb-3">
                        <Label>Usuário</Label>
                        <InputText
                            id="username"
                            name="username"
                            value={olt?.username}
                            label="Usuário administrador"
                            onChange={handleChange}
                            clearField={{
                                action: clearInputField,
                                text: "Limpar",
                            }}
                        />
                    </div>
                    <div className="mb-3">
                        <Label>Senha</Label>
                        <InputText
                            id="password"
                            name="password"
                            value={olt?.password}
                            type="password"
                            label="Senha de acesso"
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div>
                    <Label>Porta</Label>
                    <InputText
                        type="number"
                        id="port"
                        name="port"
                        value={olt?.port}
                        label="Porta"
                        onChange={handleChange}
                        clearField={{
                            action: clearInputField,
                            text: "Limpar",
                        }}
                    />
                </div>
                {allowMode ? (
                    <div className="mb-3">
                        <Label>Modo de operação</Label>
                        <Dropdown id="dropdown-operation-mode">
                            <InputIconInside
                                expandable
                                onClick={toggleDropdown}
                                iconRight={
                                    dropdownOpened ? (
                                        <MdArrowDropUp onClick={toggleDropdown} />
                                    ) : (
                                        <MdArrowDropDown onClick={toggleDropdown} />
                                    )
                                }
                            >
                                <Input
                                    id="operation_mode"
                                    name="operation_mode"
                                    value={mode}
                                    iconInsideRight
                                />
                            </InputIconInside>
                            <DropdownMenu
                                visible={dropdownOpened}
                                itens={olt?.available_operation_modes.map((label, index) => ({
                                    disabled: allowModeOperation(label),
                                    label: showLabelDropdown(label),
                                    value: label,
                                    handler: () => {
                                        setMode(label)
                                        toggleDropdown()
                                    },
                                }))}
                                width="100%"
                            />
                        </Dropdown>
                    </div>
                ) : (
                    <></>
                )}
            </>
        )
    }

    return (
        <Modal visible={true}>
            <ModalHeader>
                <div style={{ fontSize: "18px", fontWeight: "bold" }}>{title}</div>
            </ModalHeader>
            <ModalBody height={divHeight}>
                {step === 1 || mode === "NÃO ENCONTRADO" ? (
                    Step1()
                ) : step === 2 ? (
                    <OperationMode
                        operation_mode={mode}
                        ponsState={{ pons, setPons }}
                        configVlanState={{ configVlan, setConfigVlan }}
                        indexVlanState={{ indexVlan, setIndexVlan }}
                        uplinkState={{ uplinkAuto, setUplinkAuto }}
                    />
                ) : (
                    Step1()
                )}
            </ModalBody>
            <ModalFooter>
                <div style={{ display: "flex" }}>
                    <div className="mr-4" style={{ paddingRight: "20px" }}>
                        <Button secondary onClick={handleBack}>
                            Voltar
                        </Button>
                    </div>
                    <Button
                        disabled={
                            !(olt?.ip && olt?.alias && olt?.port && olt?.gw_id && olt?.username) ||
                            Object.values(errors).some((error) => error)
                        }
                        onClick={handleEditOlt}
                        primary
                    >
                        {btnText}
                    </Button>
                </div>
            </ModalFooter>
        </Modal>
    )
}
export default EditOlt
