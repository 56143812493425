import { createContext, useState } from "react"

export const SideBarContext = createContext()

export const SideBarContextProvider = ({ children }) => {
  const [sideBarCollapsed, setSideBarCollapsed] = useState(true)
  const toggleSideBar = () => {
    setSideBarCollapsed(!sideBarCollapsed)
  }
  return (
    <SideBarContext.Provider value={{ sideBarCollapsed, toggleSideBar }}>
      {children}
    </SideBarContext.Provider>
  )
}
