import { useTheme } from "@conecte-se/ui-components-reactjs"
import { ModalLogin } from "@features/login/modal/modal-login"
import { useEffect } from "react"

export default function LoginPage() {
  const [/*theme*/, setTheme] = useTheme()

  useEffect(() => {
    setTheme("light")
    // eslint-disable-next-line
  }, [])

  return <ModalLogin />
}
