import './device-header.css'

function DeviceHeaderInfo({ children }) {
    return (
        <div className="device-info" >
          {children}
        </div>
      );
}

function Divider() {
  return (
      <div className="divider" />
    );
}


DeviceHeaderInfo.Divider = Divider
export default DeviceHeaderInfo