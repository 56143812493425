import React, { useContext, useEffect, useState } from 'react';
import './pons-olt.css';
import { MdAdd, MdClose, MdDelete, MdDeleteOutline, MdInfo, MdInfoOutline, MdMediation, MdOpenInNew, MdOutlineStart, MdRestartAlt, MdSearch } from 'react-icons/md';
import { Card, CardTable, InputIconInside, InputSearch, InputWrapper } from '@conecte-se/ui-components-reactjs';
import MinusSquare from '@assets/images/icons/minus-square';
import PlusSquare from '@assets/images/icons/plus-square';
import OntDevice from '@assets/images/icons/ont';
import { ProgressSignal } from '../../../components/progress-signal';
import { UserContext } from 'src/context/UserContext';
import { BackendContext, backendStatus } from '@services/backend/backend';
import { DeviceContext } from '@features/devices/management/manage-page'
import ModalSFPInfo from '../maintenance/modal/modal-sfp';
import { AddCpePon } from './cpe/add-cpe-pon';
import { connectionStatus, DEVICE_STATE, RESOURCE_RESTART, TYPE_ONT } from '@features/devices/common/constants';
import { addTab } from '@features/devices/redux/deviceTab/device-slice';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { dissociate, getDeviceById, resourceDevice } from '@features/devices/redux/deviceTab/device-actions';
import { ToastContext } from 'src/context/ToastContext';
import { ModalConfirm } from '@features/devices/components/modal';
import { DeviceInformation } from '../../modal/device-information';
import { ActivateCpe } from '../../modal/activate-cpe';

export const PonsOlt = () => {
    const [openPON, setOpenPON] = useState(null);
    const [sfps, setSfps] = useState([])
    const [sfpSelected, setSfpSelected] = useState()
    const user = useContext(UserContext)
    const manageCtx = useContext(DeviceContext)
    const backend = useContext(BackendContext)
    const toastContext = useContext(ToastContext)
    const [caseModal, setCaseModal] = useState()
    const oltId = window.location.pathname.split("/")[2]
    const [ontId, setOntId] = useState()
    const [pons, setPons] = useState();
    const [selectedPon, setSelectedPon] = useState()
    const [errors, setErrors] = useState({})
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const [cpe, setCpe] = useState({
        id: "",
        username: "",
        password: "",
        olt_id: '',
        port: "",
        slot: "",
        model: "",
        configuration: {
            eth: {},
            veip: [],
        },
    })

    useEffect(() => {
        if (user?.activeGroup) {
            retrieveSfps()
            retrievePons()
        }
        // eslint-disable-next-line
    }, [])
    const retrieveSfps = async () => {
        let result = await manageCtx.device.api.retrieve(`/sfp?id=${oltId}`)
        if (!(result.status !== backendStatus.SUCCESS)) {
            setSfps(result.content ? result.content : [])
        }
    }

    const retrievePons = async () => {
        let result = await manageCtx.device.api.retrieve(`/pon?id=${oltId}`)
        if (!(result.status !== backendStatus.SUCCESS)) {
            setPons(result.content ? result.content : [])
        }
    }

    const retrievePon = async (pon) => {
        let result = await manageCtx.device.api.retrieve(`/pon/group?id=${oltId}&pon=${pon}`)
        if (!(result.status !== backendStatus.SUCCESS)) {
            setSelectedPon(result.content ? result.content : [])
        }
    }



    const togglePON = (id) => {
        setOpenPON(openPON === id ? null : id);
        const ponNumber = id.match(/\d+/g);
        retrievePon(ponNumber)
    };

    function formatVlans(eths, veips) {
        if (!eths || !veips) {
            return 'empty';
        }

        const ethEntries = Object.entries(eths)?.map(([key, value]) => `eth${key}: ${value}`);
        const veipEntries = veips?.join(', ');

        return `${ethEntries?.join(', ')}, veips: ${veipEntries}`;
    }
    function getInterfaceSfp(interface_name) {
        return sfps.find((sfp) => sfp.interface_name === interface_name)
    }

    const handleDissociate = () => {
        dissociate(backend, user, toastContext, dispatch, ontId)
        closeModal()
    }

    const handleRestart = (id) => {
        resourceDevice(backend, toastContext, RESOURCE_RESTART, ontId)
        closeModal()
    }
    const modal = (caseModal) => {
        switch (caseModal) {
            case "sfp":
                return <ModalSFPInfo close={closeModal} sfp={sfpSelected} />
            case "add-cpe":
                return <AddCpePon cpeState={{ cpe, setCpe }} errorState={{ errors, setErrors }} close={closeModal} />
            case "delete":
                return [
                    <ModalConfirm
                        close={closeModal}
                        action={handleDissociate}
                        title={"Deletar dispositivo"}
                        message={`Você tem certeza que deseja deletar esse dispositivo?`}
                    />,
                ]
            case "restart":
                return [<ModalConfirm
                    close={closeModal}
                    action={handleRestart}
                    title={"Reiniciar dispositivo"}
                    message={`Você tem certeza que deseja reiniciar esse dispositivo?`}
                />,]
            case "ont-information":
                return <DeviceInformation close={closeModal} id={ontId} type={TYPE_ONT} />
            case "activate-ont":
                return [
                    <ActivateCpe close={closeModal} ontId={ontId} />
                ]
            default:
                return
        }
    }
    function closeModal() {
        setCaseModal(null)
    }
    function openModal(option) {
        setCaseModal(option)
    }

    const getProduct = async (id) => {
        const result = await getDeviceById(backend, toastContext, id);
        var newDevice = { ...result }
        dispatch(addTab(newDevice));
        navigate(`/manage/${newDevice.id}`)
    }

    return (
        <div className="container">
            {/* <Card
                width="100%"
                height="100%"
            > */}
            <div className="accordion-table">
                <table>
                    <thead className='external-thead'>
                        <tr>
                            <th className="tree-column"></th>
                            <th className="status-column"><span>Status</span></th>
                            <th><span className='header-pon'>PON</span></th>
                            <th><span className='header-pon'>Descrição</span></th>
                            <th><span className='header-pon'>CPEs</span></th>
                            <th><span className='header-pon'>Speed</span></th>
                            <th style={{ textAlign: 'center' }}><span className='header-pon'>Ações</span></th>
                        </tr>
                    </thead>
                    <tbody>
                        {pons?.map((pon) => (
                            <React.Fragment key={pon.name}>
                                <tr onClick={() => togglePON(pon.name)} className="accordion-header">
                                    <td className={`tree-column ${openPON === pon.name ? 'tree-column-opened' : ''}`}>
                                        {openPON === pon.name ? <MinusSquare size={20} /> : <PlusSquare size={20} />}
                                    </td>
                                    <td>
                                        {/* {getIconStatus(pon.status)} */}
                                        <div className={`status-pon`} style={{ backgroundColor: connectionStatus[pon.status].color }}></div>
                                    </td>
                                    <td>{pon.name}</td>
                                    <td title={pon.description} style={{ width: '20%' }}>
                                        {pon.description}
                                    </td>
                                    <td>{pon.cpes}</td>
                                    <td>{pon.speed}</td>
                                    <td style={{ textAlign: 'center' }}>
                                        <button disabled={pon.status !== 1} className='button-accordion' onClick={(e) => {
                                            e.stopPropagation();
                                            setSfpSelected(getInterfaceSfp(pon.name));
                                            openModal("sfp");
                                        }}>SFP Info</button>
                                        <button disabled={pon.status !== 1} className='button-accordion' onClick={(e) => {
                                            e.stopPropagation();
                                            setCpe({ ...cpe, olt_id: oltId, port: pon.name })
                                            openModal("add-cpe");
                                        }}>CPE +</button>
                                    </td>
                                </tr>
                                {
                                    pon.name === openPON && selectedPon && (
                                        <>
                                            <tr className="accordion-content">
                                                <td colSpan="7" className="inner-content">
                                                    <div className="inner-table-container">
                                                        <table className="inner-table">
                                                            <thead>

                                                                <tr className="header-row">
                                                                    <td className="tree-column">
                                                                        <div className="tree-line"></div>
                                                                    </td>
                                                                    <th className="status-column" style={{ padding: 0, margin: 0 }}></th>
                                                                    <th className='slot-column'>Slot</th>
                                                                    <th>ID</th>
                                                                    <th>Descrição</th>
                                                                    <th>Modelo</th>
                                                                    <th>Firmware</th>
                                                                    <th>VLANs</th>
                                                                    <th colSpan="3" className="th-search">
                                                                        <div className="search-container">
                                                                            <InputWrapper>
                                                                                <InputIconInside
                                                                                    iconLeft={<MdSearch />}
                                                                                    iconRight={<MdClose />}
                                                                                    iconRightOnAction
                                                                                >
                                                                                    <InputSearch
                                                                                        id="input-search"
                                                                                        placeholder="Placeholder"
                                                                                        value={'teste'}
                                                                                        onChange={() => { }}
                                                                                    />
                                                                                </InputIconInside>
                                                                            </InputWrapper>
                                                                        </div>
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {selectedPon?.map((slot, index) => (
                                                                    <tr
                                                                        key={slot.slot}
                                                                        className={
                                                                            index === 0 || index % 2 === 0
                                                                                ? 'first-row alternate-gray'
                                                                                : 'alternate-white'
                                                                        }
                                                                    >
                                                                        <td className="tree-column">
                                                                            <div className="tree-line-ont"></div>
                                                                        </td>
                                                                        <td style={{ padding: 0, margin: 0 }}>{<OntDevice title={connectionStatus[slot.conn_status].text} size={35} color={connectionStatus[slot.conn_status].color} status={connectionStatus[slot.conn_status].type} />}</td>
                                                                        <td>{slot.slot}</td>
                                                                        <td>{slot.id}</td>
                                                                        <td title={slot.alias} style={{ width: '15%' }}>
                                                                            {slot.alias}
                                                                        </td>
                                                                        <td>{slot.model}</td>
                                                                        <td>{slot.fw_version}</td>
                                                                        <td title={formatVlans(slot?.eth, slot?.veip)}>{formatVlans(slot?.eth, slot?.veip)}</td>
                                                                        <td>{<ProgressSignal min={-30} max={-18} signal={slot.rx} type="Rx" />}</td>
                                                                        <td>{<ProgressSignal min={-3} max={5} signal={slot.tx} type="Tx" />}</td>
                                                                        <td>
                                                                            {(slot.device_state !== DEVICE_STATE.NOT_PROVISIONED) ? (
                                                                                <>
                                                                                    <MdInfoOutline
                                                                                        title="Informação"
                                                                                        className="new-ont-tab"
                                                                                        onClick={() => { setOntId(slot.id); openModal('ont-information'); }}
                                                                                        size={25}
                                                                                    />
                                                                                    <MdRestartAlt
                                                                                        title="Reiniciar"
                                                                                        className="new-ont-tab"
                                                                                        onClick={() => { setOntId(slot.id); openModal('restart'); }}
                                                                                        size={25}
                                                                                    />
                                                                                    <MdDeleteOutline
                                                                                        title="Deletar"
                                                                                        className="new-ont-tab"
                                                                                        onClick={() => { setOntId(slot.id); openModal('delete'); }}
                                                                                        size={26}
                                                                                    />

                                                                                    {slot.supported_protocols.tr069 === true && (
                                                                                        <MdOpenInNew
                                                                                            title="Abrir ONT em nova aba"
                                                                                            className="new-ont-tab"
                                                                                            onClick={() => getProduct(slot.id)}
                                                                                            size={24}
                                                                                        />
                                                                                    )}
                                                                                </>
                                                                            ) :
                                                                                <MdAdd
                                                                                    title="Provisionar"
                                                                                    size={22}
                                                                                    onClick={() => { setOntId(slot.id); openModal('activate-ont'); }}
                                                                                    style={{ cursor: "pointer", transition: 'transform 0.3s' }}
                                                                                />
                                                                            }

                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </td>
                                            </tr>
                                        </>
                                    )
                                }
                            </React.Fragment>
                        ))}
                    </tbody>
                </table>
                {modal(caseModal)}
            </div>
            {/* </Card> */}
        </div>
    );

};
