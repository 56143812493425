import React from 'react'
import common from '@lib/midgard/form/validators/common'
import network from '@lib/midgard/form/validators/network'
import Input from '@lib/midgard/input/input'

export default function WanDNS(interfaceData, wan, setWan, wanIndex) {

    const dnsSameIP = (value, {wanIp}) => {

        if(value === '' || wanIp === ''){
            return ''
        }

        if(value === wanIp)
            return 'O dns deve ser diferente do endereço IP do roteador'

        return ''
    }

    return (
        wan[wanIndex].dns_v4.automatic ? <React.Fragment></React.Fragment> :
        <React.Fragment>
            <Input id='wan-dns1'
                name='dns1'
                label='DNS 1'
                value={wan[wanIndex].dns_v4.dns1}
                onChange={(e) => {
                    wan[wanIndex].dns_v4.dns1 = e.target.value
                    setWan([...wan])
                }}
                validators={[
                    common.required,
                    network.ip4,
                    {fn:dnsSameIP, params: {wanIp: interfaceData.ip4}}
                ]}
            ></Input>
            <Input id='wan-dns2'
                name='dns2'
                label='DNS 2'
                value={wan[wanIndex].dns_v4.dns2}
                onChange={(e) => {
                    wan[wanIndex].dns_v4.dns2 = e.target.value
                    setWan([...wan])
                }}
                validators={[
                    network.ip4,
                    {fn:dnsSameIP, params: {wanIp: interfaceData.ip4}}
                ]}
            ></Input>
            {/* <Input id='wan-dns3'
                name='dns3'
                label='DNS 3'
                value={wan[wanIndex].dns_v4.dns3}
                onChange={(e) => {
                    wan[wanIndex].dns_v4.dns3 = e.target.value
                    setWan([...wan])
                }}
                validators={[
                    network.ip4,
                    {fn: dnsSameIP, params: {wanIp: interfaceData.ip4}}
                ]}
            ></Input> */}

        </React.Fragment>
    )
}