import React, { useState, useContext } from 'react'
import { Routes, Route, useNavigate, useLocation, useParams } from "react-router-dom"
import Loading from '@lib/midgard/loading/loading'
import Tabs from '@lib/midgard/tabs/tabs'
import  { DeviceContext } from'@features/devices/management/manage-page'
import '../../manage.css'
import MonitoringWanData from './wan/wan-data'
import WifiPage from './wifi/wifi-page'
import WanConfiguration from './wan/wan-configuration'
import LanManagementPage from './lan/lan'
export const DEVICE_STATUS_OFFLINE = 2


export function getTraffic(value, unitBytes, time) {

  const BYTE_SIZE = 8
  const MEGABIT_SIZE = 1000000

  if (time === 0) return 0

  return ((value * unitBytes * BYTE_SIZE) / time) / MEGABIT_SIZE
}

export function removeStrings(input, substringsToRemove) {
  let result = input;

  for (let i = 0; i < substringsToRemove.length; i++) {
    result = result.replace(new RegExp(substringsToRemove[i], 'g'), "");
  }

  return result;
}

export function isMinimalVersion(device, baseVersion) {//baseVersion is the minimum version that the device needs to have
  let versionString = device.fw_version.split(".");
  let major = parseInt(versionString[0]);

  if (isNaN(major)) {
    return false;
  }

  let minor = parseInt(versionString[1]);
  if (isNaN(minor)) {
    return false;
  }

  // firmware = 1.11.'1'-test1.63679 or bifrost-1.11.'1' or 1.11.'1'
  let charsToRemove = ["-dev", "-test1", "-test2"];
  let patch = parseInt(removeStrings(versionString[2], charsToRemove));
  if (isNaN(patch)) {
    return false;
  }

  if (major < baseVersion[0] || (major === 1 && minor < baseVersion[1]) || (major === baseVersion[0] && minor === baseVersion[1] && patch < baseVersion[2])) {
    return false;
  }
  return true
}

// export const DeviceContext = React.createContext(null)

export default function BifrostHoManagePage() {
  const manageCtx = useContext(DeviceContext)
  const [deviceInfo] = useState(manageCtx.deviceInfo)
  const params = useParams()
  const deviceid = params.id

  let pathLocation = useLocation()
  const navigate = useNavigate()

  if (!deviceInfo /*|| !canBeManaged(device)*/)
    return <Loading show={true}></Loading>
 
  return (
    <>
      {deviceInfo.status === DEVICE_STATUS_OFFLINE ?

        <div className='offline-info'>
          <Loading show={true}></Loading>
          <span>O dispositivo está offline. Aguardando o retorno testee</span>
        </div>
        : <React.Fragment>
          <Tabs
            data={
              [
                { label: 'WAN', route: `/manage/${deviceid}/wan` },
                { label: 'Wi-Fi', route: `/manage/${deviceid}/wifi` },
                { label: 'LAN', route: `/manage/${deviceid}/lan` },
                { label: 'Manutenção', route: `/manage/${deviceid}/maintenance` }

              ]
            }
            route={pathLocation.pathname}
            activateFn={(route)=>  { navigate(route) }}
          ></Tabs>
          <Routes>
            <Route path='wan' element={<MonitoringWanData WanConfigurationComponent={WanConfiguration} />} />
            <Route path='wifi' element={<WifiPage/>}/>
            <Route path='lan' element={<LanManagementPage />} /> 
          </Routes>

        </React.Fragment>}
    </>
  )
}