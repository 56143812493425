import {
  Button,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "@conecte-se/ui-components-reactjs"
import { InputText } from "@features/devices/components/inputs"
import { editGateway } from "@features/devices/redux/gateway/gateway-actions"
import { useContext, useState } from "react"
import { useDispatch } from "react-redux"
import { BackendContext } from "@services/backend/backend"
import { ToastContext } from "src/context/ToastContext"
import { UserContext } from "src/context/UserContext"

export const EditGateway = ({ close, selected }) => {
  const dispatch = useDispatch()
  const backend = useContext(BackendContext)
  const user = useContext(UserContext)
  const toastContext = useContext(ToastContext)
  const [gateway, setGateway] = useState(selected)

  const clearInputField = (property) => {
    setGateway({ ...gateway, [property]: "" })
  }

  const handleChange = (e) => {
    setGateway({
      ...gateway,
      [e.target.name]: e.target.value,
    })
  }

  function handleDisableButton() {
    if (gateway !== null) {
      return (
        selected.name !== gateway.name ||
        selected.description !== gateway.description
      )
    }
    return true
  }

  const handleEdit = () => {
    editGateway(backend, user, toastContext, gateway, dispatch)
    close()
  }

  return (
    <Modal visible={true} width={"400px"}>
      <ModalHeader>
        <div style={{ fontSize: "18px", fontWeight: "bold" }}>
          Editar Gateway
        </div>
      </ModalHeader>
      <ModalBody>
        <div>
          <Label>Alterar nome</Label>
          <InputText
            id="name"
            name="name"
            value={gateway.name}
            label="Nome"
            onChange={handleChange}
            clearField={{
              action: clearInputField,
              text: "Limpar",
            }}
          />
        </div>
        <div>
          <Label>Alterar descrição</Label>
          <InputText
            id="description"
            name="description"
            value={gateway.description}
            label="Descrição"
            onChange={handleChange}
            clearField={{
              action: clearInputField,
              text: "Limpar",
            }}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <div style={{ display: "flex" }}>
          <div className="mr-4" style={{ paddingRight: "20px" }}>
            <Button secondary onClick={close}>
              Cancelar
            </Button>
          </div>
          <Button
            disabled={!handleDisableButton()}
            onClick={handleEdit}
            primary
          >
            Confirmar
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  )
}
