import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts"

export function ComposedBarChart({ data }) {
  return (
    <ResponsiveContainer>
      <ComposedChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 5,
          right: 10,
          left: 10,
          bottom: 0,
        }}
      >
        <CartesianGrid stroke="#f5f5f5" strokeDasharray="3 3" />
        <XAxis dataKey="name" height={30} />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="Ativos" barSize={25} fill="#00A8D5" legendType="none" />
      </ComposedChart>
    </ResponsiveContainer>
  )
}
