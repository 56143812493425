import { backendStatus } from "@services/backend/backend"
import { updateDashboard } from "./dashboard-slice"
export const retrieveDashboard = async (
  backend,
  user,
  toastContext,
  dispatch
) => {
  let result = await backend.retrieveFresh(
    `dashboard?&org=${user.activeGroup}`
  )
  if (result.status !== backendStatus.SUCCESS) {
    toastContext.addToast({
      title: result.content.body,
      status: "error",
    })
  } else {
    if (Object.keys(result.content).length !== 0) {
      result.content.forEach(({ type, payload }) => {
        dispatch(updateDashboard({ type, payload }))
      })
    }
  }
}
export const fetchNotifications = async (
  backend,
  user,
  toastContext,
  dispatch
) => {
  let result = await backend.retrieveFresh(`notifications/${user.activeGroup}`)
  if (result.status !== backendStatus.SUCCESS) {
    toastContext.addToast({
      title: "Problema ao retornar notificações",
      status: "error",
    })
    return []
  } else {
    result.content.forEach(({ type, payload }) => {
      dispatch(updateDashboard({ type, payload }))
    })
  }
}
