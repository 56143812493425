import { MdOutlineSwapVert } from "react-icons/md"
import { CardTool } from "../card-tool"
import { ToolLink } from "../tool-link"
export function BackupOlt() {
  return (
    <ToolLink href={"https://backup-converter.intelbras.com.br/"}>
      <CardTool title={"Conversor backup de OLTs"}>
        <div style={{ display: "block", paddingTop: "10%" }}>
          <img
            src="/assets/img/devices/OLT_G16_01.png"
            style={{ width: "70%" }}
            alt="OLT Device"
          />
        </div>
        <MdOutlineSwapVert size={50} />
        <div style={{ display: "block" }}>
          <img
            src="/assets/img/devices/8820i.png"
            style={{ width: "70%" }}
            alt="Device"
          />
        </div>
      </CardTool>
    </ToolLink>
  )
}
