import { MdArrowDropDown } from "react-icons/md"
import "./ActionsDropdown.css"

export default function ActionsDropdown({ options }) {
    return (
        <div className="actions-btn-wrapper">
            <button className="actions-btn">
                <span>
                    Ações
                </span>
                <MdArrowDropDown />
            </button>
            <div className="dropdown-content">
                {options.map(item => {
                    return (<li onClick={item.action} key={item.label}>{item.label}</li>)
                })}
            </div>
        </div>
    )
}

