import { Button } from "@conecte-se/ui-components-reactjs"
import React, { useContext, useState } from "react"

import AddVlan from "@features/devices/management/olt/vlans/modal/add-vlan"
import EditVlan from "@features/devices/management/olt/vlans/modal/edit-vlan"
import {
  changeInterfaceMode,
  deleteVlan,
} from "@features/devices/redux/vlan/vlan-actions"
import {
  MdArrowDownward,
  MdArrowForward,
  MdDelete,
  MdEdit,
} from "react-icons/md"
import { useSelector } from "react-redux"
import { ToastContext } from "src/context/ToastContext"
import { UserContext } from "src/context/UserContext"
import { ModalConfirm } from "../modal"
import { RadioGroupButton } from "../radio-button-group"
import "./custom-table.css"
import { connectionStatus } from "@features/devices/common/constants"
import { DeviceContext } from "@features/devices/management/manage-page"

export const CustomTable = ({ columns, data, vlans, selected }) => {
  const [expandedRows, setExpandedRows] = useState([])
  const modes = [
    { id: "HYBRID", text: "Hybrid" },
    { id: "TRUNK", text: "Trunk" },
    { id: "ACCESS", text: "Access" },
  ]
  const [port, setPort] = useState()
  const [caseModal, setCaseModal] = useState()
  const [vlan, setVlan] = useState()
  const manageCtx = useContext(DeviceContext)
  const user = useContext(UserContext)
  const toastContext = useContext(ToastContext)
  const [selectedInterface, setSelectedInterface] = useState({})
  const toggleRow = (index) => {
    setExpandedRows((prevExpandedRows) => {
      if (prevExpandedRows.includes(index)) {
        return prevExpandedRows.filter((i) => i !== index)
      }
      return [...prevExpandedRows, index]
    })
  }

  function closeModal() {
    setCaseModal(null)
  }
  function openModal(option) {
    setCaseModal(option)
  }
  const handleChangeInterface = () => {
    changeInterfaceMode(manageCtx.device.api, user, toastContext, {
      id: selected.id,
      port_name: selectedInterface.port_name,
      mode: selectedInterface.mode,
    })
    closeModal()
  }

  const handleDeleteVlan = () => {
    deleteVlan(manageCtx.device.api, user, toastContext, { id: vlan.id, olt_id: selected.id })
    closeModal()
  }

  const handleOpenModalInterface = (e, row) => {
    if (e.target.value !== row.mode) {
      setSelectedInterface({ port_name: row.name, mode: e.target.value })
      openModal("interface")
    }
  }
  const modal = (caseModal) => {
    switch (caseModal) {
      case "add":
        return [<AddVlan close={closeModal} portName={port.name} />]
      case "edit":
        return [<EditVlan close={closeModal} vlanState={{ vlan, setVlan }} />]
      case "delete":
        return [
          <ModalConfirm
            close={closeModal}
            action={handleDeleteVlan}
            title={"Deletar VLAN"}
            message={`Você tem certeza que deseja deletar a vlan ${vlan.number} da interface ${port.interfaceName}?`}
          />,
        ]
      case "interface":
        return [
          <ModalConfirm
            close={closeModal}
            action={handleChangeInterface}
            title={"Alterando modo da interface"}
            message={`Tem certeza que deseja alterar o modo desta interface para ${selectedInterface.mode}? As Vlans vinculadas a esta interface serão afetadas.`}
          />,
        ]
      default:
        return
    }
  }
  const getTaggedVlanNumbers = (name) => {
    const taggedVlansNumber = vlans.filter(
      (vlan) => vlan.port_name === name && vlan.tagged === true
    )
    const sortedVlans = taggedVlansNumber.sort((a, b) => a.number - b.number)
    return sortedVlans
  }
  return (
    <div className="table-container">
      <table className="table">
        <thead className="table__header">
          <tr>
            <th></th>
            {columns?.map((column) => (
              <th key={column}>
                <span>{column}</span>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data
            ?.sort((a, b) => {
              const order = ["10GE1", "10GE2", "E1", "E2", "E3", "E4"]
              return (
                order.indexOf(a.interfaceName) - order.indexOf(b.interfaceName)
              )
            })
            .map((row, index) => {
              if (!row?.mode) {
                return null
              }
              return (
                <React.Fragment key={index}>
                  <tr
                    onClick={() => toggleRow(index)}
                    className="table-row"
                    key={`row-${index}`}
                  >
                    <td className="table-row__icon-cell">
                      {expandedRows.includes(index) ? (
                        <MdArrowDownward size={20} />
                      ) : (
                        <MdArrowForward size={20} />
                      )}
                    </td>
                    <td className="columns">{row?.interfaceName}</td>
                    <td className="columns">{row?.mode}</td>
                    <td className="columns">
                      {getTaggedVlanNumbers(row?.name)
                        .map((vlan) => vlan.number)
                        .sort((a, b) => a - b)
                        .join(", ")}
                    </td>
                    <td className="columns">{row?.pvid}</td>
                  </tr>
                  {expandedRows.includes(index) && (
                    <tr
                      className="table-row-expanded"
                      key={`expanded-${index}`}
                    >
                      <td className="columns" colSpan="5">
                        <div className="row-details">
                          <div className="button-container">
                            <div className="radio-button-container">
                              <span>
                                <strong>Alterar Modo da Interface</strong>
                              </span>
                              <RadioGroupButton
                                name={`interface-mode-${row?.interfaceName}`}
                                onChange={(e) =>
                                  handleOpenModalInterface(e, row)
                                }
                                selected={row?.mode}
                                options={modes}
                                labelClass={"onu-modes"}
                              />
                            </div>
                            <div className="button-wrapper">
                              <Button
                                primary
                                onClick={() => {
                                  setPort(row)
                                  openModal("add")
                                }}
                              >
                                {" "}
                                Criar VLAN
                              </Button>
                            </div>
                          </div>
                          <div className="column-titles">
                            <div>VLAN</div>
                            <div>Descrição</div>
                            <div>Status</div>
                            <div>Ações</div>
                          </div>
                          {getTaggedVlanNumbers(row.name).map(
                            (vlanRow, dataIndex) => (
                              <div className="row-data" key={dataIndex}>
                                <div>{vlanRow.number}</div>
                                <div>
                                  {vlanRow.description
                                    ? vlanRow.description
                                    : "--"}
                                </div>
                                <div>
                                  {vlanRow.status
                                    ? connectionStatus[vlanRow.status].text
                                    : "--"}
                                </div>
                                <div>
                                  <MdEdit
                                    onClick={() => {
                                      setVlan(vlanRow)
                                      openModal("edit")
                                    }}
                                    size={18}
                                    style={{
                                      cursor: "pointer",
                                      marginRight: "25px",
                                    }}
                                  />
                                  <MdDelete
                                    onClick={() => {
                                      setVlan(vlanRow)
                                      setPort(row)
                                      openModal("delete")
                                    }}
                                    size={20}
                                    style={{ cursor: "pointer" }}
                                  />
                                </div>
                              </div>
                            )
                          )}
                        </div>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              )
            })}
        </tbody>
      </table>
      {modal(caseModal)}
    </div>
  )
}
