export function ToolLink({ href, children }) {
  return (
    <a
      rel="noreferrer"
      href={href}
      target="_blank"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "40%",
        height: "100%",
      }}
    >
      {children}
    </a>
  )
}
