import React from 'react'
import './multTabs.css'


export default function TabsContent({ children }) {
    return (
        <div className='tab-content'
        >
            {children}
        </div>
    )
}
