export default function OntDevice({ size, color, status, title }) {
  return (
    <svg width={size} fill={color} height={size} viewBox="0 0 624 624" xmlns="http://www.w3.org/2000/svg">
      <title>{title}</title>
      <path fill={color} d="M443.057,132.943l22.634-22.634a143.764,143.764,0,0,0-211.382,0l22.634,22.634a111.838,111.838,0,0,1,166.114,0Z" />
      <path fill={color} d="M299.615,155.615l22.7,22.7a47.913,47.913,0,0,1,75.362,0l22.7-22.7a79.829,79.829,0,0,0-120.77,0Z" />
      <path fill={color} d="M472,280H376V216H344v64H40a24.028,24.028,0,0,0-24,24V416a24.028,24.028,0,0,0,24,24H472a24.028,24.028,0,0,0,24-24V304A24.028,24.028,0,0,0,472,280Zm-8,128H48V312H464Z" />
      <rect fill={color} width="32" height="32" x="96" y="344" />
      <rect fill={color} width="32" height="32" x="176" y="344" />
      <rect fill={color} width="32" height="32" x="256" y="344" />
      {status === 'off' ? (
        <line x1="50" y1="150" x2="462" y2="462" stroke={color ? color : "#191C23"} strokeWidth="40" />
      ) : null}

    </svg>
  );
}