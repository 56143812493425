import React, { useState, useEffect } from 'react'
import TooltipText from '../../midgard/tooltip-text/tooltip-text';
import DotsIcon from '../icons/dots-icons';
import './list.css'

export const ListOrderStates = {
    NONE: 0,
    UP: 1,
    DOWN: 2
}

const List = ({columns, lines, width, onClick, breakWidth, useTooltip = false, extendedLines = false, options, verticalOptions = false, aux_id}) => {

    const [columnsState, setColumnsState] = useState({})
    const [responsive, setResponsive] = useState(false)

    const handleBreak = () => {
        if(needsToBreak() && !responsive)
            setResponsive(true)
        if(!needsToBreak() && responsive)
            setResponsive(false)
    }

    useEffect(() => {
        handleBreak()

        const hideDropdown = (e) => {
            if (!options) return

            const optionsMenus = document.querySelectorAll('.dropdown-menu')
            optionsMenus.forEach(menu => menu.style.display = 'none')
        }

        window.addEventListener('click', hideDropdown);

        return () => window.removeEventListener('click', hideDropdown)

        // eslint-disable-next-line
    }, [])

    if(breakWidth){
        window.addEventListener('resize', handleBreak)
    }

    if(!columns){
        console.error('Columns definitions is mandatory for the List component')
        return null
    }

    const getWidth = () => {
        if (width) return width

        const list = document.querySelector('#list')

        if(!list)
            return 0

        if (list) return list.clientWidth
    }

    const orderIcon = (state) => {

        return <div className='list-header-order-icon'>
            {state <= ListOrderStates.UP && <div className='list-header-order-arrow up'></div>}
            {(state === ListOrderStates.NONE || state === ListOrderStates.DOWN) && <div className='list-header-order-arrow down'></div>}
        </div>
    }

    const needsToBreak = () => {

        if(!breakWidth)
            return false

        return getWidth() < breakWidth
    }

    const getItemContent = (item, key) => {

        return <React.Fragment key={key}>
            <label>{columns[key].header}</label>
            <span>{item}</span>
        </React.Fragment>
    }

    const getContentWithTooltips = (item, key) => {

        return <React.Fragment key={key}>
        <label key={`label-${key}${key}`}>{columns[key].header}</label>
        <>
            <div className={'list-tooltip'}
                style={{
                    minWidth: columns[key].size,
                    maxWidth: columns[key].size ? columns[key].size : (getWidth()/columns.length - 20),
                    textAlign: responsive ?  'right' : columns[key].align ? columns[key].align : 'left',

            }}>{item}</div>
            <TooltipText
                width={((item + "").length / 1.5)+ 'em'}
                message={item}>
            </TooltipText>
        </></React.Fragment>
    }

    return <div className={`list ${responsive ? 'responsive' : ''}`} id='list' style={width ? {width: width} : {}}>

        <div className='list-headers'>
            {
                
                columns.map(

                    (column,key) => {

                        if(column.orderable && columnsState[key] === undefined) {
                            columnsState[key] = ListOrderStates.NONE
                            setColumnsState({...columnsState})
                        }

                        return(
                            <div className={`list-header-item ${column.orderable ? 'orderable' : ''}`} key={key}
                                style={{
                                    minWidth: column.size,
                                    maxWidth:column.size,
                                    textAlign: column.align
                                }}
                                onClick={ column.orderable ? (e) => {
                                    e.stopPropagation()
                                    columnsState[key] = ++columnsState[key] % Object.keys(ListOrderStates).length
                                    setColumnsState({...columnsState})
                                    column.orderFn(columnsState[key], column.header)
                                } : null }

                            >
                                {column.header}

                                {column.orderable && orderIcon(columnsState[key])}
                            </div>
                        )
                    })

            }
            {options ?
                <div className='dropdown-header'></div>
            : null}
        </div>

        <div className='list-lines'>
        {
                (lines ? lines.map(
                    (line, key) => <div className={`list-line-entry ${ extendedLines ? 'extended' : ''} ${onClick ? 'clickable' : ''}`}
                        key={key}
                        onClick={() => {
                            if(onClick)
                                onClick(line)
                        }}
                    >

                        {

                            line.map(
                                (item, key) => <div className={`list-line-item ${ useTooltip ? 'tooltip' : ''} ${item.onClick ? 'clickable' : ''}`} key={key}
                                    style={{
                                        minWidth: responsive ? 'none' : columns[key].size ? columns[key].size : 'none',
                                        maxWidth: responsive ? 'none' : columns[key].size ? columns[key].size : 'none',
                                        textAlign: columns[key].align ? columns[key].align : 'left',
                                        margin: '8px 0px'
                                    }}

                                >
                                    {useTooltip ?
                                        getContentWithTooltips(item, key)
                                    :
                                        getItemContent(item, key)
                                    }
                                </div>
                            )
                        }

                    { options ?
                        <div className='dropdown-options'>
                            <div id={`dropdown-icon-${aux_id ? aux_id + '-' : ''}${key}`}
                                className={`dropdown-icon ${verticalOptions ? 'vertical' : ''}`}
                                onClick={(e) => {
                                    e.stopPropagation()
                                    const options = document.querySelector(`#dropdown-menu-${aux_id ? aux_id + '-' : ''}${key}`)
                                    if (options.style.display === 'block') {
                                        options.style.display = 'none'
                                        return
                                    }
                                    options.style.display = 'block'
                                }}
                            >
                                <DotsIcon></DotsIcon>
                            </div>
                            <div id={`dropdown-menu-${aux_id ? aux_id + '-' : ''}${key}`} className="dropdown-menu">
                                {options.map((opt, key2) => {
                                    return opt.show(key) ? <div key={key2} onClick={() => opt.action(key)}>{opt.name}</div> : null
                                })}
                            </div>
                        </div>
                    : null}
                    </div>
                ) : null)
        }
        </div>
    </div>
}

export default List