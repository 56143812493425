import { useEffect, useRef, useState } from "react"
import { MdArrowDropDown, MdArrowDropUp } from "react-icons/md"
import CheckIcon from "src/lib/midgard/icons/check-icon"
import "./dropdown-devices.css"

export const DropdownDevices = ({ items, selected, onChange, width }) => {
  const [selectedItem, setSelectedItem] = useState(
    items.find((item) => item.value === selected)
  )

  useEffect(() => {
    setSelectedItem(items.find((item) => item.value === selected))
    // eslint-disable-next-line
  }, [selected])

  const [isOpen, setIsOpen] = useState(false)
  const dropdownRef = useRef()

  useEffect(() => { }, [selected])
  const handleSelect = (item) => {
    if (item.disabled) return
    setSelectedItem(item)
    onChange(item)
    toggleDropdown()
  }

  const toggleDropdown = () => {
    setIsOpen(!isOpen)
  }

  return (
    <div
      className="dropdown-devices"
      style={{ width: width ? width : "150px" }}
      ref={dropdownRef}
    >
      <div className="selected-item-devices" onClick={toggleDropdown}>
        <span>{selectedItem?.label}</span>
        {isOpen ? <MdArrowDropUp /> : <MdArrowDropDown />}
      </div>
      {isOpen && (
        <div style={{ overflowY: "auto" }}>
          <ul className="dropdown-list-devices">
            {items.map((item) => (
              <li
                key={item.label}
                onClick={() => handleSelect(item)}
                className={item.disabled ? "disabled" : ""}
              >
                <div className="item-content-devices">
                  <div style={{ marginRight: "10px" }}>
                    {item.label === selectedItem.label ? (
                      <CheckIcon
                        size={10}
                        color={"var(--table-card-body-color)"}
                      />
                    ) : null}
                  </div>
                  <span className="label-devices">{item.label}</span>
                  <span className="size-devices">
                    {item.size !== null ? item.size : ""}
                  </span>
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  )
}
