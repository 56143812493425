import { Button, Card, Modal } from "@conecte-se/ui-components-reactjs"
import { useCallback } from "react"
import { MdLogin } from "react-icons/md"
import Particles from "react-tsparticles"
import { loadFull } from "tsparticles"
import { redirectToContaIntelbras } from "../authentication/auth"
import "./modal-login.css"
export function ModalLogin() {
  const particlesInit = useCallback(async (engine) => {
    console.log(engine)
    // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
    // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
    // starting from v2 you can add only the features you need reducing the bundle size
    await loadFull(engine)
  }, [])

  const particlesLoaded = useCallback(async (container) => {
    await console.log(container)
  }, [])

  const options = {
    fullScreen: {
      enable: true,
      zIndex: 1,
    },
    particles: {
      number: {
        value: 100,
        density: {
          enable: false,
          value_area: 1000,
        },
      },
      color: {
        value: "#16D7F3",
      },
      shape: {
        type: "circle",
        options: {
          sides: 5,
        },
      },
      opacity: {
        value: 0.8,
        random: false,
        anim: {
          enable: false,
          speed: 1,
          opacity_min: 0.1,
          sync: false,
        },
      },
      size: {
        value: 2,
        random: true,
        anim: {
          enable: false,
          speed: 40,
          size_min: 0.1,
          sync: false,
        },
      },
      rotate: {
        value: 0,
        random: true,
        direction: "random",
        animation: {
          enable: true,
          speed: 5,
          sync: false,
        },
      },
      line_linked: {
        enable: true,
        distance: 250,
        color: "#16D7F3",
        opacity: 0.4,
        width: 2,
      },
      move: {
        enable: true,
        speed: 1,
        direction: "none",
        random: false,
        straight: false,
        out_mode: "out",
        attract: {
          enable: false,
          rotateX: 600,
          rotateY: 1200,
        },
      },
    },
    interactivity: {
      events: {
        onhover: {
          enable: true,
          mode: ["bubble"],
        },
        onclick: {
          enable: true,
          mode: "push",
        },
        resize: true,
      },
      modes: {
        grab: {
          distance: 400,
          line_linked: {
            opacity: 1,
          },
        },
        bubble: {
          distance: 400,
          size: 7,
          duration: 2,
          opacity: 8,
          speed: 3,
        },
        repulse: {
          distance: 350,
        },
        push: {
          particles_nb: 4,
          quantity: 4,
        },
        remove: {
          particles_nb: 2,
          quantity: 2,
        },
      },
    },
    retina_detect: true,
    background: {
      color: "#fafafa",
      image: "",
      position: "50% 50%",
      repeat: "no-repeat",
      size: "cover",
    },
  }
  return (
    <Modal visible={true} fullScreen>
      <Particles
        id="tsparticles"
        init={particlesInit}
        loaded={particlesLoaded}
        options={options}
      />
      <div
        style={{
          zIndex: "9999",
          width: "40%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textlign: "center",
          minHeight: "70vh",
        }}
        className={"login-page"}
      >
        <Card width={"100%"}>
          <div style={{ textAlign: "center", width: "100%", height: "100%" }}>
            <img alt="Card" src="/assets/img/nms.png" width={"300px"}></img>
            <br></br>
            <div style={{ paddingTop: "10px" }}>
              <p>
                <b>Já possui uma conta?</b>
              </p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  height: "40px",
                }}
              >
                <Button
                  secondary
                  outline
                  onClick={() => {
                    redirectToContaIntelbras()
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      width: "200px",
                      justifyContent: "center",
                    }}
                  >
                    <p style={{ display: "flex", alignItems: "center" }}>
                      Entrar <MdLogin style={{ marginLeft: "10px" }} />
                    </p>
                  </div>
                </Button>
              </div>
            </div>
            <div style={{ marginTop: "40px" }}>
              Deseja um período de teste grátis ou quer utilizar nossa
              plataforma?<br></br>
              Contate-nos:{" "}
              <a
                href="https://wa.me/48996279905"
                style={{ textDecorationLine: "underline" }}
              >
                (48)9 9627-9905
              </a>
            </div>
          </div>
        </Card>
      </div>
    </Modal>
  )
}
