import React, { useContext } from "react"
import classNames from "classnames"
import styles from "./TopBar.module.css"
import {
  DropdownNotifications,
  DropdownSystem,
  DropdownUser,
} from "../Dropdown"
import { useLocation } from "react-router-dom"
import { DropdownGroup } from "../DropdownGroup/DropdownGroup"
import { SideBarContext } from "../../context/SideBarContext"
import { UserContext } from "src/context/UserContext"

function TopBar() {
  //TODO: Criar context para mapear as mudanças de tema
  const { sideBarCollapsed } = useContext(SideBarContext)
  const user = useContext(UserContext)
  // console.log(theme)


  const location = useLocation()
  const pathname = location.pathname.split('/')[1]
  const routeTitles = {
    dashboard: 'Dashboard',
    manage: 'Dispositivos',
    'users-management': 'Contas',
    'help': 'Ajuda',
    'my-account': 'Minha conta'
  }

  return (
    <div id="topbar" className={classNames([styles.topbar, sideBarCollapsed ? styles.collapsed : "",])}>
      <div className={classNames([styles.logo])}>

        {sideBarCollapsed && (<span className={classNames([styles.topbarTitle])}>{routeTitles[pathname]}</span>)} 

        {/* {theme === "light" ? (
          <img src={nms} alt="logo Intelbras" />
        ) : (
          ""
        )}
        {theme === "dark" ? (
          //<img src={logoIntelbrasWhite} alt="logo Intelbras" />
          <img src={nms} alt="logo Intelbras" />
        ) : (
          ""
        )}
        {theme === null ? (
          //<img src={logoIntelbras} alt="logo Intelbras" />
          <img src={nms} alt="logo Intelbras" />
        ): (
          ""
        )} */}
      </div>
      <div style={{ flex: "1" }}></div>
      {
        <div className={classNames([styles.topbar_options])}>
          {
            //TODO:precisará existir um servidor com os arquivos de imagem.
          }

          <DropdownGroup></DropdownGroup>
          <DropdownNotifications></DropdownNotifications>
          {/* <DropdownSystem></DropdownSystem> */}
          <DropdownUser></DropdownUser>
        </div>
      }
    </div>
  )
}

export default TopBar
