import React from 'react'
import { Routes, Route } from 'react-router-dom'
import SiteSurvey from './site-survey'
import MonitoringWifiData from './wifi-data'

export default function WifiPage() {
    return (
        <div className='container'>
            <div id='wifi-page'>
                <Routes>
                    <Route path='wifi/site-survey' element={<SiteSurvey />}></Route>
                    <Route path='/' element={<MonitoringWifiData />}></Route>
                </Routes>
            </div>
        </div>

    )
}