const WanConstants = {
    MAX_IP4_MTU: 1500,
    MAX_VLAN_ID_SIZE: 32
}

export const DefaultWan = {
    id: "",
    interfaceID: "new",
    mode: 0,
    gateway: "",
    gateway_v6: "",
    dns_v4:{
        automatic: true,
        dns1: "",
        dns2: "",
        dns3: ""
    },
    dns_v6: {
        automatic: true,
        dns1: "",
        dns2: "",
        dns3: ""
    },
    clonedMac: ""
}

export const DefaultInterface = {
    id: "new",
    vlanid: "",
    ip4: "",
    ip6_count: 0,
    ip6_list: [],
    mac: "",
    mtu: 1500,
    netmask: ""
}

export const WanOpMode = {
    DHCP: 0,
    STATIC: 1,
    PPPOE: 2,
    BRIDGE: 3,
    BRIDGE_PPPOE: 4
}


export const DefaultPonStatus = {
    rx_power: 0,
    tx_power: 0,
    voltage: 0,
    bias_current: 0,
    temperature: 0,
}

export default WanConstants