import { AppLocal } from "../components/app-local"
import { BackupOlt } from "../components/backup-olt"
import { CustomFirmwareOnu } from "../components/custom-firmware-onu"
import { CustomFirmwareRouters } from "../components/custom-firmware-routers"

export function EquipmentTool() {
  return (
    <div style={{ height: "100%" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          height: "45%",
          alignItems: "center",
          paddingTop: "30px",
        }}
      >
        <BackupOlt />
        <div style={{ width: "20px" }}></div>

        <CustomFirmwareOnu />
      </div>

      <div style={{ height: "20px" }}></div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "45%",
        }}
      >
        <AppLocal />

        <div style={{ width: "20px" }}></div>

        <CustomFirmwareRouters />
      </div>
    </div>
  )
}
