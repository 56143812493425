import {
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
} from "@conecte-se/ui-components-reactjs"
import { useEffect, useState } from "react"
import { formatDate } from "src/common/validators"

export const InfoGateway = ({ close, selected }) => {
    const [gateway /*, setGateway*/] = useState(selected)

    useEffect(() => {
        console.log(selected)
    // eslint-disable-next-line
    }, [])
    return (
        <Modal visible={true} width={"400px"}>
            <ModalHeader>
                <div style={{ fontSize: "18px", fontWeight: "bold" }}>
                    Informações do Gateway
                </div>
            </ModalHeader>
            <ModalBody>
                <div>
                    <div className="card-info">
                        <div className="col-md-12 list-topic">
                            <div
                                className="list-item"
                            >
                                <div className="list-topic-item">ID</div>
                                <div className="list-topic-item">{gateway.id}</div>
                            </div>
                            {Object.entries(gateway.last_conn || {}).map(
                                ([key, value]) => (
                                    <div
                                        key={key}
                                        className="list-item"
                                    >
                                        <div className="list-topic-item">Última comunicação {key}</div>
                                        <div className="list-topic-item">{formatDate(value)} </div>
                                    </div>
                                )
                            )}
                        </div>
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <div style={{ display: "flex" }}>
                    <div className="mr-4" style={{ paddingRight: "20px" }}>
                        <Button secondary onClick={close}>
                            Voltar
                        </Button>
                    </div>
                </div>
            </ModalFooter>
        </Modal>
    )
}
