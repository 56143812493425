import {
  Button,
  Dropdown,
  DropdownMenu,
  Input,
  InputIconInside,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "@conecte-se/ui-components-reactjs"
import classNames from "classnames"
import { useContext, useEffect, useState } from "react"
import { MdArrowDropDown, MdArrowDropUp } from "react-icons/md"
import { BackendContext, backendStatus } from "@services/backend/backend"
import { UserContext } from "src/context/UserContext"
import styles from "./user-access-level.module.css"

export function UserAccessLevel(props) {
  const [searchAccessLevel, setSearchAccessLevel] = useState(props.role)
  const [searchAccessLevelExpanded, setSearchAccessLevelExpanded] =
    useState(false)
  const [modalRole, setModalRole] = useState(false)
  const [searchAccessLevelBlocked, setSearchAccessLevelBlocked] = useState(
    props.role
  )

  useEffect(() => {
    setSearchAccessLevelBlocked(props.role)
    setSearchAccessLevel(props.role)
  }, [props.role])

  const user = useContext(UserContext)
  const backend = useContext(BackendContext)

  const applyBlockedUserNewRole = async () => {
    let result = await backend.update(
      "group/" + user.activeGroup + "/" + props.email + "/role",
      searchAccessLevelBlocked
    )
    if (result.status === backendStatus.SUCCESS) {
      console.log("Succesfully changed blocked user role")
      setModalRole(!modalRole)
      setSearchAccessLevel(searchAccessLevelBlocked)
    } else {
      console.log("cabeça de batata, deu erro mano!")
    }
  }

  const setUserRole = async (newRole) => {
    let result = await backend.update(
      "group/" + user.activeGroup + "/" + props.email + "/role",
      newRole
    )
    if (result.status === backendStatus.SUCCESS) {
      console.log("Successfully changed user role")
    } else {
      console.log("Could not change user role ERROR")
    }
  }

  const allowApplyNewRoleBlockedUser = () => {
    if (searchAccessLevelBlocked !== "") {
      return false
    } else {
      return true
    }
  }

  const search_accessLevel = [
    {
      label: "Dono",
      handler: () => {
        setSearchAccessLevel("Dono")
        setUserRole("Dono")
        toggleSearchAccessLevel()
      },
    },
    {
      label: "Administrador",
      handler: () => {
        setSearchAccessLevel("Administrador")
        setUserRole("Administrador")
        toggleSearchAccessLevel()
      },
    },
    {
      label: "Técnico",
      handler: () => {
        setSearchAccessLevel("Técnico")
        setUserRole("Técnico")
        toggleSearchAccessLevel()
      },
    },
  ]

  const search_accessLevelBlocked = [
    {
      label: "Dono",
      handler: () => {
        setSearchAccessLevelBlocked("Dono")
        toggleSearchAccessLevel()
      },
    },
    {
      label: "Administrador",
      handler: () => {
        setSearchAccessLevelBlocked("Administrador")
        toggleSearchAccessLevel()
      },
    },
    {
      label: "Técnico",
      handler: () => {
        setSearchAccessLevelBlocked("Técnico")
        toggleSearchAccessLevel()
      },
    },
  ]

  // useEffect(()=>{
  //     if (searchAccessLevel === "Bloqueado"){
  //         setSearchAccessLevelExpanded(true)
  //     }
  // })

  const toggleSearchAccessLevel = () => {
    if (user.role.Group) {
      setSearchAccessLevelExpanded(() => !searchAccessLevelExpanded)
    }
  }

  return searchAccessLevel !== "Bloqueado" ? (
    <Dropdown id="" width={"100%"}>
      <InputIconInside
        expandable
        onClick={toggleSearchAccessLevel}
        iconRight={
          searchAccessLevelExpanded ? (
            <MdArrowDropUp onClick={toggleSearchAccessLevel} />
          ) : (
            <MdArrowDropDown onClick={toggleSearchAccessLevel} />
          )
        }
      >
        <Input
          disabled={!user.role.Group}
          small
          id=""
          value={searchAccessLevel}
          iconInsideRight
        />
      </InputIconInside>
      <DropdownMenu
        visible={user.role.Group && searchAccessLevelExpanded}
        itens={search_accessLevel}
        width="100%"
      />
    </Dropdown>
  ) : (
    <>
      <div style={{ display: "flex" }}>
        <div className="mr-4" style={{ paddingRight: "20px" }}>
          <Button
            secondary
            disabled={!user.role.Group}
            onClick={() => {
              props.removeUserGroupAssociation(props.email)
            }}
            small
          >
            Recusar
          </Button>
        </div>
        <Button
          primary
          disabled={!user.role.Group}
          small
          onClick={() => setModalRole(true)}
        >
          Aceitar
        </Button>
      </div>
      <Modal visible={modalRole} width={"500px"}>
        <ModalHeader>
          <span style={{ fontSize: "18px", fontWeight: "bold" }}>
            Qual nível de acesso deseja conceder a este usuário?
          </span>
        </ModalHeader>
        <ModalBody height={"40%"}>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Dropdown id="selectbox" width={"70%"}>
              <InputIconInside
                expandable
                onClick={toggleSearchAccessLevel}
                iconRight={
                  searchAccessLevelExpanded ? (
                    <MdArrowDropUp onClick={toggleSearchAccessLevel} />
                  ) : (
                    <MdArrowDropDown onClick={toggleSearchAccessLevel} />
                  )
                }
              >
                <Input
                  id="input_selectbox_model"
                  disabled={!user.role.Group}
                  value={
                    searchAccessLevelBlocked !== "Bloqueado"
                      ? searchAccessLevelBlocked
                      : ""
                  }
                  iconInsideRight
                />
              </InputIconInside>
              <div
                className={classNames([styles.search_access_level_expanded])}
              >
                <DropdownMenu
                  visible={searchAccessLevelExpanded}
                  itens={search_accessLevelBlocked}
                  width="100%"
                />
              </div>
            </Dropdown>
          </div>
        </ModalBody>
        <ModalFooter>
          <div style={{ display: "flex" }}>
            <div className="mr-4" style={{ paddingRight: "20px" }}>
              <Button
                secondary
                onClick={() => {
                  setModalRole(false)
                  setSearchAccessLevelBlocked("")
                }}
              >
                Cancelar
              </Button>
            </div>
            <Button
              disabled={allowApplyNewRoleBlockedUser()}
              onClick={() => applyBlockedUserNewRole()}
            >
              Salvar
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </>
  )
}
