import { Label } from "@conecte-se/ui-components-reactjs"
import { InputText } from "@features/devices/components/inputs"
export const AddByUser = ({ cpeState, errorState }) => {
  const { cpe, setCpe } = cpeState
  const { errors, setErrors } = errorState
  const handleChange = (e) => {
    setCpe({
      ...cpe,
      [e.target.name]: e.target.value,
    })
  }
  const clearInputField = (property) => {
    setCpe({ ...cpe, [property]: "" })
  }
  // eslint-disable-next-line
  const handleError = (name, error) => {
    if (error) {
      setErrors({ ...errors, [name]: error })
    } else {
      const { [name]: removed, ...rest } = errors
      setErrors(rest)
    }
  }
  return (
    <>
      <div className="mb-3">
        <Label>MAC*</Label>
        <InputText
          id="id"
          name="id"
          value={cpe.id}
          label="Mac Address"
          length={{ max: 12 }}
          // validate={{
          //     action: e => verificaGatewayId(e),
          //     handleError: handleError,
          // }}
          onChange={handleChange}
          clearField={{
            action: clearInputField,
            text: "Limpar",
          }}
        />
      </div>
      <div className="mb-3">
        <Label>Usuário</Label>
        <InputText
          id="username"
          name="username"
          label="Usuário"
          value={cpe.username}
          onChange={handleChange}
        />
      </div>
      <div className="mb-3">
        <Label>Senha</Label>
        <InputText
          id="password"
          name="password"
          label="Senha"
          value={cpe.password}
          onChange={handleChange}
        />
      </div>
    </>
  )
}
