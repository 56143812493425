import {
  Button,
  Modal,
  ModalFooter,
  ModalHeader,
} from "@conecte-se/ui-components-reactjs"
import { useContext } from "react"
import { useNavigate } from "react-router-dom"
import { BackendContext, backendStatus } from "@services/backend/backend"
import { ToastContext } from "src/context/ToastContext"

export function ModalRemoveUser({ modalState }) {
  const { removeUserModal, setRemoveUserModal } = modalState
  const backend = useContext(BackendContext)
  const toastContext = useContext(ToastContext)
  const navigate = useNavigate()
  const deleteUser = async () => {
    let result = await backend.delete("user")
    if (result.status === backendStatus.SUCCESS) {
      toastContext.addToast({
        title: "Operação efetuada com sucesso!",
        status: "success",
      })
      backend.setAuthInfo(null)
      localStorage.removeItem("access_token")
      localStorage.removeItem("refresh_token")
      localStorage.removeItem("theme")
      let headers = {}
      headers["Authorization"] = `Bearer ${backend.auth}`
      let result = await fetch(
        `${process.env.REACT_APP_FILE_SERVER_ENDPOINT}/avatar`,
        {
          method: "DELETE",
          headers: headers,
        }
      )
      if (result.status !== 200) {
        //TODO: display toast error
      }
      navigate("/login")
    } else {
      toastContext.addToast({
        title: result.content.body,
        status: "error",
      })
    }
  }
  return (
    <Modal visible={removeUserModal} width={"600px"}>
      <ModalHeader>
        <div className="mb-2" style={{ fontSize: "18px", fontWeight: "bold" }}>
          Deseja realmente excluir seu usuário?
        </div>
        <div style={{ fontSize: "14px" }}>
          <p style={{ textAlign: "center" }}>
            Isso implicará na <b>remoção permanente</b> dos dispostivos
            associados a sua conta e de todos os grupos que só possuem você como
            administrador, além de excluir customizações de firmware,
            atualizações agendadas, etc.<br></br>
            <br></br> Em caso de dúvida fale com um de nossos especialistas:{" "}
            <a
              href="https://wa.me/48996279905"
              style={{ textDecorationLine: "underline" }}
            >
              (48)9 9627-9905
            </a>
          </p>
        </div>
      </ModalHeader>
      <ModalFooter>
        <div style={{ display: "flex" }}>
          <div className="mr-4" style={{ paddingRight: "20px" }}>
            <Button
              secondary
              onClick={() => {
                setRemoveUserModal(!removeUserModal)
              }}
            >
              Cancelar
            </Button>
          </div>
          <Button onClick={deleteUser}>Excluir</Button>
        </div>
      </ModalFooter>
    </Modal>
  )
}
