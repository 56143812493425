import React, { useContext, useEffect, useState } from 'react'
import { backendStatus } from '@services/backend/backend'
import Form from '@lib/midgard/form/form'
import common from '@lib/midgard/form/validators/common'
import Input from '@lib/midgard/input/input'
import DefaultStatusModals from '@lib/midgard/modal/default-status-modals'
import Select from '@lib/midgard/select/select'
import DeviceFactory from '@features/devices/management/device-factory'
import { WanOpMode } from './tr069-wan-constants'
import { DeviceContext } from '@features/devices/management/manage-page'
import WanConstants from '../wan/wan-constants'
import { DefaultWan } from '../wan/tr069-wan-constants'

import { DefaultBindLan, DefaultBindLanAX } from '../wan/tr069-wan-constants'
import WanDHCP from './wan-dhcp'
import LanBind from '../wan/lan-bind'
import WanPPPoE from './wan-pppoe'
import WanStatic from './wan-static'
import RFOFields from './wan-tr069-fields'
import Button from '@lib/midgard/button/button'
import { ModalConfirm } from '@features/devices/components/modal'

export default function TR069WanConfiguration({
    wan,
    setWan,
    wanIndex,
    setWanIndex,
    _interface,
    setInterface,
    pppoe,
    fetchWan,
    wanLanList,
    setWanLanList,
    setStopFetch
}) {
    const [caseModal, setCaseModal] = useState()
    const [saving, setSaving] = useState(false)
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(false)
    const [localPPPoE, setLocalPPPoE] = useState(null)
    const [createWan, setCreateWan] = useState(false)
    const manageCtx = useContext(DeviceContext)

    useEffect(() => {
        if (pppoe && pppoe[wanIndex])
            setLocalPPPoE(pppoe[wanIndex])

        // eslint-disable-next-line
    }, [wanIndex, pppoe])

    useEffect(() => {

        if (createWan) {
            setWanIndex(wan.length - 1)
            return
        }

        // eslint-disable-next-line
    }, [wan, createWan])


    function closeModal() {
        setCaseModal(null)
    }

    function onRemoveWanClick() {
        console.log("remove wan")
        setCaseModal("delete")
    }

    const modal = (caseModal) => {
        switch (caseModal) {
            case "delete":
                return [
                    <ModalConfirm
                        close={closeModal}
                        action={deleteWan}
                        title={"Remover WAN"}
                        message={`Você tem certeza que deseja remover essa WAN?`}
                    />,
                ]
            default:
                return
        }
    }


    const getWanModeOptions = () => {
        let options = [
            { text: 'Automático (DHCP)', value: WanOpMode.DHCP },
            { text: 'PPPoE', value: WanOpMode.PPPOE_MODE },
            { text: 'Estático', value: WanOpMode.STATIC },
        ]

        if (DeviceFactory.isRFO(manageCtx.device.data.model)) {
            options.push({ text: 'Bridge', value: WanOpMode.BRIDGE }) //FIXME: temp mode used by RFO devices
        }

        return options
    }


    const handleError = (result) => {
        console.error('Error:', result.status, result.content)
        setSaving(false)
        setError(true)
    }

    const handleResult = (result) => {

        if (result.status !== backendStatus.SUCCESS) {
            handleError(result)
            return
        }

        setSaving(false)
        setSuccess(true)
        setCreateWan(false)

    }

    const saveWanConf = async () => {
        console.log('saveWanConf -- stop fetch wan statistics')
        setStopFetch(true)
        setSaving(true)

        let result = null

        let wanResp = null

        wanLanList[wanIndex].mode = wan[wanIndex].mode
        setWan([...wan])

        if (createWan) {
            localPPPoE.id = null
            result = await manageCtx.device.createResource('wan', wan[wanIndex])
        } else {
            result = await manageCtx.device.updateResource('wan', wan[wanIndex])

            if (wan[wanIndex].mode === WanOpMode.PPPOE_MODE) {
                localPPPoE.interfaceID = wan[wanIndex].interfaceID
                localPPPoE.id = wan[wanIndex].interfaceID
            }
        }

        if (!result || result.status !== backendStatus.SUCCESS) {
            handleError(result)
            return
        }

        wanResp = result.content

        if (wan[wanIndex].mode === WanOpMode.PPPOE_MODE) {
            if (!localPPPoE.id) {
                localPPPoE.interfaceID = 'new'

                if (createWan) {
                    result = await manageCtx.device.createResource('pppoe', localPPPoE)
                } else {
                    result = await manageCtx.device.updateResource('pppoe', localPPPoE)
                }
                if (result.status !== backendStatus.SUCCESS) {
                    handleError(result)
                    return
                }

            } else {
                result = await manageCtx.device.updateResource('pppoe', localPPPoE)
            }

            if (result.status !== backendStatus.SUCCESS) {
                handleError(result)
                return
            }

        }


        if (createWan) {

            _interface.id = 'new'

            result = await manageCtx.device.createResource(`interface`, _interface)

        } else {

            _interface.id = wanResp.interfaceID

            result = await manageCtx.device.updateResource(`interface`, _interface)
        }
        if (!result || result.status !== backendStatus.SUCCESS) {
            handleResult(result)
            return
        }

        if (createWan) {
            result = await manageCtx.device.createResource('wanlanlist', wanLanList[wanIndex])
        } else {
            result = await manageCtx.device.updateResource('wanlanlist', wanLanList[wanIndex])
            if (!result || result.status !== backendStatus.SUCCESS) {
                handleResult(result)
                return
            }
        }

        result = await manageCtx.device.apply()
        setStopFetch(true)
        handleResult(result)

        window.location.reload()
    }

    const deleteWan = async (e) => {

        e.stopPropagation()

        setSaving(true)

        let result = await manageCtx.device.deleteResource('wan', wan[wanIndex])
        handleResult(result)

        fetchWan()
        window.location.reload()
    }

    const dismissModal = () => {
        setSaving(false)
        setSuccess(false)
        setError(false)
    }

    const getWanSelectOptions = () => {
        return wan.map((net, i) => {
            return { text: net.id !== '' ? net.id : 'Nova WAN', value: i }
        })
    }

    const toggleCreateWan = () => {

        if (!createWan) {
            wan.push(DefaultWan)
            var model = manageCtx.device.data.model
            if (model.indexOf("AX") > -1) {
                wanLanList.push(DefaultBindLanAX)
            } else {
                wanLanList.push(DefaultBindLan)
            }
        } else {
            wan.pop()
            setWanIndex(0)
        }

        setWan([...wan])
        setCreateWan(!createWan)
    }

    const operationMode = () => {
        let options = getWanModeOptions()
        let opMode = "unknown"
        // eslint-disable-next-line
        options.map(x => {
            if (x.value === wan[wanIndex].mode) {
                opMode = x.text
            }
        })

        return opMode
    }

    return <div className='wan-form-container'>
        {modal(caseModal)}
        <Form id='wan-configuration-form'
            submitText={createWan ? 'Criar WAN' : 'Aplicar'}
            onSubmit={saveWanConf}
        >

            <DefaultStatusModals
                saving={saving}
                success={success}
                error={error}
                continueFn={dismissModal}
            ></DefaultStatusModals>

            <div className='subtitle'>
                {
                    !createWan ? 'Configurações' : 'Criação de nova WAN'
                }
            </div>
            <div className='wan-actions'>
                {!createWan &&
                    <div
                        className={`wan-link cancel`}
                        style={{ paddingRight: '0' }}
                        onClick={onRemoveWanClick}
                    >
                        Remover WAN
                    </div>}

                {DeviceFactory.isRFO(manageCtx.device.data.model) && <div
                    className={`wan-link ${createWan ? 'cancel' : 'add'}`}
                    onClick={toggleCreateWan}
                >
                    {createWan ? 'Cancelar criação' : 'Criar nova WAN'}
                </div>}

            </div>



            {wan.length > 1 && !createWan ? <Select id='wan-select'
                label='WAN'
                value={wanIndex}
                options={getWanSelectOptions()}
                onChange={e => {
                    setWanIndex(Number(e.target.value))
                }}
                clearErrors={true}
            ></Select> : null}

            <RFOFields
                device={manageCtx.device}
                wan={wan}
                wanIndex={wanIndex}
                setWan={setWan}
            ></RFOFields>

            {createWan ?
                <Select id='wan-mode'
                    label='Modo de operação'
                    value={wan[wanIndex].mode}
                    options={getWanModeOptions()}
                    onChange={e => {
                        wan[wanIndex].mode = Number(e.target.value)
                        setWan([...wan])
                    }}
                    clearErrors={true}
                ></Select>
                : <Input id='wan-mode'
                    label='Modo de operação'
                    value={operationMode()}
                    disabled
                    clearErrors={true}
                ></Input>}


            <Input id='wan-dhcp-vlanid'
                name='vlanid'
                label='Identificador da VLAN'
                value={_interface.vlanid}
                onChange={(event) => {
                    _interface.vlanid = event.target.value
                    setInterface({ ..._interface })
                }}
                validators={[{ fn: common.size, params: { min: 0, max: WanConstants.MAX_VLAN_ID_SIZE } }]}
            ></Input>

            {wan[wanIndex].mode === WanOpMode.DHCP && <WanDHCP
                _interface={_interface}
                setInterface={setInterface}
                wan={wan}
                wanIndex={wanIndex}
                setWan={setWan}
            ></WanDHCP>}

            {wan[wanIndex].mode === WanOpMode.PPPOE_MODE && <WanPPPoE
                _interface={_interface}
                pppoe={localPPPoE}
                setPPPoE={setLocalPPPoE}
                wan={wan}
                wanIndex={wanIndex}
                setWan={setWan}
                device={manageCtx.device}
            ></WanPPPoE>}

            {wan[wanIndex].mode === WanOpMode.STATIC && <WanStatic
                wan={wan}
                wanIndex={wanIndex}
                setWan={setWan}
                _interface={_interface}
                setInterface={setInterface}
            ></WanStatic>}
            {manageCtx.device.data.model === "ONU R1" || !wanLanList[wanIndex] ? null : <LanBind
                //temp thing while R1 doesn't have lan bind
                wanIndex={wanIndex}
                wanLanList={wanLanList}
                setWan={setWan}
                setWanLanList={setWanLanList}
            ></LanBind>}

        </Form>

        {/* {DeviceFactory.isRFO(manageCtx.device.data.model) && <Button
            id='delete-wan'
            isError text='Remover WAN'
            onClick={deleteWan}
        ></Button>} */}

    </div>


}