import { InputText } from "@features/devices/components/inputs"
import { useState } from "react"
import "./separated-buttons.css"

export const SeparatedButtons = ({ onChangeConfiguration }) => {
  const [ethFields, setEthFields] = useState([])
  const [veipFields, setVeipFields] = useState([])

  const handleAddEthField = () => {
    setEthFields([...ethFields, { id: "", vlan: "" }])
  }

  const handleRemoveEthField = (index) => {
    const updatedEthFields = [...ethFields]
    updatedEthFields.splice(index, 1)
    setEthFields(updatedEthFields)

    const eth = updatedEthFields.reduce((acc, { id, vlan }) => {
      acc[id] = parseInt(vlan) ? parseInt(vlan) : 0
      return acc
    }, {})
    onChangeConfiguration("eth", eth)
  }

  const handleEthFieldChange = (index, field, e) => {
    const updatedEthFields = [...ethFields]
    updatedEthFields[index] = {
      ...updatedEthFields[index],
      [field]: e.target.value,
    }
    setEthFields(updatedEthFields)

    const eth = updatedEthFields.reduce((acc, { id, vlan }) => {
      acc[id] = parseInt(vlan) ? parseInt(vlan) : 0
      return acc
    }, {})
    onChangeConfiguration("eth", eth)
  }

  const handleAddVeipField = () => {
    setVeipFields([...veipFields, { vlan: "" }])
  }

  const handleRemoveVeipField = (index) => {
    const updatedVeipFields = [...veipFields]
    updatedVeipFields.splice(index, 1)
    setVeipFields(updatedVeipFields)

    const veip = updatedVeipFields.map((v) => parseInt(v.vlan))
    onChangeConfiguration("veip", veip)
  }

  const handleVeipFieldChange = (index, e) => {
    const updatedVeipFields = [...veipFields]
    updatedVeipFields[index] = {
      ...updatedVeipFields[index],
      vlan: e.target.value,
    }
    setVeipFields(updatedVeipFields)

    const veip = updatedVeipFields.map((v) => parseInt(v.vlan))
    onChangeConfiguration("veip", veip)
  }

  return (
    <div className="separated-buttons-container">
      <div className="left-section">
        <button className="button-side" onClick={handleAddEthField}>
          + ETH
        </button>
        <div className="eth-fields">
          {ethFields.map((field, index) => (
            <div key={index} className="field">
              <InputText
                type="number"
                id={`eth-${index}`}
                name={`eth-${index}`}
                label="ETH"
                value={field.id}
                onChange={(e) => handleEthFieldChange(index, "id", e)}
              />
              <InputText
                type="number"
                id={`vlan-${index}`}
                name={`vlan-${index}`}
                label="VLAN"
                value={field.vlan}
                onChange={(e) => handleEthFieldChange(index, "vlan", e)}
              />
              <button
                className="remove-button"
                onClick={() => handleRemoveEthField(index)}
              >
                -
              </button>
            </div>
          ))}
        </div>
      </div>

      <div className="separator"></div>

      <div className="right-section">
        <button className="button-side" onClick={handleAddVeipField}>
          + VEIP
        </button>
        <div className="veip-fields">
          {veipFields.map((field, index) => (
            <div key={index} className="field">
              <InputText
                id={`vlan-${index}`}
                name={`vlan-${index}`}
                label="VLAN"
                value={field.vlan}
                onChange={(e) => handleVeipFieldChange(index, e)}
              />
              <button
                className="remove-button"
                onClick={() => handleRemoveVeipField(index)}
              >
                -
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
