import { createContext, useContext, useEffect, useState } from "react"
import { UserContext } from "./UserContext"
import { BackendContext } from "@services/backend/backend"

export const WsEventsContext = createContext()

export default function WsEventsProvider({ children }) {
  const userContext = useContext(UserContext)
  const backend = useContext(BackendContext)
  const [groupRequests, setGroupRequests] = useState([])
  const audio = new Audio("/assets/audio/Simple-notification.mp3")
  const [websockets, setWebsockets] = useState(null)
  const [newNotification, setNewNotification] = useState(false)

  useEffect(() => {
    var url
    if (userContext.user != null) {
      url = process.env.REACT_APP_WEBSOCKET_ENDPOINT + userContext.user.Email
      const ws = new WebSocket(url)
      ws.onopen = (event) => {
        console.log("Established websocket connection")
        ws.send(backend.auth)
        setWebsockets(ws)
      }
      ws.onclose = (event) => {
        console.log("Connection closed")
      }
      var countNotify = 1
      ws.onmessage = function (event) {
        const json = JSON.parse(event.data)
        try {
          console.log("new websocket message:", json)
          if (json.association) {
            if (countNotify !== 1) {
              setNewNotification(true)
              audio.play()
            }
            setGroupRequests(json.association)
            countNotify++
          } else {
            console.log("Not able to understand websocket message: ", json)
          }
        } catch (err) {
          console.log(err)
        }
      }
      //clean up function
      return () => ws.close()
    }
    // eslint-disable-next-line
  }, [userContext.user])

  return (
    <WsEventsContext.Provider
      value={{
        groupRequests: groupRequests,
        setGroupRequests: setGroupRequests,
        ws: websockets,
        newNotification: newNotification,
        setNewNotification: setNewNotification,
      }}
    >
      {children}
    </WsEventsContext.Provider>
  )
}
