import { createSlice } from "@reduxjs/toolkit"

const vlanInitial = {}
const initialState = {
  vlans: [],
  vlan: vlanInitial,
}
export const VlanSlice = createSlice({
  name: "vlanState",
  initialState: initialState,
  reducers: {
    setVlanInitial: (state, action) => {
      state.vlan = vlanInitial
    },
    appendVlan: (state, action) => {
      const newVlan = action.payload
      if (!state.vlans || !Array.isArray(state.vlans)) {
        state.vlans = []
      }
      state.vlans = [...state.vlans, newVlan]
    },
    removeVlan: (state, action) => {
      const indexToRemove = state.vlans.findIndex(
        (vlan) => vlan.id === action.payload.id
      )
      if (indexToRemove !== -1) {
        if (state.vlans.length > 1) {
          state.vlans.splice(indexToRemove, 1)
        } else {
          state.vlans = []
        }
      }
    },
    setVlan: (state, action) => {
      state.vlan = action.payload
    },
    setVlans: (state, action) => {
      state.vlans = action.payload
    },
    updateVlanInfo: (state, action) => {
      const updatedVlan = action.payload
      const index = state.vlans.findIndex((vlan) => vlan.id === updatedVlan.id)

      if (index !== -1) {
        state.vlans[index] = updatedVlan
      } else {
        state.vlans = [...state.vlans, updatedVlan]
      }
    },
    setVlanById: (state, action) => {
      const index = state.vlans.findIndex((vlan) => vlan.id === action.payload)
      state.vlan = state.vlans[index]
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  setVlanInitial,
  appendVlan,
  removeVlan,
  setVlan,
  setVlanById,
  setVlans,
  updateVlanInfo,
} = VlanSlice.actions

export default VlanSlice.reducer
