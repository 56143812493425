import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  cpes: [],
}
export const CpeSlice = createSlice({
  name: "cpeState",
  initialState: initialState,
  reducers: {
    addCpe: (state, action) => {
      const newCpe = action.payload
      if (!state.cpes || !Array.isArray(state.cpes)) {
        state.cpes = []
      }
      state.cpes = [...state.cpes, newCpe]
    },
    removeCpe: (state, action) => {
      const indexToRemove = state.cpes.findIndex(
        (cpe) => cpe.deviceid === action.payload.id
      )
      if (indexToRemove !== -1) {
        if (state.cpes.length > 1) {
          state.cpes.splice(indexToRemove, 1)
        } else {
          state.cpes = []
        }
      }
    },
    setCpes: (state, action) => {
      state.cpes = action.payload
    },
    updateCpeInfo: (state, action) => {
      const updatedCpe = action.payload
      const index = state.cpes.findIndex(
        (cpe) => cpe.deviceid === updatedCpe.deviceid
      )

      if (index !== -1) {
        state.cpes[index] = updatedCpe
      }
    },
    updateCpeInfoList: (state, action) => {
      const updatedCpes = action.payload

      updatedCpes.forEach((updatedCpe) => {
        const index = state.cpes.findIndex(
          (cpe) => cpe.deviceid === updatedCpe.deviceid
        )

        if (index !== -1) {
          state.cpes[index] = updatedCpe
        }
      })
    },
  },
})

// Action creators are generated for each case reducer function
export const { addCpe, removeCpe, setCpes, updateCpeInfo, updateCpeInfoList } =
  CpeSlice.actions

export default CpeSlice.reducer
