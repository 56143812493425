import { createContext, useEffect, useState, useCallback } from "react"

export const ToastContext = createContext()

export default function ToastContextProvider({ children }) {
  const [toasts, setToasts] = useState([])

  function addToast(newToast) {
    newToast.idToast = `toast-${Date.now()}-${Math.random()}`
    setToasts((oldArray) => [...oldArray, newToast])
  }

  const removeToast = useCallback(
    (idClicked) => {
      setToasts((prevToasts) =>
        prevToasts.filter((toast) => toast.idToast !== idClicked)
      )
    },
    [setToasts]
  )

  useEffect(() => {
    const interval = setInterval(() => {
      if (toasts.length) {
        removeToast(toasts[0].idToast)
      }
    }, 3000)
    return () => {
      clearInterval(interval)
    }
  }, [toasts, removeToast])

  return (
    <ToastContext.Provider
      value={{
        addToast: addToast,
        removeToast: removeToast,
        toasts: toasts,
      }}
    >
      {children}
    </ToastContext.Provider>
  )
}
