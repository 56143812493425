import React, { useContext, useEffect, useState } from "react"
import classNames from "classnames"
import styles from "./Dropdown.module.css"
import "./animation.css"
import { MdNotifications, MdSettings, MdManageAccounts } from "react-icons/md"
import ToggleTheme from "../Toggle"
import { MdLightMode, MdDarkMode } from "react-icons/md"
import { WsEventsContext } from "../../context/WsEvents"
import { useTheme } from "@conecte-se/ui-components-reactjs"
import { useNavigate } from "react-router-dom"
import { UserContext } from "../../context/UserContext"

function DropdownNotifications() {
  const navigate = useNavigate()
  const wsEvents = useContext(WsEventsContext)
  const user = useContext(UserContext)

  useEffect(() => {
    if (wsEvents.groupRequests.length > 0) {
      wsEvents.setNewNotification(true)
    }
    console.log("NEW NOTIFICATION")
    // eslint-disable-next-line
  }, [wsEvents.groupRequests])

  const notifications = () => {
    console.log("notifications:", wsEvents.groupRequests)
    return wsEvents.groupRequests.map((item, index) => {
      let date = new Date(item.date)
      let yyyy = date.getFullYear()
      let mm = date.getMonth() + 1 // Months start at 0!
      let dd = date.getDate()

      if (dd < 10) dd = "0" + dd
      if (mm < 10) mm = "0" + mm

      let formattedToday = dd + "/" + mm + "/" + yyyy

      console.log(formattedToday)

      const notificationAssociationValid = (grID) => {
        if (user.group.length === 0) {
          return false
        }
        for (let i = 0; i < user.group.length; i++) {
          console.log("grID:", grID)
          console.log("user.group[i].ID:", user.group[i].ID)
          if (
            user.group[i].ID === grID &&
            user.group[i].Role !== "Esperando resposta da organização"
          ) {
            return true
          }
        }
        return false
      }

      const goToNotification = (item) => {
        user.setActiveGroup(item.group_id)
        navigate("users-management")
        wsEvents.ws.send(JSON.stringify({ association: item }))
        wsEvents.setGroupRequests(
          wsEvents.groupRequests.filter((i) => i !== item)
        )
      }

      const deleteNotification = (event,item) => {
        wsEvents.ws.send(JSON.stringify({ association: item }))
        wsEvents.setGroupRequests(
          wsEvents.groupRequests.filter((i) => i !== item)
        )
        if (!event) 
          event.cancelBubble = true
        if (event.stopPropagation) event.stopPropagation()
      }

      return (
        <li
          style={{
            cursor: notificationAssociationValid(item.group_id)
              ? "pointer"
              : "default",
          }}
          key={index}
          className={classNames([styles.dropdown_content_body_list_item])}
          onClick={(e) => {
            notificationAssociationValid(item.group_id)
              ? goToNotification(item)
              : console.log("This notification probably is obsolete")
          }}
        >
          <div
            className={classNames([styles.dropdown_content_body_list_item_img])}
          >
            <MdManageAccounts />
          </div>
          <div>
            <span
              className={classNames([
                styles.dropdown_content_body_list_item_title,
              ])}
            >
              Solicitação de Acesso
            </span>
            <p
              className={classNames([
                styles.dropdown_content_body_list_item_description,
              ])}
            >
              <b>{item.from_user}</b> deseja fazer parte da organização{" "}
              <b>{item.group_name}</b>
            </p>
            {
              <span
                className={classNames([
                  styles.dropdown_content_body_list_item_date,
                ])}
              >
                {formattedToday}
              </span>
            }
          </div>
          <div
            className={classNames([
              styles.dropdown_content_body_list_item_actions,
            ])}
          >
            <div
              className={classNames([
                styles.dropdown_content_body_list_item_remove,
              ])}
              onClick={(e) => {
                deleteNotification(e,item)
                e.stopPropagation()
              }}
            >
              x
            </div>
          </div>
        </li>
      )
    })
  }

  return (
    <div className={classNames([styles.dropdown])}>
      <div>
        <span className={classNames([styles.dropdown_trigger])}>
          <span
            style={{
              animation: wsEvents.newNotification ? "bell 2s" : "none",
              marginLeft: "5px",
            }}
            onAnimationEnd={() => wsEvents.setNewNotification(false)}
          >
            <MdNotifications />
          </span>
          {wsEvents.groupRequests.length > 0 && <div className={"dot"}></div>}
        </span>
        <div className={classNames([styles.dropdown_content])}>
          <div className={classNames([styles.dropdown_content_header])}>
            <span className={classNames([styles.dropdown_header_title])}>
              Notificações
            </span>
          </div>
          <div>
            <ul className={classNames([styles.dropdown_content_body_list])}>
              {wsEvents.groupRequests && notifications()}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

function DropdownSystem() {
  const [theme, setTheme] = useTheme()
  const [operationTheme, setOperationTheme] = useState(theme === "dark")

  const changeThemeMode = (localTheme) => {
    setTheme(localTheme ? "dark" : "light")
    setOperationTheme(localTheme)
  }

  return (
    <div className={classNames([styles.dropdown])}>
      <span className={classNames([styles.dropdown_trigger])}>
        <MdSettings />
      </span>

      <div className={classNames([styles.dropdown_content])}>
        <div className={classNames([styles.dropdown_content_header])}>
          <span className={classNames([styles.dropdown_header_title])}>
            Sistema
          </span>
        </div>
        <div>
          <div className={classNames([styles.change_theme])}>
            <span>Modo tema claro/escuro</span>

            <ToggleTheme
              id="theme-toogle"
              onChange={() => {
                changeThemeMode(!operationTheme)
              }}
              checked={theme === "dark"}
              iconLeft={<MdLightMode />}
              iconRight={<MdDarkMode />}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

function DropdownUser() {
  const navigate = useNavigate()
  const user = useContext(UserContext)

  const [loggedUser, setLoggedUser] = useState(null)

  useEffect(() => {
    setLoggedUser(user.user)
    if (!user.avatar) {
      user.fetchAvatar()
    }
    // eslint-disable-next-line
  }, [user.user])

  return loggedUser ? (
    <div className={classNames([styles.dropdown])}>
      <span className={classNames([styles.dropdown_trigger])}>
        <img
          alt="avatar"
          src={user.avatar ? user.avatar : "/assets/img/default-avatar.png"}
          className={classNames([styles.my_account_avatar])}
          onClick={() => navigate("my-account")}
        ></img>
      </span>
    </div>
  ) : null
}

export { DropdownNotifications, DropdownSystem, DropdownUser }
