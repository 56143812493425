
/*
    Interfaces
*/
export const LOOPBACK_INTERFACE = "loopback"

/*
    IPV6
*/
export const IPV6_MULTICAST_PREFIX = "ff"

/*
    Others
*/
export const LimitedUserName = "user"
export const AdminUserName = "admin"

//statusIcons
export const TYPE_DESCONHECIDO = 0;
export const TYPE_GATEWAY = 1;
export const TYPE_OLT = 2;
export const TYPE_ONT = 3;
export const TYPE_ONU = 4;
export const TYPE_ROUTER = 5;
export const TYPE_TR069_ROUTER = 6;
export const TYPE_TR069_ONT = 7;

//resources actions to device
export const RESOURCE_RESTART = "restart";
export const RESOURCE_UPDATE = "update";
export const RESOURCE_SAVE = "save";

const deviceTypes = {
    0: "DESCONHECIDO",
    1: "GATEWAY",
    2: "OLT",
    3: "ONT",
    4: "ONU",
    5: "ROUTER",
    6: "TR069_ROUTER",
    7: "TR069_ONT"
}

export const DEVICE_STATE = {
    // General state
    STATE_UNKNOWN: 0,
    ASSOCIATED: 1,
    NOT_ASSOCIATED: 2,
    ASSOCIATING: 3,
    RESTARTING: 4,
    NOT_CONNECTED: 5,
    UPDATING_FIRMWARE: 6,

    // Ont specific state
    NOT_PROVISIONED: 7,
    PROVISIONING: 8,
    OPTICAL_PROBLEM: 9,

    // Olt specific state
    CREATING: 10,
    DELETING: 11,
    LOADING: 12,
    CONNECTING: 13,
    RECONNECTING: 14,
    DELETED: 15,
    NOT_SUPPORTED: 16,
    DOWNLOADING: 17,
    UPDATING_BOOTROM: 18,
    ERROR: 19,
    DELETE_ERROR: 20,
    PROCESS_ERROR: 21,
    CREDENTIAL_ERROR: 22,
    IP_ERROR: 23,
    UPDATE_ERROR: 24,
    DOWNLOAD_ERROR: 25
};

export const deviceStates = {
    0: { text: "DESCONHECIDO", color: "#787878", type: 'on' },
    1: { text: "ASSOCIADO", color: "#32c563", type: 'on' },
    2: { text: "NÃO ASSOCIADO", color: "#fe5242", type: 'on' },
    3: { text: "ASSOCIANDO", color: "#fc7b54", type: 'on' },
    4: { text: "REINICIANDO", color: "#fc7b54", type: 'on' },
    5: { text: "DESCONECTADO", color: "#787878", type: 'off' },
    6: { text: "ATUALIZANDO FIRMWARE", color: "#fc7b54", type: 'on' },
    7: { text: "NÃO PROVISIONADA", color: "#902bf5", type: 'on' },
    8: { text: "PROVISIONANDO", color: "#fc7b54", type: 'on' },
    9: { text: "PROBLEMA ÓPTICO", color: "#fc7b54", type: 'on' },
    10: { text: "CRIANDO", color: "#fc7b54", type: 'on' },
    11: { text: "DELETANDO", color: "#fc7b54", type: 'on' },
    12: { text: "CARREGANDO", color: "#fc7b54", type: 'on' },
    13: { text: "CONECTANDO", color: "#fc7b54", type: 'on' },
    14: { text: "RECONECTANDO", color: "#fc7b54", type: 'on' },
    15: { text: "DELETADO", color: "#787878", type: 'off' },
    16: { text: "NÃO SUPORTADO", color: "#787878", type: 'off' },
    17: { text: "BAIXANDO", color: "#fc7b54", type: 'on' },
    18: { text: "ATUALIZANDO BOOTROM", color: "#fc7b54", type: 'on' },
    19: { text: "ERRO", color: "#fe5242", type: 'off' },
    20: { text: "ERRO AO DELETAR", color: "#fe5242", type: 'off' },
    21: { text: "ERRO DE PROCESSAMENTO", color: "#fe5242", type: 'off' },
    22: { text: "ERRO DE CREDENCIAL", color: "#fe5242", type: 'off' },
    23: { text: "ERRO DE IP", color: "#fe5242", type: 'off' },
    24: { text: "ERRO DE ATUALIZAÇÃO", color: "#fe5242", type: 'off' },
    25: { text: "ERRO DE DOWNLOAD", color: "#fe5242", type: 'off' },
}

export const connectionStatus = {
    0: { text: "DESCONHECIDO", color: "#787878", type: 'on' },
    1: { text: "ONLINE", color: "#32c563", type: 'on' },
    2: { text: "OFFLINE", color: "#787878", type: 'off' },
}

export const getDeviceTypeName = (type) => {
    return deviceTypes[type]
}