
const APPLY_ACTION = 5

export class OnuAPI {

    constructor(backend) {
        this.backend = backend
    }

    async create(id, resourceName, data) {
        console.log(' create resourceName', resourceName)
        let result = await this.backend.create(`device/olt/${resourceName}?id=${id}`, data)

        return result

    }

    async retrieve(id, resourceName) {
        console.log(' retrieve resourceName', resourceName)
        //TODO Remove when the API responds correctly
        try {

            let rsc = resourceName.split('?')
            let par = new URLSearchParams(rsc[1]);

            if (par.has('timeout')) {
                if (parseInt(par.get('timeout'), 10) <= 20)
                    par.set('timeout', 20)
            } else {
                par.append('timeout', 20)
            }

            let result = { status: 1, content: [] }
            if (resourceName === "connected_device1") {

                result.content = [
                    {
                        "frequency": 0,
                        "hostname": "RedmiNote8-RedmiNote",
                        "id": "E0:1F:88:61:A2:FA",
                        "ip": "10.0.0.217",
                        "ip6_count": 1,
                        "ip6_list": [
                            "fe80::e21f:88ff:fe61:a2fa"
                        ],
                        "isDMZ": false,
                        "isStaticIP": false,
                        "mac": "E0:1F:88:61:A2:FA",
                        "network": "voice",
                        "opMode": 5,
                        "rxBytes": 838,
                        "rxRate": 390,
                        "signalLevel": -54,
                        "time": 1722295193,
                        "txBytes": 2363,
                        "txRate": 390,
                        "unitySize": 100000
                    },
                    {
                        "frequency": 0,
                        "hostname": "MikroTik",
                        "id": "64:D1:54:83:BF:8C",
                        "ip": "10.0.0.188",
                        "ip6_count": 0,
                        "ip6_list": [],
                        "isDMZ": false,
                        "isStaticIP": false,
                        "mac": "64:D1:54:83:BF:8C",
                        "network": "",
                        "opMode": 0,
                        "rxBytes": 0,
                        "rxRate": 0,
                        "signalLevel": 0,
                        "time": 0,
                        "txBytes": 0,
                        "txRate": 0,
                        "unitySize": 0
                    }
                ]

            } 
             else if (resourceName ==="sip1"){

                    result.content = {
                        "id": "sip-1",
                        "voip_proxy_account": {
                            "default_proxy": true,
                            "display_name": "",
                            "number": "",
                            "login_id": "",
                            "password": ""
                        },
                        "voip_proxy_server_register": {
                            "proxy_enable": false,
                            "proxy_addr": "",
                            "proxy_port": "5060",
                            "sip_subscribe": false,
                            "sip_domain": "192.168.1.6",
                            "reg_expire": "3600"
                        },
                        "voip_proxy_nat_transv_server": {
                            "outbound_proxy_enable": false,
                            "outbound_proxy_addr": "",
                            "outbound_proxy_port": "5060"
                        }
                    }
            }else if (resourceName === "resources") {

                result.content = [
                    { "resource": "wifi", "active": true },
                    { "resource": "wan", "active": true },
                    { "resource": "lan", "active": true },
                    { "resource": "sip", "active": false },
                    { "resource": "inmesh", "active": false },
                    { "resource": "maintenance", "active": true },
                    ]
            } else {
                result = await this.backend.retrieveFresh(`device/ont/${resourceName}?id=${id}&${par}`)
             }
              
            return result

        }catch{
            console.error(`Error retrieving  ${resourceName}`)
            return {status: 2} //FIXME: magic number
        }
    }

    async update(id,resourceName, data) {
        console.log(' update resourceName', resourceName)
        let result = await this.backend.update(`device/ont/${resourceName}/${data.id}?id=${id}`, data)

        return result

    }

    async delete(resourceName, data) {

        let result = await this.backend.delete(`device/ont/${resourceName}/${data.id}?id=${this.data.id}`, data)

        return result

    }


    async apply(id) {

        let result = await this.backend.create(`device/ont/${id}/action`, {actionID: APPLY_ACTION})

        return result
    }
}
