import React from 'react'
import common from '@lib/midgard/form/validators/common';
import network from '@lib/midgard/form/validators/network';
import Input from '@lib/midgard/input/input';
import Loading from '@lib/midgard/loading/loading';
import WanDNS from './wan-dns';

export default function WanDHCP({_interface, setInterface, wan, setWan, wanIndex}) {

    return (

        !_interface ? <Loading show={true}></Loading> :

        <React.Fragment>

            <Input id='wan-dhcp-mtu'
                name='mtu'
                label='MTU'
                value={_interface.mtu}
                onChange={(event) => {
                    let value = event.target.value

                    if(isNaN(value)) return

                    _interface.mtu = Number(value)
                    setInterface({..._interface})
                }}
                validators={[common.required, network.mtu]}
            ></Input>
            {WanDNS(_interface, wan, setWan, wanIndex)}

        </React.Fragment>
    )
}