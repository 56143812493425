import { format } from "date-fns"
import { ptBR } from "date-fns/locale"
import { MdInfoOutline } from "react-icons/md"
import React from "react"
import "./notification.css"
export function NotificationItem({ data }) {
  return (
    <div className="notification-item">
      <div className="notification-item__title">
        <MdInfoOutline className="notification-item__icon" size="18px" />
        <div>{data.title}</div>
      </div>
      <div className="notification-item__info">
        <div className="notification-item__info__model">
          Modelo {data.model}
        </div>
        <div className="notification-item__info__date">
          {format(new Date(data.date), "P HH:mm", {
            locale: ptBR,
          })}
        </div>
      </div>
    </div>
  )
}
