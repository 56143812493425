import {
  Button,
  Modal,
  ModalFooter,
  ModalHeader,
} from "@conecte-se/ui-components-reactjs"
import { useContext } from "react"
import { useNavigate } from "react-router-dom"
import { BackendContext } from "@services/backend/backend"

export function ModalLogout({ modalState }) {
  const { goOut, setGoOut } = modalState
  const backend = useContext(BackendContext)
  const navigate = useNavigate()
  return (
    <Modal visible={goOut} width={"600px"}>
      <ModalHeader>
        <div
          className="mb-2"
          style={{
            fontSize: "18px",
            fontWeight: "bold",
            paddingBottom: "10px",
          }}
        >
          Logout
        </div>
        <div>Deseja realmente sair da sua conta?</div>
      </ModalHeader>
      <ModalFooter>
        <div style={{ display: "flex" }}>
          <div className="mr-4" style={{ paddingRight: "20px" }}>
            <Button
              secondary
              onClick={() => {
                setGoOut(!goOut)
              }}
            >
              Cancelar
            </Button>
          </div>
          <Button
            onClick={() => {
              backend.setAuthInfo(null)
              localStorage.removeItem("access_token")
              localStorage.removeItem("refresh_token")
              navigate("/login")
            }}
          >
            Sair
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  )
}
