import { useState } from "react"
import { CloseIcon } from "../icons/close-icon"
import { CloseSee } from "../icons/close-see"
import { OpenSee } from "../icons/open-see"
import "./input-text.css"

export const InputText = ({
  type = "text",
  label = "",
  name = "",
  id = "",
  value = "",
  onChange,
  autoComplete = "off",
  validate,
  length,
  className,
  disabled,
  reference,
  clearField,
}) => {
  const [error, setError] = useState()
  const [showPassword, setShowPassword] = useState(false)

  const handleBlur = (event) => {
    if (validate) {
      const validationError = validate.action(event.target.value)
      if (validationError) {
        setError(validationError)
        validate.handleError(name, validationError)
      } else {
        setError("")
        setError("")
        validate.handleError(name, null)
      }
    }
    onChange(event)
  }
  const handleChange = (event) => {
    if (validate) {
      const validationError = validate.action(event.target.value)
      if (validationError) {
        setError(validationError)
        validate.handleError(name, validationError)
      } else {
        setError("")
        setError("")
        validate.handleError(name, null)
      }
    }
    onChange(event)
  }
  function typeText() {
    if (type === "password" && showPassword) return "text"
    else if (type === "password" && !showPassword) return "password"
    else return type
  }
  return (
    <div className={`box-form ${disabled ? "disabled" : ""}`}>
      <input
        id={id}
        ref={reference}
        disabled={disabled ? disabled : false}
        type={typeText()}
        name={name}
        placeholder={label && label}
        value={value}
        onChange={handleChange}
        required={!!validate}
        autoComplete={autoComplete}
        onBlur={validate && handleBlur}
        pattern={validate && validate.pattern}
        maxLength={length && length.max}
        minLength={length && length.min}
      />
      {clearField && value && !disabled && (
        <button
          name={name}
          id="btnLimpar"
          type="button"
          style={{ top: error ? "40%" : "55%" }}
          onClick={() => clearField.action(name)}
          title={clearField.text}
        >
          <CloseIcon title={clearField.text} />
        </button>
      )}

      {type === "password" ? (
        <button
          type="button"
          onClick={(e) => {
            e.preventDefault()
            setShowPassword(!showPassword)
          }}
          style={{ top: error ? "40%" : "55%" }}
        >
          {showPassword ? <CloseSee /> : <OpenSee title={"Ver"} />}
        </button>
      ) : null}
      <div className="error-message">{validate && error ? error : ""}</div>
    </div>
  )
}
