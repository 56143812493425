import "./radio-group.css"

export const RadioGroupButton = ({
  name,
  text,
  options,
  selected,
  onChange,
  validate,
  defaultChecked,
  disabled,
  labelClass = "onu-modes",
  style,
}) => {
  return (
    <div className={`custom-control-radio`}>
      <label className="custom-label">{text}</label>
      {options.map((option) => (
        <div style={style} key={option.id} className="radio-inline">
          <input
            type="radio"
            value={option.id}
            defaultValue={defaultChecked}
            defaultChecked={defaultChecked}
            checked={selected === option.id}
            id={`${name}-${option.id}`}
            name={name}
            onChange={onChange}
            disabled={disabled}
          />
          <label className={`${labelClass}`} htmlFor={`${name}-${option.id}`}>
            {option.text}
          </label>
        </div>
      ))}
    </div>
  )
}
