import DashboardSlice from "@features/dashboard/redux/dashboard-slice"
import CpeSlice from "@features/devices/redux/cpe/cpe-slice"
import TabSlice from "@features/devices/redux/deviceTab/device-slice"
import GatewaySlice from "@features/devices/redux/gateway/gateway-slice"
import OltSlice from "@features/devices/redux/olt/olt-slice"
import VlanSlice from "@features/devices/redux/vlan/vlan-slice"
import { configureStore } from "@reduxjs/toolkit"

export const store = configureStore({
  reducer: {
    cpesState: CpeSlice,
    oltsState: OltSlice,
    gatewayState: GatewaySlice,
    vlansState: VlanSlice,
    dashboardState: DashboardSlice,
    tabSlice: TabSlice
  },
  devTools: process.env.NODE_ENV !== "production",
})
export default store
/* export default createStore(Reducer, composeWithDevTools(applyMiddleware(thunkMiddleware, loggerMiddleware, multi))); */
