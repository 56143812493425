import React, { useState, useEffect, useContext } from 'react'

import Loading from '@lib/midgard/loading/loading'
import { BackendContext, backendStatus } from '@services/backend/backend'
import { useDispatch } from 'react-redux';

import DeviceFactory from '@features/devices/management/device-factory'
import { addTab, selectTab } from '@features/devices/redux/deviceTab/device-slice';
import './manage.css'

export const DEVICE_STATUS_OFFLINE = 1

export function getTraffic(value, unitBytes, time) {

  const BYTE_SIZE = 8
  const MEGABIT_SIZE = 1000000

  if (time === 0) return 0

  return ((value * unitBytes * BYTE_SIZE) / time) / MEGABIT_SIZE
}

export const DeviceContext = React.createContext(null)

export default function DeviceManagePage() {

  const [deviceInfo, setDeviceInfo] = useState(null)
  const backend = useContext(BackendContext)
  const dispatch = useDispatch()
  const id = window.location.pathname.split("/")[2]

  const fetchDeviceInfo = async () => {

    let result = await backend.retrieveFresh(`device?id=${id}`)

    if (result.status === backendStatus.SUCCESS) {
      var newDevice = result.content
      dispatch(addTab(newDevice));
      dispatch(selectTab(newDevice));
      setDeviceInfo(newDevice)
    }

  }

  useEffect(() => {
    //TODO: need to solve this problem properly (sometimes is "Devices" or "devices")
    if (id === "devices" || id === undefined || id === "Devices") {
      let newDevice = {
        "id": "devices",
        "model": "devices",
        "origin": "manage devices"
      }
      dispatch(selectTab(newDevice));
      setDeviceInfo(newDevice)
      return
    }

    fetchDeviceInfo()

    let deviceInterval = setInterval(fetchDeviceInfo, 600000)

    return () => clearInterval(deviceInterval)

    // eslint-disable-next-line
  }, [])

  useEffect(() => {

    if (!deviceInfo) return

  }, [deviceInfo])

  // const device = useMemo(() => {
  //   return deviceInfo ? DeviceFactory.getDevice(deviceInfo, backend) : null
  // }, [deviceInfo, backend])

  if (!deviceInfo /*|| !canBeManaged(deviceInfo)*/) {
    return <Loading show={true}></Loading>
  }




  let device = DeviceFactory.getDevice(deviceInfo, backend)

  console.log('DeviceManagePage', device, 'deviceInfo', deviceInfo)

  return (
    <div id="device-management-page">
      <DeviceContext.Provider value={{ device, deviceInfo }}>
        {device?.getManagementPage()}
      </DeviceContext.Provider>

    </div>
  )
}