import React, { useState, useContext } from 'react'
import Loading from '@lib/midgard/loading/loading'

import { DeviceContext } from '@features/devices/management/manage-page'

import '@features/devices/management/manage.css'
import { formatDate } from "src/common/validators"
export const DEVICE_STATUS_OFFLINE = 2

export default function GatewayManagePage() {
  const manageCtx = useContext(DeviceContext)
  const [deviceInfo /*, setDeviceInfo*/] = useState(manageCtx.deviceInfo)
  //Todo remover mock
  const [gateway /*,, setGateway */] = useState({
    "id": "00155db55552",
    "org": {
      "String": "ded23933-e30f-4860-b8ff-3fe36f9f2552",
      "Valid": true
    },
    "token": {
      "String": "e99219",
      "Valid": true
    },
    "name": {
      "String": "myGW",
      "Valid": true
    },
    "description": {
      "String": "myGW",
      "Valid": true
    },
    "first_hello": {
      "Time": "2024-07-16T22:55:20.659257Z",
      "Valid": true
    },
    "last_hello": {
      "Time": "2024-07-17T01:03:22.609795Z",
      "Valid": true
    },
    "assoc_status": {
      "Int32": 0,
      "Valid": false
    },
    "assoc_time": {
      "Time": "2024-07-16T22:57:17.892883Z",
      "Valid": true
    },
    "hosts": null,
    "status": 0,
    "supported_protocols": {
      "omci": false,
      "tr069": false,
      "tr369": false,
      "mqtt": false
    }
  })



  if (!deviceInfo /*|| !canBeManaged(device)*/)
    return <Loading show={true}></Loading>

  return (
    <>
      {deviceInfo.status === DEVICE_STATUS_OFFLINE ?

        <div className='offline-info'>
          <Loading show={true}></Loading>
          <span>O dispositivo está offline. Aguardando o retorno </span>
        </div>
        : <React.Fragment>

          <div visible={true} width={"400px"}>
            <div>
              <div style={{ fontSize: "18px", fontWeight: "bold" }}>
                Informações do Gateway
              </div>
            </div>
            <div>
              <div>
                <div className="card-info">
                  <div className="col-md-12 list-topic">
                    <div
                      className="list-item"
                    >
                      <div className="list-topic-item">ID</div>
                      <div className="list-topic-item">{gateway.id}</div>
                    </div>
                    {Object.entries(gateway.last_conn || {}).map(
                      ([key, value]) => (
                        <div
                          key={key}
                          className="list-item"
                        >
                          <div className="list-topic-item">Última comunicação {key}</div>
                          <div className="list-topic-item">{formatDate(value)} </div>
                        </div>
                      )
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div>
            </div>
          </div>
        </React.Fragment>}
    </>
  )
}