import {
  Button,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "@conecte-se/ui-components-reactjs"
import { InputText } from "@features/devices/components/inputs"
import { DeviceContext } from "@features/devices/management/manage-page"
import { updateVlan } from "@features/devices/redux/vlan/vlan-actions"
import { useContext } from "react"
import { ToastContext } from "src/context/ToastContext"
import { UserContext } from "src/context/UserContext"

const EditVlan = ({ close, vlanState }) => {
  const { vlan, setVlan } = vlanState
  const user = useContext(UserContext)
  const toastContext = useContext(ToastContext)
  const manageCtx = useContext(DeviceContext)

  const clearInputField = (property) => {
    setVlan({ ...vlan, [property]: "" })
  }

  const handleEditVlan = () => {
    updateVlan(manageCtx.device.api, toastContext, vlan)
    close()
  }

  const handleChange = (e) => {
    setVlan({
      ...vlan,
      [e.target.name]: e.target.value,
    })
  }
  return (
    <Modal visible={true} width={"500px"}>
      <ModalHeader>
        <div style={{ fontSize: "18px", fontWeight: "bold" }}>
          Editando VLAN {vlan.number}
        </div>
      </ModalHeader>
      <ModalBody>
        <div>
          <Label>Alterar descrição</Label>
          <InputText
            id="description"
            name="description"
            value={vlan.description}
            label="Descrição"
            onChange={handleChange}
            clearField={{
              action: clearInputField,
              text: "Limpar",
            }}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <div style={{ display: "flex" }}>
          <div className="mr-4" style={{ paddingRight: "20px" }}>
            <Button secondary onClick={() => close()}>
              Cancelar
            </Button>
          </div>
          <Button onClick={handleEditVlan} primary>
            Aplicar
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  )
}
export default EditVlan
