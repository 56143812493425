import { Button, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "@conecte-se/ui-components-reactjs"
import { validateIdentifier } from "src/common/validators"
import { DropdownScroll } from "@features/devices/components/dropdown-scroll"
import { InputText } from "@features/devices/components/inputs"
import { SeparatedButtons } from "@features/devices/components/separated-buttons/separated-buttons"
import { associateCpe } from "@features/devices/redux/cpe/cpe-actions"
import { BackendContext } from "@services/backend/backend"
import { useContext, useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { ToastContext } from "src/context/ToastContext"
import { UserContext } from "src/context/UserContext"
export const AddCpePon = ({ cpeState, errorState, close }) => {
    const backend = useContext(BackendContext)
    const user = useContext(UserContext)
    const toastContext = useContext(ToastContext)
    const olt = useSelector((state) => state.tabSlice.selected);
    const { cpe, setCpe } = cpeState
    const { errors, setErrors } = errorState
    const [isDropdownSelected, setIsDropdownSelected] = useState({
        port: false,
    })

    const handleError = (name, error) => {
        if (error) {
            setErrors({ ...errors, [name]: error })
        } else {
            const { [name]: removed, ...rest } = errors
            setErrors(rest)
        }
    }
    useEffect(() => {
        console.log(cpe)
        // eslint-disable-next-line
    }, [])
    const handleChange = (e) => {
        const { name, value } = e.target

        if (/^.{4}(?!-)/.test(value)) {
            if (value.length > 4 && value.charAt(4) !== "-") {
                const newValue = value.slice(0, 4) + "-" + value.slice(4)
                setCpe({
                    ...cpe,
                    [name]: newValue,
                })
            } else {
                setCpe({
                    ...cpe,
                    [name]: value,
                })
            }
        } else {
            setCpe({
                ...cpe,
                [name]: value,
            })
        }
    }

    const clearInputField = (property) => {
        setCpe({ ...cpe, [property]: "" })
    }
    const onChangeConfiguration = (key, value) => {
        setCpe((prevCpe) => ({
            ...prevCpe,
            configuration: {
                ...prevCpe.configuration,
                [key]: value,
            },
        }))
    }

    const handleSelect = (item, dropdownId) => {
        setCpe({
            ...cpe,
            [dropdownId]: item.id,
        })

        setIsDropdownSelected((prevSelected) => ({
            ...prevSelected,
            [dropdownId]: true,
        }))
    }

    const getSlotsByPort = (olt, port) => {
        const index = parseInt(port.substring(1), 10)
        const objPort = olt && olt.ports_G && olt?.ports_G[index - 1]
        if (objPort && objPort?.slots) {
            return objPort?.slots.map((slot) => ({
                id: slot,
                value: slot,
                label: slot?.toString(),
            }))
        }

        return []
    }
    function getModelOptions(olt) {
        if (!olt || !olt.cpes_model || !Array.isArray(olt.cpes_model)) {
            return []
        }

        const modelOptions = olt.cpes_model.map((item) => ({
            id: item.model,
            value: item.model,
            label: item.model,
        }))

        return modelOptions
    }
    const handleCancel = () => {
        close()
        clearCpe()
    }
    const handleConfirm = () => {
        associateCpe(backend, user, toastContext, cpe, 'SIG')
        close()
    }

    function clearCpe() {
        setCpe({
            id: "",
            username: "",
            password: "",
            olt_id: "",
            port: "",
            slot: "",
            model: "",
            configuration: {
                eth: {},
                veip: [],
            },
        })
    }
    function disableButton() {
        const result =
            cpe.id !== "" &&
            olt.id !== "" &&
            cpe.port !== "" &&
            cpe.slot !== "" &&
            cpe.model !== "" &&
            (Object.keys(cpe.configuration.eth).length > 0 ||
                cpe.configuration.veip.length > 0)

        return !result

    }
    return (
        <Modal visible={true} width={"500px"}>
            <ModalHeader>
                <div style={{ fontSize: "18px", fontWeight: "bold" }}>
                    Adicionar Dispositivo
                </div>
            </ModalHeader>
            <ModalBody>
                <div className="mb-3">
                    <Label>Identificador</Label>
                    <InputText
                        id="id"
                        name="id"
                        value={cpe.id}
                        label="Identificador"
                        length={{ max: 13 }}
                        onChange={handleChange}
                        validate={{
                            action: (e) => validateIdentifier(e),
                            handleError: handleError,
                        }}
                        clearField={{
                            action: clearInputField,
                            text: "Limpar",
                        }}
                    />
                </div>
                <div className="mb-3">
                    <Label>Slot Pon</Label>
                    <DropdownScroll
                        id={"slot-cpe"}
                        items={getSlotsByPort(olt, cpe.port)}
                        onSelect={(item) => handleSelect(item, "slot")}
                    />
                </div>

                <div className="mb-3">
                    <Label>Modelo</Label>
                    <DropdownScroll
                        id={"model-cpe"}
                        items={getModelOptions(olt)}
                        onSelect={(item) => handleSelect(item, "model")}
                        disabled={!isDropdownSelected.slot}
                    />
                </div>
                <div className="mb-3">
                    <Label>
                        <h3>Configurações</h3>
                    </Label>
                    <Label>
                        <h4>Adicione a quantidade que desejar de interfaces ETH/VEIP.</h4>
                    </Label>
                    <SeparatedButtons onChangeConfiguration={onChangeConfiguration} />
                </div>
            </ModalBody>
            <ModalFooter>
                <div style={{ display: "flex" }}>
                    <div className="mr-4" style={{ paddingRight: "20px" }}>
                        <Button secondary onClick={handleCancel}>
                            Cancelar
                        </Button>
                    </div>
                    <Button
                        disabled={
                            disableButton() || Object.values(errors).some((error) => error)
                        }
                        onClick={handleConfirm}
                        primary
                    >
                        Confirmar
                    </Button>
                </div>
            </ModalFooter>
        </Modal>
    )
}
