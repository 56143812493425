import {
  Button,
  Modal,
  ModalFooter,
  ModalHeader,
} from "@conecte-se/ui-components-reactjs"
import { useContext } from "react"
import { BackendContext, backendStatus } from "@services/backend/backend"
import { ToastContext } from "src/context/ToastContext"
import { UserContext } from "src/context/UserContext"

export function RemoveGroup({ modalState, groupDeleteState }) {
  const { removeGroupModal, setRemoveGroupModal } = modalState
  const { groupIdToDelete, setGroupIdToDelete } = groupDeleteState
  const backend = useContext(BackendContext)
  const toastContext = useContext(ToastContext)
  const user = useContext(UserContext)

  const deleteGroup = async () => {
    let result = await backend.delete("group", { ID: groupIdToDelete })
    setRemoveGroupModal(!removeGroupModal)
    if (result.status !== backendStatus.SUCCESS) {
      toastContext.addToast({
        title: "Erro",
        status: "error",
      })
    } else {
      let newGroups = user.group.filter((g) => g.ID !== groupIdToDelete)
      const remainingGroup = newGroups[0]?.ID ? newGroups[0]?.ID : null
      user.setGroup(newGroups)
      user.setActiveGroup(remainingGroup)
      user.fetchRole(remainingGroup)
      localStorage.setItem("active_group", remainingGroup)
      setGroupIdToDelete("")
      toastContext.addToast({
        title: 'Organização excluída com sucesso!',
        status: 'success'
      })
    }
  }

  return (
    <Modal visible={removeGroupModal} width={"600px"}>
      <ModalHeader>
        <div className="mb-2" style={{ fontSize: "18px", fontWeight: "bold" }}>
          Deseja realmente excluir esta organização?
        </div>
        <div style={{ fontSize: "14px" }}>
          <p style={{ textAlign: "center" }}>
            Isso implicará na <b>remoção permanente</b> dos dados deste grupo,
            incluindo os dispostivos e membros associados a ele.<br></br>
            <br></br> Em caso de dúvida, ligue para o nosso suporte
            especializado:{" "}
            <a
              href="https://wa.me/48996279905"
              style={{ textDecorationLine: "underline" }}
            >
              (48)9 9627-9905
            </a>
          </p>
        </div>
      </ModalHeader>
      <ModalFooter>
        <div style={{ display: "flex" }}>
          <div className="mr-4" style={{ paddingRight: "20px" }}>
            <Button
              secondary
              onClick={() => {
                setRemoveGroupModal(!removeGroupModal)
              }}
            >
              Cancelar
            </Button>
          </div>
          <Button onClick={deleteGroup}>Excluir</Button>
        </div>
      </ModalFooter>
    </Modal>
  )
}
