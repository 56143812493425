import {
    Button,
    Input,
    InputFile,
    InputIconInside,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
} from "@conecte-se/ui-components-reactjs"
import { useContext, useEffect, useState } from "react"
import { MdClose, MdImageSearch } from "react-icons/md"
import { cnpjMask } from "src/common/masks"
import { BackendContext, backendStatus } from "@services/backend/backend"
import { ToastContext } from "src/context/ToastContext"
import { UserContext } from "src/context/UserContext";
export function GroupFormModal({ isEdit = false, isModalOpen, setIsModalOpen, groupToBeEdited, groups }) {
    const backend = useContext(BackendContext)
    const toastContext = useContext(ToastContext)
    const user = useContext(UserContext)
    const [nameInEdit, setNameInEdit] = useState('')
    const [groupForm, setGroupForm] = useState({
        ID: "",
        Name: "",
        Cnpj: "",
    })
    const [groupLogo, setGroupLogo] = useState(null)
    useEffect(() => {
        if (isModalOpen && isEdit) {
            setNameInEdit(groupToBeEdited.Name)
            setGroupForm({
                ID: groupToBeEdited.ID,
                Name: groupToBeEdited.Name,
                Cnpj: groupToBeEdited.Cnpj,
            })
        } else {
            setNameInEdit("")
        }
    }, [isModalOpen, setGroupForm, groupToBeEdited, isEdit])

    const handleFormChange = (attribute, value) => {
        setGroupForm({
            ...groupForm,
            [attribute]: value,
        })
    }

    const updateFile = (e) => {
        const [file] = e
        const validFormats = ['image/jpeg', 'image/jpg', 'image/png']
        if (!validFormats.includes(file.type)) {
            showFeedbackMessage('error', 'Formato da imagem inválido! (Formatos permitidos: JPG, JPEG, PNG)')
        } else if (file.size > 1000000) {
            showFeedbackMessage('error', 'Tamanho da imagem inválido! (Máximo até 1Mb)')
        } else {
            setGroupLogo(e)
        }

    }

    const applyNewGroup = async () => {
        const payload = {
            Name: normalizeString(groupForm.Name),
            Cnpj: normalizeString(groupForm.Cnpj),
        }
        let result = await backend.create("group", payload)
        if (result.status === backendStatus.SUCCESS) {
            if (groupLogo) {
                handleChangeLogoImgUpload(result.content)
            }
            showFeedbackMessage("success", "Nova organização criada com sucesso!")
        } else {
            showFeedbackMessage("error", "Erro ao criar nova organização")
        }
        user.fetchGroup()
        close(false)
    }

    const applyEditOrg = async () => {
        const payload = {
            Id: groupForm.ID,
            Name: normalizeString(groupForm.Name),
            Cnpj: normalizeString(groupForm.Cnpj),
        }
        let result = await backend.update("group", payload)
        if (result.status === backendStatus.SUCCESS) {
            if (groupLogo) {
                handleChangeLogoImgUpload(groupForm.ID)
            }
            showFeedbackMessage("success", "Organização editada com sucesso!")
        } else {
            showFeedbackMessage("error", "Erro ao editar organização")
        }
        user.fetchGroup()
        close()
    }

    const showFeedbackMessage = (status, title) => {
        toastContext.addToast({
            title,
            status
        })
    }

    const clearFields = () => {
        setGroupForm({
            ID: "",
            Name: "",
            Cnpj: "",
        })
        clearFiles()
    }

    const close = () => {
        setIsModalOpen(false)
        clearFields()
    }

    function onSubmit() {
        if (isEdit) {
            applyEditOrg()
        } else {
            applyNewGroup()
        }
    }

    function isCnpjValid(cnpj) {
        cnpj = cnpj.replace(/[^\d]+/g, '');

        if (cnpj.length !== 14) return false;

        if (/^(\d)\1+$/.test(cnpj)) return false;

        function calculateDigit(cnpj, pos) {
            let sum = 0;
            let multiplier = pos === 1 ? [5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2] : [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
            for (let i = 0; i < multiplier.length; i++) {
                sum += cnpj[i] * multiplier[i];
            }
            let digit = (sum % 11);
            return digit < 2 ? 0 : 11 - digit;
        }

        if (calculateDigit(cnpj, 1) !== parseInt(cnpj[12])) return false;
        if (calculateDigit(cnpj, 2) !== parseInt(cnpj[13])) return false;
        return true;
    }

    const handleChangeLogoImgUpload = async (groupId) => {
        const file = groupLogo[0]

        let headers = {}
        headers["Authorization"] = `Bearer ${backend.auth}`
        //headers['Content-Type'] = 'multipart/form-data; boundary="?"'

        var data = new FormData()
        data.append("file", file)

        let result = await fetch(
            `${process.env.REACT_APP_FILE_SERVER_ENDPOINT}/logo/${groupId}`,
            {
                method: "POST",
                body: data,
                headers: headers,
            }
        )

        let response = await result.text()
        let parsedResp = await JSON.parse(response)

        if (result.status === 200) {
            //user.setAvatar(URL.createObjectURL(file))
        } else {
            toastContext.addToast({
                title: parsedResp,
                status: "error",
            })
        }
    }

    function clearFiles() {
        setGroupLogo(null)
    }

    function normalizeString(string) {
        return string.trim().replaceAll('  ', ' ')
    }

    function isNameValid() {
        if (!!groups) {
            const groupNames = groups.map(({ Name }) => Name.toLowerCase()).filter(name => name !== nameInEdit);
            const normalizedGroupName = normalizeString(groupForm.Name).toLowerCase();
            return !groupNames.includes(normalizedGroupName)
        } return true

    }

    function shouldDisableButton() {
        return !groupForm.Name.length || !isNameValid() || (!!groupForm.Cnpj.length && !isCnpjValid(groupForm.Cnpj))
    }

    return (
        <Modal visible={isModalOpen} width={"500px"}>
            <ModalHeader>
                <span style={{ fontSize: "18px", fontWeight: "bold" }}>
                    {isEdit ? 'Editar' : 'Nova'}  organização:
                </span>
            </ModalHeader>
            <ModalBody>
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <div style={{ textAlign: "left", width: "95%" }}>
                        <div style={{ margin: "10px" }}>
                            <Label>Nome*</Label>
                            <Input
                                id="input-1"
                                placeholder={"Nome da empresa/organização"}
                                onChange={(e) => {
                                    handleFormChange("Name", e.target.value)
                                }}
                                value={groupForm.Name}
                            />
                            <span style={{ color: 'red' }}>
                                {isNameValid() ? '' : 'Nome já utilizado'}
                            </span>
                        </div>
                        <div style={{ margin: "10px" }}>
                            <Label>CNPJ</Label>
                            <Input
                                id="input-2"
                                placeholder={"CNPJ"}
                                onChange={(e) => {
                                    handleFormChange("Cnpj", cnpjMask(e.target.value))
                                }}
                                value={groupForm.Cnpj}
                            />
                            {groupForm.Cnpj.length > 0 ? <span style={{ color: 'red' }}>
                                {isCnpjValid(groupForm.Cnpj) ? '' : 'CNPJ inválido'}
                            </span> : ''}

                        </div>
                        <div style={{ margin: "10px" }}>
                            <Label>Logo</Label>
                            <div style={{ cursor: "pointer" }}>
                                <InputIconInside
                                    iconRight={
                                        !groupLogo ? (
                                            <MdImageSearch />
                                        ) : (
                                            <MdClose onClick={clearFiles} />
                                        )
                                    }
                                >
                                    <InputFile
                                        id="input-6"
                                        placeholder="Insira a logo da sua organização"
                                        files={groupLogo}
                                        accept="image/jpg, image/png, image/jpeg"
                                        onChange={updateFile}
                                        onClick={(e) => (e.target.value = null)}
                                        name="file"
                                    />
                                </InputIconInside>
                            </div>
                        </div>
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <div style={{ display: "flex" }}>
                    <div className="mr-4" style={{ paddingRight: "20px" }}>
                        <Button
                            secondary
                            onClick={() => {
                                close(false)
                            }}
                        >
                            Cancelar
                        </Button>
                    </div>
                    <Button disabled={shouldDisableButton()} onClick={onSubmit}>
                        Aplicar
                    </Button>
                </div>
            </ModalFooter>
        </Modal>
    )
}
