import React from "react"
import InformationOnt from "./information-ont"
import { TYPE_OLT, TYPE_ONT } from "@features/devices/common/constants";
import EditOlt from "../olt/modal/edit-olt";

export const DeviceInformation = ({ close, id, type }) => {

    const handleDeviceInfo = () => {
        if (type === TYPE_ONT) {
            return <InformationOnt close={close} id={id} />;
        } else if (type === TYPE_OLT) {
            return <EditOlt id={id} close={close} />
        }

        return null;
    };

    return (
        <>
            {id ? handleDeviceInfo() : null}
        </>
    );
};
