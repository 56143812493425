import {
    Button,
    Dropdown,
    DropdownMenu,
    TablePagination,
    ToastContainer,
} from "@conecte-se/ui-components-reactjs"
import React, { useContext, useEffect, useState } from "react"
import {
    MdAdd,
    MdDeleteOutline,
    MdInfoOutline,
    MdOpenInNew,
    MdOutlineViewWeek,
    MdRestartAlt,
} from "react-icons/md"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { BackendContext } from "@services/backend/backend"
import { formatDate } from "src/common/validators"
import { ToastContext } from "src/context/ToastContext"
import { UserContext } from "src/context/UserContext"
import { DeviceTable } from "../components/device-table"
import { DropdownDevices } from "../components/dropdown-devices"
import { AddDevice, ModalConfirm } from "../components/modal"
// import { SearchBar } from "../components/search-bar"
import {
    dissociateGateway,
} from "../redux/gateway/gateway-actions"
import { ActivateCpe } from "./modal/activate-cpe"
import "./devices.css"
import { EditGateway } from "./gateway/edit-gateway"
import { InfoGateway } from "./gateway/info-gateway"
import { initialPreferences, loadPreferences, savePreferences } from "../common/preferences"
import { addTab } from '@features/devices/redux/deviceTab/device-slice';
import { dissociate, fetchDevices, resourceDevice } from "../redux/deviceTab/device-actions"
import { DEVICE_STATE, getDeviceTypeName, RESOURCE_RESTART, TYPE_ONT } from '@features/devices/common/constants'
import { CircleStatus } from "../components/circle-status"
import { DeviceInformation } from "./modal/device-information"
export function Devices() {
    const navigate = useNavigate()
    const backend = useContext(BackendContext)
    const user = useContext(UserContext)
    const toastContext = useContext(ToastContext)
    const dispatch = useDispatch()
    const devices = useSelector((state) => state.tabSlice.devices)

    const [deviceid, setDeviceid] = useState()
    const [deviceType, setDeviceType] = useState()
    const [gateway] = useState()
    const [tableRows, setTableRows] = useState([])
    const [caseModal, setCaseModal] = useState()
    const [filters, setFilters] = useState(() => loadPreferences('filters'))
    const [columnsDynamically, setColumnsDynamically] = useState(
        () => loadPreferences('columns')
    )
    const [query, setQuery] = useState(filters.query)
    const [showList] = useState(true)
    const [showProjects] = useState(false)
    const [paginationState, setPaginationState] = useState({
        pageSize: 10,
        totalItems: 0,
        totalPages: 1,
        currentPage: 1
    })


    const handlePageChange = (newPage) => {
        setPaginationState((prevState) => ({
            ...prevState,
            currentPage: newPage
        }));
        handleFetchDevices(newPage)
    }
    const [dropdownTypes /*, setDropdownTypes*/] = useState([
        { label: 'Todos', value: 0, size: 0 },
        { label: "Gateway", value: 1, size: 0, disabled: false },
        { label: "OLT", value: 2, size: 0, disabled: false },
        { label: "ONT", value: 3, size: 0, disabled: false },
        { label: "Roteador", value: 4, size: 0, disabled: true },
        { label: "Switch", value: 5, size: 0, disabled: true },
        { label: "Telefone", value: 6, size: 0, disabled: true },
    ])

    function closeModal() {
        setCaseModal(null)
    }
    function openModal(option) {
        setCaseModal(option)
    }

    const modal = (caseModal) => {
        switch (caseModal) {
            case "add":
                return <AddDevice close={closeModal} handleFilters={handleFilters} updateFilters={updateFilters} />
            case "activate-ont":
                return [
                    <ActivateCpe close={closeModal} ontId={deviceid} />
                ]
            case "delete":
                return [
                    <ModalConfirm
                        close={closeModal}
                        action={handleDissociate}
                        title={"Deletar dispositivo"}
                        message={`Você tem certeza que deseja deletar esse dispositivo?`}
                    />,
                ]
            case "info-gateway":
                return <InfoGateway close={closeModal} selected={gateway} />
            case "edit-gateway":
                return <EditGateway close={closeModal} selected={gateway} />
            case "delete-gateway":
                return [
                    <ModalConfirm
                        close={closeModal}
                        action={handleDissociateGateway}
                        title={"Desassociar Gateway"}
                        message={`Você tem certeza que deseja desassociar esse Gateway?`}
                    />,
                ]
            case "restart":
                return [<ModalConfirm
                    close={closeModal}
                    action={handleRestart}
                    title={"Reiniciar dispositivo"}
                    message={`Você tem certeza que deseja reiniciar esse dispositivo?`}
                />,]
            case "information":
                return <DeviceInformation close={closeModal} id={deviceid} type={deviceType} />

            default:
                return
        }
    }

    const handleDissociateGateway = () => {
        dissociateGateway(backend, user, toastContext, dispatch, gateway.id)
        closeModal()
    }
    const handleDissociate = () => {
        dissociate(backend, user, toastContext, dispatch, deviceid)
        closeModal()
    }

    const handleRestart = (id) => {
        resourceDevice(backend, toastContext, RESOURCE_RESTART, deviceid)
        closeModal()
    }

    const handleFetchDevices = async (pageNumber) => {
        const result = await fetchDevices(backend,
            user,
            toastContext,
            dispatch,
            pageNumber,
            filters.type,
            filters.status,
            paginationState.pageSize)

        setPaginationState(result)
    }

    useEffect(() => {
        if (user.activeGroup != null) {
            // fetchOlts(backend, user, toastContext, dispatch, 1)
            // setOlt({})
            handleFilters("type", filters.type)
        }
        // eslint-disable-next-line
    }, [user.activeGroup])

    useEffect(() => {
        rowsDynamicallyRemoved()
        // eslint-disable-next-line
    }, [devices])

    function updateColumns(field) {
        return columnsDynamically.map((item) => {
            if (item.field === field) {
                const itemLocal = item
                itemLocal.display = !item?.display
                return itemLocal
            }
            return item
        })
    }

    function updateCombobox(field) {
        return comboxDynamicallyCol.map((item) => {
            if (item.id === field) {
                const itemLocal = item
                itemLocal.checkbox.checked = !item.checkbox.checked
                return itemLocal
            }
            return item
        })
    }

    function onCheckRemoveCol(field) {
        const action = columnsDynamically.filter((col) => col.field === field)[0]
            .display

        const numDisplayed = columnsDynamically.filter((col) => col.display).length

        if ((action && numDisplayed === 1) || field === "last_hello") {
            return
        }
        const newCol = updateColumns(field)
        setColumnsDynamically(newCol)
        setComboxDynamicallyCol(updateCombobox(field))
        savePreferences('columns', newCol)
    }

    const getProduct = async (device) => {
        var newDevice = { ...device }
        dispatch(addTab(newDevice));
        navigate(`/manage/${newDevice.id}`)
    }

    const menu_itens = columnsDynamically.map((col) => {
        return {
            label: col?.name,
            id: col?.field,
            checkbox: {
                checked: col?.display,
                onChange: () => {
                    onCheckRemoveCol(col?.field)
                },
            },
        }
    })

    function showDeviceModel(model) {
        return (
            <div
                style={{
                    alignItems: "center",
                }}
            >
                <span>{model}</span>
            </div>
        )
    }

    function showDeviceProtocols(protocols) {
        if (!protocols) {
            return (
                <div>
                    <span>No protocols available</span>
                </div>
            );
        }

        const protocolView = Object.entries(protocols)
            .filter(([key, value]) => value)
            .map(([key]) => key.toUpperCase())
            .join('/');

        return (
            <div>
                <span>{protocolView}</span>
            </div>
        );
    }

    const rowsDynamicallyRemoved = () => {
        let rows = []

        devices?.forEach((x, i) => {
            rows.push({
                id: x.id,
                status: (
                    <div>
                        <CircleStatus statusCode={x.conn_status} stateCode={x.device_state} />
                    </div>
                ),
                name: x.alias,
                type: getDeviceTypeName(x.type),
                model: showDeviceModel(x.model),
                protocols: showDeviceProtocols(x.protocols),
                fw_version: <div>{x.fw_version}</div>,
                last_hello: formatDate(x.last_hello),
                perfil: <div>Intelbras</div>,
                actions: (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {x.device_state !== DEVICE_STATE.NOT_PROVISIONED ?
                            <div style={{ display: 'flex', alignItems: 'center', paddingRight: '20px' }}
                                onClick={() => { setDeviceid(x.id); setDeviceType(x.type); openModal('information') }}
                            >
                                <MdInfoOutline
                                    title="INFORMAÇÃO"
                                    size={25}
                                    style={{ cursor: "pointer", transition: 'transform 0.3s' }}
                                />
                            </div>
                            : null}
                        {x.device_state !== DEVICE_STATE.NOT_PROVISIONED ?
                            <div style={{ display: 'flex', alignItems: 'center', paddingRight: '20px' }}
                                onClick={() => { setDeviceid(x.id); openModal('restart') }}
                            >
                                <MdRestartAlt
                                    title="REINICIAR"
                                    size={25}
                                    style={{ cursor: "pointer", transition: 'transform 0.3s' }}
                                />
                            </div>
                            : null}
                        {x.device_state !== DEVICE_STATE.NOT_PROVISIONED ?
                            <div style={{ display: 'flex', alignItems: 'center', paddingRight: '20px' }}
                                onClick={() => { setDeviceid(x.id); openModal('delete') }}
                            >
                                <MdDeleteOutline
                                    title="DELETAR"
                                    size={25}
                                    style={{ cursor: "pointer", transition: 'transform 0.3s' }}
                                />

                            </div>
                            : null}

                        {(x.type !== TYPE_ONT && x.protocols?.tr069 === true) ||
                            (x.type !== TYPE_ONT && x.device_state !== DEVICE_STATE.NOT_PROVISIONED)
                            ?
                            <div style={{ display: 'flex', alignItems: 'center', paddingRight: '20px' }}
                                onClick={() => getProduct(x)}>
                                <MdOpenInNew
                                    title="Abrir"
                                    size={22}
                                    style={{ cursor: "pointer", transition: 'transform 0.3s' }}
                                />
                            </div>
                            : null}
                        {x.device_state === DEVICE_STATE.NOT_PROVISIONED ?
                            <div style={{ display: 'flex', alignItems: 'center', paddingRight: '20px' }}
                                onClick={() => { setDeviceid(x.id); openModal('activate-ont') }}>
                                <MdAdd
                                    title="Provisionar"
                                    size={22}
                                    style={{ cursor: "pointer", transition: 'transform 0.3s' }}
                                />
                            </div>
                            : null}
                    </div>
                )
            })
        })


        setTableRows(rows)
    }

    const [comboxDynamicallyCol, setComboxDynamicallyCol] = useState(menu_itens)

    const [expandedCombobox, seteEpandedCombobox] = useState(false)

    function changeExpandedCombobox() {
        seteEpandedCombobox(!expandedCombobox)
    }

    function handleFilters(id, value) {
        const newFilters = updateFilters(id, value)
        setFilters(newFilters)
        savePreferences('filters', newFilters)
        setPaginationState((prevState) => ({
            ...prevState,
            currentPage: 1
        }));
    }
    const handleDropdownChange = (dropdown, item) => {
        handleFilters(dropdown, item.value)
    }

    const updateFilters = (id, value) => {
        let newStatus = filters.status
        let idNew = id === "status" ? value : newStatus
        const newFilters = {
            ...filters,
            [id]: value,
            status: idNew
        };
        setFilters(newFilters)
        return newFilters
    }

    useEffect(() => {
        if (user.activeGroup != null) {
            handleFetchDevices(1)
        }
        // eslint-disable-next-line
    }, [filters])

    const clearFilter = () => {
        savePreferences('filters', initialPreferences.filters)
        setFilters(initialPreferences.filters)
        setPaginationState((prevState) => ({
            ...prevState,
            currentPage: 1
        }));

        window.location.reload();
    }

    const [rowExpanded, setRowExpanded] = useState(false)
    function rowsExpand() {
        setRowExpanded(() => !rowExpanded)
    }
    function pageChange(newValue) {
        setPaginationState((prevState) => ({
            ...prevState,
            pageSize: newValue,
            currentPage: 1
        }));
    }
    return (<React.Fragment>
        <div className="page-device-list">
            <div style={{ display: "flex" }}>
                <div style={{ display: "flex", flex: "1" }}>{modal(caseModal)}</div>
                <div className="button-add">
                <Button
                    disabled={!user.activeGroup}
                    icon={<MdAdd />}
                    onClick={() => openModal("add")}>
                    Adicionar
                </Button>
                </div>

            </div>

            <div className="centered-container">
                <span style={{ padding: "0px 10px 0px 10px" }}>Tipo:</span>
                <DropdownDevices
                    items={dropdownTypes}
                    onChange={(item) => handleDropdownChange("type", item)}
                    selected={filters.type}
                />
                {/* <span style={{ padding: "0px 10px 0px 10px" }}>Status:</span> */}
                {/* <DropdownDevices
          items={dropdownStatus}
          onChange={(item) => handleDropdownChange("status", item)}
          selected={filters.status}
          width={"200px"}
        /> */}
                {/* <div style={{ paddingLeft: "25%", display: 'flex' }}>
                    <SearchBar
                        valueState={{ query, setQuery }}
                        action={() => handleFilters("query", query)}
                    />
                    <div style={{ paddingLeft: '15px', paddingTop: '6px' }}>
                        <span className="clear-filters" onClick={clearFilter}>Limpar filtro</span>
                    </div>
                </div> */}

                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "auto",
                        marginRight: "0",
                        width: "fit-content",
                    }}
                >
                    <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                        <TablePagination
                            rowsPerPageOptions={[10, 15, 20]}
                            onClickExpandRowsOptions={rowsExpand}
                            expandRowsOptions={rowExpanded}
                            page={paginationState.currentPage}
                            onPageChange={handlePageChange}
                            totalItens={paginationState.totalItems}
                            rowsPerPage={paginationState.totalItems < paginationState.pageSize ? paginationState.totalItems : paginationState.pageSize}
                            onRowsPerPageChange={pageChange}
                            small
                        />
                        <Dropdown id="combobox" width="fit-content" right="0">
                            <MdOutlineViewWeek
                                size={20}
                                style={{ cursor: "pointer" }}
                                onClick={changeExpandedCombobox}
                            />
                            <DropdownMenu
                                visible={expandedCombobox}
                                itens={comboxDynamicallyCol}
                                width="100%"
                                right="0"
                            />
                        </Dropdown>
                    </div>
                </div>
            </div>

            {showList && tableRows ? (
                <DeviceTable columns={columnsDynamically} rows={tableRows} />
            ) : null}

            {showProjects ? <p>Groups view</p> : null}
            <ToastContainer toasts={toastContext.toasts} position={"bottom_right"} />

        </div>
    </React.Fragment >
    )
}
