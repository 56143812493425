import { backendStatus } from "@services/backend/backend"
import { appendVlan, setVlans } from "./vlan-slice"

export const retrieveVlans = async (
  backend,
  user,
  oltId,
  toastContext,
  dispatch
) => {
  let result = await backend.retrieve(`/eth/group?id=${oltId}`)
  if (result.status !== backendStatus.SUCCESS) {
    toastContext.addToast({
      title: result.content.body,
      status: "error",
    })
  } else {
    console.log(result.content)
    dispatch(setVlans(result.content ? result.content : []))
  }
}
export const addVlan = async (backend, user, toastContext, dispatch, vlan) => {
  let result = await backend.create("/eth", {
    ...vlan,
  })
  if (result.status !== backendStatus.SUCCESS) {
    toastContext.addToast({
      title: result.content.body,
      status: "error",
    })
  } else {
    toastContext.addToast({
      title: "Operação efetuada com sucesso!",
      status: "success",
    })
    // dispatch(appendVlan(result.content))
  }
}
export const updateVlan = async (backend, toastContext, vlan) => {
  let result = await backend.update(`/eth`, {
    ...vlan,
  })
  if (result.status !== backendStatus.SUCCESS) {
    toastContext.addToast({
      title: result.content.body,
      status: "error",
    })
  } else {
    toastContext.addToast({
      title: "Operação efetuada com sucesso!",
      status: "success",
    })
  }
}

export const changeInterfaceMode = async (
  backend,
  user,
  toastContext,
  newInterfaceMode
) => {
  let result = await backend.update(`/eth/group`, {
    ...newInterfaceMode,
  })
  if (result.status !== backendStatus.SUCCESS) {
    toastContext.addToast({
      title: result.content.body,
      status: "error",
    })
  } else {
    toastContext.addToast({
      title: "Operação efetuada com sucesso!",
      status: "success",
    })
  }
}

export const deleteVlan = async (backend, user, toastContext, deletedVlan) => {
  let result = await backend.delete(`/eth?id=${deletedVlan.id}`)
  if (result.status !== backendStatus.SUCCESS) {
    toastContext.addToast({
      title: result.content.body,
      status: "error",
    })
  } else {
    toastContext.addToast({
      title: "Operação efetuada com sucesso!",
      status: "success",
    })
  }
}
