import React from 'react'

import pppoeConstants from './pppoe-constants'

import WanDNS from './wan-dns';
import Loading from '@lib/midgard/loading/loading';
import Input from '@lib/midgard/input/input';
import common from '@lib/midgard/form/validators/common';
import network from '@lib/midgard/form/validators/network';
import Checkbox from '@lib/midgard/checkbox/checkbox';
import DeviceFactory from '@features/devices/management/device-factory'

const INPUT_MAX_OFFSET = 100000

export default function WanPPPoE({_interface, pppoe, setPPPoE, wan, setWan, device, wanIndex}) {

    const handleMTU = (e) => {

        let value = e.target.value

        if(isNaN(value)) return

        let mtu = Number(value)

        if(value > pppoeConstants.PPPOE_MAX_MTU_VALUE + INPUT_MAX_OFFSET) return

        pppoe.mtu = mtu
        setPPPoE({...pppoe})
    }

    const getPPPoEPasswordValidators = () => {

        let validators = [common.nonASCII]
        let minLength = 0

        if(!DeviceFactory.isTR069(device.data.model)) {
            validators.push(common.required)
            minLength = pppoeConstants.PPPOE_FIELD_MIN_SIZE
        }

        validators.push({fn:common.size, params:{min: minLength, max: pppoeConstants.PPPOE_FIELD_MAX_SIZE}})

        return validators
    }

    return (
        !pppoe ? <Loading show={true}></Loading> :
        <React.Fragment>
            <Input id='wan-pppoe-username'
                name='username'
                label='Usuário'
                value={pppoe.username}
                onChange={(e) => {
                    pppoe.username = e.target.value
                    setPPPoE({...pppoe})
                }}
                validators={[
                    common.required,
                    common.nonASCII,
                    {fn:common.size, params:{min: pppoeConstants.PPPOE_FIELD_MIN_SIZE, max: pppoeConstants.PPPOE_FIELD_MAX_SIZE}}
                ]}
            ></Input>
            <Input id='wan-pppoe-password'
                name='password'
                label='Senha'
                value={pppoe.password}
                onChange={(e) => {
                    pppoe.password = e.target.value
                    setPPPoE({...pppoe})
                }}
                validators={getPPPoEPasswordValidators()}
            ></Input>
            <Input id='wan-pppoe-server'
                name='server'
                label='Servidor'
                value={pppoe.server}
                onChange={(e) => {
                    pppoe.server = e.target.value
                    setPPPoE({...pppoe})
                }}
                validators={[
                    common.nonASCII,
                    {fn:common.size, params:{min: pppoeConstants.PPPOE_FIELD_MIN_SIZE, max: pppoeConstants.PPPOE_SERVER_FIELD_MAX_SIZE}}
                ]}
            ></Input>
            <Input id='wan-pppoe-service'
                name='service'
                label='Nome de serviço'
                value={pppoe.service}
                onChange={(e) => {
                    pppoe.service = e.target.value
                    setPPPoE({...pppoe})
                }}
                validators={[
                    common.nonASCII,
                    {fn:common.size, params:{min: pppoeConstants.PPPOE_FIELD_MIN_SIZE, max: pppoeConstants.PPPOE_SERVER_FIELD_MAX_SIZE}}
                ]}
            ></Input>
            <Input id='wan-pppoe-mtu'
                name='mtu'
                label='MTU'
                value={pppoe.mtu}
                onChange={handleMTU}
                validators={[
                    common.required,
                    network.pppoeMtu
                ]}
            ></Input>

            <Checkbox
                id={'wan-pppoe-toggle'}
                label='Obter DNS automaticamente'
                value={wan[wanIndex].dns_v4.automatic}
                toggleFn={() => {
                    wan[wanIndex].dns_v4.automatic = !wan[wanIndex].dns_v4.automatic
                    setWan([...wan])
                }}
            ></Checkbox>

            {WanDNS(_interface, wan, setWan, wanIndex)}

        </React.Fragment>
    )
}