import {
  Button,
  Modal,
  ModalFooter,
  ModalHeader,
} from "@conecte-se/ui-components-reactjs"
import { useContext } from "react"
import { BackendContext, backendStatus } from "@services/backend/backend"
import { ToastContext } from "src/context/ToastContext"
import { UserContext } from "src/context/UserContext"

export function LeaveGroup({ modalState, groupState, loggedUserState }) {
  const { goOutOrg, setGoOutOrg } = modalState
  const { groupIdToGoOut, setGroupIdToGoOut } = groupState
  const { loggedUser } = loggedUserState
  const backend = useContext(BackendContext)
  const user = useContext(UserContext)
  const toastContext = useContext(ToastContext)
  const desassociateOrg = async () => {
    let result = await backend.delete(
      "/group/" + groupIdToGoOut + "/" + loggedUser.Email
    )
    if (result.status === backendStatus.SUCCESS) {
      toastContext.addToast({
        title: "Operação efetuada com sucesso!",
        status: "success",
      })
      user.setGroup(user.group.filter((item) => item.ID !== groupIdToGoOut))
      if (user.group.length === 0) {
        user.setActiveGroup(null)
      } else {
        user.setActiveGroup(user.group[0].ID)
      }
      setGroupIdToGoOut(null)
      setGoOutOrg(!goOutOrg)
    } else {
      console.log("error:", result.content.body)
      if (result.content.body !== "") {
        toastContext.addToast({
          title: result.content.body,
          status: "error",
        })
      } else {
        toastContext.addToast({
          title: "Erro ao sair da organização",
          status: "error",
        })
      }
    }
  }
  return (
    <Modal visible={goOutOrg} width={"600px"}>
      <ModalHeader>
        <div>Deseja realmente sair desta organização?</div>
      </ModalHeader>
      <ModalFooter>
        <div style={{ display: "flex" }}>
          <div className="mr-4" style={{ paddingRight: "20px" }}>
            <Button
              secondary
              onClick={() => {
                setGoOutOrg(false)
              }}
            >
              Cancelar
            </Button>
          </div>
          <Button onClick={() => desassociateOrg()}>Sair</Button>
        </div>
      </ModalFooter>
    </Modal>
  )
}
