import React, { useContext, useEffect, useRef, useState } from 'react'
import { backendStatus } from '@services/backend/backend'
import Form from '@lib/midgard/form/form'
import common from '@lib/midgard/form/validators/common'
import network from '@lib/midgard/form/validators/network'
import Input from '@lib/midgard/input/input'
import Checkbox from '@lib/midgard/checkbox/checkbox'
import Loading from '@lib/midgard/loading/loading'
import DefaultStatusModals from '@lib/midgard/modal/default-status-modals'
import iputils from '@lib/midgard/utils/iputils'
import { WanOpMode } from '../wan/wan-constants'
import { DeviceContext } from '@features/devices/management/manage-page'

import './lan.css'
import DeviceFactory from '@features/devices/management/device-factory'

export default function LanManagementPage() {

    const [lan, setLan] = useState(null)
    const [wan, setWan] = useState(null)
    const [lanDhcp, setLanDhcp] = useState(null)
    const [lansGate, setLansGate] = useState(null)
    const [lanGroup, setlanGroup] = useState(null)
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(false)
    const [saving, setSaving] = useState(false)

    const manageCtx = useContext(DeviceContext)
    const lanTimer = useRef(null)
    const wanTimer = useRef(null)
    const lanGroupTimer = useRef(null)
    const mounted = useRef(true)

    if (DeviceFactory.isTR069(manageCtx.deviceInfo.model)) {
        WanOpMode['BRIDGE'] = 3 //Temp: sharing lan page with tr069. Needs a refact.
    }

    useEffect(() => {

        fetchLanGroup()
        fetchWan()

        return () => {
            mounted.current = false
            clearTimeout(lanTimer.current)
            clearTimeout(wanTimer.current)
        }

        // eslint-disable-next-line
    }, [])


    const fetchLanGroup = async () => {

        let result = await manageCtx.device.retrieveResource('lan-group')
        if (result.status !== backendStatus.SUCCESS) {
            console.error('error fetching resource: lan-group')
            if (mounted.current)
                lanGroupTimer.current = setTimeout(fetchLanGroup, 5000)
            return
        }
        setlanGroup(result.content)
        setLanDhcp(result.content.dhcpserver)
        setLansGate(result.content.laninterface)
        window.oldLanGroup = Object.assign({}, result.content.dhcpserver);

        let lan = result.content.laninterface
        result = await manageCtx.device.retrieveResource(`interface/${lan.interfaceID}`)
        if (result.status !== backendStatus.SUCCESS) {
            console.error('error fetching resource: lan')
            if (mounted.current)
                lanTimer.current = setTimeout(fetchLanGroup, 5000)
            return
        }

        setLan(result.content)
        window.oldlan = Object.assign({}, result.content);
    }

    const fetchWan = async () => {
        let result = await manageCtx.device.retrieveResource('wan')
        if (result.status !== backendStatus.SUCCESS) {
            console.error('error fetching resource: wan')
            if (mounted.current)
                wanTimer.current = setTimeout(fetchWan, 5000)
            return
        }

        let wan = result.content[0]
        let interfaceID = ''
        if (wan.mode === WanOpMode.PPPOE_MODE) {

            result = await manageCtx.device.retrieveResource('pppoe')
            if (result.status !== backendStatus.SUCCESS) {
                console.error('error fetching resource: wan - pppoe')
                if (mounted.current)
                    wanTimer.current = setTimeout(fetchWan, 5000)
                return
            }

            interfaceID = result.content[0].interfaceID

        } else {
            interfaceID = wan.interfaceID
        }


        result = await manageCtx.device.retrieveResource(`interface/${interfaceID}`)
        if (result.status !== backendStatus.SUCCESS) {
            console.error('error fetching resource: wan')
            if (mounted.current)
                wanTimer.current = setTimeout(fetchWan, 5000)
            return
        }

        setWan(result.content)
    }



    const wanConflict = (value) => {

        if (wan.ip4 === "")
            return ''

        if (iputils.sameNetwork(value, wan.ip4, wan.netmask) ||
            iputils.sameNetwork(value, wan.ip4, lan.netmask)) {
            return 'Este endereço está em conflito com sua rede WAN'
        }

        return ''

    }

    const saveLan = async () => {

        setSaving(true)
        if (JSON.stringify(lan) !== JSON.stringify(window.oldlan)) {
            let result = await manageCtx.device.updateResource('interface', lan)

            if (result.status !== backendStatus.SUCCESS) {
                setSaving(false)
                setError(true)
                return
            }
        }

        if (JSON.stringify(lanGroup.dhcpserver) !== JSON.stringify(window.oldLanGroup)) {
            if (!DeviceFactory.isRFO(manageCtx.deviceInfo.model)) {
                lanGroup.id = lanGroup.laninterface.interfaceID
            }
            let result = await manageCtx.device.updateResource('lan-group', lanGroup)


            if (result.status !== backendStatus.SUCCESS) {
                setSaving(false)
                setError(true)
                return
            }
        }
        let result = await manageCtx.device.apply()

        if (result.status !== backendStatus.SUCCESS) {
            setSaving(false)
            setError(true)
            return
        }

        setSaving(false)
        setSuccess(true)

    }

    const dismissModal = () => {
        setSuccess(false)
        setSaving(false)
        setError(false)
    }

    return <div className='container'>
        <div className='lan-management-page'>

            <DefaultStatusModals
                success={success}
                error={error}
                saving={saving}
                continueFn={dismissModal}
            ></DefaultStatusModals>

            {!lan || !wan || !lanDhcp ? <Loading show={true}></Loading> :
                <div className='lan-flex-container'>
                    <div className='lan-ip'>
                        <Form onSubmit={saveLan} buttonId='button-save-lan'>

                            <div className='subtitle'>Configurações da LAN</div>

                            <Input id='lan-ip' label='Endereço IP'
                                value={lan.ip4}
                                name="ip"
                                onChange={(e) => {
                                    setLan({ ...lan, ip4: e.target.value })
                                }}
                                validators={[common.required, network.ip4, wanConflict]}
                            ></Input>
                            <Input id='lan-netmask' label='Máscara de sub-rede'
                                value={lan.netmask}
                                name="netmask"
                                onChange={(e) => {
                                    setLan({ ...lan, netmask: e.target.value })
                                }}
                                validators={[common.required, network.netmask]}
                            ></Input>
                            <Checkbox
                                id={'DhcpServer'}
                                label='Servidor DHCP'
                                value={lanDhcp.enabled}
                                checked={lanDhcp.enabled}
                                toggleFn={(e) => {
                                    lanGroup.dhcpserver.enabled = !lanGroup.dhcpserver.enabled
                                    setlanGroup({ ...lanGroup, enabled: e.target.value })
                                }
                                }
                            ></Checkbox>

                            {!lanDhcp.enabled ? <React.Fragment></React.Fragment> :
                                <React.Fragment>

                                    <Input id='dhcpRangeStart' label='Início de Endereço IP'
                                        value={lanDhcp.rangeStart}
                                        name="ip1"
                                        onChange={(event) => {
                                            let value = event.target.value
                                            lanGroup.dhcpserver.rangeStart = value
                                            setlanGroup({ ...lanGroup })

                                        }}
                                        validators={[common.required, network.ip4]}
                                    ></Input>
                                    <Input id='dhcpRangeEnd' label='Fim de Endereço IP'
                                        value={lanDhcp.rangeEnd}
                                        name="ip2"
                                        onChange={(event) => {
                                            let value = event.target.value
                                            lanGroup.dhcpserver.rangeEnd = value
                                            setlanGroup({ ...lanGroup })

                                        }}
                                        validators={[common.required, network.ip4]}
                                    ></Input>
                                    <Input id='lan-netmask' label='Máscara de sub-rede'
                                        value={lan.netmask}
                                        name="ip3"
                                        onChange={(e) => {
                                            lan.netmask = e.target.value

                                        }}
                                        validators={[common.required, network.ip4]}
                                    ></Input>
                                    <Input id='lease-time' label='Lease Time'
                                        value={lanDhcp.leases}
                                        name="ip4"
                                        onChange={(event) => {
                                            let value = event.target.value
                                            lanGroup.dhcpserver.leases = value
                                            setlanGroup({ ...lanGroup })

                                        }}
                                        validators={[common.required]}
                                    ></Input>
                                    <Input id='lan-gateway' label='Gateway'
                                        value={lansGate.gateway}
                                        name="ip5"
                                        onChange={(e) => {
                                            lansGate.gateway = e.target.value
                                            setLansGate({ ...lansGate })
                                        }}
                                        validators={[common.required, network.ip4]}
                                    ></Input>
                                </React.Fragment>}
                        </Form>
                    </div>
                </div>
            }
        </div>
    </div>
}

