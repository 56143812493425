import React from 'react'
import './multTabs.css'

export default function Tabs({ children,index }) {
  return (
    <div className='mult-tabs-container'
      key={index}
    >
      {children}
    </div>
  )
}
