import { createSlice } from "@reduxjs/toolkit"

const oltInitial = {
  gw_id: "",
  name: "",
  ip: "",
  username: "admin",
  password: "admin",
  port: 23,
}
const initialState = {
  olts: [],
  olt: oltInitial,
}
export const OltSlice = createSlice({
  name: "oltState",
  initialState: initialState,
  reducers: {
    setOltInitial: (state, action) => {
      state.olt = oltInitial
    },
    addOlt: (state, action) => {
      const newOlt = action.payload
      if (!state.olts || !Array.isArray(state.olts)) {
        state.olts = []
      }

      state.olts = [...state.olts, newOlt]
    },
    removeOlt: (state, action) => {
      const indexToRemove = state.olts.findIndex(
        (olt) => olt.id === action.payload.id
      )

      if (state.olts.length > 1) {
        state.olts.splice(indexToRemove, 1)
      } else {
        state.olts = []
      }
    },
    setOlt: (state, action) => {
      state.olt = action.payload
    },
    setOlts: (state, action) => {
      state.olts = action.payload
    },
    updateOltInfo: (state, action) => {
      const updatedOlt = action.payload
      const index = state.olts.findIndex((olt) => olt.id === updatedOlt.id)

      if (state.olt && state.olt.id === updatedOlt.id) {
        state.olt = { ...state.olt, ...updatedOlt }
      }
      if (index !== -1) {
        state.olts[index] = updatedOlt
      } else {
        state.olts = [...state.olts, updatedOlt]
      }
    },
    setOltById: (state, action) => {
      const index = state.olts.findIndex((olt) => olt.id === action.payload)
      state.olt = state.olts[index]
    },
    editOlt: (state, action) => {
      const { gw_id, username, password, port, name, ip, operation_mode } =
        action.payload
      state.olt = {
        ...state.olt,
        gw_id: gw_id ? gw_id : state.olt.gw_id,
        name: name ? name : state.olt.name,
        username: username ? username : state.olt.username,
        password: password ? password : state.olt.password,
        port: port ? port : state.olt.port,
        operation_mode: operation_mode
          ? operation_mode
          : state.olt.operation_mode,
        ip: ip ? ip : state.olt.ip,
      }
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  setOltInitial,
  addOlt,
  removeOlt,
  editOlt,
  setOlt,
  setOltById,
  setOlts,
  updateOltInfo,
} = OltSlice.actions

export default OltSlice.reducer
