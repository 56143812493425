import { LoadingCircle } from "@conecte-se/ui-components-reactjs"
import Information from "@features/devices/management/olt/maintenance/information"
import Vlans from "@features/devices/management/olt/vlans/vlans"
import MultTabs from '@features/devices/components/multTabs/multTabs'
import { GroupFormModal } from "@features/my-account/components/group"
import { useContext, useEffect } from "react"
import {
  // MdAppSettingsAlt,
  // MdBuild,
  MdHelpOutline,
  MdHome,
  MdManageAccounts,
  MdRouter,
} from "react-icons/md"
import { Route, Routes, useNavigate, useSearchParams } from "react-router-dom"
import "./App.css"
import Backend, { BackendContext } from "@services/backend/backend"
import Content from "./components/Content"
import PrivateRoute from "./components/Route/PrivateRoute"
import SideBar from "./components/SideBar"
import TopBar from "./components/Topbar"
import MainHeaderGlobalState from "./components/main-header/main-header-state"
import MenuGlobalState from "./lib/midgard/menu/menu-state"
import NotificationsState from "@lib/midgard/notifications/notifications-state"
import ToastContextProvider from "./context/ToastContext"
import UserContextProvider from "./context/UserContext"
import WsDevicesProvider from "./context/WsDevicesEvents"
import WsEventsProvider from "./context/WsEvents"

import AppMobilePage from "./pages/AppMobile"
import DashboardPage from "./pages/Dashboard"
import HelpPage from "./pages/Help"
import LoginPage from "./pages/Login"
import MyAccountPage from "./pages/MyAccount"
import PageNotFound from "./pages/PageNotFound"
import ToolsPage from "./pages/Tools"
import UpdateFlowPage from "./pages/UpdateFlow"
import UsersManagementPage from "./pages/UserManagement"

//* ---- Method of authentication is OAuth 2.0 and Authorization Code with PKCE ----
// for more information about Conta Intelbras and the auth protocol used in our app,
// go to: https://intelbrascombr.sharepoint.com/sites/Team-TICePeD/Documentos%20Compartilhados/Forms/AllItems.aspx?id=%2Fsites%2FTeam%2DTICePeD%2FDocumentos%20Compartilhados%2FGeneral%2FContaIntelbras%5FManualIntegracao%2Epdf&parent=%2Fsites%2FTeam%2DTICePeD%2FDocumentos%20Compartilhados%2FGeneral&p=true&ct=1674308765611&or=Teams%2DHL&ga=1

/*
TODO: if someone manually changes local storage token values but still keeps the expire attribute, the code keeps making requests in loop.
*/

const tokenUrl = process.env.REACT_APP_CONTA_INTELBRAS_TOKEN_URL
const client_id = process.env.REACT_APP_CONTA_INTELBRAS_CLIENT_ID

function localStorageExpires() {
  var toRemove = [], // Itens para serem removidos
    currentDate = new Date().getTime() // Data atual em milissegundos

  for (var i = 0, j = localStorage.length; i < j; i++) {
    var key = localStorage.key(i),
      value = localStorage.getItem(key)

    // Verifica se o formato do item para evitar conflitar com outras aplicações
    if (value && value[0] === "{" && value.slice(-1) === "}") {
      // Decodifica de volta para JSON
      var current = JSON.parse(value)

      // Checa a chave expires do item especifico se for mais antigo que a data atual ele salva no array
      if (current.expires && current.expires <= currentDate) {
        toRemove.push(key)
      }
    }
  }

  // Remove itens que já passaram do tempo
  // Se remover no primeiro loop isto poderia afetar a ordem,
  // pois quando se remove um item geralmente o objeto ou array são reordenados
  for (var t = toRemove.length - 1; t >= 0; t--) {
    localStorage.removeItem(toRemove[t])
  }
}

/**
 * Função para adicionar itens no localStorage
 * @param {string} chave Chave que será usada para obter o valor posteriormente
 * @param {*} valor Quase qualquer tipo de valor pode ser adicionado, desde que não falhe no JSON.stringify
 * @param {number} Tempo de vida em minutos do item
 */
function setLocalStorage(chave, valor, minutos) {
  var expirarem = new Date().getTime() + 60000 * minutos

  localStorage.setItem(
    chave,
    JSON.stringify({
      value: valor,
      expires: expirarem,
    })
  )
}

/**
 * Função para obter itens do localStorage que ainda não expiraram
 * @param {string} chave Chave para obter o valor associado
 * @return {*} Retorna qualquer valor, se o item tiver expirado irá retorna undefined
 */
function getLocalStorage(chave) {
  localStorageExpires() //Limpa itens

  var value = localStorage.getItem(chave)

  if (value && value[0] === "{" && value.slice(-1) === "}") {
    // Decodifica de volta para JSON
    var current = JSON.parse(value)

    return current.value
  }
}

function RedirectToContaIntelbras() {
  const backend = useContext(BackendContext)

  const navigate = useNavigate()

  const [urlParams,] = useSearchParams()

  //*----------------------------------------------------------------------

  const handleAuthAnswer = async (result) => {
    let response = await result.json()
    if (result.status === 200) {
      console.log("sucesso")
      /*
      access and refresh token duration is set in the backend too, we use this local storage logic
      to avoid having to make a request to the auth server even thought we know the token is expired.
      */
      //access token lasts 60 minutes = 1 hour
      setLocalStorage("access_token", response.access_token, 60)
      //refresh_token lasts 1440 minutes = 24 hours = 1 day
      setLocalStorage("refresh_token", response.refresh_token, 1440)

      backend.setAuthInfo(response.access_token)
    } else {
      localStorage.removeItem("refresh_token")
      localStorage.removeItem("access_token")
      window.reload()
    }
  }

  const fetchJwtfromRefreshToken = async (refresh_token, refresh_token_uri) => {
    console.log("fething jwt from refresh token ...")
    let headers = new Headers()
    let urlencoded = new URLSearchParams()
    urlencoded.append("grant_type", "refresh_token")
    urlencoded.append("refresh_token", refresh_token)
    urlencoded.append("client_id", client_id)

    let getTokenRequest = {
      method: "POST",
      headers: headers,
      body: urlencoded,
      redirect: "follow",
    }

    let result = await fetch(refresh_token_uri, getTokenRequest)
    handleAuthAnswer(result)
  }

  const fetchJwt = async (code) => {
    console.log("fething jwt...")
    let headers = new Headers()

    let urlencoded = new URLSearchParams()
    urlencoded.append("Content-Type", "application/x-www-form-urlencoded")
    urlencoded.append("grant_type", "authorization_code")
    urlencoded.append("redirect_uri", process.env.REACT_APP_AUTH_REDIRECT_URI)
    urlencoded.append("code", code)
    urlencoded.append("code_verifier", localStorage.getItem("codeVerifier"))
    urlencoded.append("client_id", client_id)
    urlencoded.append("scope", "openid")

    let getTokenRequest = {
      method: "POST",
      headers: headers,
      body: urlencoded,
      redirect: "follow",
    }

    let result = await fetch(
      "https://" + tokenUrl + "/auth/token",
      getTokenRequest
    )
    handleAuthAnswer(result)
  }

  useEffect(() => {
    //Verifies if authentication is disabled, if so, bypass all auth verifications
    if (process.env.REACT_APP_DISABLE_AUTHENTICATION === "true") {
      /*? create backend .env var to disable authentication for api requests ?*/
      backend.setAuthInfo(true)
      return
    }
    let access_token = getLocalStorage("access_token")
    if (typeof access_token !== "undefined") {
      console.log("already exists a jwt in localStorage")
      backend.setAuthInfo(access_token)
      return
    }

    let refresh_token = getLocalStorage("refresh_token")
    if (typeof refresh_token !== "undefined") {
      console.log("already exists a refresh token in localStorage")
      let refresh_token_uri = "https://" + tokenUrl + "/auth/token"
      fetchJwtfromRefreshToken(refresh_token, refresh_token_uri)
      return
    }

    const code = urlParams.get("code")
    if (code) {
      console.log("URL has the code auth: " + code)
      //get jwt:
      fetchJwt(code)
      return
    }
    navigate("login")
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div
      style={{
        display: "flex",
        width: "100vw",
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <LoadingCircle
        id="loading_circle_verify_logged_in"
        value={40}
        width={100}
        height={100}
        cardWidth={"300px"}
        radius={30}
        indeterminate
        message={"Carregando..."}
      />
    </div>
  )
}

function Middleware() {
  const backend = useContext(BackendContext)

  const menuItens = [
    {
      icon: <MdHome />,
      name: "Dashboard",
      path: "/dashboard",
    },
    {
      icon: <MdRouter />,
      name: "Dispositivos",
      path: "/manage/devices",
    },
    // {
    //   icon: <MdLocationPin />,
    //   name: "Mapa",
    //   path: "/map",
    // },
    // {
    //   icon: <MdUpgrade />,
    //   name: "Atualização",
    //   path: "/update-devices",
    // },
    // {
    //   icon: <MdSettings />,
    //   name: "Perfis",
    //   path: "/global-settings",
    // },
    {
      icon: <MdManageAccounts />,
      name: "Contas",
      path: "/users-management",
    },
    // {
    //   icon: <MdBuild />,
    //   name: "Ferramentas",
    //   path: "/tools",
    // },
    // {
    //   icon: <MdAppSettingsAlt />,
    //   name: "Aplicativo",
    //   path: "/app-mobile",
    // },
    {
      icon: <MdHelpOutline />,
      name: "Ajuda",
      path: "/help",
    },
  ]
  /* 
    backend.auth has the bearer token used in requests
  */

  return backend.auth ? (
    <Content>
      <ToastContextProvider>
        <WsEventsProvider>
          <WsDevicesProvider>
            <TopBar />
            <SideBar>
              <SideBar.Body menuItens={menuItens}></SideBar.Body>
            </SideBar>
            <Routes>
              <Route path="/" element={<MultTabs />} />
              {/* <Route path="new-user" element={<NewUser/>} /> */}
              {/* <Route path="update-devices" element={<UpdateDevicesPage />} /> */}
              {/* <Route path="global-settings" element={<GlobalSettingsPage />} /> */}
              <Route
                path="users-management"
                element={<UsersManagementPage />}
              />
              <Route path="tools" element={<ToolsPage />} />
              <Route path="app-mobile" element={<AppMobilePage />} />
              {/* <Route path="help" element={<Help />} /> */}
              <Route
                path="help"
                element={<PrivateRoute component={<HelpPage />} />}
              />
              <Route path="my-account" element={<MyAccountPage />} />
              <Route path="my-account/new-group" element={<GroupFormModal />} />
              <Route path="dashboard" element={<DashboardPage />} />
              <Route
                exact
                path="olt/informacoes/:id"
                element={<PrivateRoute component={<Information />} />}
              />
              <Route
                exact
                path="olt/vlans/:id"
                element={<PrivateRoute component={<Vlans />} />}
              />
              {/* <Route path="map" element={<MapProvider><MapManagementPage /></MapProvider>} /> */}
              <Route
                path="update-devices/update-flow"
                element={<UpdateFlowPage />}
              />
              {/* <Route path="global-settings/fiber-devices" element={<FiberCustomizationPage />} /> */}
              {/* <Route path="global-settings/fiber-devices/cpes-associate-to-perfil" element={<AssociateCpesToPerfil />} /> */}
              <Route path="/manage/:id/*" element={<MultTabs />} />
              <Route path="*" element={<PageNotFound />} />
            </Routes>
          </WsDevicesProvider>
        </WsEventsProvider>
      </ToastContextProvider>
    </Content>
  ) : (
    <RedirectToContaIntelbras />
  )
}

function App() {
  return (
    <div className="App">
      <MainHeaderGlobalState>
        <NotificationsState>
          <MenuGlobalState>
            <Backend>
              <UserContextProvider>
                <Routes>
                  <Route path="login" element={<LoginPage />} />
                </Routes>
                <Middleware />
              </UserContextProvider>
            </Backend>
          </MenuGlobalState>
        </NotificationsState>
      </MainHeaderGlobalState>
    </div>
  )
}

export default App
