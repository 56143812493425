import { createSlice } from "@reduxjs/toolkit"

const gatewayInitial = {
  id: "",
  name: "",
  description: "",
  status: false,
}
const initialState = {
  gateways: [],
  gateway: gatewayInitial,
}
export const GatewaySlice = createSlice({
  name: "gatewayState",
  initialState: initialState,
  reducers: {
    setGatewayInitial: (state, action) => {
      state.gateway = gatewayInitial
    },
    addGateway: (state, action) => {
      state.gateways.push(action.payload)
    },
    setGateway: (state, action) => {
      state.gateway = action.payload
    },
    setGateways: (state, action) => {
      state.gateways = action.payload
    },
    setGatewayById: (state, action) => {
      const index = state.gateways.findIndex(
        (gateway) => gateway.id === action.payload
      )
      state.gateway = state.gateways[index]
    },
    editGateway: (state, action) => {
      const { name, description } = action.payload
      state.gateway = {
        ...state.gateway,
        name: name ? name : state.gateway.name,
        description: description ? description : state.gateway.description,
      }
    },
    updateGatewayInfo: (state, action) => {
      const updatedGateway = action.payload
      const index = state.gateways.findIndex(
        (vlan) => vlan.id === updatedGateway.id
      )

      if (index !== -1) {
        state.gateways[index] = updatedGateway
      } else {
        state.gateways.push(updatedGateway)
      }
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  setGatewayInitial,
  addGateway,
  editGateway,
  setGateway,
  setGateways,
  setGatewayById,
  updateGatewayInfo,
} = GatewaySlice.actions

export default GatewaySlice.reducer
