import { Input, Label } from "@conecte-se/ui-components-reactjs"
import { useSelector } from "react-redux"
import Checkbox from "@lib/midgard/checkbox/checkbox"
import { InputText } from "../inputs"
import { RadioGroupButton } from "../radio-button-group"
import "./operation-mode.css"

export function OperationMode({
  operation_mode,
  ponsState,
  configVlanState,
  indexVlanState,
  uplinkState,
}) {
  const { pons, setPons } = ponsState
  const olt = useSelector((state) => state.tabSlice.selected);

  const { configVlan, setConfigVlan } = configVlanState
  const { indexVlan, setIndexVlan } = indexVlanState
  const { uplinkAuto, setUplinkAuto } = uplinkState

  const handleConfigVlan = (event, index) => {
    const { value } = event.target
    const changes = [...configVlan]
    changes[index] = value
    setConfigVlan(changes)
  }

  const handlePonChange = (index, event) => {
    setPons((prevPons) => {
      const updatedPons = [...prevPons]
      updatedPons[index] = event.target.value
      return updatedPons
    })
  }
  function sizePons() {
    return olt?.model === "olt_g16" ? 16 : 8
  }
  function Manually() {
    return (
      <>
        <div className="container-manually">
          <div className="info-box">
            <p>
              Ao alterar a configuração para o modo Manual, as ONU's deverão ser
              configuradas individualmente e as configurações da Uplink deverão
              ser configuradas corretamente.
            </p>
          </div>
        </div>
      </>
    )
  }

  function VlanPorPon() {
    const inputs = []
    let row = []
    const modes = [
      { id: "ETH1", text: "ETH1" },
      { id: "VEIP", text: "VEIP" },
    ]
    const models = [
      { id: "ONU", text: "ONU" },
      { id: "ONT", text: "ONT" },
    ]
    for (let i = 0; i < sizePons(); i++) {
      row.push(
        <div key={i} className="input-resize">
          <Label>{`GPON ${i + 1}`}</Label>
          <Input
            id={`gpon_${i}`}
            value={pons[i]}
            placeholder={`GPON ${i + 1}`}
            length={{ max: 4 }}
            name={`gw_id_${i}`}
            onChange={(event) => handlePonChange(i, event)}
          />
        </div>
      )

      if ((i + 1) % 4 === 0 || i === sizePons() - 1) {
        inputs.push(
          <div key={`row_${i}`} className="custom-row">
            {row}
          </div>
        )
        row = []
      }
    }

    return (
      <>
        {inputs}
        <div className="group-onu">
          <Label>ONU R1</Label>
          <RadioGroupButton
            name={"onur1"}
            onChange={(e) => handleConfigVlan(e, 0)}
            selected={configVlan[0]}
            options={modes}
            labelClass={"onu-modes"}
          />
          <Label>ONU R1v2</Label>
          <RadioGroupButton
            name={"onur1v2"}
            onChange={(e) => handleConfigVlan(e, 1)}
            selected={configVlan[1]}
            options={modes}
            labelClass={"onu-modes"}
          />
          <div className="group-others">
            <Label>Outros Fabricantes</Label>
            <div className="group-others-radio">
              <Label>Modelo</Label>
              <RadioGroupButton
                name={"model"}
                onChange={(e) => handleConfigVlan(e, 2)}
                selected={configVlan[2]}
                options={models}
                labelClass={"onu-modes"}
              />
              <Label>Lines</Label>
              <RadioGroupButton
                name={"lines"}
                onChange={(e) => handleConfigVlan(e, 3)}
                selected={configVlan[3]}
                options={modes}
                labelClass={"onu-modes"}
              />
            </div>
          </div>
        </div>
      </>
    )
  }

  function AutoService() {
    return (
      <>
        <div class="container-manually">
          <div class="info-box">
            <p>
              Ao alterar a configuração para o modo Autoserviço, todas as novas
              ONU's adicionadas serão configuradas automaticamente com a VLAN de
              serviço {indexVlan} e as portas da uplink estarão em modo{" "}
              {uplinkAuto ? "TAGGED" : "UNTAGGED"}. Você pode personalizar a
              VLAN no campo abaixo:
            </p>
            <div className="box-index-vlan">
              <span class="text">Índice da VLAN:</span>
              <div className="input">
                <InputText
                  length={{ max: 4 }}
                  id={`index-vlan`}
                  value={indexVlan}
                  label="VLAN"
                  onChange={(event) => setIndexVlan(event.target.value)}
                />
              </div>
            </div>
            <Checkbox
              id="uplink-auto"
              label="UPLINK"
              toggleFn={() => {
                setUplinkAuto(!uplinkAuto)
              }}
              value={uplinkAuto}
            ></Checkbox>
          </div>
        </div>
      </>
    )
  }

  switch (operation_mode) {
    case "MANUALLY":
      return Manually()
    case "VLANPORPON":
      return VlanPorPon()
    case "AUTOSERVICE":
      return AutoService()
    default:
      return <div>Componente não encontrado</div>
  }
}
