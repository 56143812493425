import {
  Button,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "@conecte-se/ui-components-reactjs"
import { useContext, useEffect, useState } from "react"
import { BackendContext, backendStatus } from "@services/backend/backend"
import { ToastContext } from "src/context/ToastContext"

export function ModalEditUser({ editNameState, loggedUserState }) {
  const [newUserName, setNewUsername] = useState("")
  const { editName, setEditName } = editNameState
  const { loggedUser, setLoggedUser } = loggedUserState
  const backend = useContext(BackendContext)
  const toastContext = useContext(ToastContext)

  useEffect(() => {
    if (editNameState.editName) {
      setNewUsername(loggedUserState.loggedUser.Name)
    }

  }, [editNameState, loggedUserState])

  const applyNewUserName = async () => {
    let result = await backend.update("user", { Name: newUserName.trim() })
    if (result.status === backendStatus.SUCCESS) {
      loggedUser.Name = newUserName
      setLoggedUser(loggedUser)
      toastContext.addToast({
        title: "Nome de usuário editado com sucesso!",
        status: "success",
      })
      setEditName(false)
      setNewUsername("")
    }
  }
  const handleKeyDown = (key) => {
    if (newUserName && key === "Enter") {
      applyNewUserName()
    }
  }
  return (
    <Modal visible={editName} width={"500px"}>
      <ModalHeader>
        <span style={{ fontSize: "18px", fontWeight: "bold" }}>
          Novo nome de usuário:
        </span>
      </ModalHeader>
      <ModalBody>
        <div
          style={{ display: "flex", justifyContent: "center" }}
          onKeyDownCapture={(e) => {
            handleKeyDown(e.key)
          }}
        >
          <div style={{ textAlign: "left", width: "95%" }}>
            <div style={{ margin: "10px" }}>
              <Label>Nome</Label>
              <Input
                id="username-input"
                placeholder={"Nome do usuário"}
                onChange={(e) => {
                  setNewUsername(e.target.value.replaceAll('  ', ' '))
                }}
                value={newUserName}
              />
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <div style={{ display: "flex" }}>
          <div className="mr-4" style={{ paddingRight: "20px" }}>
            <Button
              secondary
              onClick={() => {
                setNewUsername("")
                setEditName(false)
              }}
            >
              Cancelar
            </Button>
          </div>
          <Button
            disabled={newUserName ? false : true}
            onClick={applyNewUserName}
          >
            Aplicar
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  )
}
