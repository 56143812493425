import React from 'react'
import './multTabs.css'

export default function TabsHeader({ children }) {
    return (
        <div className='nav nav-tabs'
        >
            {children}
        </div>
    )
}
