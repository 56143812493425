import { createSlice } from "@reduxjs/toolkit";
const defaultd = {
    "id": "devices",
    "deviceid": "devices",
    "model": "devices",
}
const initialState = { selected: defaultd, devices: [], visible: {}, listTabs: [defaultd] };

const tabSlice = createSlice({
    name: "deviceTab",
    initialState,
    reducers: {
        setDevices: (state, action) => {
            state.devices = action.payload
        },
        selectTab: (state, action) => {
            state.selected = action.payload;
        },
        showTabs: (state, action) => {
            state.visible = { ...state.visible, ...action.payload };
        },
        addTab: (state, action) => {
            const newlistTabs = action.payload
            if (!state.listTabs || !Array.isArray(state.listTabs)) {
                state.listTabs = []
            }
            if (action.payload.id !== undefined) {

                const existsItem = state.listTabs.some(
                    (listTab) => listTab.id === action.payload.id
                );
                if (!existsItem) {
                    state.listTabs = [...state.listTabs, newlistTabs]
                }
                state.selected = { id: newlistTabs.id, model: newlistTabs.model, status: newlistTabs.status }
            }


        },
        removeTab: (state, action) => {
            const indexToRemove = state.listTabs.findIndex(
                (listTabs) => listTabs.id === action.payload.id
            )
            if (indexToRemove !== -1) {
                if (state.listTabs.length > 1) {
                    state.listTabs.splice(indexToRemove, 1)
                } else {
                    state.listTabs = []
                }
            }
        },
    },
});

export const { setDevices, selectTab, showTabs, addTab, removeTab } = tabSlice.actions;



export default tabSlice.reducer;