import { Label } from "@conecte-se/ui-components-reactjs"
import { InputText } from "@features/devices/components/inputs"
// import { useSelector } from "react-redux"
import { requiredValue, validateIP } from "src/common/validators"
const AssociateOlt = ({ oltState, errorState }) => {
  // const gateways = useSelector((state) => state.gatewayState.gateways)

  const { errors, setErrors } = errorState
  const { olt, setOlt } = oltState

  const handleChange = (e) => {
    setOlt({
      ...olt,
      [e.target.name]: e.target.value,
    })
  }

  const clearInputField = (property) => {
    setOlt({ ...olt, [property]: "" })
  }
  const handleError = (name, error) => {
    if (error) {
      setErrors({ ...errors, [name]: error })
    } else {
      const { [name]: removed, ...rest } = errors
      setErrors(rest)
    }
  }

  // const handleSelect = (item) => {
  //   setOlt({
  //     ...olt,
  //     gw_id: item.id,
  //   })
  // }

  return (
    <>
      <div style={{ flexDirection: "column" }}>
        <div className="mb-3">
          <Label>Nome</Label>
          <InputText
            id="name"
            value={olt.name}
            label="Nome"
            name="name"
            validate={{
              action: (e) => requiredValue(e),
              handleError: handleError,
            }}
            onChange={handleChange}
            clearField={{
              action: clearInputField,
              text: "Limpar",
            }}
          />
        </div>
        <div className="mb-3">
          <Label>Endereço IP</Label>
          <InputText
            id="ip"
            name="ip"
            value={olt.ip}
            label="Endereço IP"
            onChange={handleChange}
            validate={{
              action: (e) => validateIP(e),
              handleError: handleError,
            }}
            clearField={{
              action: clearInputField,
              text: "Limpar",
            }}
          />
        </div>
        <div className="mb-3">
          <Label>Usuário</Label>
          <InputText
            id="username"
            name="username"
            value={olt.username}
            label="Usuário administrador"
            onChange={handleChange}
            clearField={{
              action: clearInputField,
              text: "Limpar",
            }}
          />
        </div>
        <div className="mb-3">
          <Label>Senha</Label>
          <InputText
            id="password"
            name="password"
            value={olt.password}
            type="password"
            label="Senha de acesso"
            onChange={handleChange}
          />
        </div>
      </div>
      <div>
        <Label>Porta Telnet</Label>
        <InputText
          type="number"
          id="port"
          name="port"
          value={olt.port}
          label="Porta"
          onChange={handleChange}
          clearField={{
            action: clearInputField,
            text: "Limpar",
          }}
        />
      </div>
    </>
  )
}

export default AssociateOlt
