
const APPLY_ACTION = 5

export class GatewayAPI {

    constructor(backend) {
        this.backend = backend
    }

    async create( resourceName, data) {

        let result = await this.backend.create(`device/gateway${resourceName}`, data)

        return result

    }

    async retrieve(resourceName) {

        try {

            let result = await this.backend.retrieveFresh(`device/gateway${resourceName}`)

            return result

        }catch{
            console.error(`Error retrieving  ${resourceName}`)
            return {status: 2} //FIXME: magic number
        }
    }

    async update(resourceName, data) {

        let result = await this.backend.update(`device/gateway${resourceName}`, data)

        return result

    }

    async delete(resourceName, data) {

        let result = await this.backend.delete(`device/gateway${resourceName}`, data)

        return result

    }


    async apply(id) {

        let result = await this.backend.create(`device/gateway${id}/action`, {actionID: APPLY_ACTION})

        return result
    }
}