export class OltAPI {

    constructor(backend) {
        this.backend = backend
    }

    async retrieveOltById(resourceName) {

        try {

            let result = await this.backend.retrieveFresh(`device/olt${resourceName}`)
            console.log('result', result)
            return result

        } catch {
            console.error(`Error retrieving  ${resourceName}`)
            return { status: 2 } //FIXME: magic number
        }
    }

    async retrieve(resourceName) {

        try {

            let result = await this.backend.retrieveFresh(`device/olt${resourceName}`)

            return result

        } catch {
            console.error(`Error retrieving  ${resourceName}`)
            return { status: 2 } //FIXME: magic number
        }
    }


    async create(resourceName, data) {

        let result = await this.backend.create(`device/olt${resourceName}`, data)

        return result

    }


    async update(resourceName, data) {

        let result = await this.backend.update(`device/olt${resourceName}`, data)

        return result

    }

    async delete( resourceName, data = null) {

        let result = await this.backend.delete(`device/olt${resourceName}`, data)

        return result

    }
}