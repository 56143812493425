import React, { useContext, useEffect, useRef, useState } from 'react'
import { backendStatus } from '@services/backend/backend'
import Form from '@lib/midgard/form/form'
import common from '@lib/midgard/form/validators/common'
import network from '@lib/midgard/form/validators/network'
import Input from '@lib/midgard/input/input'
import Loading from '@lib/midgard/loading/loading'
import DefaultStatusModals from '@lib/midgard/modal/default-status-modals'
import iputils from '@lib/midgard/utils/iputils'
import { WanOpMode } from '../wan/wan-constants'
import { DeviceContext } from '@features/devices/management/manage-page'

import './lan.css'
import DeviceFactory from '@features/devices/management/device-factory'

const  LanManagementPage = ()=> {
  
    const [lan, setLan] = useState(null)
    const [wan, setWan] = useState(null)

    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(false)
    const [saving, setSaving] = useState(false)

    const manageCtx = useContext(DeviceContext)
    const lanTimer = useRef(null)
    const wanTimer = useRef(null)
    const mounted = useRef(true)
    console.log(' LAN - > manageCtx', manageCtx)
    if (DeviceFactory.isTR069(manageCtx.deviceInfo.model)){
        WanOpMode['BRIDGE'] = 3 //Temp: sharing lan page with tr069. Needs a refact.
    }

    useEffect(() => {

        fetchLan()
        fetchWan()

        return () => {
            mounted.current = false
            clearTimeout(lanTimer.current)
            // clearTimeout(wanTimer.current)
        }

        // eslint-disable-next-line
    }, [])

    const fetchLan = async() => {
        let result = await manageCtx.device.retrieveResource('lan')
        console.log('fetchLan result', result)
        if(result.status !== backendStatus.SUCCESS){
            console.error('error fetching resource: lan')
            if(mounted.current)
                lanTimer.current = setTimeout(fetchLan, 5000)
            return
        }
        console.log('result.content[0]', result.content[0])
        let lan = result.content[0]
        result = await manageCtx.device.retrieveResource(`interface/${lan.interfaceID}`)
        if(result.status !== backendStatus.SUCCESS){
            console.error('error fetching resource: lan')
            if(mounted.current)
                lanTimer.current = setTimeout(fetchLan, 5000)
            return
        }

        setLan(result.content)
    }

    const fetchWan = async() => {

        let result = await manageCtx.device.retrieveResource('wan')
        if(result.status !== backendStatus.SUCCESS){
            console.error('error fetching resource: wan')
            if(mounted.current)
                wanTimer.current = setTimeout(fetchWan, 5000)
            return
        }

        let wan = result.content[0]
        let interfaceID = ''
        if(wan.mode === WanOpMode.PPPOE){

            result = await manageCtx.device.retrieveResource('pppoe')
            if(result.status !== backendStatus.SUCCESS){
                console.error('error fetching resource: wan - pppoe')
                if(mounted.current)
                    wanTimer.current = setTimeout(fetchWan, 5000)
                return
            }

            interfaceID = result.content[0].interfaceID

        }else{
            interfaceID = wan.interfaceID
        }


        result = await manageCtx.device.retrieveResource(`interface/${interfaceID}`)
        if(result.status !== backendStatus.SUCCESS){
            console.error('error fetching resource: wan')
            if(mounted.current)
                wanTimer.current = setTimeout(fetchWan, 5000)
            return
        }

        setWan(result.content)
    }



    const wanConflict = (value) => {

        if(wan.ip4 === "")
            return ''

        if( iputils.sameNetwork(value, wan.ip4, wan.netmask) ||
            iputils.sameNetwork(value, wan.ip4, lan.netmask))
        {
            return 'Este endereço está em conflito com sua rede WAN'
        }

        return ''

    }

    const saveLan = async() => {

        setSaving(true)

        let result = await manageCtx.device.updateResource('interface', lan)

        if(result.status !== backendStatus.SUCCESS){
            setSaving(false)
            setError(true)
            return
        }

        result = await manageCtx.device.apply()

        if(result.status !== backendStatus.SUCCESS){
            setSaving(false)
            setError(true)
            return
        }

        setSaving(false)
        setSuccess(true)

    }

    const dismissModal = () => {
        setSuccess(false)
        setSaving(false)
        setError(false)
    }
    console.log('lan', lan)
    return (
        <div className='lan-management-page'>

        <DefaultStatusModals
            success={success}
            error={error}
            saving={saving}
            continueFn={dismissModal}
        ></DefaultStatusModals>

        {!lan ? <Loading show={true}></Loading> :

            <Form onSubmit={saveLan} buttonId='button-save-lan'>

                <div className='subtitle'>Configurações da LAN</div>

                <Input id='lan-ip' label='Endereço IP'
                    value={lan.ip4}
                    name="ip"
                    onChange={(e) => {
                        setLan({ ...lan, ip4: e.target.value })
                    }}
                    validators={[common.required, network.ip4, wanConflict]}
                ></Input>
                <Input id='lan-netmask' label='Máscara de sub-rede'
                    value={lan.netmask}
                    name="netmask"
                    onChange={(e) => {
                        setLan({ ...lan, netmask: e.target.value })
                    }}
                    validators={[common.required, network.netmask]}
                ></Input>

            </Form>

        }

        </div>)
}
export default LanManagementPage 