import { backendStatus } from "@services/backend/backend"
// import BifrostHoManagePage from "@features/devices/management/bifrostHO/bifrostHoManagePage"
export default class Device {

  constructor(device) {
    this.data = device
    this.api = null
  }

  // getManagementPage() {
  //   return <BifrostHoManagePage></BifrostHoManagePage>
  // }

  async createResource(resourceName, data) {
    return await this.api.create(this.data.id, resourceName, data)
  }

  async retrieveResource(resourceName) {

    let result = await this.api.retrieve(this.data.id, resourceName)

    if (result && result.status !== backendStatus.SUCCESS) {
      console.error('error:', result.content)
    }

    return result
  }

  async updateResource(resourceName, data) {
    return await this.api.update(this.data.id, resourceName, data)
  }

  async deleteResource(resourceName, data) {
    return await this.api.delete(this.data.id, resourceName, data)
  }

  async apply() {

    return await this.api.apply(this.data.id)
  }

}