import {
    Button,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
} from "@conecte-se/ui-components-reactjs";
import { DropdownScroll } from "@features/devices/components/dropdown-scroll";
import { activateCpe } from "@features/devices/redux/cpe/cpe-actions";
import { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { BackendContext } from "@services/backend/backend";
import { ToastContext } from "src/context/ToastContext";
import { UserContext } from "src/context/UserContext";
import { SeparatedButtons } from "../../components/separated-buttons/separated-buttons";
import { getDeviceById } from "@features/devices/redux/deviceTab/device-actions";

export const ActivateCpe = ({ close, ontId }) => {
    const dispatch = useDispatch();
    const backend = useContext(BackendContext);
    const user = useContext(UserContext);
    const toastContext = useContext(ToastContext);
    const [cpe, setCpe] = useState(null);
    const [olt, setOlt] = useState(null);
    const [selected, setSelected] = useState(null);

    useEffect(() => {
        const fetchInfo = async () => {
            try {
                const ontResult = await getDeviceById(backend, toastContext, ontId);
                setCpe(ontResult);
                setSelected(ontResult);

                const oltResult = await getDeviceById(backend, toastContext, ontResult.olt_id);
                setOlt(oltResult);
            } catch (error) {
                console.error("Failed to fetch device info:", error);
                toastContext.addToast("Erro ao carregar informações. Tente novamente mais tarde.", "error");
            }
        };
        fetchInfo();
    }, [backend, toastContext, ontId]);

    const getSlotsByPort = (olt, port) => {
        if (!olt || !olt.ports_G || !olt.ports_G[port - 1]) {
            return [];
        }
        const objPort = olt.ports_G[port - 1];
        if (objPort.slots) {
            return objPort.slots.map((slot, index) => ({
                id: slot,
                value: slot,
                label: index === 0 ? `${slot} (Recomendado)` : `${slot}`,
            }));
        }
        return [];
    };

    const onChangeConfiguration = (key, value) => {
        setCpe((prevCpe) => ({
            ...prevCpe,
            configuration: {
                ...prevCpe.configuration,
                [key]: value,
            },
        }));
    };

    const getModelOptions = (olt) => {
        if (!olt || !olt.cpes_model || !Array.isArray(olt.cpes_model)) {
            return [];
        }
        return olt.cpes_model.map((item) => ({
            id: item.model,
            value: item.model,
            label: item.model,
        }));
    };

    const handleSelect = (item, dropdownId) => {
        setCpe((prevCpe) => ({
            ...prevCpe,
            [dropdownId]: item.id,
        }));
    };

    const handleDisableButton = () => {
        return cpe !== null && cpe.model !== "" &&
            (Object.keys(cpe.configuration?.eth || {}).length > 0 ||
                (cpe.configuration?.veip || []).length > 0);
    };

    const handleActivate = () => {
        activateCpe(backend, toastContext, cpe);
        close();
    };

    return (
        <Modal visible={true} width={"400px"}>
            <ModalHeader>
                <div style={{ fontSize: "18px", fontWeight: "bold" }}>
                    Ativando ONT
                </div>
            </ModalHeader>
            <ModalBody>
                {!selected?.model && olt && (
                    <div className="mb-3">
                        <Label>Modelo</Label>
                        <DropdownScroll
                            id={"model-cpe"}
                            items={getModelOptions(olt)}
                            onSelect={(item) => handleSelect(item, "model")}
                        />
                    </div>
                )}

                {!selected?.slot && olt && selected?.port && (
                    <div className="mb-3">
                        <Label>Slot Pon</Label>
                        <DropdownScroll
                            id={"slot-cpe"}
                            items={getSlotsByPort(olt, cpe.port)}
                            onSelect={(item) => handleSelect(item, "slot")}
                        />
                    </div>
                )}
                <div className="mb-3">
                    <Label>
                        <h3>Configurações</h3>
                    </Label>
                    <Label>
                        <h4>Adicione a quantidade que desejar de interfaces ETH/VEIP.</h4>
                    </Label>
                    <SeparatedButtons onChangeConfiguration={onChangeConfiguration} />
                </div>
            </ModalBody>
            <ModalFooter>
                <div style={{ display: "flex" }}>
                    <div className="mr-4" style={{ paddingRight: "20px" }}>
                        <Button secondary onClick={close}>
                            Cancelar
                        </Button>
                    </div>
                    <Button
                        disabled={!handleDisableButton()}
                        onClick={handleActivate}
                        primary
                    >
                        Ativar
                    </Button>
                </div>
            </ModalFooter>
        </Modal>
    );
};
