import React, { useContext } from "react"
import { NavLink } from "react-router-dom"
import classNames from "classnames"
import styles from "./SideBar.module.css"
import { SideBarContext } from "../../context/SideBarContext"
import { TbLayoutSidebarLeftExpand } from "react-icons/tb";
function SideBar({ children }) {
  const { sideBarCollapsed } = useContext(SideBarContext)
  return (
    <div
      id="sidebar"
      className={classNames([
        styles.sidebar,
        sideBarCollapsed ? styles.collapsed : "",
      ])}
    >
      {children}
    </div>
  )
}

function Body({ children, menuItens }) {
  const topItems = menuItens.slice(0, 2);
  const bottomItems = menuItens.slice(2);
  const { sideBarCollapsed } = useContext(SideBarContext)
  const { toggleSideBar } = useContext(SideBarContext)
  return (
    <div className={styles.menuList} >
      <div className={styles['sidebar-logo-wrapper']}>
        <p
          className={classNames([
            styles.sidebarLogo,
            sideBarCollapsed ? styles.collapsed : "",
          ])}>iNMS</p>
        {/* <img className={classNames([
          styles.sidebarLogo,
          sideBarCollapsed ? styles.collapsed : "",
        ])}
         src="/assets/img/nms-with-no-intelbras.png" alt="Intelbras">
        
         </img> */}
      </div>

      <div className={styles.itemsTop}>
        {topItems.map((item, index) => (
          <NavLink
            to={item.path}
            key={index}
            className={(navData) =>
              navData.isActive
                ? classNames([styles.menu_item, styles.menu_item_active])
                : classNames([styles.menu_item])
            }
          >
            <span className={classNames([styles.menu_item_icon])}>
              {item.icon}
            </span>
            <span className={classNames([styles.menu_item_name])}>
              {item.name}
            </span>
          </NavLink>
        ))}
      </div>

      <div className={styles.itemsBottom}>
        {bottomItems.map((item, index) => (
          <NavLink
            to={item.path}
            key={index}
            className={(navData) =>
              navData.isActive
                ? classNames([styles.menu_item, styles.menu_item_active])
                : classNames([styles.menu_item])
            }
          >
            <span className={classNames([styles.menu_item_icon])}>
              {item.icon}
            </span>
            <span className={classNames([styles.menu_item_name])}>
              {item.name}
            </span>
          </NavLink>
        ))}
        <div
          onClick={() => toggleSideBar()}
          style={{ cursor: 'pointer' }}
          title={sideBarCollapsed ? 'Expandir menu' : 'Colapsar menu'}
          className={
            classNames([styles.toggle_sidebar])
          }
        >
          <span className={classNames([styles.menu_item_icon])}>
            <TbLayoutSidebarLeftExpand style={{ transform: sideBarCollapsed ? 'rotate(0deg)' : 'rotate(180deg)', transition: '0.3s' }} />
          </span>
          <span className={classNames([styles.menu_item_name, styles.collapsed])}>
            Menu
          </span>
        </div>
      </div>

    </div>
  )
}

SideBar.Body = Body

export default SideBar
