import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom"
import Information from "./maintenance/information"
import Vlans from "./vlans/vlans"
import Tabs from "@lib/midgard/tabs/tabs"
import React, { useContext, useEffect, useState } from "react"
import { PonsOlt } from "./pons/pons-olt"
import OltDeviceInformation from "./maintenance/olt-device-information"
import "./olt-management.css"
import ActionsDropdown from "../actions-dropdown/ActionsDropdown"
import EditOlt from "./modal/edit-olt"
import { ModalConfirm } from "@features/devices/components/modal"
import {
  dissociate,
  resourceDevice,
} from "../../redux/deviceTab/device-actions"
import { BackendContext } from "@services/backend/backend"
import { ToastContext } from "src/context/ToastContext"
import {
  RESOURCE_RESTART,
  RESOURCE_UPDATE,
} from "@features/devices/common/constants"
import { UserContext } from "src/context/UserContext"
import { useDispatch, useSelector } from "react-redux"
export default function OltManagement() {
  const toastContext = useContext(ToastContext)
  const params = useParams()
  const deviceid = params.id
  const [tabs, setTabs] = useState([
    { label: "PON", route: `/manage/${deviceid}/pon` },
    { label: "ETH", route: `/manage/${deviceid}/eth` },
  ])
  let pathLocation = useLocation()
  const navigate = useNavigate()
  const [caseModal, setCaseModal] = useState()
  const backend = useContext(BackendContext)
  const user = useContext(UserContext)
  const dispatch = useDispatch()
  const olt = useSelector((state) => state.tabSlice.selected)

  useEffect(() => {
    if (tabs.length) {
      navigate(tabs[0].route)
    }
  }, [])

  function closeModal() {
    setCaseModal(null)
  }

  const openModal = (option) => {
    setCaseModal(option)
  }

  const handleUpdateOlt = () => {
    resourceDevice(backend, toastContext, RESOURCE_UPDATE, deviceid)
    closeModal()
  }

  const handleRestart = () => {
    resourceDevice(backend, toastContext, RESOURCE_RESTART, deviceid)
    closeModal()
  }

  const handleDissociate = () => {
    dissociate(backend, user, toastContext, dispatch, deviceid)
    closeModal()
  }

  const modal = (caseModal) => {
    switch (caseModal) {
      case "edit-olt":
        return [<EditOlt id={deviceid} close={closeModal} allowMode={true} />]
      case "restart":
        return [
          <ModalConfirm
            close={closeModal}
            action={handleRestart}
            title={"Reiniciar dispositivo"}
            message={`Você tem certeza que deseja reiniciar esse dispositivo?`}
          />,
        ]
      case "update":
        return [
          <ModalConfirm
            close={closeModal}
            action={handleUpdateOlt}
            title={"Atualizar dispositivo"}
            message={`Tem certeza que deseja atualizar esse dispositivo?`}
          />,
        ]
      case "delete":
        return [
          <ModalConfirm
            close={closeModal}
            action={handleDissociate}
            title={"Deletar dispositivo"}
            message={`Você tem certeza que deseja deletar esse dispositivo?`}
          />,
        ]
      default:
        return
    }
  }

  const actionOptions = [
    { label: "Editar", action: () => openModal("edit-olt") },
    { label: "Reiniciar", action: () => openModal("restart") },
    { label: "Atualizar", action: () => openModal("update") },
    { label: "Deletar", action: () => openModal("delete") },
  ]

  return (
    <>
      {/* {deviceInfo.status === 1 ? */}

      {/* <div className='offline-info'> */}
      {/* <Loading show={true}></Loading> */}
      {/* </div> */}
      {/* : */}
      <React.Fragment>
        <OltDeviceInformation />

        <div className="tabs-wrapper">
          <Tabs
            data={tabs}
            route={pathLocation.pathname}
            activateFn={(route) => {
              navigate(route)
            }}
          ></Tabs>
          <div className="actions-dropdown">
            <ActionsDropdown options={actionOptions}></ActionsDropdown>
          </div>
        </div>
        <div style={{ display: "flex", flex: "1" }}>{modal(caseModal)}</div>
        <div style={{ margin: "0 auto" }}>
          <Routes>
            <Route path="pon" element={<PonsOlt />} />
            <Route path="eth" element={<Vlans />} />
            <Route path="maintenance" element={<Information />} />
          </Routes>
        </div>
      </React.Fragment>
    </>
  )
}
