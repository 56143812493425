import React from 'react'


import Tabs from './tabs'
import TabsHeader from './tabsHeader'
import TabHeader from './tabHeader'
import { useSelector } from 'react-redux';
import './multTabs.css'

const MultTabHead = () => {
  const listTabs = useSelector((state) => state.tabSlice.listTabs);
  return (
    <React.Fragment>

      <Tabs>
        <TabsHeader>
          {listTabs.map((item, index) => (
            <TabHeader
              key={index}
              data={item}
            />
          ))}

        </TabsHeader>
      </Tabs>
    </React.Fragment>
  )

}

export default MultTabHead