import PropTypes from "prop-types"
import { useState } from "react"
import "./device-table.css"

export const DeviceTable = ({ columns, rows }) => {
  const [hoveredRow, setHoveredRow] = useState(null)

  function showRow(col, row) {
    if (hoveredRow === row.id) {
      if (col.field !== "last_hello" && col.field !== "status") {
        return <span className="span-ellipsis">{row[col.field]}</span>
      }
      return null
    }
    return col.field !== "status" ? (
      <span className="span-ellipsis">{row[col.field]}</span>
    ) : null
  }

  return (
    <div className="table-container-devices">
      <table className="table-devices">
        <thead>
          <tr>
            {columns.map(
              (col) =>
                col.display && (
                  <th
                    key={col.field}
                    className={`cell-devices ${col.field === "last_hello" ? "cell-devices-last_hello" : ""}`}
                    style={{
                      width: col.widthPercentage,
                      minWidth: col.minWidth,
                    }}
                  >
                    <span style={{ color: "#646464" }}>
                      {col.name}
                    </span>
                  </th>
                )
            )}
          </tr>
        </thead>
        <tbody>
          {rows.map((row) => (
            <tr
              key={row.id}
              className="row-with-tooltip"
              onMouseOver={() => setHoveredRow(row.id)}
              onMouseLeave={() => setHoveredRow(null)}
            >
              {columns.map(
                (col) =>
                  col.display && (
                    <td
                      key={col.field}
                      className="cell-devices"
                      style={{
                        textAlign: col.alignCenter ? "center" : "left",
                        height: "35px",
                      }}
                    >
                      {col.field === "status" && (
                        <div className="status-devices-table">
                          {row[col.field]}
                        </div>
                      )}
                      {showRow(col, row)}
                    </td>
                  )
              )}
              <div className="tooltip">
                {row.actions && <div>{row.actions}</div>}
              </div>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

DeviceTable.propTypes = {
  columns: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
}
