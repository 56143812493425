import DefaultDevice from "./default/default-device"
import GatewayDevice from "./gateway/gateway-device"
import OltDevice from "./olt/olt-device"
import OntDevice from "./ont/ont-device"
import PhoneDevice from "./phone/ont-device"
import RouterWifiDevice from "./routerWifi/routerWifi-device"

let gatewayCategory = ['Gateway'].map(model => model.toLocaleLowerCase())
let oltCategory = ['g8', 'g16', 'olt_g16', 'olt_g8', 'olt_8820i'].map(model => model.toLocaleLowerCase())
let ontCategory = ['121ac', '110Gi', 'WiFiber 121 AC', 'ONT 121 W', 'R1', '110Gb', '142NW', '110G', '110B', /*'Huawei EG8145V5', 'Huawei EG8141A5', 'Huawei HG8546M',*/'ONU R1', 'WiFiber 120 AC',
  'WiFiber 1200 R', 'WiFiber AX 1800', 'WiFiber AX 1800V', 'ONT 140 PoE', 'WiFiber AX 3000V', 'hg6145f3', 'hg6145f', 'hg6145d2'].map(model => model.toLocaleLowerCase())
let phoneCategory = ['v5502'].map(model => model.toLocaleLowerCase())
let routerWifiCategory = ['GF1200', 'W5-1200F', 'W4-300F', 'W5-1200G', 'W5-2100G', 'Bifrost mock', 'Twibi Force Plug', 'Twibi Force AX', 'W6-1500',
  'Twibi Force Voice', 'Twibi Force Plug Giga', 'EX-1500', 'W5-1200GS', 'W4-300S', 'Twibi', 'ACtion RG1200', 'RX-1500', 'RX-3000', 'rx1500'].map(model => model.toLocaleLowerCase())

var DeviceFactory = {
  getIcoType: (model) => {
    switch (DeviceFactory.getDeviceType(model)) {
      case 'devices':
        return '/assets/img/icons/menu.svg'
      case 'Gateway':
        return '/assets/img/icons/olt8.svg'
      case 'Olt':
        return '/assets/img/icons/olt16.svg'
      case 'Ont':
        return '/assets/img/icons/onu.svg'
      case 'Phone':
        return '/assets/img/icons/phone.svg'
      case 'RouterWifi':
        return '/assets/img/icons/ont-router.svg'
      default:
        return '/assets/img/menu.png'
    }
  },

  getDeviceImage: (model) => {
    switch (model.toLowerCase()) {
      case 'olt_g16':
        return {
          src: '/assets/img/devices/OLT_G16_01.png',
          className: 'G16_olt_device'
        }
      case 'olt_g8':
        return {
          src: '/assets/img/devices/olt_g08.png',
          className: ''
        }
      case 'g16':
        return {
          src: '/assets/img/devices/OLT_G16_01.png',
          className: 'G16_olt_device'
        }
      case 'g8':
        return {
          src: '/assets/img/devices/olt_g08.png',
          className: ''
        }
      case 'olt_8820i':
        return {
          src: '/assets/img/devices/8820i.png',
          className: ''
        }
      case '121ac':
        return {
          src: '/assets/img/devices/WiFiber 121 AC.png',
          className: ''
        }
      case 'wifiber 120 ac':
        return {
          src: '/assets/img/devices/120ac.png',
          className: ''
        }
      case 'wifiber ax 1800':
        return {
          src: '/assets/img/devices/ax1800.png',
          className: ''
        }
      case 'wifiber ax 1800v':
        return {
          src: '/assets/img/devices/ax1800.png',
          className: ''
        }
      case 'ont 140 poe':
        return {
          src: '/assets/img/devices/ONT_140_POE.png',
          className: ''
        }
      case 'r1':
        return {
          src: '/assets/img/devices/ONU_R1.png',
          className: ''
        }
      case 'wifiber 1200 r':
        return {
          src: '/assets/img/devices/WiFiber 1200 R.png',
          className: ''
        }
      case 'ont 121 w':
        return {
          src: '/assets/img/devices/Ont_121_W.png',
          className: ''
        }
      case '110':
        return {
          src: '/assets/img/devices/ONU_110.png',
          className: ''
        }
      case '110gi': {
        return {
          src: '/assets/img/devices/ONU_110.png',
          className: ''
        }
      }
      case '110gb':
        return {
          src: '/assets/img/devices/ONU_R1.png',
          className: ''
        }
      case '110b':
        return {
          src: '/assets/img/devices/ONU_R1.png',
          className: ''
        }
      case '110g':
        return {
          src: '/assets/img/devices/ONU_110G.png',
          className: ''
        }

      case '142nw':
        return {
          src: '/assets/img/devices/ONT_142NW.png',
          className: ''
        }
      case 'wifiber ax 3000v':
        return {
          src: '/assets/img/devices/ONT_AX3000V.png',
          className: ''
        }
      case 'hg6145f3':
        return {
          src: '/assets/img/devices/ONT_HG6145F3.png',
          className: ''
        }
      case 'hg6145f':
        return {
          src: '/assets/img/devices/ONT_HG6145F.png',
          className: ''
        }
      case 'hg6145d2':
        return {
          src: '/assets/img/devices/ONT_HG6145D2.png',
          className: ''
        }
      default:
        return {
          src: '/assets/img/devices/OLT_G16_01.png',
          className: ''
        }
    }
  },

  getDeviceType: (model) => {
    const lowerCaseModel = model.toLowerCase()
    if (lowerCaseModel === "devices") {
      return 'devices'
    }
    if (gatewayCategory.includes(lowerCaseModel)) {
      return 'Gateway'
    }

    if (oltCategory.includes(lowerCaseModel)) {
      return 'Olt'
    }
    if (ontCategory.includes(lowerCaseModel)) {
      return 'Ont'
    }
    if (phoneCategory.includes(lowerCaseModel)) {
      return 'Phone'
    }
    if (routerWifiCategory.includes(lowerCaseModel)) {
      return 'RouterWifi'
    }

    console.log('DeviceFactory - Unknown model:', model)

    return 'unknown'
  },

  getDevice: (device, backend) => {
    switch (DeviceFactory.getDeviceType(device.model)) {
      case 'Phone':
        return new PhoneDevice(device, backend)
      case 'Gateway':
        return new GatewayDevice(device, backend)
      case 'Olt':
        return new OltDevice(device, backend)
      case 'Ont':
        return new OntDevice(device, backend)
      case 'RouterWifi':
        return new RouterWifiDevice(device, backend)
      case 'devices':
        return new DefaultDevice(device, backend)
      default:
        throw Error('Unknown device model')
    }
  },
  haveManagementAPI: (device) => {
    if (routerWifiCategory.includes(device.model)) {
      // const [major, minor] = device.fw_version ? device.fw_version.split('.').map(v => isNaN(v) ? v : Number(v)) : ['0', '0']
      // return (major > 1 || (major === 1 && minor >= 11))
      return true
    }
    if (gatewayCategory.includes(device.model)) {
      return true
    }

    if (ontCategory.includes(device.model)) {
      return true
    }
  },

  isTR069: (model) => {
    return ontCategory.includes(model) || oltCategory.includes(model)
  },

  isGateway: (model) => {
    return gatewayCategory.includes(model)
  },

  isRFO: (model) => {
    return ontCategory.includes(model)
  }
}

export default DeviceFactory
