import CryptoJS from "crypto-js"
export const redirectToContaIntelbras = () => {
  const tokenUrl = process.env.REACT_APP_CONTA_INTELBRAS_TOKEN_URL
  const client_id = process.env.REACT_APP_CONTA_INTELBRAS_CLIENT_ID

  function base64URL(string) {
    return string
      .toString(CryptoJS.enc.Base64)
      .replace(/=/g, "")
      .replace(/\+/g, "-")
      .replace(/\//g, "_")
  }

  let codeVerifier = String
  let codeChallenge = String

  //* ---- Functions used to generate code_verifier and code_challenge ----
  function generateRandomString(length) {
    console.log("generating code verifier...")
    var code_verifier = ""
    var possible =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~"
    for (var i = 0; i < length; i++) {
      code_verifier += possible.charAt(
        Math.floor(Math.random() * possible.length)
      )
    }
    // code_verifier is a secret random string usually with 128 caracters
    return code_verifier
  }

  function generateCodeChallenge(code_verifier) {
    console.log("generating code challenge ...")
    return base64URL(CryptoJS.SHA256(code_verifier))
  }

  //code verifier:
  codeVerifier = generateRandomString(128)
  localStorage.setItem("codeVerifier", codeVerifier)
  console.log("codeVerifier: " + codeVerifier)
  //code challenge:
  codeChallenge = generateCodeChallenge(codeVerifier)
  console.log("codeChallenge: " + codeChallenge)

  let contaIntelbrasUri =
    "https://" +
    tokenUrl +
    "/auth/authorize?response_type=code&client_id=" +
    client_id +
    "&redirect_uri=" +
    process.env.REACT_APP_AUTH_REDIRECT_URI +
    "&scope=openid&code_challenge=" +
    codeChallenge +
    "&code_challenge_method=S256"
  window.location.replace(contaIntelbrasUri)
}
