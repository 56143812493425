import React, { useState } from "react"
import BackendDriver from "./drivers/rest"
import { useNavigate } from "react-router-dom"

const APPLY_ACTION = 5

export const backendStatus = {
  SUCCESS: 1,
  ERROR: 2,
  UNAUTHORIZED: 3,
  PAYMENT_REQUIRED: 4,
  FORBIDDEN: 5,
}

export const BackendContext = React.createContext()

var data = {}
var driver = new BackendDriver(process.env.REACT_APP_REST_ENDPOINT)

export default function Backend({ children }) {
  const [auth, setAuth] = useState(null)
  const [authHandlerEnabled, setAuthHandlerEnabled] = useState(true)
  const [needApply, setNeedApply] = useState(false)
  const navigate = useNavigate()

  async function handleDriverResponse(driverResponse, key, refresh) {
    // //* ---- Functions used to generate code_verifier and code_challenge ----
    // function generateRandomString(length) {
    //     console.log("generating code verifier...")
    //     var code_verifier = "";
    //     var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~";
    //     for (var i = 0; i < length; i++) {
    //     code_verifier += possible.charAt(Math.floor(Math.random() * possible.length));
    //     }
    //     // code_verifier is a secret random string usually with 128 caracters
    //     return code_verifier;
    // }
    // function generateCodeChallenge(code_verifier) {
    //     console.log("generating code challenge ...")
    //     return base64URL(CryptoJS.SHA256(code_verifier))
    // }
    // function base64URL(string) {
    //     return string.toString(CryptoJS.enc.Base64).replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_')
    // }

    //*----------------------------------------------------------------------

    if (driverResponse.status === backendStatus.UNAUTHORIZED) {
      if (authHandlerEnabled) {
        //TODO: Verify if unauthoreized requests generate confusion to user.
        console.log("unauthorizedHandler()")
        setAuth(null)
      }
      return
    }

    if (driverResponse.status === backendStatus.PAYMENT_REQUIRED) {
      console.log("needs payment")
      navigate("payment")
    }

    if (driverResponse.status === backendStatus.SUCCESS)
      data[key] = driverResponse.content
  }

  async function backendApply() {
    if (needApply) {
      const driverResponse = await driver.create(
        "action",
        { actionID: APPLY_ACTION },
        null,
        auth
      )

      handleDriverResponse(driverResponse, null, true)

      return driverResponse
    }
  }

  async function create(key, data, headers, apply = true) {
    //FIXME: turn headers into some kind of driver agnostic data

    let driverResponse = await driver.create(key, data, headers, auth)

    handleDriverResponse(driverResponse, key, true)

    if (apply) await backendApply()

    return driverResponse
  }

  async function upload(key, data, headers) {
    //FIXME: turn headers into some kind of driver agnostic data

    let driverResponse = await driver.upload(key, data, headers, auth)

    handleDriverResponse(driverResponse, key, true)

    return driverResponse
  }

  async function download(key, data, headers) {
    //FIXME: turn headers into some kind of driver agnostic data

    let driverResponse = await driver.download(key, data, headers, auth)

    handleDriverResponse(driverResponse, key, true)

    return driverResponse
  }

  async function retrieve(key) {
    if (!data[key]) return await retrieveFresh(key)

    return {
      status: backendStatus.SUCCESS,
      content: data[key],
    }
  }

  async function retrieveFresh(key) {
    const driverResponse = await driver.retrieve(key, auth)

    handleDriverResponse(driverResponse, key, true)

    return driverResponse
  }

  async function update(key, value, headers, apply = true) {
    //FIXME: turn headers into some kind of driver agnostic data

    const driverResponse = await driver.update(key, value, headers, auth)

    handleDriverResponse(driverResponse, null, true)

    if (driverResponse.status === backendStatus.SUCCESS && data[key])
      data[key] = value

    if (apply) await backendApply()

    return driverResponse
  }

  async function backendDelete(key, value, apply = true) {
    //FIXME: remove value parameter from APIs

    const driverResponse = await driver.delete(key, value, auth)

    handleDriverResponse(driverResponse, null, true)

    if (driverResponse.status === backendStatus.SUCCESS && data[key])
      delete data[key]

    if (apply) await backendApply()

    return driverResponse
  }

  function setDriverBaseUrl(url) {
    driver.setBaseUrl(url)
  }

  function getDriverBaseUrl() {
    return driver.getBaseUrl()
  }

  return (
    <BackendContext.Provider
      value={{
        create: create,
        retrieve: retrieve,
        retrieveFresh: retrieveFresh,
        download: download,
        update: update,
        delete: backendDelete,
        upload: upload,
        setAuthInfo: setAuth,
        auth: auth,
        setDriverBaseUrl: setDriverBaseUrl,
        getDriverBaseUrl: getDriverBaseUrl,
        setAuthHandlerEnabled: setAuthHandlerEnabled,
        setNeedApply: setNeedApply,
        backendApply: backendApply,
      }}
    >
      {children}
    </BackendContext.Provider>
  )
}
