import React, { useState, useContext, useEffect } from 'react'
import { Routes, Route, useNavigate, useLocation, useParams } from "react-router-dom"
import Loading from '@lib/midgard/loading/loading'
import Tabs from '@lib/midgard/tabs/tabs'
import { DeviceContext } from '@features/devices/management/manage-page'
import { BackendContext, } from '@services/backend/backend'
import '@features/devices/management/manage.css'
import MonitoringWanData from './wan/tr069-wan-data'
import WifiPage from './wifi/wifi-page'
import TR069WanConfiguration from './wan/tr069-wan-configuration'
import ActionsDropdown from "../../actions-dropdown/ActionsDropdown"
import LanManagementPage from './lan/lan'
import SipManagementPage from './sip/sip'
import InMeshManagementPage from './inmesh/inmesh'
import InfoOnt from './info/info'
import Maintenance from './maintenance/maintenance'
import { getDeviceById } from '@features/devices/redux/deviceTab/device-actions'
import { UserContext } from 'src/context/UserContext'
import { ToastContext } from 'src/context/ToastContext'
import { useSelector } from 'react-redux'
import {
  dissociate,
  resourceDevice,
} from "../../../redux/deviceTab/device-actions"
import { RESOURCE_RESTART } from "@features/devices/common/constants"
import { useDispatch } from "react-redux"
import { ModalConfirm } from '@features/devices/components/modal'
export const DEVICE_STATUS_OFFLINE = 2


export function getTraffic(value, unitBytes, time) {

  const BYTE_SIZE = 8
  const MEGABIT_SIZE = 1000000

  if (time === 0) return 0

  return ((value * unitBytes * BYTE_SIZE) / time) / MEGABIT_SIZE
}

export function removeStrings(input, substringsToRemove) {
  let result = input;

  for (let i = 0; i < substringsToRemove.length; i++) {
    result = result.replace(new RegExp(substringsToRemove[i], 'g'), "");
  }

  return result;
}

export function isMinimalVersion(device, baseVersion) {//baseVersion is the minimum version that the device needs to have
  let versionString = device.fw_version.split(".");
  let major = parseInt(versionString[0]);

  if (isNaN(major)) {
    return false;
  }

  let minor = parseInt(versionString[1]);
  if (isNaN(minor)) {
    return false;
  }

  // firmware = 1.11.'1'-test1.63679 or bifrost-1.11.'1' or 1.11.'1'
  let charsToRemove = ["-dev", "-test1", "-test2"];
  let patch = parseInt(removeStrings(versionString[2], charsToRemove));
  if (isNaN(patch)) {
    return false;
  }

  if (major < baseVersion[0] || (major === 1 && minor < baseVersion[1]) || (major === baseVersion[0] && minor === baseVersion[1] && patch < baseVersion[2])) {
    return false;
  }
  return true
}

// export const DeviceContext = React.createContext(null)

export default function DefaultOntManagePage() {
  const backend = useContext(BackendContext)
  const manageCtx = useContext(DeviceContext)
  const [deviceInfo] = useState(manageCtx.deviceInfo)
  const [routes, setRoutes] = useState([])
  const params = useParams()
  const deviceid = params.id
  const device = useSelector((state) => state.tabSlice.selected);
  const user = useContext(UserContext)
  const toastContext = useContext(ToastContext)
  const [caseModal, setCaseModal] = useState()
  let pathLocation = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [gateway, setGateway] = useState()
  const [pontStatus, setPonStatus] = useState()
  const [olt, setOlt] = useState()

  useEffect(() => {
    if (device?.gw_id) {
      const fetchGateway = async () => {
        const result = await getDeviceById(backend, toastContext, device.gw_id);
        setGateway(result);
      };
      fetchGateway();
    }
    if (device?.olt_id) {
      const fetchOlt = async () => {
        const result = await getDeviceById(backend, toastContext, device.olt_id);
        setOlt(result);
      };
      fetchOlt();
    }
    fetchDeviceWanGroup()
  }, [device])

  useEffect(() => {
    const fetchRoutes = async () => {
      const response = await manageCtx.device.retrieveResource('resources')
      if (manageCtx.device.data.model === "121ac" || "WiFiber 121 AC" || manageCtx.device.data.model === "ONT 121 W"
        || manageCtx.device.data.model === "WiFiber AX 1800V") {
        response.content[3].active = true
      }
      const availableRoutes = response.content.filter(route => route.active);
      const lines = [];

      availableRoutes.forEach(route => {
        //TODO:
        switch (route.resource) {
          case 'wifi':
            if (deviceInfo.supported_protocols.tr069) {
              lines.push({ label: 'Wi-Fi', route: `/manage/${deviceid}/wifi` });
            }
            break;
          case 'wan':
            if (deviceInfo.supported_protocols.tr069) {
              lines.push({ label: 'WAN', route: `/manage/${deviceid}/wan` });
            }
            break;
          case 'lan':
            if (deviceInfo.supported_protocols.tr069) {
              lines.push({ label: 'LAN', route: `/manage/${deviceid}/lan` });
            }
            break;
          case 'sip':
            if (deviceInfo.supported_protocols.tr069) {
              lines.push({ label: 'SIP', route: `/manage/${deviceid}/sip` });
            }
            break;
          case 'inmesh':
            if (deviceInfo.supported_protocols.tr069) {
              lines.push({ label: 'InMesh', route: `/manage/${deviceid}/inmesh` });
            }
            break;
          default:
            break;
        }
      });

      setRoutes(lines);
      const hasNoTabSelected = pathLocation.pathname === `/manage/${deviceid}`
      if (lines.length > 0 && hasNoTabSelected) {
        navigate(lines[0].route)
      }
    };

    fetchRoutes();
    // eslint-disable-next-line
  }, [deviceid, backend]);

  if (!deviceInfo /*|| !canBeManaged(device)*/)
    return <Loading show={true}></Loading>
  const fetchDeviceWanGroup = async () => {
    let result = await manageCtx.device.retrieveResource('wan-group')

    if (result.status !== 200) {
      if (result.content.pon_status)
        setPonStatus(result.content.pon_status)

  }
}

  const modal = (caseModal) => {
    switch (caseModal) {
      case "restart":
        return [
          <ModalConfirm
            close={closeModal}
            action={handleRestart}
            title={"Reiniciar dispositivo"}
            message={`Você tem certeza que deseja reiniciar esse dispositivo?`}
          />,
        ]
      case "delete":
        return [
          <ModalConfirm
            close={closeModal}
            action={handleDissociate}
            title={"Deletar dispositivo"}
            message={`Você tem certeza que deseja deletar esse dispositivo?`}
          />,
        ]
      default:
        return
    }
  }

  function closeModal() {
    setCaseModal(null)
  }

  function openModal(option) {
    setCaseModal(option)
  }

  const handleRestart = (id) => {
    resourceDevice(backend, toastContext, RESOURCE_RESTART, deviceid)
    closeModal()
  }

  const handleDissociate = () => {
    dissociate(backend, user, toastContext, dispatch, deviceid)
    closeModal()
  }


  const options = [
    { label: "Reiniciar", action: () => openModal("restart") },
    { label: "Deletar", action: () => openModal("delete") },
  ]


  return (
    <>
      {deviceInfo.conn_status === DEVICE_STATUS_OFFLINE ?

        <div className='offline-info'>
          <Loading show={true}></Loading>
          <span>O dispositivo está offline. Aguardando o retorno testee</span>
        </div>
        : <React.Fragment>
          <InfoOnt olt={olt} gateway={gateway} ponStatus={pontStatus} />
          <div className="tabs-wrapper">
            <Tabs
              data={routes}
              route={pathLocation.pathname}
              activateFn={(route) => { navigate(route) }}
            ></Tabs>
            <div className="actions-dropdown">
              <ActionsDropdown options={options}></ActionsDropdown>
            </div>
          </div>
          <div style={{ display: "flex", flex: "1" }}>{modal(caseModal)}</div>
          <Routes>
            {routes.map((route, index) => {
              switch (route.route.split('/').pop()) {
                case 'wifi':
                  return <Route key={index} path='wifi' element={<WifiPage />} />
                case 'wan':
                  return <Route key={index} path='wan' element={<MonitoringWanData WanConfigurationComponent={TR069WanConfiguration} />} />
                case 'lan':
                  return <Route key={index} path='lan' element={<LanManagementPage />} />
                case 'sip':
                  return <Route key={index} path='sip' element={<SipManagementPage />} />
                case 'inmesh':
                  return <Route key={index} path='inmesh' element={<InMeshManagementPage />} />
                case 'maintenance':
                  return <Route key={index} path='maintenance' element={<Maintenance olt={olt} />} />
                default:
                  return null;
              }
            })}
          </Routes>
        </React.Fragment>}
    </>
  )
}
