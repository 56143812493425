import {
  Button,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "@conecte-se/ui-components-reactjs"
import { InputText } from "@features/devices/components/inputs"
import { RadioGroupButton } from "@features/devices/components/radio-button-group"
import { DeviceContext } from "@features/devices/management/manage-page"
import { addVlan } from "@features/devices/redux/vlan/vlan-actions"
import { useContext, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { validateVlan8820i } from "src/common/validators"
import { ToastContext } from "src/context/ToastContext"
import { UserContext } from "src/context/UserContext"

const AddVlan = ({ close, portName }) => {
  const dispatch = useDispatch()
  const modes = [
    { id: "tagged", text: "Tagged" },
    { id: "untagged", text: "Untagged" },
  ]
  const olt = useSelector((state) => state.tabSlice.selected);
  const vlans = useSelector((state) => state.vlansState.vlans)
  const [vlan, setVlan] = useState({
    gw_id: olt.gw_id,
    olt_id: olt.id,
    numbackendber: "",
    description: "",
    port_name: portName,
    tagged: true,
  })
  // const backend = useContext(BackendContext)
  const manageCtx = useContext(DeviceContext)
  const user = useContext(UserContext)
  const toastContext = useContext(ToastContext)
  const [errors, setErrors] = useState({})
  const handleError = (name, error) => {
    if (error) {
      setErrors({ ...errors, [name]: error })
    } else {
      const { [name]: removed, ...rest } = errors
      setErrors(rest)
    }
  }

  const clearInputField = (property) => {
    setVlan({ ...vlan, [property]: "" })
  }

  const handleAddVlan = () => {
    if (!Object.values(errors).some((error) => error)) {
      addVlan(manageCtx.device.api, user, toastContext, dispatch, vlan)
      close()
    }
  }

  const handleChange = (e) => {
    const inputValue = e.target.value.trim()
    if (e.target.id == "number") {
      if (inputValue === "") {
        setVlan({
          ...vlan,
          [e.target.name]: "",
        })
      } else if (!isNaN(inputValue)) {
        if (inputValue >= 1 && inputValue <= 4094) {
          setVlan({
            ...vlan,
            [e.target.name]: inputValue,
          })
        } else {
          console.log("O valor de vlan deve estar entre 1 e 4094.")
        }
      } 
    }
    setVlan({
      ...vlan,
      [e.target.name]: e.target.value,
    })
  }
  const handleTaggedChange = (e) => {
    const selectedValue = e.target.value === "tagged"
    setVlan({ ...vlan, tagged: selectedValue })
  }
  return (
    <Modal visible={true} width={"500px"}>
      <ModalHeader>
        <div style={{ fontSize: "18px", fontWeight: "bold" }}>Criando VLAN</div>
      </ModalHeader>
      <ModalBody>
        <div style={{ flexDirection: "column" }}>
          <div className="mb-3">
            <Label>VLAN ID</Label>
            <InputText
              type="number"
              id="number"
              name="number"
              value={vlan.number}
              label="VLAN ID"
              onChange={handleChange}
              validate={{
                action: (e) =>
                  validateVlan8820i(
                    vlans.filter((vlan) => vlan.port_name === portName),
                    vlan,
                    olt.modelo
                  ),
                handleError: handleError,
              }}
              clearField={{
                action: clearInputField,
                text: "Limpar",
              }}
            />
          </div>
          <div className="mb-3">
            <Label>Descrição</Label>
            <InputText
              id="description"
              name="description"
              value={vlan.description}
              label="Descrição"
              onChange={handleChange}
              clearField={{
                action: clearInputField,
                text: "Limpar",
              }}
            />
          </div>
          <div className="mb-3">
            <Label>Tipo da VLAN</Label>
            <RadioGroupButton
              name={`tagged`}
              onChange={handleTaggedChange}
              selected={vlan.tagged ? "tagged" : "untagged"}
              options={modes}
              labelClass={"onu-modes"}
            />
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <div style={{ display: "flex" }}>
          <div className="mr-4" style={{ paddingRight: "20px" }}>
            <Button secondary onClick={() => close()}>
              Cancelar
            </Button>
          </div>
          <Button disabled={!vlan.number} onClick={handleAddVlan} primary>
            Aplicar
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  )
}
export default AddVlan
