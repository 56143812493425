import { retrieveDashboard } from "@features/dashboard/redux/dashboard-actions"
import React, { useContext, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { BackendContext } from "@services/backend/backend"
import { ToastContext } from "src/context/ToastContext"
import { UserContext } from "src/context/UserContext"
import { ComposedBarChart } from "../charts/composed-bar-chart"
import { StatusPieChart } from "../charts/status-pie-chart"
import { NotificationItem } from "../notifications"
import orderBy from "lodash/orderBy"
import "./dashboard.css"

export function Dashboard() {
  const dispatch = useDispatch()
  const user = useContext(UserContext)
  const backend = useContext(BackendContext)
  const toastContext = useContext(ToastContext)
  const devicesField = useSelector((state) => state.dashboardState.devicesField)
  const oltsStatus = useSelector((state) => state.dashboardState.oltsStatus)
  const cpesStatus = useSelector((state) => state.dashboardState.cpesStatus)
  const notifications = useSelector(
    (state) => state.dashboardState.notifications
  )

  useEffect(() => {
    if (user.activeGroup != null) {
      retrieveDashboard(backend, user, toastContext, dispatch)
    }
    // eslint-disable-next-line
  }, [user.activeGroup, dispatch])

  const RADIAN = Math.PI / 180

  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
    payload,
    fill,
    text,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5
    const x = cx + radius * Math.cos(-midAngle * RADIAN)
    const y = cy + radius * Math.sin(-midAngle * RADIAN)
    const sin = Math.sin(-RADIAN * midAngle)
    const cos = Math.cos(-RADIAN * midAngle)
    const sx = cx + outerRadius * cos
    const sy = cy + outerRadius * sin
    const mx = cx + (outerRadius + 10) * cos
    const my = cy + (outerRadius + 10) * sin
    const ex = mx + (cos >= 0 ? 1 : -1) * 22
    const ey = my
    const textAnchor = cos >= 0 ? "start" : "end"

    return (
      <g>
        <text
          x={x}
          y={y}
          fill="white"
          textAnchor={x > cx ? "start" : "end"}
          dominantBaseline="central"
        >
          {`${(percent * 100).toFixed(0)}%`}
        </text>
        <path
          d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
          stroke={fill}
          fill="none"
        />
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />

        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey - 5}
          dy={10}
          textAnchor={textAnchor}
          fill="#999"
        >
          {payload.value + " - (" + payload.name + ")"}
        </text>
      </g>
    )
  }

  return (
    <React.Fragment>
      <div className="dashboard-grid-areas">
        <div className="pie-charts card">
          {cpesStatus ? (
            <div>
              <h3 style={{ textAlign: "center" }}>Status das CPEs</h3>
              <StatusPieChart
                data={cpesStatus}
                renderCustomizedLabel={renderCustomizedLabel}
              />
            </div>
          ) : null}
          {oltsStatus ? (
            <div>
              <h3 style={{ textAlign: "center" }}>Status das OLTs</h3>
              <StatusPieChart
                data={oltsStatus}
                renderCustomizedLabel={renderCustomizedLabel}
              />
            </div>
          ) : null}
        </div>
        <div className="bar-chart card">
          {devicesField ? (
            <div>
              <h3 style={{ textAlign: "center" }}>Dispositivos em campo</h3>
              <ComposedBarChart data={devicesField} />
            </div>
          ) : null}
        </div>
        <div className="notifications card">
          <h3 style={{ textAlign: "left" }}>Notificações</h3>
          {orderBy(notifications, "date", "desc").map((item, index) => {
            return <NotificationItem data={item} key={index} />
          })}
        </div>
      </div>
    </React.Fragment>
  )
}
