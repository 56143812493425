import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "@conecte-se/ui-components-reactjs"
import React from "react"
import "./modal-sfp.css"
const ModalSFPInfo = ({ close, sfp }) => {
  return (
    <Modal visible={true} width={"500px"}>
      <ModalHeader>
        <div style={{ fontSize: "18px", fontWeight: "bold" }}>
          Informações SFP:
        </div>
      </ModalHeader>
      <ModalBody>
        <div>
          <div className="col-md-12 list-topic">
            <h5 style={{ marginTop: "0px" }}>Informações Gerais</h5>
            <div className="list-item">
              <div className="list-topic-item">Status de Módulo Óptico:</div>
              <div className="list-topic-item">
                {sfp?.opt_module_status ? sfp?.opt_module_status : "---"}
              </div>
            </div>
            <div className="list-item">
              <div className="list-topic-item">Estado da Porta:</div>
              <div className="list-topic-item">
                {sfp?.port_state ? sfp?.port_state : "---"}
              </div>
            </div>
            <div className="list-item">
              <div className="list-topic-item">Tipo de Transceiver:</div>
              <div className="list-topic-item">
                {sfp?.transceiver_type ? sfp?.transceiver_type : "---"}
              </div>
            </div>
            <div className="list-item">
              <div className="list-topic-item">Tipo de Módulo:</div>
              <div className="list-topic-item">
                {sfp?.module_type ? sfp?.module_type : "---"}
              </div>
            </div>
            <h5>Informações de Diagnostico</h5>
            <div className="list-item">
              <div className="list-topic-item">Temperatura(°C):</div>
              <div className="list-topic-item">
                {sfp?.temperature ? sfp?.temperature : "---"}
              </div>
            </div>
            <div className="list-item">
              <div className="list-topic-item">Tensão(V):</div>
              <div className="list-topic-item">
                {sfp?.voltage ? sfp?.voltage : "---"}
              </div>
            </div>
            <div className="list-item">
              <div className="list-topic-item">Corrente(mA):</div>
              <div className="list-topic-item">
                {sfp?.bias_current ? sfp?.bias_current : "---"}
              </div>
            </div>
            <div className="list-item">
              <div className="list-topic-item">Potência RX(dBm):</div>
              <div className="list-topic-item">{sfp?.rx ? sfp?.rx : "---"}</div>
            </div>
            <div className="list-item">
              <div className="list-topic-item">Potência TX(dBm):</div>
              <div className="list-topic-item">{sfp?.tx ? sfp?.tx : "---"}</div>
            </div>
            <h5>Informações do Fabricante</h5>
            <div className="list-item">
              <div className="list-topic-item">Nome do Fornecedor:</div>
              <div className="list-topic-item">
                {sfp?.vendor_name ? sfp?.vendor_name : "---"}
              </div>
            </div>
            <div className="list-item">
              <div className="list-topic-item">PN do Fornecedor:</div>
              <div className="list-topic-item">
                {sfp?.vendor_PN ? sfp?.vendor_PN : "---"}
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <div style={{ display: "flex" }}>
          <div className="mr-4" style={{ paddingRight: "20px" }}>
            <Button secondary onClick={() => close()}>
              Voltar
            </Button>
          </div>
        </div>
      </ModalFooter>
    </Modal>
  )
}
export default ModalSFPInfo
