import {
    Card,
    ToastContainer,
} from "@conecte-se/ui-components-reactjs"
import { useContext, useState } from "react"
import { useSelector } from "react-redux"
import { ModalConfirm } from "@features/devices/components/modal"
import { ToastContext } from "src/context/ToastContext"
import Button from "src/lib/midgard/button/button"
import "./maintenance.css"
import { resourceDevice } from "@features/devices/redux/deviceTab/device-actions"
import { BackendContext } from "@services/backend/backend"
import { RESOURCE_RESTART } from "@features/devices/common/constants"
import InformationOnt from "../../../modal/information-ont"
const Maintenance = ({ olt }) => {
    const backend = useContext(BackendContext)
    const toastContext = useContext(ToastContext)
    const ont = useSelector((state) => state.tabSlice.selected);
    const [caseModal, setCaseModal] = useState()


    function closeModal() {
        setCaseModal(null)
    }
    function openModal(option) {
        setCaseModal(option)
    }
    const handleRestart = () => {
        resourceDevice(backend, toastContext, RESOURCE_RESTART, ont.id)
        closeModal()
    }
    const modal = (caseModal) => {
        switch (caseModal) {
            case "restart":
                return [
                    <ModalConfirm
                        close={closeModal}
                        action={handleRestart}
                        title={"Reiniciar ONT"}
                        message={`Tem certeza que deseja reiniciar a ONT ${ont.id}?`}
                    />,
                ]
            case "information":
                return [
                    <InformationOnt close={closeModal} selected={ont} />
                ]
            case "edit":
                return [
                    <InformationOnt close={closeModal} selected={ont} />
                ]
            default:
                return
        }
    }

    return (
        <div className="container">
            <Card
                width="100%"
                height="100%"
            >
                <div className="content">
                    <div className="info">
                        <div className="buttons">
                            <div className="buttons-olt-config">
                                <Button
                                    text="Reiniciar"
                                    onClick={() => {
                                        openModal("restart")
                                    }}
                                />
                                <Button
                                    text="Informações"
                                    onClick={() => {
                                        openModal("information")
                                    }}
                                />
                                <Button
                                    text="Editar"
                                    onClick={() => {
                                        openModal("edit")
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Card>
            <ToastContainer toasts={toastContext.toasts} position={"bottom_right"} />
            {modal(caseModal)}
        </div>
    )
}

export default Maintenance
