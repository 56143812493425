const cleanPercentage = (percentage) => {
    const isNegativeOrNaN = !Number.isFinite(+percentage) || percentage < 0;
    const isTooHigh = percentage > 100;
    return isNegativeOrNaN ? 0 : isTooHigh ? 100 : +percentage;
};

const Circle = ({ color, percentage }) => {
    const r = 28;
    const circ = 2 * Math.PI * r;
    const strokePct = ((100 - percentage) * circ) / 100;
    return (
        <circle
            r={r}
            cx={40}
            cy={40}
            fill="transparent"
            stroke={strokePct !== circ ? color : ""}
            strokeWidth={8}
            strokeDasharray={circ}
            strokeDashoffset={percentage ? strokePct : 0}
        ></circle>
    );
};

const Text = ({ percentage }) => {
    const textColor = percentage >= 75 ? '#00B26B' : '#888888'
    return (
        <text
            style={{ fill: textColor }}
            x="50%"
            y="50%"
            dominantBaseline="central"
            textAnchor="middle"
            fontSize={"1em"}
        >
            {percentage.toFixed(0)}%
        </text>
    );
};

export default function PercentageCircle({ percentage, color }) {
    const pct = cleanPercentage(percentage);
    return (
        <div>
            <svg width={80} height={80}>
                <g transform={`rotate(-90 ${"40 40"})`}>
                    <Circle color="#c0c0c0" />
                    <Circle color={color} percentage={pct} />
                </g>
                <Text percentage={pct} />
            </svg>
        </div>

    );
};