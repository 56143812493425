import { useState } from "react"
import { MdArrowDropDown, MdArrowDropUp } from "react-icons/md"
import "./dropdown-scroll.css"

export const DropdownScroll = ({
  id,
  items,
  onSelect,
  defaultItem = "Selecione",
  disabled = false,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [selectedItem, setSelectedItem] = useState(null)

  const toggleDropdown = () => {
    if (disabled) return
    if (items.length === 0) return
    setIsOpen(!isOpen)
  }

  const handleItemClick = (item) => {
    setSelectedItem(item)
    toggleDropdown()

    onSelect && onSelect(item)
  }

  return (
    <div
      className={`dropdown-container ${isOpen ? "open" : ""} ${disabled ? "disabled" : ""}`}
    >
      <div id={id} className="dropdown-header" onClick={toggleDropdown}>
        <span>{selectedItem ? selectedItem.label : defaultItem}</span>
        <span className="dropdown-icon">
          {isOpen ? (
            <MdArrowDropUp onClick={toggleDropdown} />
          ) : (
            <MdArrowDropDown onClick={toggleDropdown} />
          )}
        </span>
      </div>
      {isOpen && (
        <div className="scrollable-list">
          <ul className="dropdown-list">
            {items?.map((item, index) => (
              <li key={index} onClick={() => handleItemClick(item)}>
                {item.label}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  )
}
