import React from "react"

export function OpenSee(props) {
  return (
    <svg
      className="icon"
      viewBox="0 0 48 48"
      style={props.style ? props.style : {}}
    >
      <title>{props.title ? props.title : null}</title>
      <path d="M24 9c-10.468 0-19.541 6.096-24 15 4.459 8.904 13.532 15 24 15s19.541-6.096 24-15c-4.459-8.904-13.532-15-24-15zM35.834 16.955c2.82 1.799 5.21 4.208 7.005 7.045-1.795 2.837-4.185 5.246-7.005 7.045-3.543 2.26-7.635 3.455-11.834 3.455s-8.29-1.195-11.834-3.455c-2.82-1.799-5.21-4.208-7.005-7.045 1.795-2.837 4.185-5.246 7.005-7.045 0.184-0.117 0.369-0.231 0.556-0.342-0.467 1.281-0.722 2.664-0.722 4.106 0 6.627 5.373 12 12 12s12-5.373 12-12c0-1.443-0.256-2.825-0.722-4.106 0.186 0.111 0.372 0.225 0.556 0.342v0zM24 19.5c0 2.485-2.015 4.5-4.5 4.5s-4.5-2.015-4.5-4.5 2.015-4.5 4.5-4.5 4.5 2.015 4.5 4.5z" />
    </svg>
  )
}
