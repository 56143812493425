import React from 'react'
import {  useSelector } from 'react-redux';
import './multTabs.css'

 import If from './if'
import DeviceManagePage from "@features/devices/management/manage-page"

export const DeviceContext = React.createContext(null)
const TabContent = ({ data }) => {
    const selectedTab = useSelector((state) => state.tabSlice.selected);
    const selected = selectedTab.id === data.id
        return (
            <If show={selected}>
                    <div id={data.id} className={`tab-pane ${selected ? 'active' : ''}`}>
                        <DeviceManagePage/>
                    </div> 
            </If>
        )
    }

export default TabContent;
