import React, { useContext, useEffect, useState } from "react"
import {
  MdOutlineRouter,
  MdAccessTime,
  MdMemory,
  MdDomain,
  MdCable,
  MdSquare,
  MdBolt,
  MdOutlineThermostat,
} from "react-icons/md"
import { TbCircuitAmmeter } from "react-icons/tb"
import { FaSquare } from "react-icons/fa"
import "./info.css"
import { useSelector, useDispatch } from "react-redux"
import { ProgressSignal } from "../../../../components/progress-signal"
import DeviceHeaderInfo from "@features/devices/management/device-header/device-header-info"
import DeviceHeaderImage from "@features/devices/management/device-header/device-header-image"
import { useNavigate } from "react-router-dom"
import { addTab } from "@features/devices/redux/deviceTab/device-slice"
import { getDeviceById } from "@features/devices/redux/deviceTab/device-actions"
import { ToastContext } from "src/context/ToastContext"
import { BackendContext } from "@services/backend/backend"
import { UserContext } from "src/context/UserContext"
function InfoOnt({ gateway, olt, ponStatus={} }) {
  const device = useSelector((state) => state.tabSlice.selected)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const user = useContext(UserContext)
  const backend = useContext(BackendContext)
  const toastContext = useContext(ToastContext)
  function splitVersion() {
    var result = device?.software_version?.match(/SP(.*)/)
    if (result && result.length > 1) {
      return "SP" + result[1]
    }
    return device?.software_version
  }

  const getProduct = async (id) => {
    const result = await getDeviceById(backend, toastContext, id)
    var newDevice = { ...result }
    dispatch(addTab(newDevice))
    navigate(`/manage/${newDevice.id}`)
  }

  return (
    <DeviceHeaderInfo>
      <DeviceHeaderImage model={device?.model} />
      <DeviceHeaderInfo.Divider />
      <div className="device-info-system">
        <span className="device-info-system-text">Sistema</span>
        <div className="device-info-system-icons">
          {device?.fw_version && (
            <span className="icon-span" title="Versão de Firmware">
              <MdMemory size={24} />
              <span>{device.fw_version}</span>
            </span>
          )}

          {device?.ip && (
            <span className="icon-span" title="Endereço IP">
              <MdOutlineRouter size={24} />
              <span>{device.ip}</span>
            </span>
          )}
          {device?.up_time && (
            <span className="icon-span" title="Tempo de atividade">
              <MdAccessTime size={22} />
              <span>{device?.up_time}</span>
            </span>
          )}
          {device?.gw_id && (
            <span className="icon-span" title="ID do gateway">
              <MdDomain size={22} />
              <span
                className="clickable-span"
                onClick={() => getProduct(device.gw_id)}
              >
                {device.gw_id}
              </span>
            </span>
          )}
        </div>
      </div>
      <DeviceHeaderInfo.Divider />
      <div className="device-info-pon">
        <span className="device-info-system-text">PON Status</span>
        <div className="device-info-pon-icons">
          {device?.olt_id && (
            <span className="icon-span" title={`OLT: ${device.olt_id}`}>
              <MdCable size={22} />
              <span
                className="clickable-span"
                onClick={() => getProduct(device.olt_id)}
              >
                {olt?.name}
              </span>
            </span>
          )}

          {device?.port && (
            <span className="icon-span" title="Porta">
              <FaSquare size={20} />
              <span>Porta {device?.port}</span>
            </span>
          )}
          {ponStatus?.voltage && (
            <span className="icon-span" title="Voltagem">
              <MdBolt size={24} />
              <span>{Number(ponStatus.voltage).toFixed(2)} V</span>
            </span>
          )}
          {device?.slot && (
            <span className="icon-span" title="Slot">
              <MdMemory size={24} />
              <span>Slot {device?.slot}</span>
            </span>
          )}
          {ponStatus?.temperature && (
            <span className="icon-span" title="Temperatura">
              <MdOutlineThermostat size={24} />
              <span>{Number(ponStatus.temperature).toFixed(2)} °C</span>
            </span>
          )}
          {ponStatus?.bias_current && (
            <span className="icon-span" title="Corrente">
              <TbCircuitAmmeter size={24} />
              <span>{Number(ponStatus.bias_current).toFixed(2)} mA</span>
            </span>
          )}
        </div>
        {ponStatus?.rx_power && (
          <div className="device-info-cpu">
            <ProgressSignal min={-30} max={-18} signal={ ponStatus.rx_power} type="Rx" />
            <ProgressSignal min={-30} max={18} signal={ ponStatus.tx_power} type="Tx" />
          </div>
        )
          }
      </div>
    </DeviceHeaderInfo>
  )
}

export default InfoOnt
