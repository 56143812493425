import { createContext, useContext, useEffect } from "react"
import { useDispatch } from "react-redux"

import {
  updateCpeInfo,
  updateCpeInfoList,
} from "@features/devices/redux/cpe/cpe-slice"
import { updateGatewayInfo } from "@features/devices/redux/gateway/gateway-slice"
import { updateOltInfo } from "@features/devices/redux/olt/olt-slice"
import { updateVlanInfo } from "@features/devices/redux/vlan/vlan-slice"
import { UserContext } from "./UserContext"

export const WsDevicesContext = createContext()

export default function WsDevicesProvider({ children }) {
  const dispatch = useDispatch()
  const userContext = useContext(UserContext)

  useEffect(() => {
    const rooms = ["gateway", "olt", "cpe", "cpes", "vlan"]
    const websockets = {}
    if (userContext.activeGroup != null) {
      const wsEndpoint = process.env.REACT_APP_WS_DEVICES_ENDPOINT

      rooms.forEach((type) => {
        const url = `${wsEndpoint}${type}/${userContext.activeGroup}`
        const ws = new WebSocket(url)

        ws.onopen = () => {
          console.log(`WebSocket connection established for ${type}`)
        }

        ws.onerror = () => {
          console.log("Websocket connection error")
        }

        ws.onclose = () => {
          console.log(`WebSocket connection closed for ${type}`)
        }

        ws.onmessage = (event) => {
          try {
            const json = JSON.parse(event.data)
            switch (type) {
              case "gateway":
                dispatch(updateGatewayInfo(json))
                break
              case "olt":
                dispatch(updateOltInfo(json))
                break
              case "cpe":
                dispatch(updateCpeInfo(json))
                break
              case "cpes":
                dispatch(updateCpeInfoList(json))
                break
              case "vlan":
                dispatch(updateVlanInfo(json))
                break
              default:
                console.log(`Unhandled device type: ${type}`)
            }
          } catch (error) {
            console.error("Erro:", error)
          }
        }

        websockets[type] = ws
      })
    }
    return () => {
      rooms.forEach((type) => {
        if (websockets[type]) {
          websockets[type].close()
        }
      })
    }
  }, [userContext.activeGroup, dispatch])

  return (
    <WsDevicesContext.Provider value={{}}>{children}</WsDevicesContext.Provider>
  )
}
