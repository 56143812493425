import React from 'react'
import TabsContent from './tabsContent'
import TabContent from './tabContent'
import { useSelector } from 'react-redux';
import './multTabs.css'

const MultTabBody = () => {
  const listTabs = useSelector((state) => state.tabSlice.listTabs);

  return (
    <React.Fragment>
      <TabsContent >
        {listTabs.map((item, index) => (
          <TabContent
            key={index}
            data={item}
          >
          </TabContent>
        ))}
      </TabsContent>
    </React.Fragment>
  )

}

export default MultTabBody