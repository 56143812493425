import React from 'react'
import MultTabHead from'./multTabHead'
import MultTabBody from'./multTabBody'
import './multTabs.css'

const MultTabs = () => {
    return (
      <React.Fragment>
        <MultTabHead/>
        <MultTabBody/>
      </React.Fragment>
    )
  
}

export default MultTabs