import "./DeviceInfoProgressBar.css"
export function DeviceInfoProgessBar({ title, percentage, percentageLabel }) {
  return (
    <div>
      <span className="progress-title">{title}</span>
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <div className="device-info-progress-bar">
          <div
            className="device-info-progress"
            style={{ width: `${percentage}%` }}
          ></div>
        </div>
        <span className="percentage-label" style={{ paddingLeft: "5px" }}>
          {percentageLabel}
        </span>
      </div>
    </div>
  )
}
