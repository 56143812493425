import React from 'react'
import Checkbox from '@lib/midgard/checkbox/checkbox'
export default function inMeshTrigger(inmesh,setInMesh) {

    // const dnsSameIP = (value, {wanIp}) => {

    //     if(value === '' || wanIp === ''){
    //         return ''
    //     }

    //     if(value === wanIp)
    //         return 'O dns deve ser diferente do endereço IP do roteador'

    //     return ''
    // }

    return (
        !inmesh.enabled ? <React.Fragment></React.Fragment> :
        <React.Fragment>
    <Checkbox id={'ControllerWPSTrigger'}
            label='Ativar WPS Trigger'
            value={inmesh.wps_trigger}
            
            toggleFn={(e) => {
                console.log(inmesh.wps_trigger)
                inmesh.wps_trigger= !inmesh.wps_trigger
                setInMesh({...inmesh})               
            }}

    ></Checkbox>
 

        </React.Fragment>
    )
}