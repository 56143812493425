import { CardTable } from "@conecte-se/ui-components-reactjs"
import "./help.css"

export const Help = () => {
  return (
    <div className="body container-help">
      <CardTable
        title={
          <div style={{ display: "flex", justifyContent: "center" }}>
            <h3>Precisando de ajuda?</h3>
          </div>
        }
        width="auto"
        height="100%"
      >
        <div className="body-card">
          <h3>Segue as informações de nosso contato para o suporte.</h3>
          <div className="element">
            <div className="row">
              <div className="col-md-6">
                <h4>Suporte via whatsapp</h4>
                <p>(48) 2106 0006</p>

                <h4>Suporte via email</h4>
                <p>suporte@intelbras.com.br</p>

                <h4>Suporte a provedores</h4>
                <p>(48) 2106 0030</p>
              </div>
              <div className="col-md-6">
                <h4>Suporte via chat</h4>
                <span
                  style={{ cursor: "pointer", textDecoration: "underline" }}
                  onClick={() =>
                    window.open("http://chat.intelbras.com.br", "_blank")
                  }
                >
                  Chat Intelbras
                </span>

                <h4>Fórum</h4>
                <span
                  style={{ cursor: "pointer", textDecoration: "underline" }}
                  onClick={() =>
                    window.open("http://forum.intelbras.com.br", "_blank")
                  }
                >
                  Fórum Intelbras
                </span>

                <h4>SAC</h4>
                <p>0800 7042767</p>
              </div>
            </div>
          </div>
        </div>
        <div className="footer">
          <h4>Produzido por</h4>
          <p>
            Intelbras S/A - Indústria de Telecomunicação Eletrônica Brasileira -{" "}
            <span
              style={{ cursor: "pointer", textDecoration: "underline" }}
              onClick={() =>
                window.open("http://www.intelbras.com.br", "_blank")
              }
            >
              Intelbras
            </span>
          </p>
          <p>
            Rodovia SC 281, km 4,5 - Sertão do Maruim - São José/SC - 88122-001{" "}
          </p>
          <p>CNPJ 82.901.000/0014-41</p>
        </div>
      </CardTable>
    </div>
  )
}
