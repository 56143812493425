import React from "react"
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import {
  selectTab,
  removeTab,
} from "@features/devices/redux/deviceTab/device-slice"
import DeviceFactory from "@features/devices/management/device-factory"
import { MdClose } from "react-icons/md"
import "./multTabs.css"

const TabHeader = ({ data }) => {
  const dispatch = useDispatch()
  const selectedTab = useSelector((state) => state.tabSlice.selected)
  const navigate = useNavigate()

  const handleRemoveTabs = () => {
    dispatch(removeTab({ id: data.id }))
    dispatch(
      selectTab({
        id: "devices",
        model: "devices",
        origin: "Manage Devices",
      })
    )
    navigate(`/manage/devices`)
  }

  const handleSelectTab = () => {
    console.log("*** handleSelectTab", data)
    dispatch(
      selectTab({
        id: data.id,
        model: data.model,
        status: data.status ? data.status : 0,
      })
    )
    navigate(`/manage/${data.id}`)
  }

  const title = data.id === "Devices" || data.id === "devices" ? "Todos" : `${data.model} - ${data.id}`
  const active = selectedTab.id === data.id;
  return (
    <div
      className={`tab-buttons ${selectedTab.id === data.id ? "active" : ""}`}
    >
      {(!active && data.id !== "devices") && (<div className="divider-tabs" ></div>)}
      <div
        className="tab-head-content"
        onClick={() => {
          handleSelectTab()
        }}
      >
        {/* onClick={() => {
                    dispatch(selectTab({ id: data.id, model: data.model, status: data.status }));
                    navigate(`/manage/${data.id}`)
                }}> */}
        <img
          alt={data.id}
          onClick={null}
          src={DeviceFactory.getIcoType(data.model)}
          className="tab-icon"
          style={{ width: "30px", height: "20px", margin: "0 2px" }}
        />
        <span
          data-toggle="tab"
          data-target={data.id}
          className={`button-tab ${selectedTab.id === data.id ? "active" : "inactive"}`}
        >
          {title}
        </span>
      </div>
      {data.id !== "devices" && data.id !== "Devices" && (
        <div
          className={`button-tab close ${selectedTab.id === data.id ? "active" : "inactive"}`}
          onClick={() => {
            handleRemoveTabs()
          }}
        >
          {" "}
          <MdClose title="Fechar" style={{ marginLeft: "2px" }} />
        </div>
      )}
    </div>
  )
}

export default TabHeader

//d8365f645dee
