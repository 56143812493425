import React, { useEffect, useState } from "react"
import "./progress-signal.css"

export const ProgressSignal = ({ min, max, signal, type }) => {
  const [value /*, setValue*/] = useState(
    signal?.replace(/\s*\(.*?\)\s*/g, "").trim()
  )
  const [progressWidth, setProgressWidth] = useState(0)

  const progressPercent = Math.max(((value - min) / (max - min)) * 100, 5)

  function signalRx() {
    let progressBarColor
    if (value <= -27 && value >= min) {
      progressBarColor = "#ff0000" //red
    } else if (value < -30) {
      progressBarColor = "#ff0000" //red
    } else if (value > -18) {
      progressBarColor = "#04ab42" //green
    } else if (value <= 21 && value >= -27) {
      progressBarColor = "#ffcc00" //yellow
    }
    return progressBarColor
  }

  function signalTx() {
    let progressBarColor
    if (value <= min) {
      progressBarColor = "#ff0000"
    } else if (value >= min && value <= -1) {
      progressBarColor = "#ff0000" //red
    } else if (value >= -1 && value <= 0.5) {
      progressBarColor = "#ffcc00" //yellow
    } else if (value > 0.5) {
      progressBarColor = "#04ab42" //green
    }
    return progressBarColor
  }

  const getProgressBarColor = () => {
    if (type === "Rx") {
      return signalRx()
    }
    return signalTx()
  }

  const progressBarColor = getProgressBarColor()

  useEffect(() => {
    setProgressWidth(`${progressPercent}%`)
  }, [progressPercent])

  return (
    <div className="progress-signal-container">
      {type}
      <div className="progress-signal-bar-background">
        <div
          className="progress-signal-bar"
          style={{ width: progressWidth, backgroundColor: progressBarColor }}
        ></div>
      </div>
      <span>{value} dBm</span>
    </div>
  )
}
