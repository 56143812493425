import React from 'react'
//import { backendStatus } from '../../../../../../backend/backend'
import Checkbox from '@lib/midgard/checkbox/checkbox'
import InfoPopup from '@lib/midgard/info-popup/info-popup'
import inMeshTrigger from './inmeshtrigger'


export default function InMeshAccount({
    inmesh,
    setInMesh,
}){

    return (


    <div className='imesh-sp'>
    <div className='subtitle'>Configurações Da Rede InMesh </div>

        <React.Fragment>
        <div className='controller'>
    <Checkbox id={'ControllerEnabled'}
            label='Ativar Controller'
            value={inmesh.enabled}
          
            toggleFn={(e) => {
                console.log(inmesh.enabled)
                inmesh.enabled= !inmesh.enabled
                setInMesh({...inmesh})               
            }}
        
    ></Checkbox>
    <div className='infoPopUp'>
    <InfoPopup message='Certifique-se de usar as redes de 2.4GHz e 5GHz com o mesmo SSID, segurança WPA2 não nula, e um canal estático para a frequência de 5GHz' ></InfoPopup>
    </div>
    </div>
    {inMeshTrigger(inmesh,setInMesh)}

</React.Fragment>



    </div>
    )
}   