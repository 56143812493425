import PercentageCircle from 'src/components/PercentageCircle/PercentageCircle';
import './device-header.css'
export default function DeviceHeaderInfoPon({ online, offline, total }) {
    const percentage = online ? Math.floor((online / total) * 100) : 0
    return (

        <div className='device-info-pon'>
            <span className='device-info-system-text'>PON Status</span>
            <div style={{ display: 'flex' }}>
                <span className='device-info-pon-text cpes' >CPEs</span>
                <PercentageCircle color={'#008083'} percentage={percentage} />
            </div>
            <div className='device-info-pon-data'>
                <div className='device-info-pon-dash'>
                    <span className='device-info-pon-text'>Online</span>
                    <span className='device-info-pon-number' style={{ color: '#00B26B' }}>{online}</span>
                </div>
                <div className='device-info-pon-dash'>
                    <span className='device-info-pon-text'>Offline</span>
                    <span className='device-info-pon-number' style={{ color: '#fe2523' }}>{offline}</span>
                </div>
                <div className='device-info-pon-dash'>
                    <span className='device-info-pon-text'>Total</span>
                    <span className='device-info-pon-number' style={{ color: '#379a9b' }}>{total}</span>
                </div>
            </div>
        </div>
    );
}