import React, { useEffect } from "react"
import {
  MdOutlineRouter,
  MdAccessTime,
  MdMemory,
  MdDomain,
} from "react-icons/md"
import "./olt-device-information.css"
import { useSelector, useDispatch } from "react-redux"
import DeviceHeaderImage from "src/features/devices/management/device-header/device-header-image"
import DeviceHeaderInfo from "src/features/devices/management/device-header/device-header-info"
import DeviceHeaderInfoPon from "src/features/devices/management/device-header/device-header-info-pon"
import { addTab } from "@features/devices/redux/deviceTab/device-slice"
import { useNavigate } from "react-router-dom"
import { DeviceInfoProgessBar } from "src/components/DeviceInfoProgessBar/DeviceInfoProgressBar"
function OltDeviceInformation() {
  const device = useSelector((state) => state.tabSlice.selected)
  const usedMemory = device.total_memory - device.free_memory
  const usedMemoryPercentage = (
    (usedMemory / device.total_memory) *
    100
  ).toFixed(0)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const getProduct = async (device) => {
    var newDevice = { ...device }
    dispatch(addTab(newDevice))
    navigate(`/manage/${newDevice.id}`)
  }

  useEffect(() => {}, [device])
  function splitVersion() {
    var result = device?.software_version?.match(/SP(.*)/)
    if (result && result.length > 1) {
      return "SP" + result[1]
    }
    return device?.software_version
  }

  function formatUpTime(inputText) {
    const parts =
      inputText === undefined ? ["14", "14", "30"] : inputText.split(/[ :]/)

    const day = parts[0]
    const hour = parts[1]
    const minute = parts[2]

    const formattedText = `${day}d ${hour}h ${minute}m`

    return formattedText
  }

  return (
    <DeviceHeaderInfo>
      <DeviceHeaderImage model={device?.model} />
      <DeviceHeaderInfo.Divider />
      <div className="device-info-system">
        <span className="device-info-system-text">Sistema</span>
        <div className="device-info-system-icons">
          <span className="icon-span" title="Versão de Firmware">
            <MdMemory size={24} />
            <span>{splitVersion(device.software_version)}</span>
          </span>
          <span className="icon-span" title="Endereço IP">
            <MdOutlineRouter size={24} />
            <span>{device.ip}</span>
          </span>
          <span className="icon-span" title="Tempo de atividade">
            <MdAccessTime size={24} />
            <span>{formatUpTime(device?.up_time)}</span>
          </span>
          <span className="icon-span" title="ID do gateway">
            <MdDomain size={24} />
            <span
              className="clickable-span"
              onClick={() => getProduct(device.gw_id)}
            >
              {device.gw_id}
            </span>
          </span>
        </div>
        <div className="device-info-system-plus">
          <div className="device-info-cpu">
            <DeviceInfoProgessBar
              title="CPU"
              percentageLabel={`${device.cpu_utilization}%`}
              percentage={device.cpu_utilization}
            ></DeviceInfoProgessBar>
            <DeviceInfoProgessBar
              title="Memória"
              percentageLabel={`${usedMemory}/${device.total_memory}`}
              percentage={usedMemoryPercentage}
            ></DeviceInfoProgessBar>
          </div>
        </div>
        <div className="font-sources">
          {device?.power?.map((status, index) => (
            <div key={index} className="source">
              <div
                className="device-info-fonte-dot"
                style={{
                  backgroundColor: status === "INSERT" ? "#05b247" : "#fe0201",
                }}
              ></div>
              <span>Fonte {index + 1}</span>
            </div>
          ))}
        </div>
      </div>
      <DeviceHeaderInfo.Divider />
      <DeviceHeaderInfoPon online={90} offline={10} total={100} />
    </DeviceHeaderInfo>
  )
}

export default OltDeviceInformation
