import {
    Card,
    ToastContainer,
    Tooltip,
    TooltipCard,
} from "@conecte-se/ui-components-reactjs"
import { useContext, useState } from "react"
import { useSelector } from "react-redux"
import { ModalConfirm } from "@features/devices/components/modal"
import { ToastContext } from "src/context/ToastContext"
import Button from "src/lib/midgard/button/button"
import "./information.css"
import { RESOURCE_RESTART, RESOURCE_SAVE, RESOURCE_UPDATE } from "@features/devices/common/constants"
import { BackendContext } from "@services/backend/backend"
import { resourceDevice } from "@features/devices/redux/deviceTab/device-actions"

const Information = () => {
    const backend = useContext(BackendContext)
    const toastContext = useContext(ToastContext)
    const olt = useSelector((state) => state.tabSlice.selected);
    const [caseModal, setCaseModal] = useState()
    const oltId = window.location.pathname.split("/")[2]
    const [lastVersion, setLastVersion] = useState("")


    function closeModal() {
        setCaseModal(null)
    }
    function openModal(option) {
        setCaseModal(option)
    }
    const handleUpdateOlt = () => {
        resourceDevice(backend, toastContext, RESOURCE_UPDATE, oltId)
        closeModal()
    }
    const handleSaveOlt = () => {
        resourceDevice(backend, toastContext, RESOURCE_SAVE, oltId)
        closeModal()
    }
    const handleRestartOlt = () => {
        resourceDevice(backend, toastContext, RESOURCE_RESTART, oltId)
        closeModal()
    }
    const modal = (caseModal) => {
        switch (caseModal) {
            case "update":
                return [
                    <ModalConfirm
                        close={closeModal}
                        action={handleUpdateOlt}
                        title={"Atualizar OLT"}
                        message={`Tem certeza que deseja atualizar a OLT ${olt.name}?`}
                    />,
                ]
            case "save":
                return [
                    <ModalConfirm
                        close={closeModal}
                        action={handleSaveOlt}
                        title={"Salvar configurações"}
                        message={`Tem certeza que deseja salvar as configurações da OLT ${olt.name}?`}
                    />,
                ]
            case "restart":
                return [
                    <ModalConfirm
                        close={closeModal}
                        action={handleRestartOlt}
                        title={"Reiniciar OLT"}
                        message={`Tem certeza que deseja reiniciar a OLT ${olt.name}?`}
                    />,
                ]
            default:
                return
        }
    }

    function updateButton() {
        return (
            <Tooltip>
                <Button
                    style={{ width: "500px" }}
                    text="Atualizar"
                    disabled={lastVersion === olt?.software_version}
                    onClick={() => {
                        openModal("update")
                    }}
                />

                <TooltipCard right bottomValue="0px" width={"200px"}>
                    {lastVersion === olt?.software_version
                        ? "A versão de software da OLT está atualizada com a última versão."
                        : "Atualizar versão de software da OLT para a mais recente."}
                </TooltipCard>
            </Tooltip>
        )
    }

    return (
        <div className="container">
            <Card
                width="100%"
                height="100%"
            >
                <div className="content">
                    <div className="info">
                        <div className="buttons">
                            <div className="buttons-olt-config">
                                <Button
                                    text="Reiniciar"
                                    onClick={() => {
                                        openModal("restart")
                                    }}
                                />
                                {updateButton()}
                                <Button
                                    text="Salvar configurações"
                                    onClick={() => {
                                        openModal("save")
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    {/* <div className="interfaces">
            <Table columns={columns} rows={rows} />
          </div> */}
                </div>
            </Card>
            <ToastContainer toasts={toastContext.toasts} position={"bottom_right"} />
            {modal(caseModal)}
        </div>
    )
}

export default Information
