import React, { useState, useContext, useEffect, useRef } from 'react'
import { backendStatus } from '@services/backend/backend'
import Loading from '@lib/midgard/loading/loading'
import NetworksIcon from '@lib/midgard/icons/networks-icon'
import { DeviceContext } from '@features/devices/management/manage-page'
import Form from '@lib/midgard/form/form'
import Input from '@lib/midgard/input/input'
import common from '@lib/midgard/form/validators/common'
import Select from '@lib/midgard/select/select'
import { RadioBandwidth, channelExtension, RadioFrequency, WirelessCipher, WirelessCrypto } from './wifi-constants'
import DefaultStatusModals from '@lib/midgard/modal/default-status-modals'
import InfoLine from '@lib/midgard/info-line/info-line'
import DeviceFactory from '@features/devices/management/device-factory'
import Button from '@lib/midgard/button/button'
import { useNavigate} from 'react-router-dom'
import { isMinimalVersion } from '../bifrostHoManagePage'
import Parameterize from '@lib/midgard/utils/parameterize'

const RETRY_TIME = 5000
const MIN_SSID_LENGTH = 1
const MAX_SSID_LENGTH = 32
const MIN_KEY_LENGTH = 8
const MAX_KEY_LENGTH = 63

const ModalStatus = {
    SAVING: 0,
    SUCCESS: 1,
    ERROR: 2,
    NOTHING: 3
}

export default function MonitoringWifiInfo() {

    const [wifi, setWiFi] = useState(null)
    const [radios, setRadio] = useState(null)
    const [status, setStatus] = useState(ModalStatus.NOTHING)

    const manageCtx = useContext(DeviceContext)
    const navegate = useNavigate()

    const wirelessTimer = useRef(null)
    const radioTimer = useRef(null)
    const mounted = useRef(true)
    const versionCripto = [1, 30, 0]// minimum version encrypt and decrypt passwords

    const fetchWireless = async() => {

        let result = await manageCtx.device.retrieveResource('wireless')

        if(!result || result.status !== backendStatus.SUCCESS){
            console.log('Error fetching wireless:', result.content)
            if(mounted.current)
                wirelessTimer.current = setTimeout(fetchWireless, RETRY_TIME)
            return
        }
        if (isMinimalVersion(manageCtx.device.data, versionCripto)) {
            for (let i = 0; i < result.content.length; i++) {
                
                result.content[i].key = Parameterize(result.content[i].key, true)
            }
        }

        setWiFi(result.content)
    }

    const fetchRadio = async() => {

        let result = await manageCtx.device.retrieveResource('radio')

        if(!result || result.status !== backendStatus.SUCCESS){
            console.log('Error fetching radio:', result.content)
            if(mounted.current)
                radioTimer.current = setTimeout(fetchRadio, RETRY_TIME)
            return
        }

        setRadio(result.content)
    }

    useEffect(() => {

        fetchWireless()
        fetchRadio()

        return () => {
            mounted.current = false
            clearTimeout(wirelessTimer.current)
            clearTimeout(radioTimer.current)
        }

        // eslint-disable-next-line
    },[])

    const frequencies = [
        '2.4',
        '5'
    ]

    const securityOptions = [
        {value: WirelessCipher.NONE, text: 'Aberta'},
        {value: WirelessCipher.WPA_WPA2, text: 'WPA/WPA2'},
        {value: WirelessCipher.WPA2_WPA3, text: 'WPA2/WPA3'},
    ]

    const getRadio = (radioID) => {
        for(let r of radios) {
            if(r.id === radioID)
                return r
        }

        console.error("Radio not found, check your API responses")

        return null
    }

    const getRadioIndex = (radioID) => {

        for(let i in radios) {
            if(radios[i].id === radioID)
                return i
        }

        console.error("Radio not found, check your API responses")

        return null
    }

    const getFrequencyString = (wifi) => {

        let r = getRadio(wifi.radioID)

        return frequencies[r.frequency]
    }

    const getChannelOptions = (net) => {

        let radio = getRadio(net.radioID)

        let channels = []

        if(radio.frequency === RadioFrequency._2_4GHz){
            channels = [...Array(14).keys()]
        }else{
            if(radio.bandwidth === RadioBandwidth._20MHz)
                channels = [0, 36, 40, 44, 48, 149, 153, 157, 161, 165]
            if(radio.bandwidth === RadioBandwidth._40MHz)
                channels = [0, 36, 40, 44, 48, 149, 153, 157, 161]
            if(radio.bandwidth === RadioBandwidth._80MHz)
                channels = [0, 36, 40, 44, 48, 149, 153, 157, 161]
        }

        let opt = channels.map((c) => {return {value: c, text: c === 0 ? 'Automático' : String(c)}})

        return opt

    }

    const getChannelExtension = (wifiIndex) => {

        let net = wifi[wifiIndex]
        let radio = getRadio(net.radioID)

        if(radio.configuredChannel === 0 || radio.frequency === RadioFrequency._5GHz || radio.bandwidth === RadioBandwidth._20MHz)
            return null
   
        let available = channelExtension(radio.configuredChannel)
        //Conditional to fix taskheimdal#967 - when don't have a onchange event at extension select
        if (available.length === 1) radio.extension = available[0].value
        
        return<React.Fragment>
            {available.length === 1 ?
                <Input
                    label='Canal de extensão'
                    id='wifi-extension'
                    value={available[0].text}
                    disabled={true}
                ></Input> :
                <Select id='wifi-extension'
                    label='Canal de extensão'
                    value={radio.extension}
                    onChange={(e) => {
                        let i = getRadioIndex(radio.id)
                        radios[i].extension = Number(e.target.value)
                        setRadio([...radios])
                    }}
                    options={available}
                ></Select>
            }
        </React.Fragment>
    }

    const getChannel = (wifi) => {
        let i = getRadioIndex(wifi.radioID)
        return radios[i].channel
    }

    const getConfiguredChannel = (wifi) => {
        let i = getRadioIndex(wifi.radioID)
        return radios[i].configuredChannel
    }

    const getBandwidth = (wifi) => {
        let i = getRadioIndex(wifi.radioID)
        return radios[i].bandwidth
    }

    const getBandwidthOptions = (wifi) => {
        let i = getRadioIndex(wifi.radioID)
        let freq = radios[i].frequency
        let options = [
            {value: RadioBandwidth._20MHz, text: '20MHz'},
            {value: RadioBandwidth._40MHz, text: '20/40MHz'},
        ]

        if(freq === RadioFrequency._5GHz){
            options.push({value: RadioBandwidth._80MHz, text: '20/40/80MHz'})
        }

        return options
    }

    const getPasswordValidators = () => {

        let validators = [common.nonASCII]

        let minLength = 0

        if (!DeviceFactory.isTR069(manageCtx.device.data.model)) {
            validators.push(common.required)
            minLength = MIN_KEY_LENGTH
        }

        validators.push({fn: common.size, params:{min: minLength, max: MAX_KEY_LENGTH}})

        return validators
    }

    const wifiForm = (wifiIndex) => {

        return(
        <div key={`wifi-${wifiIndex}`} className='form-section'>


            <div className='dashboard-subtitle'>
                <NetworksIcon size='20'></NetworksIcon>
                <label>Rede {getFrequencyString(wifi[wifiIndex])} GHz</label>
            </div>

            <Input id={`wifi-ssid-${wifiIndex}`}
                name={`wifi-ssid-${wifiIndex}`}
                label='Nome da rede (SSID)'
                value={wifi[wifiIndex].ssid}
                onChange={(e) => {
                    wifi[wifiIndex].ssid = e.target.value
                    setWiFi([...wifi])
                }}
                validators={[
                    common.required,
                    common.nonASCII,
                    {fn: common.size, params:{min: MIN_SSID_LENGTH, max: MAX_SSID_LENGTH}},
                ]}
            >
            </Input>

            <Select id={`wifi-security-${wifiIndex}`}
                name={`wifi-security-${wifiIndex}`}
                label='Segurança'
                value={wifi[wifiIndex].cipher === WirelessCipher.WPA2 ? WirelessCipher.WPA_WPA2 : wifi[wifiIndex].cipher}
                options={securityOptions}
                onChange={(e) => {
                    wifi[wifiIndex].cipher = Number(e.target.value)
                    wifi[wifiIndex].crypto = wifi[wifiIndex].cipher === WirelessCipher.NONE ? WirelessCrypto.NONE : WirelessCrypto.AES
                    setWiFi([...wifi])
                }}
            ></Select>

            <Input id={`wifi-password-${wifiIndex}`}
                name={`wifi-password-${wifiIndex}`}
                label='Senha da rede'
                value={wifi[wifiIndex].key}
                onChange={(e) => {
                    wifi[wifiIndex].key = e.target.value
                    setWiFi([...wifi])
                }}
                collapse={wifi[wifiIndex].cipher === WirelessCipher.NONE}
                validators={getPasswordValidators()}
            >
            </Input>

            <Select id={`wifi-channel-${wifiIndex}`}
                name={`wifi-channel-${wifiIndex}`}
                label='Canal'
                value={getConfiguredChannel(wifi[wifiIndex])}
                options={getChannelOptions(wifi[wifiIndex])}
                onChange={(e) => {
                    let i = getRadioIndex(wifi[wifiIndex].radioID)
                    radios[i].configuredChannel = Number(e.target.value)
                    setRadio([...radios])
                }}
            ></Select>

            <InfoLine
                label='Canal atual'
                value={getChannel(wifi[wifiIndex])}
            ></InfoLine>

            {!DeviceFactory.isTR069(manageCtx.device.data.model) && <Select id={`wifi-bandwidth-${wifiIndex}`}
                name={`wifi-bandwidth-${wifiIndex}`}
                label='Largura de banda'
                value={getBandwidth(wifi[wifiIndex])}
                options={getBandwidthOptions(wifi[wifiIndex])}
                onChange={(e) => {
                    let i = getRadioIndex(wifi[wifiIndex].radioID)
                    radios[i].bandwidth = Number(e.target.value)
                    setRadio([...radios])
                }}
            ></Select>}


            {getChannelExtension(wifiIndex)}

        </div>
        )


    }

    const saveWifi = async() => {
        let resultVersion = isMinimalVersion(manageCtx.device.data, versionCripto)
        setStatus(ModalStatus.SAVING)

        let radioIDs = []

        for(let net of wifi){

            if(!net.isRoot)
                continue

            radioIDs.push(net.radioID)
            if (resultVersion) {
                net.key = Parameterize(net.key)
            }

            let result = await manageCtx.device.updateResource('wireless', net)
            if (resultVersion) {
                net.key = Parameterize(net.key, true)
            }

            if (!result || result.status !== backendStatus.SUCCESS){
                console.error(result.content)
                setStatus(ModalStatus.ERROR)
                return
            }
        }

        for(let radio of radios){

            if(!radioIDs.includes(radio.id))
                continue

            let result = await manageCtx.device.updateResource('radio', radio)
            if(!result || result.status !== backendStatus.SUCCESS){
                console.error(result.content)
                setStatus(ModalStatus.ERROR)
                return
            }
        }

        let result = await manageCtx.device.apply()
        if(!result || result.status !== backendStatus.SUCCESS){
            console.error(result.content)
            setStatus(ModalStatus.ERROR)
            return
        }

        setStatus(ModalStatus.SUCCESS)
    }

    return <div id='wifi-management-page'>

        <a href="#" class="survey-link" onClick={() => navegate(`/manage/${manageCtx.device.data.deviceid}/wifi/site-survey`)}>Site survey</a>

        <DefaultStatusModals
            saving={status === ModalStatus.SAVING}
            success={status === ModalStatus.SUCCESS}
            error={status === ModalStatus.ERROR}
            continueFn={() => setStatus(ModalStatus.NOTHING)}
        ></DefaultStatusModals>

        {!wifi || !radios ? <Loading show={true}></Loading> :
        <Form id='wifi-management-info'
            submitText='Aplicar'
            onSubmit={saveWifi}
        >


            {wifi.map((net, i) => {
                if(!net.isRoot) return null
                return wifiForm(i)
            })}

        </Form>}

    </div>
}