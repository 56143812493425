import { Label } from "@conecte-se/ui-components-reactjs"
import { validateIdentifier } from "src/common/validators"
import { DropdownScroll } from "@features/devices/components/dropdown-scroll"
import { InputText } from "@features/devices/components/inputs"
import { setOltById } from "@features/devices/redux/olt/olt-slice"
import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { SeparatedButtons } from "../../components/separated-buttons/separated-buttons"
export const AddByOlt = ({ cpeState, errorState }) => {
    const olts = useSelector((state) => state.oltsState.olts)
    const olt = useSelector((state) => state.tabSlice.selected);
    const dispatch = useDispatch()
    const { cpe, setCpe } = cpeState
    const { errors, setErrors } = errorState
    const [isDropdownSelected, setIsDropdownSelected] = useState({
        olt: false,
        port: false,
    })

    const handleError = (name, error) => {
        if (error) {
            setErrors({ ...errors, [name]: error })
        } else {
            const { [name]: removed, ...rest } = errors
            setErrors(rest)
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target

        if (/^.{4}(?!-)/.test(value)) {
            if (value.length > 4 && value.charAt(4) !== "-") {
                const newValue = value.slice(0, 4) + "-" + value.slice(4)
                setCpe({
                    ...cpe,
                    [name]: newValue,
                })
            } else {
                setCpe({
                    ...cpe,
                    [name]: value,
                })
            }
        } else {
            setCpe({
                ...cpe,
                [name]: value,
            })
        }
    }

    const clearInputField = (property) => {
        setCpe({ ...cpe, [property]: "" })
    }
    const onChangeConfiguration = (key, value) => {
        setCpe((prevCpe) => ({
            ...prevCpe,
            configuration: {
                ...prevCpe.configuration,
                [key]: value,
            },
        }))
    }

    const handleSelect = (item, dropdownId) => {
        if (dropdownId === "olt") {
            dispatch(setOltById(item.id))
        }
        setCpe({
            ...cpe,
            [dropdownId]: item.id,
        })

        setIsDropdownSelected((prevSelected) => ({
            ...prevSelected,
            [dropdownId]: true,
        }))
    }

    const generatePortList = (model) => {
        if (model?.startsWith("olt_g")) {
            const size = parseInt(model?.substring(5), 10)
            return Array.from({ length: size }, (_, index) => ({
                id: `G${index + 1}`,
                label: `G${index + 1}`,
                value: `G${index + 1}`,
            }))
        }
        return []
    }
    const getSlotsByPort = (olt, port) => {
        const index = parseInt(port.substring(1), 10)
        const objPort = olt && olt.ports_G && olt?.ports_G[index - 1]
        if (objPort && objPort?.slots) {
            return objPort?.slots.map((slot) => ({
                id: slot,
                value: slot,
                label: slot?.toString(),
            }))
        }

        return []
    }
    function getModelOptions(olt) {
        if (!olt || !olt.cpes_model || !Array.isArray(olt.cpes_model)) {
            return []
        }

        const modelOptions = olt.cpes_model.map((item) => ({
            id: item.model,
            value: item.model,
            label: item.model,
        }))

        return modelOptions
    }

    return (
        <>
            <div className="mb-3">
                <Label>Selecione a OLT</Label>
                <DropdownScroll
                    id={"cpe-olt"}
                    items={olts
                        ?.filter((olt) => olt.status === 1)
                        .map((olt) => ({
                            id: olt.id,
                            label: olt.name,
                            value: olt.name,
                        }))}
                    onSelect={(item) => handleSelect(item, "olt")}
                />
                <h5>
                    Certifique-se de que seu Gateway/OLT está conectado e online. Se não
                    estiver disponível, considere adicionar um novo dispositivo do tipo
                    OLT para continuar..
                </h5>
            </div>
            {isDropdownSelected.olt && (
                <>
                    <div className="mb-3">
                        <Label>Identificador</Label>
                        <InputText
                            id="id"
                            name="id"
                            value={cpe.id}
                            label="Identificador"
                            length={{ max: 13 }}
                            onChange={handleChange}
                            validate={{
                                action: (e) => validateIdentifier(e),
                                handleError: handleError,
                            }}
                            clearField={{
                                action: clearInputField,
                                text: "Limpar",
                            }}
                        />
                    </div>
                    <div className="mb-3">
                        <Label>Porta</Label>
                        <DropdownScroll
                            id={"ports-cpe"}
                            items={generatePortList(olt?.model)}
                            onSelect={(item) => handleSelect(item, "port")}
                            disabled={!isDropdownSelected.olt}
                        />
                    </div>
                    <div className="mb-3">
                        <Label>Slot Pon</Label>
                        <DropdownScroll
                            id={"slot-cpe"}
                            items={getSlotsByPort(olt, cpe.port)}
                            onSelect={(item) => handleSelect(item, "slot")}
                            disabled={!isDropdownSelected.port}
                        />
                    </div>

                    <div className="mb-3">
                        <Label>Modelo</Label>
                        <DropdownScroll
                            id={"model-cpe"}
                            items={getModelOptions(olt)}
                            onSelect={(item) => handleSelect(item, "model")}
                            disabled={!isDropdownSelected.slot}
                        />
                    </div>
                    <div className="mb-3">
                        <Label>
                            <h3>Configurações</h3>
                        </Label>
                        <Label>
                            <h4>Adicione a quantidade que desejar de interfaces ETH/VEIP.</h4>
                        </Label>
                        <SeparatedButtons onChangeConfiguration={onChangeConfiguration} />
                    </div>
                </>
            )}
        </>
    )
}
