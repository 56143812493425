import React from 'react';
import './circle-status.css'
import { connectionStatus, deviceStates } from '@features/devices/common/constants';

export const CircleStatus = ({ statusCode, stateCode, showText = true }) => {

  function getText() {
    if (stateCode == 1) {
      return connectionStatus[statusCode].text.toLowerCase();
    }
    return deviceStates[stateCode].text.toLowerCase();
  }

  function getColor() {
    if (stateCode == 1) {
      return connectionStatus[statusCode].color;
    }
    return deviceStates[stateCode].color;
  }

  return (
    <div className="circle-status-container">
      <div className="circle-status" style={{ backgroundColor: getColor() }}></div>
      {showText && <span>{getText()}</span>}
    </div>
  );
};