import React, { useState, useContext } from 'react'
import { useNavigate, useLocation, useParams } from "react-router-dom"
import Loading from '@lib/midgard/loading/loading'
import Tabs from '@lib/midgard/tabs/tabs'
import  { DeviceContext } from'@features/devices/management/manage-page'
import '@features/devices/management/manage.css'

export const DEVICE_STATUS_OFFLINE = 2

export default function PhoneManagePage() {
  const manageCtx = useContext(DeviceContext)
  const [deviceInfo] = useState(manageCtx.deviceInfo)
  const params = useParams()
  const deviceid = params.id

  let pathLocation = useLocation()
  const navigate = useNavigate()

  if (!deviceInfo /*|| !canBeManaged(device)*/)
    return <Loading show={true}></Loading>
 
  return (
    <>
      {deviceInfo.status === DEVICE_STATUS_OFFLINE ?

        <div className='offline-info'>
          <Loading show={true}></Loading>
          <span>O dispositivo está offline. Aguardando o retorno testee</span>
        </div>
        : <React.Fragment>
          <Tabs
            data={
              [
                { label: 'WAN', route: `/manage/${deviceid}/wan` },
                { label: 'Wi-Fi', route: `/manage/${deviceid}/wifi` },
                { label: 'LAN', route: `/manage/${deviceid}/lan` },
                { label: 'Manutenção', route: `/manage/${deviceid}/maintenance` }

              ]
            }
            route={pathLocation.pathname}
            activateFn={(route)=>  { navigate(route) }}
          ></Tabs>
        </React.Fragment>}
    </>
  )
}