import {
  createContext,
  useEffect,
  useState,
  useContext,
  useCallback,
} from "react"
import { BackendContext, backendStatus } from "@services/backend/backend"

export const UserContext = createContext()

export default function UserContextProvider({ children }) {
  const backend = useContext(BackendContext)

  const [user, setUser] = useState(null)
  const [avatar, setAvatar] = useState(null)
  const [group, setGroup] = useState(null)
  const [activeGroup, setActiveGroup] = useState(null)
  const [role, setRole] = useState(null)
  const [logo, setLogo] = useState(null)
  //TODO: in case of an error at fetching: return a toast error instead of only a console.error

  const fetchUser = async () => {
    let result = await backend.retrieveFresh("user")
    if (result.status !== backendStatus.SUCCESS) {
      console.error("Error to fetch user")
      return
    }
    let userRetrieved = result.content
    setUser(userRetrieved)
    return userRetrieved
  }

  const fetchRole = useCallback(
    async (s) => {
      console.log("active group:", s)
      if (s) {
        let result = await backend.retrieveFresh("role/" + s)
        if (result.status !== backendStatus.SUCCESS) {
          console.error("Error to fetch user role in this group")
          return
        }
        setRole(result.content)
        console.log("role:", result.content)
      }
    },
    // eslint-disable-next-line
    [activeGroup, backend.auth]
  )

  const fetchLogo = async () => {
    let logos = []
    for (let x of group) {
      let result = await backend.download("logo/" + x.ID)
      if (result.status !== backendStatus.SUCCESS) {
        console.error("Error to fetch logo")
        return
      }
      if (result.content.size <= 0) {
        logos.push("icon")
      } else {
        logos.push(URL.createObjectURL(result.content))
      }
      if (logos.length === group.length) {
        setLogo(logos)
      }
    }
  }

  useEffect(() => {
    if (group) {
      fetchLogo()
    }
    // eslint-disable-next-line
  }, [group])

  const fetchAvatar = async () => {
    let result = await backend.download("avatar")
    if (result.status !== backendStatus.SUCCESS) {
      console.error("Error to fetch avatar")
      return
    } else {
      if (result.content.size > 0) {
        setAvatar(URL.createObjectURL(result.content))
      }
    }
  }

  const getActiveGroup = (g) => {
    let storage = localStorage.getItem("active_group")
    if (storage) {
      console.log("storage exists")
      console.log("groups list received by getActiveGroup():", g)
      for (let i = 0; i < g?.length; i++) {
        if (storage === g[i].ID && g[i].Role !== "Bloqueado") {
          setActiveGroup(storage)
          console.log("active group:", storage)
          fetchRole(storage)
          return
        }
      }
      for (let i = 0; i < g?.length; i++) {
        if (g[i].Role !== "Bloqueado") {
          setActiveGroup(g[i].ID)
          console.log("active group:", g[i].ID)
          localStorage.setItem("active_group", g[i].ID)
          fetchRole(g[i].ID)
          return
        }
      }
    } else {
      for (let i = 0; i < g?.length; i++) {
        if (g[i].ID !== "Bloqueado") {
          setActiveGroup(g[i].ID)
          console.log("active group:", g[i].ID)
          localStorage.setItem("active_group", g[i].ID)
          fetchRole(g[i].ID)
          return
        }
      }
    }

    console.log(
      "User is not associated to any org, or has blocked role at all of them"
    )
  }

  useEffect(() => {
    if (backend.auth) {
      (async () => {
        fetchUser()
        await fetchGroup()
      })()
    }
    // eslint-disable-next-line
  }, [backend.auth])

  const fetchGroup = async () => {
    let result = await backend.retrieveFresh("group")
    if (result.status !== backendStatus.SUCCESS) {
      console.error("Error to fetch group")
      return
    }
    console.log("group:", result.content)
    setGroup(result.content)
    getActiveGroup(result.content)
    return
  }

  return (
    <UserContext.Provider
      value={{
        user: user,
        fetchAvatar: fetchAvatar,
        avatar: avatar,
        setAvatar: setAvatar,
        group: group,
        setGroup: setGroup,
        activeGroup: activeGroup,
        setActiveGroup: setActiveGroup,
        role: role,
        fetchRole: fetchRole,
        fetchGroup: fetchGroup,
        logo: logo,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}
