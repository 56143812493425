import BifrostHoManagePage from "./bifrostHO/bifrostHoManagePage"
import { RouterWifiAPI } from '@services/apis/routerWifi/routerWifi_api'
import Device from '@services/apis/device'


export default class RouterWifiDevice extends Device {

  constructor(device, backend) {
    super(device, backend)
    this.api = new RouterWifiAPI(backend)
  }
  getManagementPage() {
    return <BifrostHoManagePage></BifrostHoManagePage>
  }
  
  isDualBand() {
    return this.data.model !== 'W4-300F' && this.data.model !== 'W4-300S'
  }
}
