import { backendStatus } from "../backend"
import httpStatus from "http-status"

export default class RestDriver {
  constructor(baseUrl) {
    this.baseUrl = baseUrl
  }

  setBaseUrl(url) {
    this.baseUrl = url
  }

  getBaseUrl() {
    return this.baseUrl
  }

  async handleResult(result) {
    if (result.status === httpStatus.UNAUTHORIZED) {
      return {
        status: backendStatus.UNAUTHORIZED,
        content: {},
      }
    }

    if (result.status == httpStatus.PAYMENT_REQUIRED) {
      return {
        status: backendStatus.PAYMENT_REQUIRED,
        content: {},
      }
    }

    if (result.status == httpStatus.FORBIDDEN) {
      let response = await result.text()
      if (response !== "")
        try {
          response = await JSON.parse(response)
        } catch {
          console.warn("not json on rest response")
        }

      response = { status: result.status, body: response }

      return {
        status: backendStatus.FORBIDDEN,
        content: response,
      }
    }

    let response = await result.text()
    if (response !== "")
      try {
        response = await JSON.parse(response)
      } catch {
        console.warn("not json on rest response")
      }

    let status =
      httpStatus[`${result.status}_CLASS`] === httpStatus.classes.SUCCESSFUL
        ? backendStatus.SUCCESS
        : backendStatus.ERROR

    if (status === backendStatus.ERROR) {
      response = { status: result.status, body: response }
    }

    return {
      status: status,
      content: response,
      headers: result.headers,
    }
  }

  async handleDownloadResult(result) {
    if (result.status === httpStatus.UNAUTHORIZED) {
      return {
        status: backendStatus.UNAUTHORIZED,
        content: {},
      }
    }
    let response = await result.blob()

    let status =
      httpStatus[`${result.status}_CLASS`] === httpStatus.classes.SUCCESSFUL
        ? backendStatus.SUCCESS
        : backendStatus.ERROR

    if (status === backendStatus.ERROR) {
      response = { status: result.status, body: response }
    }

    return {
      status: status,
      content: response,
      headers: result.headers,
    }
  }

  handleAuthHeaders(headers, auth) {
    headers = !headers ? {} : headers

    if (auth) headers["Authorization"] = `Bearer ${auth}`

    return headers
  }

  async fetchWithData(resourceUrl, data, method, headers, auth) {
    headers = this.handleAuthHeaders(headers, auth)

    let result = await fetch(`${this.baseUrl}/${resourceUrl}`, {
      method: method,
      body: JSON.stringify(data),
      headers: headers,
    })

    return this.handleResult(result)
  }

  async create(resourceUrl, data, headers, auth) {
    return await this.fetchWithData(resourceUrl, data, "POST", headers, auth)
  }

  async upload(resourceUrl, data, headers, auth) {
    headers = this.handleAuthHeaders(headers, auth)

    console.log(headers, auth)

    let result = await fetch(`${this.baseUrl}/${resourceUrl}`, {
      method: "POST",
      body: data,
      headers: headers,
    })

    return this.handleResult(result)
  }

  async download(resourceUrl, data, headers, auth) {
    headers = this.handleAuthHeaders(headers, auth)

    let result = await fetch(
      `${process.env.REACT_APP_FILE_SERVER_ENDPOINT}/${resourceUrl}`,
      {
        method: "GET",
        headers: headers,
      }
    )

    return this.handleDownloadResult(result)
  }

  async retrieve(resourceUrl, auth) {
    let headers = { "Cache-Control": "no-cache" }

    if (auth) headers["Authorization"] = `Bearer ${auth}`

    let result = await fetch(`${this.baseUrl}/${resourceUrl}`, {
      method: "GET",
      headers: headers,
    })

    return this.handleResult(result)
  }

  async update(resourceUrl, data, headers, auth) {
    console.log("async update, url: " + resourceUrl + " data: ", data)
    return await this.fetchWithData(resourceUrl, data, "PUT", headers, auth)
  }

  async delete(resourceUrl, data, auth) {
    let headers = {}
    const content = () => {
      if (typeof data !== "undefined") {
        console.log("data not empty")
        return JSON.stringify(data)
      } else {
        return JSON.stringify({ empty: "empty" })
      }
    }

    if (auth) headers["Authorization"] = `Bearer ${auth}`

    let result = await fetch(`${this.baseUrl}/${resourceUrl}`, {
      method: "DELETE",
      body: content(),
      headers: headers,
    })

    return this.handleResult(result)
  }
}
