import {
  Card,
  CardTable,
  Input,
  InputIconInside,
  ToastContainer,
  Tooltip,
  TooltipCard,
} from "@conecte-se/ui-components-reactjs"
import {
  JoinGroup,
  LeaveGroup,
  RemoveGroup,
  GroupFormModal,
} from "@features/my-account/components/group"
import {
  ModalDeleteAvatar,
  ModalEditUser,
  ModalLogout,
  ModalRemoveUser,
} from "@features/my-account/components/user"
import React, { useContext, useEffect, useState } from "react"
import {
  MdAdd,
  MdCameraAlt,
  MdCopyAll,
  MdDelete,
  MdEdit,
  MdGroupAdd,
  MdImageNotSupported,
  MdLogout,
} from "react-icons/md"
import { BackendContext } from "@services/backend/backend"
import { ToastContext } from "src/context/ToastContext"
import { UserContext } from "src/context/UserContext"
import "./my-account.css"

export function MyAccount() {
  const user = useContext(UserContext)
  const backend = useContext(BackendContext)
  const toastContext = useContext(ToastContext)

  const [loggedUser, setLoggedUser] = useState(null)
  const [deleteAvatarModal, setDeleteAvatarModal] = useState(false)
  const [joinGroupModal, setJoinGroupModal] = useState(false)

  useEffect(() => {
    setLoggedUser(user.user)
    if (!user.avatar) {
      user.fetchAvatar()
    }
    // eslint-disable-next-line
  }, [user.user])

  const [mouseOverAvatar, setMouseOverAvatar] = useState(false)
  const [editName, setEditName] = useState(false)
  const [removeUserModal, setRemoveUserModal] = useState(false)
  const [removeGroupModal, setRemoveGroupModal] = useState(false)
  const [goOut, setGoOut] = useState(false)
  const [goOutOrg, setGoOutOrg] = useState(false)
  const [groupIdToGoOut, setGroupIdToGoOut] = useState(null)
  const [isGroupFormModalOpen, setIsGroupFormModalOpen] = useState(false)
  const [isGroupModalEdit, setIsGroupModalEdit] = useState(false)
  const [groupToBeEdited, setGroupToBeEdited] = useState({
    ID: "",
    Name: "",
    Cnpj: "",
    Role: "",
  })
  const [groupIdToDelete, setGroupIdToDelete] = useState("")

  const hiddenFileInputAvatar = React.useRef(null)

  const handleClickAvatar = (event) => {
    hiddenFileInputAvatar.current.click()
  }

  const handleChangeAvatarImgUpload = async (event) => {
    const file = event.target.files[0]

    if (file.size > 1000000) {
      // 1 MB
      //TODO: handle this with a modal or toast
      toastContext.addToast({
        title: 'Tamanho da imagem inválido! (Máximo até 1Mb)',
        status: 'error'
      })
      return
    }

    let headers = {}
    headers["Authorization"] = `Bearer ${backend.auth}`
    //headers['Content-Type'] = 'multipart/form-data; boundary="?"'

    var data = new FormData()
    data.append("file", file)

    let result = await fetch(
      `${process.env.REACT_APP_FILE_SERVER_ENDPOINT}/avatar`,
      {
        method: "POST",
        body: data,
        headers: headers,
      }
    )

    if (result.status === 200) {
      user.setAvatar(URL.createObjectURL(file))
      toastContext.addToast({
        title: 'Foto de perfil adicionada com sucesso!',
        status: 'success'
      })
    }
  }

  const [clickedCopy, setClickedCopy] = useState(false)

  const copyToClipboard = (id) => {
    navigator.clipboard.writeText(id)
    setClickedCopy(true)
    // toastContext.addToast({
    //   title: 'Copiado para a área de transferência.',
    // })
  }

  const onEditClick = () => {
    setEditName(true)
  }

  const onNewGroupClick = () => {
    setIsGroupModalEdit(false)
    setIsGroupFormModalOpen(true)
  }

  const onEditOrgClick = (org) => {
    setGroupToBeEdited(org)
    setIsGroupModalEdit(true)
    setIsGroupFormModalOpen(true)
  }

  const displayOrgs = () => {
    return user.group.map((gr, i) => {
      if (gr.Role === "Bloqueado") {
        gr.Role = "Esperando resposta da organização"
      }
      return (
        <div key={gr.ID}>
          {i !== 0 ? <div style={{ height: "20px" }}></div> : null}
          <Card width={"100%"}>
            {gr.Role !== "Esperando resposta da organização" && (
              <div
                style={{ display: "flex", justifyContent: "space-between" }}
                onClick={() => { }}
              >
                <div></div>
                <div style={{ display: "flex" }}>
                  <Tooltip>
                    <MdEdit
                      size={24}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        onEditOrgClick(gr)
                      }}
                    />
                    <TooltipCard up width={"130px"}>
                      Editar Dados
                    </TooltipCard>
                  </Tooltip>
                  <Tooltip>
                    <MdDelete
                      size={25}
                      onClick={() => {
                        setRemoveGroupModal(!removeGroupModal)
                        setGroupIdToDelete(gr.ID)
                      }}
                      style={{ cursor: "pointer", marginLeft: "10px" }}
                    />
                    <TooltipCard up leftValue={"10px"} width={"150px"}>
                      Excluir Organização
                    </TooltipCard>
                  </Tooltip>
                  <Tooltip>
                    <MdLogout
                      size={24}
                      onClick={() => {
                        setGoOutOrg(!goOutOrg)
                        setGroupIdToGoOut(gr.ID)
                      }}
                      style={{ cursor: "pointer", marginLeft: "10px" }}
                    />
                    <TooltipCard up leftValue={"10px"} width={"160px"}>
                      Sair desta organização
                    </TooltipCard>
                  </Tooltip>
                </div>
              </div>
            )}
            <div
              style={{ display: "flex", justifyContent: "center", gap: "10%" }}
            >
              <div style={{ width: "15%" }}>
                <h3>Nível de Acesso:</h3>
                <p style={{ marginTop: "-10px" }}>{gr.Role}</p>
              </div>
              <div style={{ width: "18%" }}>
                <h3>Identificador:</h3>
                <div
                  style={{ marginTop: "-10px", cursor: "pointer" }}
                  onMouseLeave={() => setClickedCopy(false)}
                >
                  <Tooltip>
                    {!clickedCopy ? (
                      <TooltipCard
                        up
                        rightValue={"-57px"}
                        bottomValue={"27px"}
                        width={"90px"}
                      >
                        Copiar
                      </TooltipCard>
                    ) : null}
                    <InputIconInside
                      iconRight={<MdCopyAll />}
                      onClick={() => {
                        copyToClipboard(gr.ID)
                      }}
                    >
                      <Input disabled value={gr.ID} iconInsideRight />
                    </InputIconInside>
                  </Tooltip>
                </div>
              </div>
              <div style={{ width: "15%" }}>
                <h3>Informações:</h3>
                <p style={{ marginTop: "-10px" }}>Nome: {gr.Name}</p>
                <p style={{ marginTop: "-10px" }}>
                  {gr.Cnpj ? `CNPJ: ${gr.Cnpj}` : null}
                </p>
                {/* <p style={{marginTop:'-10px'}}>Tel: (48) 996279905 </p> */}
              </div>
              <div
                /*onMouseLeave={()=>{setMouseOverLogo(false)}} onMouseEnter={()=>{setMouseOverLogo(true)}}*/ style={{
                  width: "18%",
                }}
              >
                <h3>Logo:</h3>
                {user.logo && user.logo[i] !== "icon" ? (
                  <img
                    src={user.logo[i]}
                    width={"130px"}
                    alt="group-logo"
                    style={
                      {
                        /*opacity: mouseOverLogo? 0.2: 1,*/
                        //cursor: 'pointer',
                      }
                    }
                  //onClick={handleClickLogo}
                  ></img>
                ) : (
                  <MdImageNotSupported
                    size={"70"}
                    style={{ marginTop: "-10px" }}
                  ></MdImageNotSupported>
                )}
              </div>
            </div>
          </Card>
        </div>
      )
    })
  }

  return loggedUser ? (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          paddingTop: "25px",
        }}
      >
        <Card width={"50%"}>
          <div
            style={{ display: "flex", justifyContent: "space-between" }}
            onClick={() => { }}
          >
            <a
              rel="noreferrer"
              href={
                "https://" + process.env.REACT_APP_CONTA_INTELBRAS_PORTAL_URL
              }
              target="_blank"
            >
              <img
                src="/assets/img/conta_intelbras-logo.png"
                alt="conta_intelbras-logo.png"
                style={{ borderRadius: "20px", cursor: "pointer" }}
              />
            </a>
            <div style={{ display: "flex" }}>
              <div onClick={() => {
                setRemoveUserModal(!removeUserModal)
              }} id="delete-user-btn" style={{ width: '35px', height: '30px' }}>
                <Tooltip>
                  <MdDelete
                    size={25}
                    style={{ cursor: "pointer", marginRight: "20px" }}
                  />
                  <TooltipCard up width={"130px"}>
                    Deletar usuário
                  </TooltipCard>
                </Tooltip>
              </div>
              <div id="logout-btn" onClick={() => {
                setGoOut(!goOut)
              }} style={{ width: '30px', height: '30px' }}>
                <Tooltip>
                  <MdLogout
                    size={24}
                    style={{ cursor: "pointer" }}

                  />
                  <TooltipCard up width={"130px"}>
                    Sair da conta
                  </TooltipCard>
                </Tooltip>
              </div>

            </div>
          </div>
          <div style={{ textAlign: "center" }}>
            <div className="avatar-wrapper">
              <img
                src={
                  user.avatar ? user.avatar : "/assets/img/default-avatar.png"
                }
                alt="avatar.png"
                onClick={
                  user.avatar
                    ? () => setDeleteAvatarModal(true)
                    : handleClickAvatar
                }
              ></img>
              <input
                type="file"
                ref={hiddenFileInputAvatar}
                style={{ display: "none" }}
                accept="image/jpg, image/png, image/jpeg"
                onChange={handleChangeAvatarImgUpload}
                onClick={(e) => (e.target.value = null)}
                name="file"
              />
              {user.avatar ? (
                <>
                  <div
                    id="remove-user-avatar-btn"
                    onClick={() => setDeleteAvatarModal(true)}
                    style={{ opacity: mouseOverAvatar ? 0.5 : 0 }}
                    onMouseLeave={() => {
                      setMouseOverAvatar(false)
                    }}
                    onMouseEnter={() => {
                      setMouseOverAvatar(true)
                    }}
                  ></div>
                  <MdDelete
                    className="remove-user-avatar-icon"
                    onClick={() => setDeleteAvatarModal(true)}
                    onMouseLeave={() => {
                      setMouseOverAvatar(false)
                    }}
                    onMouseEnter={() => {
                      setMouseOverAvatar(true)
                    }}
                    size={30}
                    style={{ opacity: mouseOverAvatar ? 1 : 0 }}
                  />
                </>
              ) : (
                <>
                  <div
                    onClick={() => handleClickAvatar(true)}
                    id="add-user-avatar-btn"
                    style={{ opacity: mouseOverAvatar ? 0.5 : 0 }}
                    onMouseLeave={() => {
                      setMouseOverAvatar(false)
                    }}
                    onMouseEnter={() => {
                      setMouseOverAvatar(true)
                    }}
                  ></div>
                  <MdCameraAlt
                    onClick={() => handleClickAvatar(true)}
                    onMouseLeave={() => {
                      setMouseOverAvatar(false)
                    }}
                    onMouseEnter={() => {
                      setMouseOverAvatar(true)
                    }}
                    className="add-user-avatar-icon"
                    size={30}
                    style={{ opacity: mouseOverAvatar ? 1 : 0 }}
                  />
                </>
              )}
            </div>
            <span
              style={{
                fontSize: "20px",
                display: "flex",
                justifyContent: "center",
                gap: "5px",
              }}
            >
              {loggedUser.Name}
              <Tooltip>
                <div
                  onClick={() => {
                    onEditClick()
                  }}
                  id="edit-username-btn"
                >
                  <MdEdit
                    size={15}
                    cursor="pointer"
                  />
                </div>
                <TooltipCard up width={"130px"}>
                  Editar nome de usuário
                </TooltipCard>
              </Tooltip>
            </span>
            <span> {loggedUser.Email} </span>
          </div>
        </Card>
      </div>
      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}
      >
        <CardTable
          id="card_default"
          width={"80%"}
          className={"mt-20"}
          title={"Organizações"}
        >
          <div
            style={{
              position: "absolute",
              right: "50px",
              top: "10px",
              cursor: "pointer",
            }}
            onClick={() => {
              onNewGroupClick()
            }}
          >
            <Tooltip>
              <MdAdd size={25} />
              <TooltipCard up width={"180px"}>
                Criar nova Organização
              </TooltipCard>
            </Tooltip>
          </div>
          <div
            style={{
              position: "absolute",
              right: "10px",
              top: "10px",
              cursor: "pointer",
            }}
            onClick={() => {
              setJoinGroupModal(true)
            }}
          >
            <Tooltip>
              <MdGroupAdd size={25} />
              <TooltipCard up width={"180px"}>
                Se associar a uma organização já existente
              </TooltipCard>
            </Tooltip>
          </div>
          {user.group && user.group.length > 0 ? (
            displayOrgs()
          ) : (
            <p>
              Você não possui nenhuma organização atrelada ao seu usuário, se
              associe a alguma para poder gerenciar dispostivos
            </p>
          )}
        </CardTable>
      </div>
      <GroupFormModal
        groupToBeEdited={groupToBeEdited}
        isModalOpen={isGroupFormModalOpen}
        setIsModalOpen={setIsGroupFormModalOpen}
        isEdit={isGroupModalEdit}
        groups={user.group}
      />

      <ModalEditUser
        editNameState={{ editName, setEditName }}
        loggedUserState={{ loggedUser, setLoggedUser }}
      />
      <JoinGroup modalState={{ joinGroupModal, setJoinGroupModal }} />
      <ModalRemoveUser modalState={{ removeUserModal, setRemoveUserModal }} />
      <RemoveGroup
        modalState={{ removeGroupModal, setRemoveGroupModal }}
        groupDeleteState={{ groupIdToDelete, setGroupIdToDelete }}
      />
      <ModalLogout modalState={{ goOut, setGoOut }} />

      <ModalDeleteAvatar
        modalState={{ deleteAvatarModal, setDeleteAvatarModal }}
      />
      <LeaveGroup
        modalState={{ goOutOrg, setGoOutOrg }}
        loggedUserState={{ loggedUser, setLoggedUser }}
        groupState={{ groupIdToGoOut, setGroupIdToGoOut }}
      />
      <ToastContainer toasts={toastContext.toasts} position={"bottom_right"} />
    </div>
  ) : null
}
