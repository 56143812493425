import React, { useContext } from "react"
import classNames from "classnames"
import styles from "./Content.module.css"
import { SideBarContext } from "../../context/SideBarContext"

function Content({ children }) {
  const { sideBarCollapsed } = useContext(SideBarContext)
  return (
    <div
      className={classNames([
        styles.content,
        sideBarCollapsed ? styles.content_wide : "",
      ])}
    >
      {children}
    </div>
  )
}

export default Content
