import OltManagement from "@features/devices/management/olt/olt-management"
import { OltAPI } from '@services/apis/olt/olt_api'
import Device from '@services/apis/device'


export default class OltDevice extends Device {

  constructor(device, backend) {
    super(device, backend)
    this.api = new OltAPI(backend)
  }
  getManagementPage() {
    return <OltManagement></OltManagement>
  }
  
  isDualBand() {
    return this.data.model !== 'W4-300F' && this.data.model !== 'W4-300S'
  }
}
