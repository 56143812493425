import React, { useState, useContext, useEffect, useRef } from 'react'
import { backendStatus } from '@services/backend/backend'
import Loading from '@lib/midgard/loading/loading'
import List from '@lib/midgard/list/list'
import { DeviceContext, getTraffic } from '@features/devices/management/manage-page'
// import { XYPlot, AreaSeries } from 'react-vis'
import DeviceFactory from '@features/devices/management/device-factory'

const FECTH_INTERVAL = 10000
const MAX_SAMPLES = 10
const RETRY_TIME = 5000

const protocols = {
    0: '802.11a',
    1: '802.11b',
    2: '802.11g',
    3: '802.11n',
    4: '802.11b/g/n',
    5: '802.11ac',
    6: '802.11a/n/ac',
    7: '802.11ax',
    8: '802.11a/n/ac/ax',
    9: '802.11b/g',
    10: '802.11a/n',
    11: '802.11n/ac',
    12: '802.11g/n',
}


export default function RouterDeviceList({deviceid}) {

    const [connectedDevices, setConnectedDevices] = useState(null)
    const [traffic, setTraffic] = useState({})
    const manageCtx = useContext(DeviceContext)

    const devicesTimer = useRef(null)
    const mounted = useRef(true)

    const fetchConnectedDevices = async() => {

        let result = await manageCtx.device.retrieveResource('connected_device')

        if(result.status !== backendStatus.SUCCESS){
            console.log('Error fetching connected devices:', result.content)
            if(mounted.current)
                devicesTimer.current = setTimeout(fetchConnectedDevices, RETRY_TIME)
            return
        }

        defineTraffic(result.content)
        setConnectedDevices(result.content)
    }

    useEffect(() => {

        fetchConnectedDevices()

        return () => {
            mounted.current = false
            clearTimeout(devicesTimer.current)
        }

        // eslint-disable-next-line
    }, [])

    useEffect(() => {

        if(!connectedDevices) return

        if(mounted.current)
            devicesTimer.current = setTimeout(fetchConnectedDevices, FECTH_INTERVAL)

        // eslint-disable-next-line
    }, [connectedDevices])

    const getDevice = (deviceid) => {

        if(!connectedDevices) return null
        
        for(let device of connectedDevices){
            if(device.id === deviceid)
                return device
        }
    }

    const defineTraffic = (received) => {

        for(let device of received){

            if(!(device.id in traffic)){
                traffic[device.id] = {download: 0, upload: 0}
                continue
            }

            let oldDevice = getDevice(device.id)
            if(!oldDevice) continue

            traffic[device.id].upload = getTraffic(
                device.rxBytes - oldDevice.rxBytes,
                device.unitySize,
                device.time - oldDevice.time
            )
            traffic[device.id].download = getTraffic(
                device.txBytes - oldDevice.txBytes,
                device.unitySize,
                device.time - oldDevice.time
            )

            if(!traffic[device.id].dSeries) traffic[device.id]['dSeries'] = []
            traffic[device.id].dSeries.push(traffic[device.id].download)

            if(traffic[device.id].dSeries.length > MAX_SAMPLES)
                traffic[device.id].dSeries.shift()

            if(!traffic[device.id].uSeries) traffic[device.id]['uSeries'] = []
            traffic[device.id].uSeries.push(traffic[device.id].upload)

            if(traffic[device.id].uSeries.length > MAX_SAMPLES)
                traffic[device.id].uSeries.shift()
        }

        setTraffic({...traffic})
    }

    // const getChartData = (series) => {

    //     if(!series) return []

    //     return series.map((value, index) => {
    //         return {x: index, y: value}
    //     })
    // }

    // const getChart = (deviceid, seriesField) => {
    //     return  <XYPlot height={60} width={120}>
    //                 <AreaSeries data={getChartData(traffic[deviceid][seriesField])} opacity={.2} color='green'></AreaSeries>
    //                 <AreaSeries data={getChartData(traffic[deviceid][seriesField])} color='darkcyan'></AreaSeries>
    //             </XYPlot>

    // }

    const getColumns = () => {

        let headers =  [
            {header: 'Dispositivo', size: '150px'},
            {header: 'Rede Wi-Fi', align: 'center'},
            {header: 'Endereço IP', align: 'center', size: '110px'},
            {header: 'Sinal (dBm)', align: 'center', size: '120px'},
        ]

        if (!DeviceFactory.isTR069(manageCtx.device.data.model)){
            headers.push({header: 'Link (Mbps)', align: 'center', size: '60px'})
            headers.push({header: 'Protocolo', align: 'center', size: '130px'})
            headers.push({header: 'Download (Mbps)', align: 'left', size: '150px'})
            headers.push({header: 'Upload (Mbps)', align: 'left', size: '120px'})
        }

        return headers
    }

    const getLines = () => {
        return connectedDevices.map(client => {
            let fields = [
                client.hostname || client.mac,
                client.network,
                client.ip,
                client.signalLevel,
            ]

            if (!DeviceFactory.isTR069(manageCtx.device.data.model)){
                fields.push(client.txRate.toFixed(2))
                fields.push(protocols[client.opMode])
                fields.push(
                    <div className='router-device-traffic'>
                        <span>{traffic[client.id].download.toFixed(2)}</span>
                        {/* {getChart(client.id, 'dSeries')} */}
                    </div>
                )
                fields.push(
                    <div className='router-device-traffic'>
                        <span>{traffic[client.id].upload.toFixed(2)}</span>
                        {/* {getChart(client.id, 'uSeries')} */}
                    </div>
                )
            }

            return fields
        })
    }

    return <div id='router-devices-list'>
        <div className='subtitle'>Lista de dispositivos conectados</div>

        {!connectedDevices ? <Loading show={true}></Loading> :

        <List
            columns={getColumns()}
            lines={getLines()}
        ></List>

        }
    </div>
}