export function CloseIcon(props) {
  return (
    <svg
      className="icon"
      viewBox="0 0 48 48"
      style={props.style ? props.style : {}}
    >
      <title>{props.title ? props.title : null}</title>
      <path d="M10.586 13.414l10.586 10.586-10.586 10.586c-0.782 0.782-0.782 2.048 0 2.828s2.048 0.782 2.828 0l10.586-10.586 10.586 10.586c0.782 0.782 2.048 0.782 2.828 0s0.782-2.048 0-2.828l-10.586-10.586 10.586-10.586c0.782-0.782 0.782-2.048 0-2.828s-2.048-0.782-2.828 0l-10.586 10.586-10.586-10.586c-0.782-0.782-2.048-0.782-2.828 0s-0.782 2.048 0 2.828z" />
    </svg>
  )
}
