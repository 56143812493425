import { backendStatus } from "@services/backend/backend"
import { removeCpe } from "./cpe-slice"

export const associateCpe = async (
  backend,
  user,
  toastContext,
  cpe,
  deviceFrom
) => {
  let result = await backend.create(`associate/cpe?devicefrom=${deviceFrom}`, {
    ...cpe,
    id: cpe.id,
    olt_id: cpe.olt,
    port: parseInt(cpe.port.substring(1)),
    slot: parseInt(cpe.slot),
    user: cpe.username,
    org: user.activeGroup,
  })
  if (result.status !== backendStatus.SUCCESS) {
    toastContext.addToast({
      title: result.content.body,
      status: "error",
    })
  } else {
    toastContext.addToast({
      title: "Operação efetuada com sucesso!",
      status: "success",
    })
  }
}

export const activateCpe = async (backend, toastContext, cpe) => {
  let result = await backend.create(`device/olt/ont`, {
    ...cpe,
    id: cpe.id,
    slot: parseInt(cpe.slot),
  })
  if (result.status !== backendStatus.SUCCESS) {
    toastContext.addToast({
      title: result.content.body,
      status: "error",
    })
  } else {
    toastContext.addToast({
      title: "Operação efetuada com sucesso!",
      status: "success",
    })
  }
}

export const deleteCpe = async (
  backend,
  user,
  toastContext,
  dispatch,
  olt,
  selected,
  deleteOptions
) => {
  if (deleteOptions.dissociate) {
    let result = await backend.delete(`associate/cpe?devicefrom=MQTT`, {
      id: selected.deviceid,
      org: user.activeGroup,
    })
    if (result.status !== backendStatus.SUCCESS) {
      toastContext.addToast({
        title: result.content.body,
        status: "error",
      })
    } else {
      toastContext.addToast({
        title: "CPE desassociada da organização!",
        status: "success",
      })
      dispatch(removeCpe({ id: selected.deviceid }))
    }
  }
  if (deleteOptions.disable) {
    let result = await backend.delete("associate/cpe?devicefrom=SIG", {
      id: selected.deviceid,
      gw_id: olt.gw_id,
      org: user.activeGroup,
    })
    if (result.status !== backendStatus.SUCCESS) {
      console.log(result.content)
      toastContext.addToast({
        title: result.content.body,
        status: "error",
      })
    } else {
      toastContext.addToast({
        title: "CPE desativada com sucesso!",
        status: "success",
      })
    }
  }
}

export const editCpe = async (backend, toastContext, cpe) => {
  let result = await backend.update(`device`, { ...cpe })
  if (result.status !== backendStatus.SUCCESS) {
    toastContext.addToast({
      title: result.content.body,
      status: "error",
    })
  } else {
    toastContext.addToast({
      title: "Operação efetuada com sucesso!",
      status: "success",
    })
  }
}
