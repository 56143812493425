import './device-header.css'
import DeviceFactory from '../device-factory';
export default function DeviceHeaderImage({ model }) {

  const deviceImage = DeviceFactory.getDeviceImage(model)

  return (
    <div className="device-model-info" >
      <div className='device-info-image-wrapper'>
        <img
          className={deviceImage.className}
          src={deviceImage.src}
          alt={model}
        />
      </div>
      <div>
        <div className='device-info-model-name'>
          <span className='device-info-text-intelbras'>Intelbras</span>
          <span className='device-info-text-model'>{model}</span>
        </div>
      </div>
    </div>
  );
}