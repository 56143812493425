import React, { useState, useContext, useEffect, useRef } from 'react'
import { backendStatus } from '@services/backend/backend'
import WanStatistics from './wan-statistics'
import Loading from '@lib/midgard/loading/loading'
import InfoCard from '@lib/midgard/infocard/infocard'
import { getWanModeString } from './tr069-wan-constants'
import NetworksIcon from '@lib/midgard/icons/networks-icon'
import PonIcon from '@lib/midgard/icons/pon-icon'
import { DeviceContext } from '@features/devices/management/manage-page'
import { DefaultPonStatus } from '../wan/wan-constants'
import { DefaultIpv6Prefix } from '../wan/tr069-wan-constants'
import { DefaultInterface } from '../wan/wan-constants'
import { DefaultBindLan } from '../wan/tr069-wan-constants'
import { cloneDeep } from "lodash";
import DeviceFactory from '@features/devices/management/device-factory'
import '../wan/wan.css'

const RETRY_TIME = 2000

export default function MonitoringWanData({ WanConfigurationComponent }) {

    const [wan, setWan] = useState(null)
    const [wanMode, setWanMode] = useState(null)
    const [_interface, setInterface] = useState(null)
    const [pppoe, setPPPoE] = useState(null)
    const [createWan, setCreateWan] = useState(false)

    const [ponStatus, setPonStatus] = useState(DefaultPonStatus)
    const [currentWan, setCurrentWan] = useState(null)
    const [currentInterface, setCurrentInterface] = useState(null)
    const [prefixIpv6, setIpv6Prefix] = useState(DefaultIpv6Prefix)
    const [wanLanList, setWanLanList] = useState(DefaultBindLan)
    const [wanIndex, setWanIndex] = useState(0)
    const [stopFetch, setStopFetch] = useState(false)
    const manageCtx = useContext(DeviceContext)

    const wanTimer = useRef(null)
    const pppTimer = useRef(null)
    const itfTimer = useRef(null)
    const ponTimer = useRef(null)
    const ipv6PrefixTimer = useRef(null)
    const wanLanListTimer = useRef(null)
    const mounted = useRef(true)

    useEffect(() => {
        fetchDeviceWanGroup()

        return () => {

            console.log('umounting wan', wanTimer.current)

            /* eslint-disable */
            mounted.current = false
            clearTimeout(wanTimer.current)
            clearTimeout(pppTimer.current)
            clearTimeout(itfTimer.current)
            clearTimeout(ponTimer.current)
            clearTimeout(ipv6PrefixTimer.current)
            clearTimeout(wanLanListTimer.current)
            /* eslint-enable */
        }

        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (!wan || !wan[wanIndex]) return

        if (wanMode !== wan[wanIndex].mode)
            setWanMode(wan[wanIndex].mode)

        // eslint-disable-next-line
    }, [wan])

    useEffect(() => {
        if (currentWan && currentWan[wanIndex])
            setWan(cloneDeep(currentWan))

        if (prefixIpv6 && prefixIpv6[wanIndex]) {
            setIpv6Prefix(cloneDeep(prefixIpv6))
        }

        if (currentInterface && currentInterface[wanIndex])
            setInterface(cloneDeep(currentInterface[wanIndex]))
        else
            setInterface(DefaultInterface)

        // eslint-disable-next-line
    }, [currentWan, wanIndex])

    const fetchDeviceWanGroup = async () => {
        let result = await manageCtx.device.retrieveResource('wan-group')

        if (result.status !== backendStatus.SUCCESS) {
            if (mounted.current)
                wanTimer.current = setTimeout(fetchDeviceWanGroup, RETRY_TIME)
            return
        }

        if (wanIndex >= result.content.wan.length)
            setWanIndex(0)

        //    if(wanLanIndex >= result.content.wanlanlist[0].lan_list.length)
        //      setWanLanIndex(0)

        setPPPoE(result.content.pppoe)
        setCurrentInterface(result.content.interface)
        setCurrentWan(result.content.wan)
        if (result.content.ipv6prefix)
            setIpv6Prefix(result.content.ipv6prefix)
        if (result.content.pon_status)
            setPonStatus(result.content.pon_status)
        if (result.content.wanlanlist)
            setWanLanList(result.content.wanlanlist)

    }

    const getPonDataLines = () => {

        let lines = [
            { label: 'RX Power (dBm)', value: ponStatus.rx_power },
            { label: 'TX Power (dBm)', value: ponStatus.tx_power },
            { label: 'Tensão (V)', value: ponStatus.voltage },
            { label: 'Corrente Bias (mA)', value: ponStatus.bias_current },
            { label: 'Temperatura (°C)', value: ponStatus.temperature },
        ]

        return lines
    }

    const getDataLines = () => {

        let lines = [
            { label: 'Tipo de conexão', value: getWanModeString(currentWan[wanIndex].mode) },
            { label: 'Endereço IP', value: currentInterface[wanIndex].ip4 },
            { label: 'Endereço MAC', value: currentInterface[wanIndex].mac },
            { label: 'Máscara de sub-rede', value: currentInterface[wanIndex].netmask },
            { label: 'Gateway', value: currentWan[wanIndex].gateway },
            { label: 'MTU', value: currentInterface[wanIndex].mtu },
            { label: 'DNS 1', value: currentWan[wanIndex].dns_v4.dns1 },
            { label: 'DNS 2', value: currentWan[wanIndex].dns_v4.dns2 },
            // {label: 'LAN', value: wanLanList[wanIndex].lan_list[wanLanIndex].lan_id },
            //     {label: 'Status', value: wanLanList[wanIndex].lan_list[wanLanIndex].enabled },
            //     {label: 'LAN', value: wanLanList[wanIndex].lan_list[wanLanIndex+1].lan_id },
            //     {label: 'Status', value: wanLanList[wanIndex].lan_list[wanLanIndex+1].enabled },
            //     {label: 'LAN', value: wanLanList[wanIndex].lan_list[wanLanIndex+2].lan_id },
            //     {label: 'Status', value: wanLanList[wanIndex].lan_list[wanLanIndex+2].enabled },
            //     {label: 'LAN', value: wanLanList[wanIndex].lan_list[wanLanIndex+3].lan_id },
            //     {label: 'Status', value: wanLanList[wanIndex].lan_list[wanLanIndex+3].enabled },
            //       {label: 'Status', value: wanLanList[wanIndex].lan_list.length }

        ]

        return lines
    }

    const getIP6Lines = () => {
        let lines = _interface.ip6_list.map((ip6, index) => {
            return { label: `Endereço IPv6 ${index + 1}`, value: ip6 }
        })

        lines = [...lines, ...[
            { label: 'Gateway', value: wan[wanIndex].gateway_v6 },
            { label: 'Prefixo', value: prefixIpv6[wanIndex].prefix },
            { label: 'Comprimento do Prefixo', value: prefixIpv6[wanIndex].prefix_size },
            { label: 'DNS 1', value: wan[wanIndex].dns_v6.dns1 },

        ]]

        if (wan[wanIndex].dns_v6.dns2 !== "")
            lines.push({ label: 'DNS 2', value: wan[wanIndex].dns_v6.dns2 })

        return lines

    }



    return <div className='container'>
        <div id='management-wan'>

            {!wan || wanIndex >= wan.length ? <Loading show={true}></Loading> :

                <div className='wan-flex-container'>

                    {createWan || !currentWan[wanIndex] || !currentInterface[wanIndex] || !prefixIpv6[wanIndex] ? null :
                        <div className='wan-info'>

                            <div className='subtitle'>Informações</div>

                            {/* <WanStatistics
                                deviceid={manageCtx.device.data.deviceid}
                                wan={wan[wanIndex]}
                                withIpv6={_interface.ip6_count > 0}
                                stopFetch={stopFetch}
                            ></WanStatistics> */}


                            <div className='wan-info-cards'>

                                {/* {DeviceFactory.isRFO(manageCtx.device.data?.model) ? <div className='wan-card'>
                                    <div className='dashboard-subtitle'>
                                        <PonIcon size='20'></PonIcon>
                                        <label>PON Status</label>
                                    </div>
                                    <InfoCard
                                        lines={getPonDataLines()}
                                    ></InfoCard>
                                </div> : null} */}

                                <div className='wan-card'>
                                    <div className='dashboard-subtitle'>
                                        <NetworksIcon size='20'></NetworksIcon>
                                        <label>IPv4</label>
                                    </div>

                                    <div className='wan-info-card'>
                                        <InfoCard
                                            lines={getDataLines()}
                                        ></InfoCard>
                                    </div>

                                </div>

                                {_interface.ip6_count > 0 ? <div className='wan-card'>
                                    <div className='dashboard-subtitle'>
                                        <NetworksIcon size='20'></NetworksIcon>
                                        <label>IPv6</label>
                                    </div>

                                    <InfoCard
                                        lines={getIP6Lines()}
                                    ></InfoCard>
                                </div> : null
                                }

                            </div>
                        </div>
                    }
                    <WanConfigurationComponent
                        wan={wan}
                        setWan={setWan}
                        wanIndex={wanIndex}
                        setWanIndex={setWanIndex}
                        _interface={_interface}
                        setInterface={setInterface}
                        pppoe={pppoe}
                        fetchWan={fetchDeviceWanGroup}
                        createWan={createWan}
                        setCreateWan={setCreateWan}
                        wanLanList={wanLanList}
                        setWanLanList={setWanLanList}
                        setStopFetch={setStopFetch}

                    ></WanConfigurationComponent>

                </div>

            }
        </div>
    </div>
}