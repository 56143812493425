const initialPreferences = {
    filters: {
        type: 0,
        status: "",
        query: "",
    },
    columns: [
        { field: "status", name: "Status", display: true, alignCenter: true },
        { field: "name", name: "Nome", display: true, alignCenter: true },
        { field: "id", name: "ID", display: true, alignCenter: true },
        { field: "type", name: "Tipo", display: true, alignCenter: true },
        { field: "model", name: "Modelo", display: true, alignCenter: true },
        { field: "fw_version", name: "Versão", display: true, alignCenter: true },
        { field: "protocols", name: "Protocolos", display: true, alignCenter: true },
        { field: "perfil", name: "Perfil", display: false, alignCenter: true },
        // { field: "ip", name: "Endereço IP", display: true, alignCenter: true },
        { field: "last_hello", name: "Última informação", display: true, alignCenter: true }
    ]
};
const loadPreferences = (content) => {
    try {
        const item = localStorage.getItem('preferences');
        const parsed = item ? JSON.parse(item) : initialPreferences;
        return parsed[content];
    } catch (error) {
        console.error('Error loading from localStorage:', error);
        return initialPreferences[content];
    }
};

const savePreferences = (content, value) => {
    try {
        if (typeof value !== 'undefined' && value !== null) {
            const prefs = JSON.parse(localStorage.getItem('preferences')) || initialPreferences
            prefs[content] = value
            localStorage.setItem('preferences', JSON.stringify(prefs))
        }
    } catch (error) {
        console.error('Error saving to localStorage:', error)
    }
};

export { loadPreferences, savePreferences, initialPreferences };
