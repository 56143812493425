import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "@conecte-se/ui-components-reactjs"

export const ModalConfirm = ({ close, title, message, action }) => {
  return (
    <Modal visible={true} width={"450px"}>
      <ModalHeader>
        <div style={{ fontSize: "18px", fontWeight: "bold" }}>{title}</div>
      </ModalHeader>
      <ModalBody>
        <div>
          <span>{message}</span>
        </div>
      </ModalBody>
      <ModalFooter>
        <div style={{ display: "flex" }}>
          <div className="mr-4" style={{ paddingRight: "20px" }}>
            <Button secondary onClick={() => close()}>
              Cancelar
            </Button>
          </div>
          <Button onClick={action} primary>
            Confirmar
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  )
}
