import React, { useContext, useEffect, useRef, useState } from 'react'
import { backendStatus } from '@services/backend/backend'
import Form from '@lib/midgard/form/form'
import DefaultStatusModals from '@lib/midgard/modal/default-status-modals'
import Select from '@lib/midgard/select/select'
import { isMinimalVersion } from '../bifrostHoManagePage'
import Parameterize from '@lib/midgard/utils/parameterize'
import { WanOpMode } from '../wan/wan-constants'
import { DeviceContext } from '@features/devices/management/manage-page'
import WanDHCP from './wan-dhcp'
import WanPPPoE from './wan-pppoe'
import WanStatic from './wan-static'

export default function WanConfiguration({
    wan,
    setWan,
    wanIndex,
    _interface,
    setInterface,
    pppoe,
    fetchWan
}) {

    const [lan, setLan] = useState(null)

    const [saving, setSaving] = useState(false)
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(false)
    const [localPPPoE, setLocalPPPoE] = useState(null)
    const versionCripto = [1, 30, 0]// minimum version encrypt and decrypt passwords
    const lanTimer = useRef(null)
    const mounted = useRef(true)
    const manageCtx = useContext(DeviceContext)
   

    useEffect(() => {

        fetchLan()

        return () => {
            mounted.current = false
            clearTimeout(lanTimer.current)
        }

        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if(pppoe && pppoe[wanIndex]){
            if (isMinimalVersion(manageCtx.device.data, versionCripto)) {
                pppoe[wanIndex].password = Parameterize(pppoe[wanIndex].password,true)
            }

            setLocalPPPoE(pppoe[wanIndex])
        }
            // eslint-disable-next-line
    }, [pppoe])

    const fetchLan = async() => {

        let result = await manageCtx.device.retrieveResource('lan')
        if(result.status !== backendStatus.SUCCESS){
            if(mounted.current)
                lanTimer.current = setTimeout(fetchLan, 5000)
            return
        }

        let interfaceID = result.content[0].interfaceID

        result = await manageCtx.device.retrieveResource(`interface/${interfaceID}`)

        if(result.status !== backendStatus.SUCCESS) {
            if(mounted.current)
                lanTimer.current = setTimeout(fetchLan, 5000)
            return
        }

        setLan(result.content)
    }

    const getWanModeOptions = () => {

        let options = [
            {text: 'Automático (DHCP)', value: WanOpMode.DHCP},
            {text: 'PPPoE', value: WanOpMode.PPPOE},
            {text: 'Estático', value: WanOpMode.STATIC},
        ]

        return options
    }


    const handleError = (result) => {
        console.error('Error:', result.status, result.content)
        setSaving(false)
        setError(true)
    }

    const handleResult = (result) => {

        if(result.status !== backendStatus.SUCCESS) {
            handleError(result)
            return
        }

        setSaving(false)
        setSuccess(true)

    }

    const saveWanConf = async() => {

        setSaving(true)

        let result = null

        result = await manageCtx.device.updateResource('wan', wan[wanIndex])

        if(!result || result.status !== backendStatus.SUCCESS) {
            handleError(result)
            return
        }

        if(wan[wanIndex].mode === WanOpMode.PPPOE) {
            if (isMinimalVersion(manageCtx.device.data, versionCripto)) {
                localPPPoE.password = Parameterize(localPPPoE.password)
            }
 
            if(!localPPPoE.id) {
                 result = await manageCtx.device.createResource('pppoe', localPPPoE)
            }else{
                 result = await manageCtx.device.updateResource('pppoe', localPPPoE)
            }
            if (isMinimalVersion(manageCtx.device.data, versionCripto)) {
                localPPPoE.password = Parameterize(localPPPoE.password, true)
            }
            if(result.status !== backendStatus.SUCCESS){
                handleError(result)
                return
            }

        }

        result = await manageCtx.device.updateResource(`interface`, _interface)

        if(!result || result.status !== backendStatus.SUCCESS){
            handleResult(result)
            return
        }

        result = await manageCtx.device.apply()

        handleResult(result)

    }

    const dismissModal = () => {
        setSaving(false)
        setSuccess(false)
        setError(false)
    }

    return <div className='wan-form-container'>
        <Form id='wan-configuration-form'
            submitText='Aplicar'
            onSubmit={saveWanConf}
        >

            <DefaultStatusModals
                saving={saving}
                success={success}
                error={error}
                continueFn={dismissModal}
            ></DefaultStatusModals>

            <Select id='wan-mode'
                label='Modo de operação'
                value={wan[wanIndex].mode}
                options={getWanModeOptions()}
                onChange={e => {
                    wan[wanIndex].mode = Number(e.target.value)
                    setWan([...wan])
                }}
                clearErrors={true}
            ></Select>

            {wan[wanIndex].mode === WanOpMode.DHCP && <WanDHCP
                _interface={_interface}
                setInterface={setInterface}
                wan={wan}
                wanIndex={wanIndex}
                setWan={setWan}
            ></WanDHCP>}

            {wan[wanIndex].mode === WanOpMode.PPPOE && <WanPPPoE
                _interface={_interface}
                pppoe={localPPPoE}
                setPPPoE={setLocalPPPoE}
                wan={wan}
                wanIndex={wanIndex}
                setWan={setWan}
                device={manageCtx.device}
            ></WanPPPoE>} 

            {wan[wanIndex].mode === WanOpMode.STATIC && <WanStatic
                wan={wan}
                wanIndex={wanIndex}
                setWan={setWan}
                _interface={_interface}
                setInterface={setInterface}
                lan={lan}
            ></WanStatic>}


        </Form>

    </div>


}