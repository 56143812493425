import {
  CardTable,
  LoadingCircle,
  Table,
  Tooltip,
  TooltipCard,
} from "@conecte-se/ui-components-reactjs"
import { useCallback, useContext, useEffect, useState } from "react"
import { MdDelete, MdWarning } from "react-icons/md"
import { BackendContext, backendStatus } from "@services/backend/backend"
import { UserContext } from "src/context/UserContext"
import { UserAccessLevel } from "./access/user-access-level"

export function UsersManagement() {
  const backend = useContext(BackendContext)
  const user = useContext(UserContext)

  const [members, setMembers] = useState(null)
  //const [noOrg, setNoOrg] = useState(false)

  const fetchMembers = async () => {
    let result = await backend.retrieveFresh(
      "group/" + user.activeGroup + "/members"
    )
    if (result.status !== backendStatus.SUCCESS) {
      console.error("error to fetch group members")
    } else {
      setMembers(result.content)
    }
    console.log("members:", result)
  }

  useEffect(() => {
    if (user.group != null) {
      if (user.group.length > 0) {
        if (user.activeGroup) {
          fetchMembers()
        } else {
          console.log("there is no organization")
          //setNoOrg(true)
        }
      }
    } else {
      console.log("there is no organization")
      //setNoOrg(true)
    }
    // eslint-disable-next-line
  }, [user.group, user.activeGroup])

  const removeUserGroupAssociation = async (email) => {
    let result = await backend.delete(
      "/group/" + user.activeGroup + "/" + email
    )
    if (result.status === backendStatus.SUCCESS) {
      console.log("Successfully removed user from group")
      setMembers(members.filter((item) => item.email !== email))
    } else {
      console.log("error to remove user from group:", result.content)
    }
  }

  const rows = useCallback(() => {
    const rows = []
    members.forEach((x) => {
      if (x.email !== user.user.Email) {
        rows.push({
          id: x.email,
          name: x.name,
          email: x.email,
          access_level: (
            <div style={{ position: "absolute", width: "15%" }}>
              {user.role.Group && (
                <div
                  style={{
                    position: "absolute",
                    top: "-40px",
                    right: "-40px",
                    cursor: "pointer",
                  }}
                  onClick={() => removeUserGroupAssociation(x.email)}
                >
                  <Tooltip>
                    <TooltipCard right width={"180px"}>
                      Remover usuário da organização
                    </TooltipCard>
                    <MdDelete size={20}></MdDelete>
                  </Tooltip>
                </div>
              )}
              <UserAccessLevel
                role={x.role}
                email={x.email}
                removeUserGroupAssociation={removeUserGroupAssociation}
              />
            </div>
          ),
          picture: (
            <img
              alt="spaceman"
              src="/assets/img/spaceman.png"
              style={{ borderRadius: "50%", width: "20%" }}
            ></img>
          ),
        })
      }

    })
    return rows
    // eslint-disable-next-line
  }, [members])

  const columns = [
    { field: "picture", name: "" },
    { field: "name", name: "Nome" },
    { field: "email", name: "Email" },
    { field: "access_level", name: "Nível de acesso", alignCenter: "true" },
  ]

  return user.group && user.activeGroup !== null ? (
    <div style={{ display: "flex", justifyContent: "center", padding: "20px" }}>
      <CardTable
        title={
          <div style={{ display: "flex", justifyContent: "center" }}>
            <h3>Gerenciamento de usuários</h3>
          </div>
        }
        width={"80%"}
        height={"100%"}
      >
        {members === null ? (
          <LoadingCircle
            id="loading_circle_verify_logged_in"
            value={40}
            width={100}
            height={100}
            cardWidth={"300px"}
            radius={30}
            indeterminate
          ></LoadingCircle>
        ) : null}
        {members && user.role && members.length > 1 ? (
          <div style={{ padding: "10px", margin: "auto", width: "90%" }}>
            <Table card rows={rows()} columns={columns} title={"Contas:"} />
          </div>
        ) : null}
        {members && user.role && members.length === 1 ? (
          <p>Você é o único membro desta organização</p>
        ) : null}
      </CardTable>
    </div>
  ) : (
    <div style={{ display: "flex", justifyContent: "center", padding: "20px" }}>
      <CardTable
        title={
          <div style={{ display: "flex", justifyContent: "center" }}>
            <h3>Gerenciamento de usuários</h3>
          </div>
        }
        width={"80%"}
        height={"100%"}
      >
        <div style={{ display: "flex", justifyContent: "center" }}>
          Você não está associado a uma organização
          <div style={{ margin: "3px 0 0 5px" }}>
            <MdWarning color={"orange"}></MdWarning>
          </div>
        </div>
      </CardTable>
    </div>
  )
}
