import React, { useContext, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { backendStatus } from '@services/backend/backend'
import Button from '@lib/midgard/button/button'
// import SiteSurveyLineChart from '@lib/midgard/chart/site-survey-line-chart'
import Loading from '@lib/midgard/loading/loading'
import RadioButton from '@lib/midgard/radio-button/radio'
import { DeviceContext } from '@features/devices/management/manage-page'
import SiteSurveyList from './site-survey-list'

export default function SiteSurvey() {

    const [siteSurvey, setSiteSurvey] = useState(null)
    const [is5GHz, setIs5GHz] = useState(true)

    const siteSurveyInterval = useRef(null)
    const navigate = useNavigate()
    const manageCtx = useContext(DeviceContext)


    const mounted = useRef(true)

    useEffect(() => {

        fetchSiteSurvey()

        if (!manageCtx.device.isDualBand())
            setIs5GHz(false)

        return () => {
            mounted.current = false
            clearTimeout(siteSurveyInterval.current)
        }

        // eslint-disable-next-line
    }, [])


    const fetchSiteSurvey = async() => {

        let result = await manageCtx.device.retrieveResource('site_survey?timeout=60')

        if(result.status !== backendStatus.SUCCESS){
            console.error('error fetching site_survey:', result.status, result.content)
            if(mounted.current)
                siteSurveyInterval.current = setTimeout(fetchSiteSurvey, 1000)
            return
        }

        setSiteSurvey(result.content)

    }

    const tryAgain = () => {
        setSiteSurvey(null)
        fetchSiteSurvey()
    }

    return (
    <div id='site-survey-page'>

            <Button id='wifi-ss-back' text='Voltar' onClick={() => navigate(-1)}></Button>

        <div className='subtitle'>Site Survey</div>

        {!siteSurvey && <div>
            <Loading show={true}></Loading>
            <span>Aguarde enquanto o escaneamento é realizado.</span>
        </div>}

        {siteSurvey && siteSurvey.length === 0 &&
            <div className='ss-nothing-found'>
                Nenhuma rede encontrada
                <Button id='ss-try-again' text='Tentar novamente' onClick={tryAgain}></Button>
            </div>
        }

        {siteSurvey && siteSurvey.length > 0 && <div className='wifi-ss-container'>

            {/* <SiteSurveyLineChart siteSurvey={siteSurvey} is5Ghz={is5GHz}></SiteSurveyLineChart> */}

            <div className='wifi-ss-radiobutons'>
                <div className='subtitle'>Frequências</div>
                    {manageCtx.device.isDualBand() && <RadioButton id='ss-5g' value={is5GHz} label='5 GHz' toggleFn={() => setIs5GHz(true)}></RadioButton>}
                <RadioButton id='ss-2g' value={!is5GHz} label='2.4 GHz' toggleFn={() => setIs5GHz(false)}></RadioButton>
            </div>


        </div>}

        {siteSurvey && siteSurvey.length > 0 && <SiteSurveyList networks={siteSurvey} is5GHz={is5GHz}></SiteSurveyList>}

    </div>
    )
}