import {
    Button,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
} from "@conecte-se/ui-components-reactjs"
import { connectionStatus, deviceStates } from "@features/devices/common/constants"
import { InputText } from "@features/devices/components/inputs"
import { editCpe } from "@features/devices/redux/cpe/cpe-actions"
import { getDeviceById } from "@features/devices/redux/deviceTab/device-actions"
import { setOltById } from "@features/devices/redux/olt/olt-slice"
import { BackendContext } from "@services/backend/backend"
import { useContext, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { ToastContext } from "src/context/ToastContext"
import { UserContext } from "src/context/UserContext"

const InformationOnt = ({ close, id }) => {
    const navigate = useNavigate()
    const [olt, setOlt] = useState()
    const [ont, setOnt] = useState()
    const backend = useContext(BackendContext);
    const toastContext = useContext(ToastContext);

    useEffect(() => {
        const fetchInfo = async () => {
            try {
                const ontResult = await getDeviceById(backend, toastContext, id);
                const oltResult = await getDeviceById(backend, toastContext, ontResult.olt_id);
                setOnt(ontResult);
                setOlt(oltResult);

            } catch (error) {
                console.error("Failed to fetch device info:", error);
            }
        };
        fetchInfo();
    }, [backend, toastContext]);
    const clearInputField = (property) => {
        setOnt({ ...ont, [property]: "" })
    }

    const handleChange = (e) => {
        setOnt({
            ...ont,
            [e.target.name]: e.target.value,
        })
    }

    const handleEdit = () => {
        editCpe(backend, toastContext, ont)
        close()
    }

    return (
        <Modal visible={true} width={"500px"}>
            <ModalHeader>
                <div style={{ fontSize: "18px", fontWeight: "bold" }}>
                    Informações da ONT {ont?.id}:
                </div>
            </ModalHeader>
            <ModalBody>
                <div>
                    <div className="card-info">
                        <div className="col-md-12 list-topic">
                            <div className="list-item">
                                <div className="list-topic-item">OLT vinculada:</div>
                                <div className="list-topic-item">
                                    {olt?.name === "" ? "---" : olt?.name}
                                </div>
                            </div>
                            <div className="list-item">
                                <div className="list-topic-item">Porta:</div>
                                <div className="list-topic-item">
                                    {ont?.port === "" ? "---" : ont?.port}
                                </div>
                            </div>
                            <div className="list-item">
                                <div className="list-topic-item">Slot:</div>
                                <div className="list-topic-item">
                                    {ont?.slot === "" ? "---" : ont?.slot}
                                </div>
                            </div>
                            <div className="list-item">
                                <div className="list-topic-item">Hardware:</div>
                                <div className="list-topic-item">
                                    {ont?.hw_version === "" ? "---" : ont?.hw_version}
                                </div>
                            </div>
                            <div className="list-item">
                                <div className="list-topic-item">Firmware:</div>
                                <div className="list-topic-item">
                                    {ont?.fw_version === "" ? "---" : ont?.fw_version}
                                </div>
                            </div>
                            <div className="list-item">
                                <div className="list-topic-item">Status:</div>
                                <div className="list-topic-item">
                                    {ont?.conn_status ? connectionStatus[ont?.conn_status]?.text : "---"}
                                </div>
                            </div>
                            {ont?.device_state !== 0 ?
                                <div className="list-item">
                                    <div className="list-topic-item">Estado do dispositivo:</div>
                                    <div className="list-topic-item">
                                        {connectionStatus[ont?.device_state]?.text}
                                    </div>
                                </div>
                                : null}
                            <div className="list-item">
                                <div className="list-topic-item">Rx:</div>
                                <div className="list-topic-item">
                                    {ont?.rx === "" ? "---" : ont?.rx}
                                </div>
                            </div>
                            <div className="list-item">
                                <div className="list-topic-item">Tx:</div>
                                <div className="list-topic-item">
                                    {ont?.tx === "" ? "---" : ont?.tx}
                                </div>
                            </div>
                            <div className="list-item-eth">
                                <div className="list-topic-item-eth">
                                    <h4 style={{ marginTop: "2px", marginBottom: "2px" }}>
                                        Configurações das Vlans
                                    </h4>
                                </div>
                                <div className="list-topic-item-eth">
                                    {" "}
                                    <h4 style={{ margin: "2px" }}>Eths</h4>
                                </div>
                                {Object.entries(ont?.configuration?.eth || {}).map(
                                    ([key, value]) => (
                                        <div
                                            key={key}
                                            style={{ marginLeft: "16px" }}
                                            className="list-topic-item-eth"
                                        >
                                            eth{key}: {value}
                                        </div>
                                    )
                                )}
                                {ont?.configuration.veip?.length > 0 && (
                                    <div className="list-topic-item-eth">
                                        <h4 style={{ margin: "2px" }}>Veips</h4>
                                    </div>
                                )}
                                <div
                                    className="list-topic-item-eth"
                                    style={{ marginLeft: "16px" }}
                                >
                                    {Object.values(ont?.configuration?.veip || {}).join(", ")}
                                </div>
                            </div>
                            <div>
                                <Label>Nome</Label>
                                <InputText
                                    id="description"
                                    name="description"
                                    value={ont?.description}
                                    label="Nome"
                                    onChange={handleChange}
                                    clearField={{
                                        action: clearInputField,
                                        text: "Limpar",
                                    }}
                                />
                            </div>

                            <div>
                                <Label>CTO</Label>
                                <InputText
                                    id="cto"
                                    name="cto"
                                    value={ont?.cto}
                                    label="CTO"
                                    onChange={handleChange}
                                    clearField={{
                                        action: clearInputField,
                                        text: "Limpar",
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <div style={{ display: "flex" }}>
                    <div className="mr-4" style={{ paddingRight: "20px" }}>
                        <Button secondary onClick={() => close()}>
                            Cancelar
                        </Button>
                    </div>
                    <Button
                        onClick={handleEdit}
                        primary
                    >
                        Confirmar
                    </Button>
                </div>
            </ModalFooter>
        </Modal>
    )
}
export default InformationOnt
