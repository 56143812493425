import { Card, CardTable, ToastContainer } from "@conecte-se/ui-components-reactjs"
import { CustomTable } from "@features/devices/components/custom-table"
import { retrieveVlans } from "@features/devices/redux/vlan/vlan-actions"
import { useContext, useEffect } from "react"
import { MdMediation } from "react-icons/md"
import { useDispatch, useSelector } from "react-redux"
import { DeviceContext } from '@features/devices/management/manage-page'
import { ToastContext } from "src/context/ToastContext"
import { UserContext } from "src/context/UserContext"

const Vlans = () => {
  const user = useContext(UserContext)
  const manageCtx = useContext(DeviceContext)
  const toastContext = useContext(ToastContext)

  const olt = useSelector((state) => state.tabSlice.selected);
  const vlans = useSelector((state) => state.vlansState.vlans)
  const oltId = window.location.pathname.split("/")[2]
  const dispatch = useDispatch()
  const columns = ["Interface", "Modo", "Tagged", "PVID"]

  useEffect(() => {
    if (user.activeGroup != null) {
      retrieveVlans(manageCtx.device.api, user, oltId, toastContext, dispatch)
    }
    // eslint-disable-next-line
  }, [olt.ports_E, olt.ports_10GE])

  useEffect(() => {
    if (user.activeGroup != null) {
      // getOlt()
      // retrieveVlans(manageCtx.device.api, user, oltId, toastContext, dispatch)
    }
  }, [user.activeGroup])

  return (
    <div className="container">

      <CustomTable
        columns={columns}
        data={olt?.ports_10GE?.concat(olt?.ports_G).concat(olt?.ports_E)}
        selected={olt}
        vlans={vlans}
      />
      <ToastContainer toasts={toastContext.toasts} position={"bottom_right"} />
    </div>
  )
}
export default Vlans
