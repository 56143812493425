import {
  Button,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "@conecte-se/ui-components-reactjs"
import { AddByOlt } from "@features/devices/management/cpe/add-by-olt"
import { AddByUser } from "@features/devices/management/cpe/add-by-user"
import AssociateGateway from "@features/devices/management/gateway/associate-gateway"
import AssociateOlt from "@features/devices/management/olt/associate-olt"
import { BackendContext } from "@services/backend/backend"
import { useContext, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { ToastContext } from "src/context/ToastContext"
import { UserContext } from "src/context/UserContext"
import { associateCpe } from "../../redux/cpe/cpe-actions"
import {
  associateGateway,
  retrieveGateways,
} from "../../redux/gateway/gateway-actions"
import { associateOlt } from "../../redux/olt/olt-actions"
import { DropdownScroll } from "../dropdown-scroll"
import { RadioGroupButton } from "../radio-button-group"
import { TYPE_DESCONHECIDO, TYPE_GATEWAY} from "@features/devices/common/constants"

export const AddDevice = ({ close, handleFilters, updateFilters }) => {
  const dispatch = useDispatch()
  const backend = useContext(BackendContext)
  const user = useContext(UserContext)
  const toastContext = useContext(ToastContext)
  const [device, setDevice] = useState(1)
  // const gateways = useSelector((state) => state.gatewayState.gateways)
  const devices = useSelector((state) => state.tabSlice.devices)
  const [errors, setErrors] = useState({})
  const [selected, setSelected] = useState("MQTT")
  const [gateway, setGateway] = useState({
    id: "",
    name: "",
    description: "",
  })
  const [olt, setOlt] = useState({
    gw_id: "",
    name: "",
    ip: "",
    username: "admin",
    password: "admin",
    port: 23,
  })
  const [cpe, setCpe] = useState({
    id: "",
    username: "",
    password: "",
    olt_id: "",
    port: "",
    slot: "",
    model: "",
    configuration: {
      eth: {},
      veip: [],
    },
  })

  const typeDevices = [
    { id: 0, label: "Gateway", value: 1 },
    { id: 1, label: "OLT", value: 2 },
    { id: 2, label: "ONT", value: 3 },
  ]

  const options = [
    { id: "MQTT", text: "Roteador (MQTT)" },
    { id: "SIG", text: "Via OLT" },
  ]
  const handleSelect = (item) => {
    setDevice(item.value)
    if (item.value === "olt") {
      retrieveGateways(backend, user, toastContext, dispatch)
    }
    setErrors({})
  }

  // useEffect(() => {
    // fetchOlts(backend, user, toastContext, dispatch, 1)
  // }, [])

  // function disableButton() {
  //   if (device === 1) {
  //     return !(gateway.id && gateway.name)
  //   } else if (device === 2) {
  //     return !(olt.ip && olt.name && olt.gw_id)
  //   } else if (device === 3) {
  //     if (selected === options[0].id) {
  //       return !(cpe.id !== "" && cpe.username !== "" && cpe.password !== "")
  //     }
  //     const result =
  //       cpe.id !== "" &&
  //       olt.id !== "" &&
  //       cpe.port !== "" &&
  //       cpe.slot !== "" &&
  //       cpe.model !== "" &&
  //       (Object.keys(cpe.configuration.eth).length > 0 ||
  //         cpe.configuration.veip.length > 0)

  //     return !result
  //   }
  // }
  function defaultGateway() {
    if (devices?.filter((d) => d.type === TYPE_GATEWAY).length > 0) {
      if (olt.gw_id !== "") {
        return olt.gw_id
      }
      return "Selecione"
    }
    return "Não há gateways"
  }

  const handleConfirm = () => {
    if (device === 3) {
      associateCpe(backend, user, toastContext, cpe, selected)
    } else if (device === 2) {
      associateOlt(backend, user, toastContext, dispatch, olt)
    } else if (device === 1) {
      associateGateway(backend, user, toastContext, dispatch, gateway)
    }
    updateFilters('type', device)
    close()
  }

  function clearCpe() {
    setCpe({
      id: "",
      username: "",
      password: "",
      olt_id: "",
      port: "",
      slot: "",
      model: "",
      configuration: {
        eth: {},
        veip: [],
      },
    })
  }

  function renderInputs() {
    if (device === 1) {
      return (
        <AssociateGateway
          gatewayState={{ gateway, setGateway }}
          errorState={{ errors, setErrors }}
        />
      )
    } else if (device === 2) {
      return (
        <div className="mb-3">
          <Label>Selecione o Gateway da OLT</Label>
          <DropdownScroll
            defaultItem={defaultGateway()}
            id={"gw_id"}
            items={devices
              ?.filter((device) => device.type === TYPE_GATEWAY)
              .map((device) => ({
                id: device.id,
                label: device.alias,
                value: device.id,
              }))}
            onSelect={(item) => {
              setOlt({
                ...olt,
                gw_id: item.id,
              })
            }}
          />
          <h5>
            Certifique-se de que seu Gateway está conectado e online. Se não
            estiver disponível, considere adicionar um novo dispositivo para
            continuar..
          </h5>
          {/* Mudar para TYPE_ONLINE */}
          {devices?.filter((gateway) => gateway.conn_status === TYPE_DESCONHECIDO).length &&
            olt.gw_id !== "" ? (
            <AssociateOlt
              oltState={{ olt, setOlt }}
              errorState={{ errors, setErrors }}
            />
          ) : null}
        </div>
      )
    } else if (device === 3) {
      return (
        <>
          <div className="mb-3" style={{ paddingBottom: "10px" }}>
            <Label>
              <h3>Adicionar CPE a partir de:</h3>
            </Label>
            <RadioGroupButton
              name={"option-add"}
              onChange={(e) => {
                setSelected(e.target.value)
                clearCpe()
              }}
              selected={selected}
              options={options}
            />
          </div>
          {selected === "MQTT" ? (
            <AddByUser
              cpeState={{ cpe, setCpe }}
              errorState={{ errors, setErrors }}
            />
          ) : (
            <AddByOlt
              cpeState={{ cpe, setCpe }}
              errorState={{ errors, setErrors }}
            />
          )}
        </>
      )
    }
  }
  return (
    <Modal visible={true} width={"500px"}>
      <ModalHeader>
        <div style={{ fontSize: "18px", fontWeight: "bold" }}>
          Adicionar Dispositivo
        </div>
      </ModalHeader>
      <ModalBody>
        <span>Selecione o tipo do dispositivo</span>
        <div
          className="mb-3"
          style={{
            padding: "5px 0px 15px 0px",
            borderBottom: "1px solid var(--table-card-body-border-color)",
          }}
        >
          <DropdownScroll
            id={"type-device"}
            items={typeDevices}
            defaultItem={typeDevices[0].label}
            onSelect={(item) => handleSelect(item)}
          />
        </div>
        <div style={{ paddingTop: "10px" }}>{renderInputs()}</div>
      </ModalBody>
      <ModalFooter>
        <div style={{ display: "flex" }}>
          <div className="mr-4" style={{ paddingRight: "20px" }}>
            <Button secondary onClick={close}>
              Cancelar
            </Button>
          </div>
          <Button
            // disabled={
            //   disableButton() || Object.values(errors).some((error) => error)
            // }
            onClick={handleConfirm}
            primary
          >
            Confirmar
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  )
}
