import {
  Button,
  Modal,
  ModalFooter,
  ModalHeader,
} from "@conecte-se/ui-components-reactjs"
import { useContext } from "react"
import { BackendContext } from "@services/backend/backend"
import { UserContext } from "src/context/UserContext"
import { ToastContext } from "src/context/ToastContext"

export function ModalDeleteAvatar({ modalState }) {
  const { deleteAvatarModal, setDeleteAvatarModal } = modalState
  const backend = useContext(BackendContext)
  const user = useContext(UserContext)
  const toastContext = useContext(ToastContext)
  return (
    <Modal visible={deleteAvatarModal} width={"600px"}>
      <ModalHeader>
        <div>Deseja realmente excluir sua foto de perfil?</div>
      </ModalHeader>
      <ModalFooter>
        <div style={{ display: "flex" }}>
          <div className="mr-4" style={{ paddingRight: "20px" }}>
            <Button
              secondary
              onClick={() => {
                setDeleteAvatarModal(false)
              }}
            >
              Cancelar
            </Button>
          </div>
          <Button
            id="confirm-delete-avatar-picture-btn"
            onClick={async () => {
              let headers = {}
              headers["Authorization"] = `Bearer ${backend.auth}`
              let result = await fetch(
                `${process.env.REACT_APP_FILE_SERVER_ENDPOINT}/avatar`,
                {
                  method: "DELETE",
                  headers: headers,
                }
              )
              if (result.status === 200) {
                user.setAvatar(null)
                setDeleteAvatarModal(false)
                toastContext.addToast({
                  title: 'Foto de perfil removida com sucesso!',
                  status: 'success'
                })
              }
            }}
          >
            Excluir
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  )
}
