import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  devicesField: [],
  oltsStatus: [],
  cpesStatus: [],
  notifications: [],
}

export const DashboardSlice = createSlice({
  name: "dashboardState",
  initialState: initialState,
  reducers: {
    updateDashboard(state, action) {
      const { type, payload } = action.payload
      state[type] = payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { updateDashboard } = DashboardSlice.actions

export default DashboardSlice.reducer
