import { MdSettings } from "react-icons/md"
import { CardTool } from "../card-tool"
import { ToolLink } from "../tool-link"

export function CustomFirmwareRouters() {
  return (
    <ToolLink href={"https://customize.intelbras.com.br/"}>
      <CardTool title={"Customização de firmware de roteadores residenciais"}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img
            style={{ width: "55%", height: "50%" }}
            src="/assets/img/devices/rg1200.png"
            alt="RG1200 Device"
          />
          <MdSettings
            size={70}
            color={"#00a335"}
            style={{ marginTop: "35%", marginLeft: "-14%" }}
          />
        </div>
      </CardTool>
    </ToolLink>
  )
}
