import { backendStatus } from "@services/backend/backend"
import { addOlt, setOlts } from "./olt-slice"
import { TYPE_OLT } from "@features/devices/common/constants"

export const associateOlt = async (
    backend,
    user,
    toastContext,
    dispatch,
    olt
) => {
    let result = await backend.create("device", {
        gw_id: olt.gw_id,
        alias: olt.name,
        ip: olt.ip,
        username: olt.username,
        password: olt.password,
        port: parseInt(olt.port),
        assoc: user.activeGroup,
        type: TYPE_OLT
    })
    if (result.status !== backendStatus.SUCCESS) {
        toastContext.addToast({
            title: result.content.body,
            status: "error",
        })
    } else {
        toastContext.addToast({
            title: "Nova OLT adicionada!",
            status: "success",
        })
        dispatch(addOlt(result.content))
    }
}
export const retrieveOlts = async (backend, user, toastContext, dispatch) => {
    let result = await backend.retrieveFresh(`olts/${user.activeGroup}`)
    if (result.status !== backendStatus.SUCCESS) {
        toastContext.addToast({
            title: result.content.body,
            status: "error",
        })
    } else {
        dispatch(setOlts(result.content ? result.content : []))
        console.log(result)
    }
}

export const updateOlt = async (
    backend,
    toastContext,
    olt,
    configsOperationMode,
) => {
    const updatedOlt = {
        ...olt,
        port: parseInt(olt.port),
        operation_mode: configsOperationMode.operation_mode,
        pons: configsOperationMode.pons,
        config_vlan: configsOperationMode.config_vlan,
        index_vlan: configsOperationMode.index_vlan,
        uplink_auto: configsOperationMode.uplink_auto,
    };
    let result = await backend.update(`device`, updatedOlt)
    if (result.status !== backendStatus.SUCCESS) {
        toastContext.addToast({
            title: result.content.body,
            status: "error",
        })
    } else {
        toastContext.addToast({
            title: "Operação efetuada com sucesso!",
            status: "success",
        })
    }
}

export const updateFirmwareOlt = async (backend, user, toastContext, olt) => {
    let result = await backend.create(`/update?id=` + olt.id)
    if (result.status !== backendStatus.SUCCESS) {
        toastContext.addToast({
            title: result.content.body,
            status: "error",
        })
    } else {
        toastContext.addToast({
            title: "Operação efetuada com sucesso!",
            status: "success",
        })
    }
}

export const saveOlt = async (backend, user, toastContext, olt) => {
    let result = await backend.create(`/save?id=` + olt.id)
    if (result.status !== backendStatus.SUCCESS) {
        toastContext.addToast({
            title: result.content.body,
            status: "error",
        })
    } else {
        toastContext.addToast({
            title: "Operação efetuada com sucesso!",
            status: "success",
        })
    }
}

export const fetchOlts = async (
    backend,
    user,
    toastContext,
    dispatch,
    pageNumber
) => {
    let result = await backend.retrieveFresh(
        `device/olt`
    )
    if (result.status !== backendStatus.SUCCESS) {
        toastContext.addToast({
            title: "Problema ao retornar OLTs",
            status: "error",
        })
        return 1
    }
    const data = result.content.data
    dispatch(setOlts(data.olts))
}
