import { Label } from "@conecte-se/ui-components-reactjs"
import { InputText } from "@features/devices/components/inputs"
import { requiredValue, verificaGatewayId } from "src/common/validators"
const AssociateGateway = ({ gatewayState, errorState }) => {
  const { gateway, setGateway } = gatewayState
  const { errors, setErrors } = errorState

  const handleChange = (e) => {
    setGateway({
      ...gateway,
      [e.target.name]: e.target.value,
    })
  }

  const clearInputField = (property) => {
    setGateway({ ...gateway, [property]: "" })
  }

  const handleError = (name, error) => {
    if (error) {
      setErrors({ ...errors, [name]: error })
    } else {
      const { [name]: removed, ...rest } = errors
      setErrors(rest)
    }
  }

  return (
    <>
      <div style={{ display: "flex" }}>
        <div style={{ width: "50%" }}>
          <Label>Token</Label>
          <InputText
            id="name"
            value={gateway.token}
            label="Token"
            name="token"
            // validate={{
            //   action: (e) => requiredValue(e),
            //   handleError: handleError,
            // }}
            onChange={handleChange}
            clearField={{
              action: clearInputField,
              text: "Limpar",
            }}
          />

        </div>
        <div style={{ width: "60%", marginLeft: "10px" }}>
          <Label>ID do gateway</Label>
          <InputText
            id="id"
            value={gateway.id}
            label="ID do gateway"
            length={{ max: 12 }}
            name="id"
            validate={{
              action: (e) => verificaGatewayId(e),
              handleError: handleError,
            }}
            onChange={handleChange}
            clearField={{
              action: clearInputField,
              text: "Limpar",
            }}
          />
        </div>
      </div>
      <div style={{ flexDirection: "column" }}>
        <div className="mb-3">
          <Label>Nome</Label>
          <InputText
            id="name"
            value={gateway.name}
            label="Nome"
            name="name"
            validate={{
              action: (e) => requiredValue(e),
              handleError: handleError,
            }}
            onChange={handleChange}
            clearField={{
              action: clearInputField,
              text: "Limpar",
            }}
          />
        </div>
        <div className="mb-3">
          <Label>Descrição</Label>
          <InputText
            id="description"
            name="description"
            value={gateway.description}
            label="Descrição"
            onChange={handleChange}
            clearField={{
              action: clearInputField,
              text: "Limpar",
            }}
          />
        </div>
      </div>
    </>
  )
}

export default AssociateGateway
