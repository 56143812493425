import { CardTool } from "../card-tool"
import { ToolLink } from "../tool-link"

export function AppLocal() {
  return (
    <ToolLink
      href={
        "https://downloads.remotize.intelbras.com.br/desktop-apps/linux.zip"
      }
    >
      <CardTool title={"Aplicação de perfil local (offline)"}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img
            style={{ paddingTop: "60px" }}
            src="/assets/img/localPerfil.png"
            alt="Local Perfil"
          />
        </div>
      </CardTool>
    </ToolLink>
  )
}
