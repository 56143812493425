import { deviceStates } from "@features/devices/common/constants"
import { Cell, Pie, PieChart, ResponsiveContainer } from "recharts"

export function StatusPieChart({ renderCustomizedLabel, data }) {
  const newData = data
    ? data?.map((item) => ({
        name: item.status ? deviceStates[item.status].text : "OFFLINE",
        value: item.value,
      }))
    : []

  const colors = data
    ? data?.map((item) => (item.status ? deviceStates[item.status].color : "gray"))
    : []

  return (
    <ResponsiveContainer width="100%" height="100%">
      <PieChart onClick={null}>
        <Pie
          data={newData}
          cx="50%"
          cy="50%"
          outerRadius={130}
          fill="#8884d8"
          dataKey="value"
          labelLine={false}
          label={renderCustomizedLabel}
          margin={{
            top: 5,
            right: 10,
            left: 10,
            bottom: 0,
          }}
          onClick={null}
        >
          {newData.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={colors[index % colors.length]}
              onClick={(e) => {
                console.log("goiaba")
              }}
            />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  )
}
