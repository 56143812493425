import {
  Button,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "@conecte-se/ui-components-reactjs"
import { useContext, useState } from "react"
import { BackendContext, backendStatus } from "@services/backend/backend"
import { ToastContext } from "src/context/ToastContext"
import { UserContext } from "src/context/UserContext"

export function JoinGroup({ modalState }) {
  const { joinGroupModal, setJoinGroupModal } = modalState
  const [groupId, setGroupId] = useState("")

  const backend = useContext(BackendContext)
  const toastContext = useContext(ToastContext)
  const user = useContext(UserContext)

  const handleKeyDown = (key) => {
    if (groupId.length > 0 && key === "Enter") {
      applyJoinGroupID()
    }
  }

  const applyJoinGroupID = async () => {
    let result = await backend.create("associate", groupId)
    console.log(result.content)
    console.log("groupid:", groupId)
    setGroupId("")
    if (result.status !== backendStatus.SUCCESS) {
      toastContext.addToast({
        title: result.content.body,
        status: "error",
      })
    } else {
      toastContext.addToast({
        title: result.content,
        status: "success",
      })
      setJoinGroupModal(!joinGroupModal)
    }
    user.fetchGroup()
    user.fetchLogo()
  }

  return (
    <Modal visible={joinGroupModal}>
      <ModalHeader>
        <span style={{ fontSize: "18px", fontWeight: "bold" }}>
          ID da organização
        </span>
      </ModalHeader>
      <ModalBody>
        <div
          style={{ display: "flex", justifyContent: "center" }}
          onKeyDownCapture={(e) => {
            handleKeyDown(e.key)
          }}
        >
          <div style={{ textAlign: "left", width: "95%" }}>
            <div style={{ margin: "10px" }}>
              <Label>ID:</Label>
              <Input
                id="input-1"
                placeholder={"ID único da organização"}
                onChange={(e) => {
                  setGroupId(e.target.value)
                }}
                value={groupId}
              />
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <div style={{ display: "flex" }}>
          <div className="mr-4" style={{ paddingRight: "20px" }}>
            <Button
              secondary
              onClick={() => {
                setGroupId("")
                setJoinGroupModal(false)
              }}
            >
              Cancelar
            </Button>
          </div>
          <Button
            disabled={groupId.length > 0 ? false : true}
            onClick={applyJoinGroupID}
          >
            Aplicar
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  )
}
