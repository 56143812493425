import React, { useContext, useEffect, useRef, useState } from 'react'
import { backendStatus } from '@services/backend/backend'
//simport Form from '../../../../../../components/form/form'
import Loading from '@lib/midgard/loading/loading'
import DefaultStatusModals from '@lib/midgard/modal/default-status-modals'
import { DeviceContext } from '@features/devices/management/manage-page'
import SipAccount from './fxs-sip'

import './sip.css'

export default function SipManagementPage() {

    const [fxsSip, setFxsSip] = useState(null)
    const [sipServer, setSipServer] = useState(null)
    const [sipNat, setSipNat] = useState(null)
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(false)
    const [saving, setSaving] = useState(false)
    const [sipGroup, setSipGroup] = useState(null)

    const manageCtx = useContext(DeviceContext)
    const fxsSipTimer = useRef(null)
    const mounted = useRef(true)
    console.log('SipManagementPage', manageCtx)
    useEffect(() => {

        fetchFxsSip()
        return () => {
            mounted.current = false
            clearTimeout(fxsSipTimer.current)
            //clearTimeout(wanTimer.current)
        }
        // eslint-disable-next-line
    }, [])

    const fetchFxsSip = async () => {
        console.log("fetchFxsSip")
        let result = await manageCtx.device.retrieveResource('sip')
        if (result.status !== backendStatus.SUCCESS) {
            if (mounted.current)
                fxsSipTimer.current = setTimeout(fetchFxsSip, 5000)
            return
        }

        setSipGroup(result.content)

        if (result.content.voip_proxy_server_register) {

            setSipServer(result.content.voip_proxy_server_register)
        }

        if (result.content.voip_proxy_account) {

            setFxsSip(result.content.voip_proxy_account)
        }

        if (result.content.voip_proxy_nat_transv_server) {

            setSipNat(result.content.voip_proxy_nat_transv_server)
        }

    }



    /*const handleError = (result) => {
        console.error('Error:', result.status, result.content)
        setSaving(false)
        setError(true)
    }

    const handleResult = (result) => {

        if(result.status !== backendStatus.SUCCESS) {
            handleError(result)
            return
        }

        setSaving(false)
        setSuccess(true)

    }
    const saveSip = async() => {
        setSaving(true)

        sipGroup.id = 'sip-1'

       let  result = await device.updateResource('sip',sipGroup)

        // sipNat.id = 'sipnat-1'
        // result =  device.updateResource('voip_proxy_nat_transv_server',sipNat)

        // fxsSip.id = 'sipaccount-1'
        // result = await device.updateResource('voip_proxy_account',fxsSip)


        if(result.status !== backendStatus.SUCCESS){
            setSaving(false)
            setError(true)
            return
        }
        result = await device.apply()

        handleResult(result)

        window.location.reload()

        setSaving(false)
        setSuccess(true)

    }*/

    const dismissModal = () => {
        setSuccess(false)
        setSaving(false)
        setError(false)
    }


    return <div className='container'>
        <div className='sip-management-page'>
            {/*<Form id='sip-configuration-form'
            onSubmit={saveSip}
        >*/}
            <DefaultStatusModals
                success={success}
                error={error}
                saving={saving}
                continueFn={dismissModal}
            ></DefaultStatusModals>


            {!fxsSip && !sipServer && !sipNat ? <Loading show={true}></Loading> :
                <div className='sip-info'>

                    {!fxsSip || !sipServer || !sipNat ? null :

                        <div className='sip-flex-container'>
                            <SipAccount
                                fxsSip={fxsSip}
                                sipNat={sipNat}
                                sipServer={sipServer}
                                setFxsSip={setFxsSip}
                                setSipNat={setSipNat}
                                setSipServer={setSipServer}
                                setSipGroup={setSipGroup}
                                sipGroup={sipGroup}
                            ></SipAccount>
                        </div>

                    }
                </div>
            }
            {/*</Form>*/}
        </div>
    </div>


}