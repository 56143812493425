import {Devices} from '../devices-page'
import { DefaultAPI } from '@services/apis/default/default_api'
import Device from "@services/apis/device"



export default class DefaultDevice extends Device {

  constructor(device, backend) {
    super(device, backend)
    this.api = new DefaultAPI(backend)
  }
  getManagementPage() {
    return <Devices></Devices>
  }
  
  isDualBand() {
    return this.data.model !== 'W4-300F' && this.data.model !== 'W4-300S'
  }
}
