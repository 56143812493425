import {
  Button,
  ButtonTextOutside,
  CardTable,
  Checkbox,
  DatePicker,
  Dropdown,
  DropdownMenu,
  Input,
  InputHelper,
  InputIconInside,
  InputWrapper,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
  TimePicker,
  ToastContainer,
  Tooltip,
  TooltipCard,
} from "@conecte-se/ui-components-reactjs"
import { setHours, setMinutes } from "date-fns"
import { useCallback, useEffect, useState } from "react"
import {
  MdArrowBack,
  MdArrowDropDown,
  MdArrowDropUp,
  MdDone,
  MdFilterAlt,
  MdHelpOutline,
  MdOutlineViewWeek,
} from "react-icons/md"
import { useNavigate } from "react-router-dom"
import { formatDate } from "src/common/validators"

import "./update-flow.css"

export function UpdateFlow() {
  const elements = document.getElementsByClassName("_2gLuB")
  elements.innerHTML = `<p>oi<p>`
  console.log(elements)

  const navigate = useNavigate()

  // const [continueModal, setContinueModal] = useState(false)

  const [flowName, setFlowName] = useState("")

  const [searchStatusExpanded, setSearchStatusExpanded] = useState(false)
  // const [searchStatus, setSearchStatus] = useState("Selecione")

  function toggleSearchStatus() {
    setSearchStatusExpanded(() => !searchStatusExpanded)
  }

  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  function onChangeRange(dates) {
    const [start, end] = dates
    setStartDate(start)
    setEndDate(end)
  }

  const search_model = [
    {
      label: "Selecione",
      handler: () => {
        setSearchModel("Selecione")
        toggleSearchStatus()
      },
    },
    {
      label: "W5 1200F",
      handler: () => {
        setSearchModel("W5 1200F")
        toggleSearchStatus()
      },
    },
    {
      label: "WiFiber 121 AC",
      handler: () => {
        setSearchModel("WiFiber 121 AC")
        toggleSearchStatus()
      },
    },
    {
      label: "WiFiber 1200 R",
      handler: () => {
        setSearchModel("WiFiber 1200 R")
        toggleSearchStatus()
      },
    },
  ]

  const search_perfil = [
    {
      label: "Selecione",
      handler: () => {
        setSearchPerfil("Selecione")
        toggleSearchStatus()
      },
    },
    {
      label: "Pombonet",
      handler: () => {
        setSearchPerfil("Pombonet")
        toggleSearchStatus()
      },
    },
    {
      label: "Jericoacoara",
      handler: () => {
        setSearchPerfil("Jericoacoara")
        toggleSearchStatus()
      },
    },
  ]

  const [searchModelExpanded, setSearchModelExpanded] = useState(false)
  const [searchModel, setSearchModel] = useState("Selecione")

  const [searchPerfilExpanded, setSearchPerfilExpanded] = useState(false)
  const [searchPerfil, setSearchPerfil] = useState("Selecione")

  function toggleSearchPerfil() {
    setSearchPerfilExpanded(() => !searchPerfilExpanded)
  }

  function toggleSearchModel() {
    setSearchModelExpanded(() => !searchModelExpanded)
  }

  const columnsDynamicallyRemoved = [
    { field: "model", name: "Modelo", display: true, alignCenter: true },
    {
      field: "deviceid",
      name: "Dispositivo",
      display: true,
      alignCenter: true,
    },
    { field: "fw_version", name: "Firmware", display: true, alignCenter: true },
    {
      field: "assoc_time",
      name: "Associado em",
      display: true,
      alignCenter: true,
    },
    // { field: "first_hello", name: "Primeira resposta", display: true },
    // { field: "last_hello", name: "Última resposta", display: true },
    { field: "client", name: "Cliente", display: true, alignCenter: true },
    { field: "perfil", name: "Perfil", display: true, alignCenter: true },
    { field: "perfil", name: "Perfil", display: true, alignCenter: true },
  ]

  const [expandedCombobox, seteEpandedCombobox] = useState(false)

  function changeExpandedCombobox() {
    seteEpandedCombobox(!expandedCombobox)
  }

  function updateColumns(field) {
    return columnsDynamically.map((item) => {
      if (item.field === field) {
        const itemLocal = item
        itemLocal.display = !item?.display
        return itemLocal
      }
      return item
    })
  }

  function updateCombobox(field) {
    return comboxDynamicallyCol.map((item) => {
      if (item.id === field) {
        const itemLocal = item
        itemLocal.checkbox.checked = !item.checkbox.checked
        return itemLocal
      }
      return item
    })
  }

  const [columnsDynamically, setColumnsDynamically] = useState(
    columnsDynamicallyRemoved
  )

  // function formatDate(string) {
  //   var options = { year: "numeric", month: "short", day: "numeric" };
  //   return new Date(string).toLocaleDateString([], options);
  // }


  const rowsDynamicallyRemoved = [
    {
      id: 1,
      model: (
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <img
            alt="WiFiber"
            style={{ width: "50px" }}
            src="/assets/img/devices/WiFiber 1200 R.png"
          />
          <span>WiFiber 1200 R</span>
        </div>
      ),
      deviceid: <div>ITBS12345678</div>,
      fw_version: "1.1-220624",
      assoc_time: formatDate("2022-06-24T16:00:46.204Z"),
      // first_hello: "2022-06-24T16:00:46.204Z",
      // last_hello: "2022-07-05T16:32:41.518Z",
      client: <div>José da Rosa</div>,
      perfil: <div>Pombonet</div>,
      //   actions: (<div style={{ cursor: 'pointer', height: '19px' }}>
      //   <MdLogin onClick={()=>console.log("entrar no produto")} size={20} style={{ cursor: 'pointer', paddingRight: '25px' }} />
      //   <MdBuild  onClick={()=>console.log("acessar via OLT")} size={18} style={{ cursor: 'pointer', paddingRight: '25px'}}/>
      //   <MdDelete onClick={()=>console.log("remover produto")} size={20} style={{ cursor: 'pointer'}}/>
      // </div>),
      checked: false,
    },
    {
      id: 2,
      deviceid: "443b325b6de1",
      model: (
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <img
            alt="W5-1200F"
            style={{ width: "50px" }}
            src="/assets/img/devices/W5-1200F.png"
          />
          <span>W5-1200F</span>
        </div>
      ),
      fw_version: "1.3.28",
      assoc_time: formatDate("2022-06-17T13:50:18.463Z"),
      // first_hello: "2022-07-21T03:05:16.093Z",
      // last_hello: "2022-07-21T03:05:16.093Z",
      client: <div>Romildo Souza</div>,
      perfil: <div>Jericoacara</div>,
      //   actions: (<div style={{ cursor: 'pointer', height: '19px' }}>
      //   <MdLogin onClick={()=>console.log("entrar no produto")} size={20} style={{ cursor: 'pointer', paddingRight: '25px' }} />
      //   <MdBuild onClick={()=>console.log("acessar produto via OLT")} size={18} style={{ cursor: 'pointer',paddingRight: '25px'  }} />
      //   <MdDelete onClick={()=>console.log("remover produto")} size={20} style={{ cursor: 'pointer', }} />
      // </div>),
      checked: false,
    },
    {
      id: 3,
      deviceid: "00e0f74b6d00",
      model: (
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <img
            alt="EG8145V5"
            style={{ width: "50px" }}
            src="/assets/img/devices/EG8145V5.png"
          />
          <span>EG8145V5</span>
        </div>
      ),
      fw_version: "1.1-220624",
      assoc_time: formatDate("2022-06-17T13:50:18.463Z"),
      // first_hello: "2022-07-21T03:05:16.093Z",
      // last_hello: "2022-07-21T03:05:16.093Z",
      client: <div>Elaine Aparecida</div>,
      perfil: <div>Bangu</div>,
      //   actions: (<div style={{ cursor: 'pointer', height: '19px' }}>
      //   <MdLogin onClick={()=>console.log("entrar no produto")} size={20} style={{ cursor: 'pointer', paddingRight: '25px' }} />
      //   <MdBuild onClick={()=>console.log("acessar produto via OLT")} size={18} style={{ cursor: 'pointer',paddingRight: '25px'  }} />
      //   <MdDelete onClick={()=>console.log("remover produto")} size={20} style={{ cursor: 'pointer', }} />
      // </div>),
      checked: false,
    },
    {
      id: 4,
      deviceid: "ITBS28955678",
      model: (
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <img
            alt="wifiber-ax-1800-front"
            style={{ width: "50px" }}
            src="/assets/img/devices/wifiber-ax-1800-front.png"
          />
          <span>WiFiber AX 1800</span>
        </div>
      ),
      fw_version: "2.3-221122",
      assoc_time: formatDate("2021-02-19T13:50:18.432Z"),
      // first_hello: "2022-07-21T03:05:16.093Z",
      // last_hello: "2022-07-21T03:05:16.093Z",
      client: <div>Romildo Souza</div>,
      perfil: <div>Jericoacara</div>,
      //   actions: (<div style={{ cursor: 'pointer', height: '19px' }}>
      //   <MdLogin onClick={()=>console.log("entrar no produto")} size={20} style={{ cursor: 'pointer', paddingRight: '25px' }} />
      //   <MdBuild onClick={()=>console.log("acessar produto via OLT")} size={18} style={{ cursor: 'pointer',paddingRight: '25px'  }} />
      //   <MdDelete onClick={()=>console.log("remover produto")} size={20} style={{ cursor: 'pointer', }} />
      // </div>),
      checked: false,
    },
  ]

  const [checked, setChecked] = useState(false)
  const [rowsWithCheck, setRowsWithCheck] = useState(rowsDynamicallyRemoved)
  const [checkAllState, setCheckAllState] = useState(false)

  function onCheckAll() {
    setChecked(!checked)
    setCheckAllState(!checkAllState)
    setRowsWithCheck(
      rowsWithCheck.map((row) => {
        row.checked = !checkAllState
        return row
      })
    )
  }

  const [modalFilterOpened, setModalFilterOpened] = useState(false)
  function onClickModalFilter() {
    console.log("goiaba azeda")
    setModalFilterOpened(!modalFilterOpened)
  }

  function onCheck(changedRow, index) {
    console.log(`Index changed: ${index}`)
    setRowsWithCheck(
      rowsWithCheck.map((row) =>
        row?.id === changedRow?.id ? changedRow : row
      )
    )
  }

  function onCheckRemoveCol(field) {
    const action = columnsDynamically.filter((col) => col.field === field)[0]
      .display

    const numDisplayed = columnsDynamically.filter((col) => col.display).length

    if (action && numDisplayed === 1) {
      return
    }

    setColumnsDynamically(updateColumns(field))
    setComboxDynamicallyCol(updateCombobox(field))
  }

  const menu_itens = columnsDynamicallyRemoved.map((col) => {
    return {
      label: col?.name,
      id: col?.field,
      checkbox: {
        checked: col?.display,
        onChange: () => {
          onCheckRemoveCol(col?.field)
        },
      },
    }
  })

  const [startDateTime, setStartDateTime] = useState(
    setHours(setMinutes(new Date(), 30), 0)
  )
  const [endDateTime, setEndDateTime] = useState(
    setHours(setMinutes(new Date(), 30), 4)
  )

  function onchangeStartTime(date) {
    if (!Array.isArray(date)) {
      setStartDateTime(date)
    }
  }

  function onChangeEndTime(date) {
    setEndDateTime(date)
  }

  const [toasts, setToasts] = useState([])
  const [position/*, setPosition*/] = useState("bottom_right")

  const removeToast = useCallback(
    (idClicked) => {
      setToasts((prevToasts) =>
        prevToasts.filter((toast) => toast.idToast !== idClicked)
      )
    },
    [setToasts]
  )

  useEffect(() => {
    const interval = setInterval(() => {
      if (toasts.length) {
        removeToast(toasts[0].idToast)
      }
    }, 3000)
    return () => {
      clearInterval(interval)
    }
  }, [toasts, removeToast])

  const [comboxDynamicallyCol, setComboxDynamicallyCol] = useState(menu_itens)

  const [invalidInput, setInvalidInput] = useState(false)
  const [validInput, setValidInput] = useState(false)

  const [applyModal, setApplyModal] = useState(false)

  function addToast(newToast) {
    newToast.idToast = `toast-${Date.now()}-${Math.random()}`
    setToasts((oldArray) => [...oldArray, newToast])
  }

  const applyNewFlow = () => {
    if (flowName.length < 1) {
      setInvalidInput(true)
      return
    }
    let thereAreSelectedDevices = false
    for (let i = 0; i < rowsWithCheck.length; i++) {
      if (rowsWithCheck[i].checked) {
        thereAreSelectedDevices = true
        break
      }
    }
    if (!thereAreSelectedDevices) {
      addToast({
        title: "É preciso adicionar dispositivos para serem atualizados",
        status: "alert",
      })
      return
    }
    setApplyModal(true)
  }

  const [checkedSeeOnlySelected /*, setCheckedSeeOnlySelected*/] = useState(false)

  function seeOnlyselected() {
    //TODO: Mostrar apenas CPEs selecionadas
  }

  return (
    <div className="padding-20" style={{ height: "95%" }}>
      <div style={{ display: "flex", justifyContent: "center" }}>
        {/* <Card
            id="card_table_buttons"
            title={<h3>Escolha os dispositivos que você deseja atualizar</h3>}
            width="50%"
            height="27.5%"
            settingsButtons
            firstButton
            lastButton
        >
        <div style={{maxWidth:'300px',margin:'auto'}}>
          <Label>Nome</Label>
          <div style={{display:'flex',justifyContent:'center', maxWidth:'300px', margin:'auto'}}>
            <Input
              id="input-6"
              placeholder="Nome do fluxo de atualização"
              onChange={() => {}}
            />
          </div>
        </div>
        </Card> */}
      </div>
      <div style={{ height: "10px" }}></div>
      <CardTable
        id="card_table_buttons"
        title={<h3>Novo Fluxo de Atualização</h3>}
        width="100%"
        height="100%"
        settingsButtons
        firstButton
        lastButton
      >
        <InputWrapper invalid={invalidInput} valid={validInput}>
          <div
            style={{ maxWidth: "300px", margin: "auto", marginBottom: "20px" }}
          >
            <Input
              id="input-6"
              placeholder="Nome do fluxo de atualização"
              onChange={(e) => {
                setFlowName(e.target.value)
                if (invalidInput) {
                  setInvalidInput(false)
                  setValidInput(true)
                }
              }}
              value={flowName}
            />
            {invalidInput && (
              <InputHelper value="Dê um nome para o novo fluxo" />
            )}
          </div>
        </InputWrapper>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginLeft: "30px",
          }}
        >
          <TimePicker
            maxWidth={"175px"}
            onChangeStartTime={onchangeStartTime}
            onChangeEndTime={onChangeEndTime}
            selectedStartTime={startDateTime}
            selectedEndTime={endDateTime}
            isStartEndTime
            style={{ textAlign: "center" }}
            //withPortal
          />
          <Tooltip>
            <div style={{ marginLeft: "30px", marginTop: "9px" }}></div>
            <MdHelpOutline size={18} color={"gray"} />
            <TooltipCard up leftValue="8px" bottomValue="25px" width={"150px"}>
              Janela de tempo onde acontecerão as atualizações
            </TooltipCard>
          </Tooltip>
        </div>
        <div style={{ display: "flex" /*marginBottom:'0px'*/ }}>
          <div
            style={{
              display: "block",
              marginLeft: "17px",
              alignItems: "center",
            }}
          >
            <div style={{ display: "flex" }}>
              <Checkbox checked={checked} onChange={onCheckAll} />
              <span style={{ marginLeft: "10px" }}>Selecionar Todos</span>
            </div>
            <div style={{ height: "15px" }}></div>
            <div style={{ display: "flex" }}>
              <Checkbox
                checked={checkedSeeOnlySelected}
                onChange={seeOnlyselected}
              />
              <span style={{ marginLeft: "10px" }}>
                Visualizar apenas selecionados
              </span>
            </div>
          </div>
          <div style={{ marginRight: "50px", marginLeft: "auto" }}>
            <ButtonTextOutside value="Filtrar">
              <Button
                rounded
                icon={<MdFilterAlt />}
                secondary
                onClick={onClickModalFilter}
              />
            </ButtonTextOutside>
          </div>
        </div>
        <div
          style={{
            marginLeft: "auto",
            marginRight: "0",
            width: "fit-content",
          }}
        >
          <Dropdown id="combobox" width="fit-content" right="0">
            <MdOutlineViewWeek
              size={20}
              style={{ cursor: "pointer" }}
              onClick={changeExpandedCombobox}
            />
            <DropdownMenu
              visible={expandedCombobox}
              itens={comboxDynamicallyCol}
              width="100%"
              right="0"
            />
          </Dropdown>
        </div>
        <Table
          columns={columnsDynamically}
          rows={rowsWithCheck}
          onCheck={onCheck}
          checkable
        />
        <div
          style={{ display: "flex", padding: "20px", justifyContent: "center" }}
        >
          <Button
            secondary
            icon={<MdArrowBack />}
            onClick={() => {
              navigate(-1)
            }}
          >
            Voltar
          </Button>
          <div style={{ padding: "10px" }}></div>
          <Button hasIconRight icon={<MdDone />} onClick={applyNewFlow}>
            Aplicar
          </Button>
        </div>
      </CardTable>
      <Modal visible={modalFilterOpened} width={"600px"}>
        <ModalHeader>
          <div style={{ fontSize: "18px", fontWeight: "bold" }}>
            Filtrar por:
          </div>
        </ModalHeader>
        <ModalBody>
          <div className="mb-3" style={{ display: "flex" }}>
            <div style={{ display: "block" }}>
              <Label>Modelo</Label>
              <Dropdown id="selectbox">
                <InputIconInside
                  expandable
                  onClick={toggleSearchModel}
                  iconRight={
                    searchModelExpanded ? (
                      <MdArrowDropUp onClick={toggleSearchModel} />
                    ) : (
                      <MdArrowDropDown onClick={toggleSearchModel} />
                    )
                  }
                >
                  <Input
                    id="input_selectbox"
                    value={searchModel}
                    iconInsideRight
                  />
                </InputIconInside>
                <DropdownMenu
                  visible={searchModelExpanded}
                  itens={search_model}
                  width="100%"
                />
              </Dropdown>
            </div>
            <div style={{ display: "block", paddingLeft: "30px" }}>
              <Label>Perfil</Label>
              <Dropdown id="selectbox">
                <InputIconInside
                  expandable
                  onClick={toggleSearchPerfil}
                  iconRight={
                    searchPerfilExpanded ? (
                      <MdArrowDropUp onClick={toggleSearchPerfil} />
                    ) : (
                      <MdArrowDropDown onClick={toggleSearchPerfil} />
                    )
                  }
                >
                  <Input
                    id="input_selectbox_perfil"
                    value={searchPerfil}
                    iconInsideRight
                  />
                </InputIconInside>
                <DropdownMenu
                  visible={searchPerfilExpanded}
                  itens={search_perfil}
                  width="100%"
                />
              </Dropdown>
            </div>
          </div>
          <div className="mb-3">
            <Label>Firmware</Label>
            <Input
              id="input-6"
              placeholder="Versão de firmware"
              onChange={() => {}}
            />
          </div>
          <div className="mb-3">
            <Label>Dispositivo</Label>
            <Input id="input-6" placeholder="Mac da Lan" onChange={() => {}} />
          </div>
          <div className="mb-3">
            <Label>Cliente</Label>
            <Input
              id="input-6"
              placeholder="Nome do Cliente"
              onChange={() => {}}
            />
          </div>
          <div className="mt-5">
            <Label>Data de Associação</Label>
            <DatePicker
              selected={endDate}
              startDate={startDate}
              endDate={endDate}
              onChange={onChangeRange}
              maxWidth={"400px"}
              monthsShown={1}
              selectsRange
              disabledKeyboardNavigation
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <div style={{ display: "flex" }}>
            <div className="mr-4" style={{ paddingRight: "20px" }}>
              <Button secondary onClick={onClickModalFilter}>
                Cancelar
              </Button>
            </div>
            <Button primary>Aplicar</Button>
          </div>
        </ModalFooter>
      </Modal>
      {/* <Modal visible={continueModal} width={'700px'}>
          <ModalHeader>
            <div style={{ fontSize: '18px', fontWeight: 'bold',textAlign:'center' }}>
              Escolha a janela de tempo em que as atualizações devem ocorrer
            </div>
          </ModalHeader>
          <ModalBody height={'300px'}>
            <div className="mt-5" style={{display:'flex',justifyContent:'center'}}>
            <TimePicker
              maxWidth={'175px'}
              onChangeStartTime={onchangeStartTime}
              onChangeEndTime={onChangeEndTime}
              selectedStartTime={startDateTime}
              selectedEndTime={endDateTime}
              isStartEndTime
              style={{textAlign:'center'}}
              withPortal
              //withPortal
          />
          </div>
          </ModalBody>
          <ModalFooter>
            <div style={{ display: 'flex' }}>
              <div className="mr-4"  style={{paddingRight:'20px'}}>
                <Button secondary onClick={()=>{setContinueModal(false)}}>
                  Cancelar
                </Button>
              </div>
              <Button primary>Aplicar</Button>
            </div>
          </ModalFooter>
        </Modal> */}
      <Modal visible={applyModal} width={"530px"}>
        <ModalHeader>
          <div
            className="mb-2"
            style={{ fontSize: "18px", fontWeight: "bold" }}
          >
            Atenção:
          </div>
          <div style={{ fontSize: "14px" }}>
            Você tem certeza que deseja criar um novo fluxo de atualização?
          </div>
        </ModalHeader>
        <ModalFooter>
          <div style={{ display: "flex" }}>
            <div className="mr-4" style={{ paddingRight: "20px" }}>
              <Button
                secondary
                onClick={() => {
                  setApplyModal(false)
                }}
              >
                Cancelar
              </Button>
            </div>
            <Button primary onClick={() => navigate("/update-devices")}>
              Concluir
            </Button>
          </div>
        </ModalFooter>
      </Modal>
      <ToastContainer toasts={toasts} position={position} />
    </div>
  )
}
