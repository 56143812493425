import React, { useContext } from "react"
import classNames from "classnames"
import styles from "./Dropdown.module.css"
import {
  MdCorporateFare,
  MdImageNotSupported
} from "react-icons/md"
import { UserContext } from "../../context/UserContext"

function DropdownGroup() {
  const user = useContext(UserContext)

  const changeActiveGroup = (id) => {
    if (id !== user.activeGroup) {
      user.setActiveGroup(id)
      user.fetchRole(id)
      localStorage.setItem("active_group", id)
      console.log(user.activeGroup)
    }
  }

  return user.group && user.activeGroup ? (
    <div style={{ position: "relative", display: "flex", marginRight:"30px" }}>

      <div className={classNames([styles.dropdown])}>
        <div>
          <span className={classNames([styles.dropdown_trigger])}>
            <MdCorporateFare />
            {user.logo &&
              user.group.map((gr, i) => {
                if (gr.ID === user.activeGroup) {
                  return (
                    <p>{user.group[i].Name}</p>
                  )
                }
                return (
                  <></>
                )
              })}
           
            {user.logo &&
              user.group.map((gr, i) => {
                if (gr.ID === user.activeGroup && user.logo[i] !== "icon") {
                  return (
                    <div style={{ height: '25px' }}>
                      <img
                        alt="logo"
                        style={{ height: '100%' }}
                        src={user.logo[i]}
                      ></img>
                    </div>

                  )
                }
                return (<></>)
              })}
          </span>
          <div className={classNames([styles.dropdown_content])}>
            <div className={classNames([styles.dropdown_content_header])}>
              <span className={classNames([styles.dropdown_header_title])}>
                Organizações
              </span>
            </div>
            <div>
              <ul
                className={classNames([styles.dropdown_content_body_list])}
                style={{ width: "100%" }}
              >
                {user.group.map((gr, i) => {
                  if (
                    gr.Role === "Bloqueado" ||
                    gr.Role === "Esperando resposta da organização"
                  ) {
                    console.log(
                      "must not display group as available to choose, you're a blocked member"
                    )
                    return null
                  }
                  return (
                    <li
                      key={i}
                      className={classNames([
                        styles.dropdown_content_body_list_item,
                        gr.ID === user.activeGroup ? styles.dropdown_group_item_active : '',
                      ])}

                      onClick={() => {
                        changeActiveGroup(gr.ID)
                      }}
                    >
                      <div
                        className={classNames([
                          styles.dropdown_content_body_list_item_img,
                        ])}
                      >
                        {user.logo && user.logo[i] !== "icon" ? (
                          <img alt="img" src={user.logo[i]} width={"100%"}></img>
                        ) : (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              width: "100%",
                            }}
                          >
                            <MdImageNotSupported
                              size={30}
                              color={"black"}
                            ></MdImageNotSupported>
                          </div>
                        )}
                      </div>
                      <div>
                        <span
                          className={classNames([
                            styles.dropdown_content_body_list_item_title,
                          ])}
                        >
                          {gr.Name}
                        </span>
                        <p
                          className={classNames([
                            styles.dropdown_content_body_list_item_description,
                          ])}
                        >
                          CNPJ: {gr.Cnpj}
                        </p>
                        <span
                          className={classNames([
                            styles.dropdown_content_body_list_item_description,
                          ])}
                        >
                          Privilégio: {gr.Role}
                        </span>
                      </div>
                      <div
                        className={classNames([
                          styles.dropdown_content_body_list_item_actions,
                        ])}
                      ></div>
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
        </div>
      </div >
    </div >
  ) : null
}

export { DropdownGroup }
